const calculateItemSize = (stageSize, itemSize) => {
  const dimensionRate = itemSize.width / itemSize.height;
  const newWidth = stageSize.width / 2;
  const newHeight = newWidth / dimensionRate;
  return { width: newWidth, height: newHeight };
};
const calculateItemCoordinates = (stageSize, itemSize) => {
  const x = stageSize.width / 2 - itemSize.width / 2;
  const y = stageSize.height / 2 - itemSize.height / 2;
  return { x, y };
};

const getImageProps = ({
 image, stageSize, imageSize, xmlType,
}) => {
  const { width, height } = calculateItemSize(stageSize, imageSize);
  const { x, y } = calculateItemCoordinates(stageSize, { width, height });
  return {
    url: image,
    width,
    height,
    x,
    y,
    type: 'IMAGE',
    name: 'object',
    draggable: true,
    xmlType,
  };
};

export default getImageProps;

import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { Body3 } from 'components/Shared';

const ImagePicker = () => {
  const drop = useRef(null);
  const inputRef = useRef(null);
  const [files, setFiles] = useState(null);

  const handleDragOver = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const handleDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();

    const { files } = e.dataTransfer;

    if (files && files.length) {
      setFiles(files);
    }
  };

  const onFileChange = (e) => {
    if (e.target?.files) {
      setFiles(e.target.files);
    }
  };

  useEffect(() => {
    drop.current?.addEventListener('dragover', handleDragOver);
    drop.current?.addEventListener('drop', handleDrop);

    return () => {
      drop.current?.removeEventListener('dragover', handleDragOver);
      drop.current?.removeEventListener('drop', handleDrop);
    };
  }, [files]);

  return (
    <ImagePickerContainer
      ref={drop}
      isEmpty={!(files && files.length > 0)}
      onClick={() => inputRef.current.click()}
    >
      {files && files.length > 0 ? (
        <img src={URL.createObjectURL(files[0])} alt="img-picker" />
      ) : (
        <Body3 style={{ color: '#7F7F7F', textAlign: 'center' }}>
          Drag and
          <br />
          drop a file here
          <br />
          or
          <br />
          click here
        </Body3>
      )}
      <SecretInput ref={inputRef} type="file" onChange={onFileChange} />
    </ImagePickerContainer>
  );
};

export default ImagePicker;

const ImagePickerContainer = styled.div`
  border-radius: 8px;
  border: ${(props) => (props.isEmpty ? '1px solid #675EE0' : '1px solid #FFF')};
  background: #fafafa;
  width: 120px;
  height: 120px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  overflow: hidden;

  img {
    width: 100%;
    border-radius: 8px;
  }
`;
const SecretInput = styled.input`
  display: none;
`;

import React, { useRef, useState } from 'react';
import styled from 'styled-components';
import { useClickAway } from 'react-use';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faChevronDown, faPencil, faPlus} from '@fortawesome/pro-light-svg-icons';
import {Subtitle1, Subtitle3} from 'components/Shared';

const BrandListSelect = ({
 options, title, onChange, value,
}) => {
  const [open, setOpen] = useState(false);
  const [addNew, setAddNew] = useState(false);
  const [newItem, setNewItem] = useState(null);

  const selectRef = useRef(null);

  useClickAway(selectRef, () => {
    setOpen(false);
  });

  return (
    <>
      {title === 'Brand Industry' && !value && !open ? (
        <Subtitle1
          style={{
            textAlign: 'center',
            color: '#7F7F7F',
            cursor: 'pointer',
            width: '100%',
          }}
          onClick={() => setOpen(true)}
        >
          Choose Brand Industry
        </Subtitle1>
      ) : (
        <Select isOpen={open} onClick={() => setOpen(!open)} ref={selectRef}>
          <Container isOpen={open}>
            {addNew ? (
              <InputContainer>
                <Input
                  placeholder="Paste or write url "
                  onChange={(e) => {
                    onChange(e.target.value);
                    setNewItem(e.target.value);
                    setOpen(true);
                  }}
                  value={newItem}
                />
                <FontAwesomeIcon
                  icon={faChevronDown}
                  onClick={() => setOpen(!open)}
                />
              </InputContainer>
            ) : (
              <>
                <div>
                  {value
                    ? value.length >= 15
                      ? value.substring(0, 15).concat('...')
                      : value
                    : title.length >= 15
                    ? title.substring(0, 15).concat('...')
                    : title}
                </div>
                {title === 'Competitor' && (
                <CustomIcon onClick={() => setAddNew(true)}>
                  <FontAwesomeIcon icon={faPencil} />
                </CustomIcon>
                  )}
                <FontAwesomeIcon icon={faChevronDown} />
              </>
            )}
          </Container>
          {open && (
            <Menu>
              {title === 'Competitor' && (
                <>
                  {newItem ? (
                    <Item
                      selected={value === newItem}
                      onClick={() => {
                        setAddNew(false);
                        onChange(newItem);
                      }}
                    >
                      {newItem.length >= 20
                        ? newItem.substring(0, 20).concat('...')
                        : newItem}
                    </Item>
                  ) : (
                    <Item
                      onClick={() => {
                        setAddNew(true);
                        onChange('');
                      }}
                      selected={value === newItem}
                      border
                    >
                      <FontAwesomeIcon icon={faPlus} />
                      {' '}
                      <Subtitle3 style={{marginLeft: '5px'}}>Add new competitor</Subtitle3>
                    </Item>
                  )}
                </>
              )}
              {options.map((option, i) => (
                <Item
                  key={`${title}-option-${i.toString()}`}
                  onClick={() => {
                    setAddNew(false);
                    onChange(typeof option === 'string' ? option : option?.label ? option.label : option?.domain);
                  }}
                  selected={
                    typeof option === 'string' ? value === option
                    : option?.label
                      ? value === option.label
                      : value === option?.domain
                  }
                >
                  {typeof option === 'string' ? option : option?.label ? option.label : option?.domain}
                </Item>
              ))}
            </Menu>
          )}
        </Select>
      )}
    </>
  );
};

export default BrandListSelect;

const Select = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
`;
const Container = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;

  svg {
    transition: transform 0.1s linear;
    transform: ${(props) => (props.isOpen
        ? 'rotate(180deg) translateY(0)'
        : 'rotate(0) translateY(0)')};
  }
`;
const Menu = styled.div`
  width: 211px;
  background: #fafafa;
  position: absolute;
  border: 2px solid #fff;
  top: 43px;
  left: -16px;
  border-radius: 8px;
  z-index: 9;
  box-shadow: 0px 4px 4px 0px rgba(91, 204, 124, 0.16);
  overflow-x: hidden;
`;

const Item = styled.div`
  width: 100%;
  cursor: pointer;
  //height: 24px;
  padding: ${(props) => (props.selected ? '6px 0 6px 20px' : '4px 0 4px 20px')};
  margin-bottom: 2px;
  border-radius: 8px;
  background: ${(props) => (props.selected ? '#F0EFFC' : '#FAFAFA')};
  border: ${(props) => (props.selected || props.border ? '1px solid #675EE0' : '1px solid #FAFAFA')};
  display: flex;
  justify-content: flex-start;
  align-items: center;

  font-family: Lato;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;

  :hover {
    background: #f0effc;
  }
`;

const InputContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
const CustomIcon = styled.div`
  cursor: pointer;
  flex: 1;
  display: flex;
  justify-content: flex-end;
  margin-right: 10px;
  
  svg{
    transition: transform 0.1s linear;
    transform: rotate(0) translateY(0);
  }
`;
const Input = styled.input`
  background: white;
  height: 100%;
  width: 100%;
  border: none;

  font-family: "Lato", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: ${({ theme }) => theme.sizes.base};
  line-height: 28px;
  letter-spacing: 1px;
  color: #000000;

  :focus {
    outline: none;
  }
`;

import {
buttonSizes, buttonTypes, fontWeights, sizes,
} from './variables';

const theme = {
  sizes,
  fontWeights,
  buttonTypes,
  buttonSizes,
};

export default theme;

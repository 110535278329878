import React, { useState } from 'react';
import styled from 'styled-components';
import { useMutation, useQuery } from '@tanstack/react-query';
import { useAuth } from 'context/AuthContext';
import { VideoServices } from 'services';
import { Layout } from 'components/Dashboard';
import {
Headline3, Button, FeedOptimisationModal, LoadingModal,
} from 'components/Shared';
import { getRoute } from 'utils';

const VideoSelect = () => {
  const [token] = useAuth((store) => store.token);
  const [selectedVideo, setSelectedVideo] = useState(null);
  const [filterModalIsOpen, setfilterModalIsOpen] = useState(false);

  const {
    data: responseData,
    isLoading,
    isError,
  } = useQuery({
    queryKey: ['getVideos'],
    queryFn: () => VideoServices.getVideoAll(token),
  });

  const { mutate: createVideoMutate } = useMutation({
    mutationFn: (data) => VideoServices.createNewVideo(data),
    onSuccess: ({ data }) => {
      window.open(`${getRoute('videoRecorder')}?videoID=${data.id}`, '_blank');
    },
  });

  if (isLoading) return <Layout><LoadingModal /></Layout>;
  if (isError) return <Layout>Error fetching videos</Layout>;

  const videoData = responseData?.data || [];
  const selectedVideoData = videoData.find(
    (video) => video.id === selectedVideo,
  );

  const createVideo = (selectedVideoProducts) => {
    createVideoMutate({
      data: {
        id: selectedVideo,
        filterIds: selectedVideoProducts.map((product) => product['g:id']),
      },
      token,
    });
  };
  return (
    <Layout>
      <Container>
        <FeedOptimisationModal
          buttonTitle="Create Video"
          type="video"
          productCount={selectedVideoData?.template?.productCount}
          isOpen={filterModalIsOpen}
          setIsOpen={setfilterModalIsOpen}
          onApply={createVideo}
        />
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <Headline3>Choose Video Templates</Headline3>
          <Button
            type="primary"
            size="medium"
            onClick={() => setfilterModalIsOpen(true)}
            content=" Select & Contuine"
          />
        </div>
        <div style={{ display: 'flex', flexWrap: 'wrap', marginTop: '50px' }}>
          {videoData.slice(2, 10).map(
            (video, index) => video.previewPath && (
            <VideoCard
              key={index}
              selected={video.id === selectedVideo}
              onClick={() => setSelectedVideo(video.id)}
            >
              <img src={video.previewPath} alt={`Video Preview ${index}`} />
            </VideoCard>
              ),
          )}
        </div>
      </Container>
    </Layout>
  );
};
const VideoCard = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 250px;
  height: 330px;
  border: 2px solid #fff;
  background: #fafafa;
  border-color: ${(props) => (props.selected ? '#675EE0' : '#fff')};
  cursor: pointer;
  margin-right: 20px;
  margin-bottom: 20px;
  overflow: hidden;
  border-radius: 10px;

  img {
    max-width: 100%;
    max-height: 100%;
  }
`;
const Container = styled.div`
  width: 85%;
  margin: 50px auto;
`;
export default VideoSelect;

import React from 'react';
import styled from 'styled-components';
import { ControlButton } from 'components/Design';
import {
  faExpand,
  faHeart,
  faMusic,
  faTableLayout,
} from '@fortawesome/pro-light-svg-icons';
import { faCircleDashed } from '@fortawesome/pro-duotone-svg-icons';

const controlButtons = [
  {
    icon: faMusic,
    title: 'Music',
    name: 'Music',
    onClick: () => console.log('music'),
  },
  {
    icon: faExpand,
    title: 'Resize',
    name: 'Resize',
    onClick: () => console.log('resize'),
  },
  {
    icon: faTableLayout,
    title: 'Layout',
    name: 'Layout',
    onClick: () => console.log('layout'),
  },
  {
    icon: faCircleDashed,
    title: 'Animation',
    name: 'Animation',
    onClick: () => console.log('animation'),
  },
  {
    icon: faHeart,
    title: 'Favourite',
    name: 'Favourite',
    onClick: () => console.log('favourite'),
  },
];
const RightSideBar = () => (
  <Wrapper>
    {controlButtons.map((button) => (
      <ControlButton
        key={button.name}
        icon={button.icon}
        title={button.title}
        nextFeature
        onClick={button.onClick}
      />
      ))}
  </Wrapper>
  );

const Wrapper = styled.div`
  height: 100%;
  width: 100%;
  background-color: white;
  grid-area: rightSidebar;
  z-index: 1;
  padding: 50px 0 80px;
  border-left: 1px solid #675ee0;
`;
export default RightSideBar;

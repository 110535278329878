import React from 'react';
import styled from 'styled-components';
import { CreateDesignModal } from 'components/Shared';
import { useApp } from 'context/AppContext';

const StageSize = () => {
  const { onStageSizeChange, stageSize, shapes } = useApp();

  return (
    <Wrapper id="stage-size">
      <CreateDesignModal
        stageSize={stageSize}
        onStageSizeChange={onStageSizeChange}
        isDesign
      />
    </Wrapper>
  );
};

const Wrapper = styled.div`
  position: relative;
`;

export default StageSize;

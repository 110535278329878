import React from 'react';
import styled from 'styled-components';

const NextFeature = ({ pos }) => (
  <Container y={pos.y} x={pos.x}>
    <img
      src="https://cnvs.s3.eu-central-1.amazonaws.com/cdn/site-assets/4.png"
      alt="next feat icon"
    />
  </Container>
  );
const Container = styled.div`
  position: absolute;
  width: 78px;
  top: ${({ y }) => (y ? `${y}px` : 0)};
  left: ${({ x }) => (x ? `${x}px` : 0)};
  z-index: 999;
  transform: rotate(180);
  img {
    max-width: 100%;
  }
`;
export default NextFeature;

import React, { useState } from 'react';
import styled from 'styled-components';
import { useForm } from 'react-hook-form';
import { useMutation } from '@tanstack/react-query';
import { useCookies } from 'react-cookie';
import { yupResolver } from '@hookform/resolvers/yup';
import { Link, useNavigate } from 'react-router-dom';
import { useAuth } from 'context/AuthContext';
import { AuthServices } from 'services';
import { registerSchema } from 'lib/validationSchemas';
import { InputWrapper, LoginFormLogo } from 'components/Auth';
import {
 Headline3, Headline4, Subtitle3, Loading,
} from 'components/Shared';
import { EyeIcon } from 'icons';
import {getRoute} from 'utils';
import {toast} from 'react-toastify';

const RegisterForm = () => {
  const [store, set] = useAuth((store) => store);
  const [cookies, setCookie] = useCookies(['userToken', 'refreshToken']);
  const [passwordShown, setPasswordShown] = useState(false);
  const passwordInputType = passwordShown ? 'text' : 'password';
  const togglePasswordVisiblity = () => {
    setPasswordShown(!passwordShown);
  };
  const navigate = useNavigate();

  const { mutate, isLoading } = useMutation({
    mutationFn: AuthServices.register,
    mutationKey: ['register'],
    onSuccess: ({ data }) => {
      set(data);
      setCookie('userToken', data.token);
      setCookie('refreshToken', data.refreshToken);
      navigate(getRoute('onboarding'));
    },
    onError: (error) => {
      if (error?.response?.data?.errors) {
        Object.keys(error.response.data.errors).map((key) => toast.error(error.response.data.errors[key]));
      }
    },
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(registerSchema),
  });
  const onSubmit = (data) => mutate({ ...data });

  return (
    <FormWrapper>
      <LoginFormLogo />
      <Headline3>Sign Up</Headline3>
      <Row>
        <Headline4>Do you have an account? </Headline4>
        <Redirection href={getRoute('signin')}>
          <Headline4>Sign In</Headline4>
          {' '}
        </Redirection>
      </Row>
      <FormContainer>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <InputWrapper width="100%" name="email">
            <input
              type="email"
              name="email"
              placeholder="Your email"
              error={errors.email?.message}
              style={{
                border: errors.email?.message
                  ? '2px solid #FF2100'
                  : '2px solid #DDDBF5',
              }}
              {...register('email')}
            />
          </InputWrapper>
          <InputWrapper width="100%" name="password">
            <input
              type={passwordInputType}
              name="password"
              error={errors.password?.message}
              placeholder="Type Your Password"
              {...register('password')}
              autocomplete="on"
              style={{
                border: errors.email?.message
                  ? '2px solid #FF2100'
                  : '2px solid #DDDBF5',
              }}
            />
            <EyeIcon
              onClick={togglePasswordVisiblity}
              fill={passwordShown ? '#000000' : '#7F7F7F'}
            />
          </InputWrapper>

          <TermsWrapper>
            <Subtitle3>
              By registering, you agree to our
              {' '}
              <Link to="/">Terms</Link>
              {' '}
              and
              {' '}
              <Link to="/">Privacy</Link>
            </Subtitle3>
          </TermsWrapper>
          {isLoading && <Loading />}
          <ButtonWrapper>
            <input type="submit" value="Sign Up" />
          </ButtonWrapper>
        </Form>
      </FormContainer>
    </FormWrapper>
  );
};
const FormWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 100%;
  height: 100%;
  grid-area: form;
  h1 {
    margin-bottom: 30px;
  }
  h3 {
    color: #675ee0;
    margin-bottom: 10px;
    text-align: center;
  }
  h4 {
    text-align: center;
  }

  @media (max-width: 1024px) {
    padding: 20px 0;
    position: relative;
    margin-bottom: 0;
    height: max-content;

    h1 {
      font-size: 22px;
      font-style: normal;
      font-weight: 500;
      line-height: 34px;
      margin-bottom: 15px;
      margin-top: 15px;
    }
    h4 {
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 24px;
    }
  }
`;
const Row = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;
const Redirection = styled.a`
  cursor: pointer;
  outline: none;
  text-decoration: none;
  h4 {
    color: #675ee0;
    margin-left: 5px;
  }
`;

const FormContainer = styled.div`
  width: 100%;
  max-width: 345px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  margin-top: 10px;
  @media (max-width: 1024px) {
    margin-top: 10px;
    max-width: 287px;
    padding-bottom: 50px;
    overflow: hidden;
  }
`;

const Form = styled.form`
  width: 100%;
  input[type="submit"] {
    width: 100%;
    height: 56px;
    background: #675ee0;
    border-radius: 8px;
    border: none;
    color: white;
    font-size: 16px;
    line-height: 1;
    letter-spacing: 0.2px;
    margin-top: 20px;
    cursor: pointer;
    font-family: "Lato", sans-serif;
    font-style: normal;
    font-weight: 500;
  }
`;
const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: flex-end;
  a {
    text-decoration: none;
  }
`;
const TermsWrapper = styled.div`
  width: 100%;
  text-align: center;
  a {
    color: #000;
  }
  @media (max-width: 1024px) {
    h4 {
      font-size: 9px;
      font-style: normal;
      font-weight: 400;
      line-height: 14px;
    }
  }
`;
export default RegisterForm;

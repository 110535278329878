import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Subtitle3 } from 'components/Shared';

import { RangeInput, ColorPickerModal } from 'components/Design';

const EffectCustomize = ({ feature, onValueChange }) => {
  const [value, setValue] = useState(
    feature?.dividedBy
      ? feature.initialValue * feature.dividedBy
      : feature.initialValue,
  );
  const [editedFeature, setEditedFeature] = useState(feature);

  const onChange = (e) => setValue(Number(e.target.value));

  useEffect(() => {
    if (editedFeature.initialValue === value) {
      return;
    }
    const tmpFeature = { ...editedFeature };
    tmpFeature.initialValue = feature?.dividedBy
      ? value / feature.dividedBy
      : value;
    setEditedFeature(tmpFeature);
  }, [value]);

  useEffect(() => {
    onValueChange(editedFeature);
  }, [editedFeature]);

  if (feature.type === 'color') {
    return (
      <Row style={{ position: 'relative' }} id="color-picker-effect">
        <Subtitle3>{feature.title}</Subtitle3>
        <div
          style={{
            marginLeft: 180,
          }}
        >
          <ColorPickerModal color={value} setColor={setValue} />
        </div>
      </Row>
    );
  }

  if (feature.type === 'number') {
 return (
   <Wrapper>
     <Row>
       <Subtitle3>{feature.title}</Subtitle3>
       <Subtitle3>{value}</Subtitle3>
     </Row>
     <RangeInput
       min={feature.min}
       max={feature.max}
       value={value}
       onChange={onChange}
     />
   </Wrapper>
    );
}
};

const Wrapper = styled.div`
  width: 100%;
`;
const Row = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;
export default EffectCustomize;

import React, { useState } from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPalette, faSquareB } from '@fortawesome/pro-light-svg-icons';
import { HexColorInput, HexColorPicker } from 'react-colorful';
import { useQuery } from '@tanstack/react-query';
import { useApp } from 'context/AppContext';
import { useAuth } from 'context/AuthContext';
import { DesignServices } from 'services';
import { Subtitle3 } from 'components/Shared';

const DEFAULT_COLORS = [
  '#F2453D',
  '#FD9827',
  '#FFED65',
  '#69BA6E',
  '#2FA69A',
  '#1EABF1',
  '#4154B3',
  '#9B2FAE',
  '#EA447B',
  '#8D6E64',
  '#000000',
  '#424242',
];
const TextFill = () => {
  const { changeShape, selectedShape } = useApp();
  const [color, setColor] = useState(selectedShape.fill || 'fff');
  const [token] = useAuth((store) => store.token);

  const { data } = useQuery({
    queryKey: ['getBrandkit'],
    queryFn: () => DesignServices.getBrandKit(token),
  });

  const onChange = (color) => {
    setColor(color);
    changeShape({ fill: color });
  };

  return (
    <Wrapper>
      <HexColorPicker color={color} onChange={onChange} />
      <HexColorInput
        color={color}
        onChange={onChange}
        prefixed
        placeholder="Hex Code"
        style={{
          width: '100%',
          marginTop: 10,
          height: 40,
          borderRadius: 4,
          border: '1px solid #e4e4e4',
          textAlign: 'center',
          fontFamily: 'Lato',
          fontSize: '12px',
          fontWeight: 600,
          textTransform: 'uppercase',
        }}
      />
      <SubtitleWrapper>
        <FontAwesomeIcon icon={faSquareB} />
        <Subtitle3>Brand Colors</Subtitle3>
      </SubtitleWrapper>
      <ColorWrapper>
        {data?.data?.extra?.colors.map((color) => (
          <Color color={color} onClick={() => changeShape({ fill: color })} />
          ))}
      </ColorWrapper>
      <SubtitleWrapper>
        <FontAwesomeIcon icon={faPalette} />
        <Subtitle3>Default Colors</Subtitle3>
      </SubtitleWrapper>
      <ColorWrapper>
        {DEFAULT_COLORS.map((color) => (
          <Color color={color} onClick={() => changeShape({ fill: color })} />
          ))}
      </ColorWrapper>
    </Wrapper>
  );
};
const Wrapper = styled.div`
  width: 100%;
  overflow-y: scroll;
  ::-webkit-scrollbar {
    display: none;
    width: 0 !important;
  }
  .react-colorful {
    width: 100%;
    height: 140px;
  }
  .react-colorful__saturation {
    border-radius: 8px 8px 8px 8px;
  }
  .react-colorful__hue {
    margin-top: 10px;
    height: 12px;
    border-radius: 8px;
  }
  .react-colorful__hue-pointer {
    width: 12px;
    height: 12px;
  }
`;

const SubtitleWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-top: 25px;
  padding-left: 15px;

  p {
    margin-left: 10px;
    color: #7f7f7f;
  }
`;

const ColorWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  padding-left: 15px;
  margin-top: 20px;
  width: 100%;
  align-items: center;
  gap: 10px;
`;

const Color = styled.button`
  width: 45px;
  height: 45px;
  outline: none;
  border: none;
  background-color: ${({ color }) => color || 'transparent'};
`;
export default TextFill;

import React, { useState } from 'react';
import {
  createSearchParams,
  useNavigate,
  useSearchParams,
} from 'react-router-dom';
import styled from 'styled-components';
import { useQuery } from '@tanstack/react-query';
import { OnboardingServices } from 'services';
import { getHostName, getRoute } from 'utils';
import { useAuth } from 'context/AuthContext';
import { AdsListItem } from 'components/Onboarding';

import {
  Headline2,
  Headline4,
  Subtitle2,
  Button,
  LoadingModal,
} from 'components/Shared';

const AdsPage = () => {
  const [token] = useAuth((store) => store.token);
  const navigate = useNavigate();
  const competitor = 'https://www.zara.com/';
  const [selectedAd, setSelectedAd] = useState({});

  const { data, isLoading } = useQuery({
    queryKey: ['getAds', competitor],
    enabled: competitor !== null,
    refetchOnWindowFocus: false,
    queryFn: () => OnboardingServices.getAds(
        {
          companyUrl: competitor,
        },
        token,
      ),
  });
  return (
    <>
      {isLoading ? (
        <LoadingModal />
      ) : (
        <Container>
          <Headline2>Your Top 3 Recommendations Ads</Headline2>
          <Headline4
            style={{
              textAlign: 'center',
              margin: '30px 0 45px',
            }}
          >
            Choose your favorite,
            <br />
            and our AI will craft personalized templates just for you.
          </Headline4>
          <AdsContainer>
            {/* <AdsHeader>
              {data.data.logo
              && data.data.logo.length > 0
              && data.data.logo[0] ? (
                <img src={data.data.logo[0]} alt="brand logo" />
              ) : (
                <Headline4>
                  {competitor}
                  {' '}
                </Headline4>
              )}
            </AdsHeader> */}
            <AdsList>
              {!isLoading
                && data
                && data?.data
                && data.data?.ads.length > 0
                && data.data.ads.map((item, i) => (
                  <AdsListItem
                    key={i.toString()}
                    index={i}
                    isSelected={selectedAd.index === i}
                    setIsSelected={setSelectedAd}
                    data={item}
                  />
                  ))}
            </AdsList>
          </AdsContainer>
          <AdsPageFooter>
            <Subtitle2
              onClick={() => navigate({
                  pathname: getRoute('onboarding'),
                  search: createSearchParams({
                    step: 'create-assets',
                  }).toString(),
                })}
              style={{
                cursor: 'pointer',
              }}
            >
              Back
            </Subtitle2>
            <Button
              type="primary"
              content="Continue"
              size="medium"
              handleClick={() => navigate({
                  pathname: getRoute('onboarding'),
                  search: createSearchParams({
                    step: 'upload',
                  }).toString(),
                })}
              disabled={selectedAd?.index === undefined}
            />
          </AdsPageFooter>
        </Container>
      )}
    </>
  );
};

export default AdsPage;

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;
const AdsContainer = styled.div`
  position: relative;
  flex: 1;
  border-radius: 16px;
  border: 3px solid white;
  background: #fafafa;
  margin-bottom: 30px;
  padding: 49px 30px 17px;
`;
const AdsHeader = styled.div`
  width: 211px;
  height: 66px;
  border-radius: 8px;
  background-color: white;
  padding: 15px 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0 4px 4px 0 rgba(91, 204, 124, 0.16);

  position: absolute;
  top: -34px;
  left: 14px;

  img {
    height: 100%;
  }
`;
const AdsList = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  height: 100%;
  gap: 16px;
`;
const AdsPageFooter = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 160px;
  margin-bottom: 20px;
`;

import React, { useState } from 'react';
import styled from 'styled-components';
import Modal from 'react-modal';
import { faLineHeight } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { RangeInput, TopMenuButton } from 'components/Design';
import { Subtitle3 } from 'components/Shared';

const customStyles = {
  overlay: {
    zIndex: '999',
    background: 'transparent',
    position: 'absolute',
    width: '100vw',
    height: '100vh',
  },
  content: {
    position: 'absolute',
    top: '40px',
    left: '50px',
    width: '204px',
    height: 'fit-content',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '8px',
    padding: '10px',
  },
};

const TextSpacing = ({
  lineHeight,
  setLineHeight,
  letterSpacing = 1,
  setLetterSpacing,
}) => {
  const [isOpen, setIsOpen] = useState(false);

  const openModal = () => setIsOpen(true);
  const closeModal = () => setIsOpen(false);

  const onLineHeightChange = (e) => setLineHeight(Number(e.target.value));
  const onLetterSpacingChange = (e) => setLetterSpacing(Number(e.target.value));

  return (
    <Wrapper id="text-height">
      <TopMenuButton onClick={openModal} data-tip="Spacing">
        <FontAwesomeIcon icon={faLineHeight} size="lg" />
      </TopMenuButton>
      <Modal
        shouldCloseOnOverlayClick
        isOpen={isOpen}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Pop up Modal"
        parentSelector={() => document.querySelector('#text-height')}
      >
        <OpacityInput>
          <TitleWrapper>
            <Subtitle3>Letter Spacing</Subtitle3>
            <div>
              <Subtitle3>{letterSpacing}</Subtitle3>
            </div>
          </TitleWrapper>
          <RangeInput
            min="0"
            max="20"
            value={letterSpacing}
            onChange={onLetterSpacingChange}
          />
        </OpacityInput>
        <OpacityInput>
          <TitleWrapper>
            <Subtitle3>Line Gap</Subtitle3>
            <div>
              <Subtitle3>{lineHeight}</Subtitle3>
            </div>
          </TitleWrapper>
          <RangeInput
            min="0"
            max="3"
            value={lineHeight}
            onChange={onLineHeightChange}
          />
        </OpacityInput>
      </Modal>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  position: relative;
  button {
    border: none;
    background: none;
    align-self: flex-end;
  }
`;
const OpacityInput = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-bottom: 11px;
`;

const TitleWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  margin-bottom: 12px;
  div {
    border-radius: 4px;
    border: 1px solid rgba(103, 94, 224, 0.2);
    padding: 0 12px;
  }
`;
export default TextSpacing;

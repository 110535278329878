import React from 'react';
import styled, { keyframes } from 'styled-components';

const Spinner = () => (
  <SpinnerContainer>
    <div />
    <div />
    <div />
    <div />
  </SpinnerContainer>
  );
const Button = ({
  content,
  type,
  size,
  uppercase,
  handleClick,
  children,
  loading,
  ...rest
}) => (
  <NewButton
    type={type}
    size={size}
    onClick={handleClick}
    uppercase={uppercase}
    {...rest}
  >
    {loading ? (
      <Spinner />
    ) : (
      <>{content || children}</>
    )}
  </NewButton>
);

const NewButton = styled.button`
  font-family: var(--futura-font);
  font-weight: ${({ theme }) => theme.fontWeights.medium};
  background: ${({ theme, type }) => theme.buttonTypes[type].background};
  font-size: ${({ theme, size }) => theme.buttonSizes[size].fontSize};
  line-height: ${({ theme, size }) => theme.buttonSizes[size].lineHeight};
  width: ${({ theme, size }) => theme.buttonSizes[size].width};
  height: ${({ theme, size }) => theme.buttonSizes[size].height};
  color: ${({ theme, type }) => theme.buttonTypes[type].color};
  border: ${({ theme, type }) => theme.buttonTypes[type].border};
  border-radius: ${({ theme, type }) => theme.buttonTypes[type].borderRadius};
  padding: ${({ theme, size }) => theme.buttonSizes[size].padding};
  letter-spacing: 0;
  text-transform: capitalize;
  transition: all 0.2s ease-in-out;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  white-space: nowrap;
  :hover {
    background: ${({ theme, type }) => theme.buttonTypes[type].hover.background};
    color: ${({ theme, type }) => theme.buttonTypes[type].hover.color};
    border: ${({ theme, type }) => theme.buttonTypes[type].hover.border};
  }
  :disabled {
    background: ${({ theme, type }) => theme.buttonTypes[type].disabled.background};
    color: ${({ theme, type }) => theme.buttonTypes[type].disabled.color};
    border: ${({ theme, type }) => theme.buttonTypes[type].disabled.border};
    cursor: not-allowed;
  }
`;
const LdsRing = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;
const SpinnerContainer = styled.div`
  display: inline-block;
  position: relative;
  width: 20px;
  height: 20px;

  div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: 16px;
    height: 16px;
    border: 2px solid #fff;
    border-radius: 50%;
    animation: ${LdsRing} 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: #fff transparent transparent transparent;
  }

  div:nth-child(1) {
    animation-delay: -0.45s;
  }
  div:nth-child(2) {
    animation-delay: -0.3s;
  }
  div:nth-child(3) {
    animation-delay: -0.15s;
  }
`;

export default Button;

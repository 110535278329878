import React, { useEffect, useState } from 'react';
import styled, { css, keyframes } from 'styled-components';
import { Headline5, Subtitle2 } from 'components/Shared';

const ADS = [
  {
    id: 1,
    name: 'Keikei',
    brand:
      'https://cnvs.s3.eu-central-1.amazonaws.com/cdn/site-assets/hero-section/keikei-logo.png',
    color: '#000000',
    font: 'Futura Now Headline',
    competitiveAnalysis:
      'https://cnvs.s3.eu-central-1.amazonaws.com/cdn/site-assets/hero-section/zara-logo.png',
    assets: [
      {
        type: 'Image',
        src: 'https://cnvs.s3.eu-central-1.amazonaws.com/cdn/site-assets/hero-section/kk-1.png',
      },
      {
        type: 'Image',
        src: 'https://cnvs.s3.eu-central-1.amazonaws.com/cdn/site-assets/hero-section/kk-2.png',
      },
      {
        type: 'Image',
        src: 'https://cnvs.s3.eu-central-1.amazonaws.com/cdn/site-assets/hero-section/kk-3.png',
      },
      {
        type: 'Image',
        src: 'https://cnvs.s3.eu-central-1.amazonaws.com/cdn/site-assets/hero-section/kk-4.png',
      },
      {
        type: 'Image',
        src: 'https://cnvs.s3.eu-central-1.amazonaws.com/cdn/site-assets/hero-section/kk-5.png',
      },
    ],
  },
  {
    id: 2,
    name: 'The Latest Thing',
    brand:
      'https://cnvs.s3.eu-central-1.amazonaws.com/cdn/site-assets/hero-section/tlt-logo.png',
    color: '#EDA796',
    font: 'Montserrat',
    competitiveAnalysis:
      'https://cnvs.s3.eu-central-1.amazonaws.com/cdn/site-assets/hero-section/eres-logo.png',
    assets: [
      {
        type: 'Image',
        src: 'https://cnvs.s3.eu-central-1.amazonaws.com/cdn/site-assets/hero-section/tlt-1.png',
      },
      {
        type: 'Image',
        src: 'https://cnvs.s3.eu-central-1.amazonaws.com/cdn/site-assets/hero-section/tlt-2.png',
      },
      {
        type: 'Image',
        src: 'https://cnvs.s3.eu-central-1.amazonaws.com/cdn/site-assets/hero-section/tlt-3.png',
      },
      {
        type: 'Image',
        src: 'https://cnvs.s3.eu-central-1.amazonaws.com/cdn/site-assets/hero-section/tlt-4.png',
      },
      {
        type: 'Image',
        src: 'https://cnvs.s3.eu-central-1.amazonaws.com/cdn/site-assets/hero-section/tlt-5.png',
      },
    ],
  },
];

const AdsJackpot = () => {
  const [selectedAds, setSelectedAds] = useState(ADS[0]);

  useEffect(() => {
    const interval = setInterval(() => {
      setSelectedAds((prevSelectedAd) => {
        const currentIndex = ADS.findIndex((ad) => ad.id === prevSelectedAd.id);
        const nextIndex = currentIndex + 1;
        if (nextIndex === ADS.length) {
          return ADS[0];
        }
          return ADS[nextIndex];
      });
    }, 7000);

    return () => clearInterval(interval);
  }, []);

  return (
    <Wrapper>
      <DescriptionWrapper>
        <Description>
          <FeatureBox>
            <Subtitle2>BRAND</Subtitle2>
            <Feature key={`brand_${selectedAds.brand}`}>
              <LogoImg
                className="spin"
                src={selectedAds.brand}
                alt={selectedAds.name}
              />
            </Feature>
          </FeatureBox>
          <FeatureBox bgColor={selectedAds.color}>
            <Subtitle2>COLOR</Subtitle2>
            <Feature
              key={`color_${selectedAds.color}`}
              bgColor={selectedAds.color}
            >
              <Headline5 className="spin" style={{ color: '#fff' }}>
                {selectedAds.color}
              </Headline5>
            </Feature>
          </FeatureBox>
          <FeatureBox>
            <Subtitle2>FONT</Subtitle2>
            <Feature key={`font_${selectedAds.font}`}>
              <Headline5 className="spin">{selectedAds.font}</Headline5>
            </Feature>
          </FeatureBox>
          <FeatureBox>
            <Subtitle2> ANALYSIS</Subtitle2>
            <Feature key={`analysis_${selectedAds.brand}`}>
              <LogoImg
                className="spin"
                src={selectedAds.competitiveAnalysis}
                alt={selectedAds.name}
              />
            </Feature>
          </FeatureBox>
        </Description>
      </DescriptionWrapper>
      <ImageContainer>
        {selectedAds.assets.map((asset, index) => {
          const prevIndex = index === 0 ? selectedAds.assets.length - 1 : index - 1;
          const nextIndex = index === selectedAds.assets.length - 1 ? 0 : index + 1;

          const prevAsset = selectedAds.assets[prevIndex];
          const nextAsset = selectedAds.assets[nextIndex];
          return (
            <ImageWrapper
              key={`${asset.src}_${index}`}
              className={`fadeIn animationDelay${index}`}
            >
              <ImageContent>
                <Image src={asset.src} alt="HeroBanner" />
                <Image src={prevAsset.src} alt="HeroBanner" />
                <Image src={nextAsset.src} alt="HeroBanner" />
              </ImageContent>
            </ImageWrapper>
          );
        })}
      </ImageContainer>
      <Container />
      <GradientCover />
    </Wrapper>
  );
};

const Wrapper = styled.div`
  margin-top: 260px;
  margin-bottom: -220px;
  position: relative;

  @media (max-width: 1024px) {
    margin-bottom: 100px;
    margin-top: 150px;
    .assetContainer {
      transform: translateY(157px);
      position: relative;
      z-index: 9;
    }
  }
`;
const DescriptionWrapper = styled.div`
  position: absolute;
  top: calc(-25% - (116px / 2));
  width: 100%;
  display: flex;
  justify-content: center;
  width: 100%;
  @media (max-width: 1024px) {
    top: calc(-122px);
  }
`;
const Description = styled.div`
  display: flex;
  justify-content: center;
  padding: 15px 14px;
  border: 1px solid #ffffff;
  border-radius: 16px;
  z-index: 9;
  position: relative;
  gap: 14px;
  background: #fafafa;
  box-shadow: 0px 8px 8px 0px rgba(103, 94, 224, 0.1);

  @media (max-width: 768px) {
    padding: 7px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 7px;
    box-shadow: none;
  }
`;
const FeatureBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  width: 147px;
  height: 84px;
  border-radius: 8px;
  border: 1px solid #fff;
  background: ${(props) => props.bgColor || '#fff'};
  transition: all 0.5s ease-in-out;
  h4 {
    position: absolute;
    top: -30px;
  }
  @media (max-width: 768px) {
    padding: 7px 14px;
    flex-direction: row;
    justify-content: space-between;
    background: ${(props) => props.bgColor};
    margin-top: 7px;
    border-radius: 8px;
    width: 273px;
    height: unset;
    h4 {
      position: unset;
      top: unset;
      margin-top: 0 !important;
      color: ${(props) => (props.bgColor ? '#fff' : '#000')};
    }
    div {
      height: max-content;
    }
  }
`;
const spin = keyframes`
  0% {
    --rotate: 0deg;
  }
  100% {
    --rotate: 360deg;
  }
`;
const animation = css`
  ${spin} 5s linear infinite
`;
const Feature = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${(props) => props.bgColor};
  border: ${(props) => (props.bgColor ? `1px solid ${props.bgColor}` : '1px solid #fff')};
  border-radius: 8px;
  width: 100%;
  height: 100%;
  transition: all 0.5s ease-in-out;
  @media (max-width: 768px) {
    justify-content: flex-end;
    background: transparent;
    border: none;
  }
`;
const LogoImg = styled.img`
  height: 20px;
  object-fit: cover;
`;
const Container = styled.div`
  position: relative;
  background: #fff;
  top: -370px;
  left: -50px;
  width: calc(100% + 100px);
  height: 273px;
  border-radius: 6px;
  justify-content: center;
  align-items: center;
  text-align: center;
  display: flex;
  &::before {
    content: "";
    width: calc(100% + 6px);
    height: calc(100% + 6px);
    border-radius: 8px;
    background-image: linear-gradient(var(--rotate), #675ee0 0%, #5bcc7c 100%);
    position: absolute;
    top: -3px;
    left: -3px;
    animation: ${animation};
  }

  &::after {
    position: absolute;
    content: "";
    top: 0;
    left: 0;
    right: 0;
    z-index: -1;
    height: 100%;
    width: 100%;
    margin: 0 auto;
    transform: scale(0.8);
    background-image: linear-gradient(var(--rotate), #675ee0 0%, #5bcc7c 100%);
    opacity: 1;
    transition: opacity 0.5s;
    animation: ${animation};
  }

  @media (max-width: 1024px) {
    top: -40px;
    max-width: calc(100% + 70px);
    height: 171px;
    left: 50%;
    transform: translateX(-50%);
  }
`;
const GradientCover = styled.div`
  background: #fff;
  position: absolute;
  top: -24.5%;
  left: -50px;
  width: calc(100% + 100px);
  height: 273px;
  border-radius: 6px;
  justify-content: center;
  align-items: center;
  text-align: center;
  display: flex;
  z-index: 8;
  @media (max-width: 1024px) {
    top: 55px;
    max-width: calc(100% + 72px);
    height: 173px;
    left: 50%;
    transform: translateX(-50%);
  }
`;
const ImageContainer = styled.div`
  position: relative;
  height: 243.5px;
  width: 736px;
  display: flex;

  @media (max-width: 1024px) {
    transform: translateY(150px);
    z-index: 9;
    height: 96px;
    width: 278.05px;
  }
`;
const ImageWrapper = styled.div`
  width: 160px;
  height: 240px;
  min-width: 160px;
  min-height: 240px;
  border-radius: 8px;
  position: relative;
  z-index: 9;
  transition: all 0.5s ease-in-out;
  opacity: 0;
  filter: drop-shadow(0px 8px 8px rgba(0, 0, 0, 0.08));

  &:nth-child(1) {
    margin-bottom: 0;
    margin-left: 0;
  }
  &:nth-child(2) {
    margin-top: 26px;
    margin-left: -16px;
    z-index: 10;
  }
  &:nth-child(3) {
    margin-top: 56px;
    margin-left: -16px;
    z-index: 11;
  }
  &:nth-child(4) {
    margin-top: 26px;
    margin-left: -16px;
    z-index: 10;
  }
  &:nth-child(5) {
    margin-top: 0;
    margin-left: -16px;
  }
  :hover {
    transform: scale(1.1);
    z-index: 12;
    div {
      img {
        transition: all 0.3s ease-in-out;
        :nth-child(2) {
          transform: scale(1.01);
          top: -8px;
          right: -8px;
        }
        :nth-child(3) {
          transform: scale(1.015);
          top: -16px;
          right: -16px;
        }
      }
    }
  }

  @media (max-width: 768px) {
    width: 62.01px;
    height: 93.01px;
    min-width: 62.01px;
    min-height: 93.01px;
    &:nth-child(2) {
      margin-left: -8px;
    }
    &:nth-child(3) {
      margin-left: -8px;
      z-index: 11;
    }
    &:nth-child(4) {
      margin-left: -8px;
    }
    &:nth-child(5) {
      margin-top: 0;
      margin-left: -8px;
    }

    :hover {
      div {
        img {
          :nth-child(2) {
            top: -6px;
            right: -6px;
          }
          :nth-child(3) {
            top: -12px;
            right: -12px;
          }
        }
      }
    }
  }
`;
const ImageContent = styled.div`
  img {
    :nth-child(2) {
      position: absolute;
      right: -3px;
      top: -3px;
      z-index: -1;
    }
    :nth-child(3) {
      position: absolute;
      right: -6px;
      top: -6px;
      z-index: -2;
    }
  }
`;
const Image = styled.img`
  width: 100%;
  object-fit: cover;
  transition: all 0.5s ease-in-out;
`;
export default AdsJackpot;

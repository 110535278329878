import React from 'react';
import Modal from 'react-modal';
import styled from 'styled-components';
import {
  CustomVertical, FacebookPost, InstagramPost, InstagramStory,
} from 'components/Design';
import { Headline4 } from 'components/Shared';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmarkLarge } from '@fortawesome/pro-light-svg-icons';
import Loading from '../../../Shared/ui/Loading';

const customStyles = {
  overlay: {
    zIndex: '999',
    background: 'rgb(0 0 0 / 20%)',
    position: 'absolute',
    width: '100vw',
    minHeight: '100vh',
  },
};
const Index = ({
 isOpen, setIsOpen, size, base64,
}) => {
  const checkSize = () => {
    const stringSize = `${size.width}x${size.height}`;
    switch (stringSize) {
        case '1080x1080':
            return <InstagramPost base64={base64} />;
        case '1080x1920':
            return <InstagramStory base64={base64} />;
        case '1200x1200':
            return <FacebookPost base64={base64} />;
      default:
        return <CustomVertical base64={base64} width={size.width} height={size.height} />;
    }
  };
  const closeModal = () => {
    setIsOpen(false);
  };
  return (
    <StyledModal
      isOpen={isOpen}
      style={customStyles}
      contentLabel="Preview Modal"
      onRequestClose={closeModal}
      shouldCloseOnOverlayClick
    >
      <GradientCover />
      <Container>
        <Header>Preview</Header>
        <Close onClick={closeModal}>
          <FontAwesomeIcon icon={faXmarkLarge} />
        </Close>
        {
          base64 ? checkSize() : <Loading />
        }
      </Container>
    </StyledModal>
  );
};

const StyledModal = styled(Modal)`
  position: relative;
  outline: none;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  min-width: 952px;
  max-width: 952px;
  min-height: 612px;
  max-height: 722px;
  background: linear-gradient(90deg, #675ee0 0%, #5bcc7c 100%);
  border-radius: 16px;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-items: center;
  
`;
const GradientCover = styled.div`
  position: absolute;
  background-color: #F2F4F8;
  width: calc(100% - 14px);
  height: calc(100% - 14px);
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  border-radius: 10px;
`;
const Container = styled.div`
  position: relative;
  z-index: 9;
  min-width: 952px;
  max-width: 952px;
  min-height: 612px;
  max-height: 722px;
  padding: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
`;
const Header = styled(Headline4)`
  position: absolute;
  left: 40px;
  top: 28px;
`;
const Close = styled.div`
  position: absolute;
  cursor: pointer;
  width: 40px;
  height: 40px;
  right: -16px;
  top: -16px;
  background: #FFFFFF;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
`;
export default Index;

import { client } from '../client';

const OnboardingServices = {
  getCompanyMetaData: (data, token) => client
      .post('company/meta-data', data, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => res.data),
  setCompanyMetaData: (data, token) => client
      .post('company', data, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => res.data),
  editCompanyMetaData: (data, token) => client
      .put('company', data, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => res.data),
  getFonts: (token) => client
      .get('files/font-list', {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => res.data),
  uploadXML: (data, token) => client
      .post('files/xml', data, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => res.data),
  getAds: (data, token) => client
      .post('company/compatitor-ads', data, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => res.data),
  getCompany: (token) => client
      .get('company/', {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => res.data),
  setCompetitorOutput: (data, token) => client
      .post('ai/compatitor-output', data, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => res.data),

  getPredictCompetitor: (uuid, token) => client
      .get(`ai/predict-compatitor-status?predictUuid=${uuid}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => res.data),
  downloadCompetitorOutput: (id, token) => client
      .get(`files/download-compatitor-image/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => res.data),
  getCompanyIndustries: (token) => client
      .get('company/company-industries', {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => res.data),
  getFilters: (token) => client
      .get('xml/group-filter/company', {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => res.data),
  setFilters: (data, token) => client
      .post('xml/group-filter/company', data, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => res),
  saveFilters: (data, token) => client
      .post('xml/group', data, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => res),
  editFilter: (token, data, id) => client
      .put(`xml/group/${id}`, data, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => res),
  getSavedFilters: (token) => client
      .get('xml/group', {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => res.data),
  deleteFilter: (token, id) => client
      .delete(`xml/group/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => res.data),
};

export default OnboardingServices;

import { calculateDuration } from 'utils/Video';

const animationf = {
  id: 'e28236c7-6ecd-459f-9222-c2b37d6fca5b',
  name: 'Test',
  type: 'VIDEO',
  template: {
    id: 1,
    name: 'Week Look',
    duration: 7000,
    animation: [
      {
        id: 'images-1',
        images: [
          'https://i7.keikei.co/cdn/products/7/72573/930501_T97_A.webp',
          'https://i7.keikei.co/cdn/products/7/72689/965513_T98_A.webp',
          'https://i7.keikei.co/cdn/products/7/70945/media-libraryXRWJeU',
          'https://i7.keikei.co/cdn/products/7/72582/965512_002_A.webp',
          'https://i7.keikei.co/cdn/products/7/72564/965512_001_A.webp',
          'https://i7.keikei.co/cdn/products/7/71940/950025_002_A.webp',
          'https://i7.keikei.co/cdn/products/7/72625/940502_T97_HA.webp',
        ],
        animation: {
          from: {
            x: 150,
            y: 210,
            width: 420,
            height: 860,
            opacity: 0.9,
          },
          enter: {
            opacity: 1,
          },
          leave: {
            opacity: 0.9,
          },
          count: 7,
          config: {
            duration: 1000,
          },
        },
      },
      {
        texts: ['01', '02', '03', '04', '05', '06', '07'],
        animation: {
          from: {
            x: 343,
            y: 138,
            fontSize: 30,
            fontFamily: 'Lato',
            fontWeight: '500',
            opacity: 0.9,
          },
          enter: {
            opacity: 1,
          },
          leave: {
            opacity: 0.9,
          },
          count: 7,
          config: {
            duration: 1000,
          },
        },
      },
      {
        text: 'KEI',
        animation: {
          from: {
            x: 90,
            y: 0,
            fill: '#7F7F7F',
            fontSize: 18,
            rotation: 90,
            fontFamily: 'Lato',
          },
          config: {
            duration: 0,
          },
        },
      },
      {
        text: 'KEI',
        animation: {
          from: {
            x: 90,
            y: 1250,
            fill: '#7F7F7F',
            fontSize: 18,
            rotation: 90,
            fontFamily: 'Lato',
          },
          config: {
            duration: 0,
          },
        },
      },
      {
        text: '#BRANDCOLLECTION',
        animation: {
          from: {
            x: 207,
            y: 1100,
            fontSize: 30,
            fontFamily: 'Lato',
            fontWeight: '500',
          },
          config: {
            duration: 0,
          },
        },
      },
      {
        text: 'TOTAL LOOK',
        animation: {
          from: {
            x: 269,
            y: 96,
            fontSize: 30,
            fontFamily: 'Lato',
            fontWeight: '500',
          },
          config: {
            duration: 0,
          },
        },
      },
      {
        text: 'MONDAY',
        animation: {
          from: {
            x: 90,
            y: 210,
            fill: '#000000',
            fontSize: 20,
            rotation: 90,
          },
          delay: 1000,
          enter: {
            fill: '#7F7F7F',
          },
          config: {
            duration: 0,
          },
        },
      },
      {
        text: 'TUESDAY',
        animation: {
          from: {
            x: 90,
            y: 335,
            fill: '#7F7F7F',
            fontSize: 20,
            rotation: 90,
          },
          delay: 1000,
          enter: {
            fill: '#000000',
          },
          config: {
            duration: 0,
          },
          update: {
            fill: '#7F7F7F',
          },
        },
        updateInterval: 1000,
      },
      {
        text: 'WEDNESDAY',
        animation: {
          from: {
            x: 90,
            y: 459,
            fill: '#7F7F7F',
            fontSize: 20,
            rotation: 90,
          },
          delay: 2000,
          enter: {
            fill: '#000000',
          },
          config: {
            duration: 0,
          },
          update: {
            fill: '#7F7F7F',
          },
        },
        updateInterval: 1000,
      },
      {
        text: 'THURSDAY',
        animation: {
          from: {
            x: 90,
            y: 616,
            fill: '#7F7F7F',
            fontSize: 20,
            rotation: 90,
          },
          delay: 3000,
          enter: {
            fill: '#000000',
          },
          config: {
            duration: 0,
          },
          update: {
            fill: '#7F7F7F',
          },
        },
        updateInterval: 1000,
      },
      {
        text: 'FRIDAY',
        animation: {
          from: {
            x: 90,
            y: 755,
            fill: '#7F7F7F',
            fontSize: 20,
            rotation: 90,
          },
          delay: 4000,
          enter: {
            fill: '#000000',
          },
          config: {
            duration: 0,
          },
          update: {
            fill: '#7F7F7F',
          },
        },
        updateInterval: 1000,
      },
      {
        text: 'SATURDAY',
        animation: {
          from: {
            x: 90,
            y: 861,
            fill: '#7F7F7F',
            fontSize: 20,
            rotation: 90,
          },
          delay: 5000,
          enter: {
            fill: '#000000',
          },
          config: {
            duration: 0,
          },
          update: {
            fill: '#7F7F7F',
          },
        },
        updateInterval: 1000,
      },
      {
        text: 'SUNDAY',
        animation: {
          from: {
            x: 90,
            y: 997,
            fill: '#7F7F7F',
            fontSize: 20,
            rotation: 90,
          },
          delay: 6000,
          enter: {
            fill: '#000000',
          },
          config: {
            duration: 0,
          },
          update: {
            fill: '#7F7F7F',
          },
        },
        updateInterval: 1000,
      },
    ],
    stageStyle: {
      backgroundColor: '#fff',
    },
    productCount: 7,
    channableElems: [
      {
        id: 'images-1',
        slot: 0,
        type: 'IMAGES',
        count: 7,
        required: true,
        idealSize: '420x860',
        imageCount: 7,
      },
    ],
    screenDimensions: {
      width: 720,
      height: 1280,
    },
  },
  previewPath:
    'https://cnvs.s3.eu-central-1.amazonaws.com/cdn/design/video/weekday.gif',
};

const animation8 = {
  id: 52,
  name: 'Change Product',
  productCount: 6,
  duration: 6000,
  screenDimensions: {
    width: 720,
    height: 1280,
  },
  stageStyle: {
    backgroundColor: '#fff',
  },
  animation: [
    {
      id: 'images1',
      images: [
        'https://cnvs.s3.eu-central-1.amazonaws.com/cdn/templates/Frame42.webp',
        'https://cnvs.s3.eu-central-1.amazonaws.com/cdn/templates/Frame42-1.webp',
        'https://cnvs.s3.eu-central-1.amazonaws.com/cdn/templates/Frame42-2.webp',
        'https://cnvs.s3.eu-central-1.amazonaws.com/cdn/templates/Frame42-3.webp',
        'https://cnvs.s3.eu-central-1.amazonaws.com/cdn/templates/Frame42-4.webp',
        'https://cnvs.s3.eu-central-1.amazonaws.com/cdn/templates/Frame42-5.webp',
      ],
      animation: {
        from: {
          x: 720 / 2 - 310,
          y: 154,
          width: 620,
          height: 893,
          opacity: 0.9,
        },
        enter: {
          opacity: 1,
        },
        leave: {
          opacity: 0.9,
        },
        config: { duration: 1000 },
        count: 6,
      },
    },
    {
      text: 'Your Brand',
      id: 'text-1',
      animation: {
        from: {
          x: 570,
          y: 1127,
          fontSize: 20,
          fontFamily: 'Lato',
          fontWeight: '600',
        },
        config: { duration: 1 },
      },
    },
    {
      id: 'texts-1',
      texts: [
        'YELLOW CHIFFON DRESS',
        'SATIN SLEEVELESS LONG DRESS',
        'BLACK CREPE SLEEVELESS SHORT DRESS',
        'PATTERNED SHORT SLEEVE MEDIUM LENGHT DRESS',
        'SATIN SLEEVELESS SHORT DRESS',
        'SHINY SEQUIN SHORT DRESS',
      ],
      animation: {
        from: {
          x: 50,
          y: 1127,
          fontSize: 20,
          fontFamily: 'Lato',
          fontWeight: '500',
          opacity: 0.9,
        },
        enter: {
          opacity: 1,
        },
        leave: {
          opacity: 0.9,
        },
        config: { duration: 1000 },
        count: 6,
      },
    },
    {
      id: 'texts-2',
      texts: ['$129', '$239', '$249', '$329', '$249', '$299'],
      animation: {
        from: {
          x: 50,
          y: 1100,
          fontSize: 20,
          fontFamily: 'Lato',
          fontWeight: '700',
          opacity: 0.9,
        },
        enter: {
          opacity: 1,
        },
        leave: {
          opacity: 0.9,
        },

        config: { duration: 1000 },
        count: 6,
      },
    },

    {
      type: 'line',
      animation: {
        from: {
          stroke: 'black',
          fill: 'black',
          strokeWidth: 1,
          height: 620,
          x: 50,
          y: 1157,
          points: [0, 0, 0, 620],
          rotation: -90,
        },
        config: { duration: 1 },
      },
    },
  ],
  channableElems: [
    {
      id: 'images1',
      type: 'IMAGES',
      slot: 0,
      count: 6,
      xmlType: 'imageLink',
      required: true,
      idealSize: '620x893',
    },
    {
      id: 'text-1',
      type: 'TEXT',
      slot: 1,
      xmlType: 'g:brand',
    },
    {
      id: 'texts-1',
      type: 'TEXTS',
      slot: 2,
      count: 6,
      xmlType: 'title',
      required: true,
    },
    {
      id: 'texts-2',
      type: 'TEXTS',
      slot: 3,
      count: 6,
      xmlType: 'G:price',
      required: true,
    },
  ],
};
const animation7 = {
  id: 7,
  name: 'Animation 7',
  screenDimensions: {
    width: 1080,
    height: 1080,
  },
  stageStyle: {
    backgroundColor: '#fff',
  },
  duration: 4800,
  animation: [
    {
      id: 'image-1',
      image:
        'https://cnvs.s3.eu-central-1.amazonaws.com/cdn/site-assets/products/product-2.jpg',
      animation: {
        from: {
          opacity: 0.8,
          width: 1000,
          height: 800,
          scaleX: 1,
          scaleY: 1,
          x: 0,
          y: 0,
          cornerRadius: 32,
          transformOrigin: 'center',
        },
        enter: {
          opacity: 1,
          scaleX: 0.5,
          scaleY: 0.5,
          x: 290,
          y: 340,
        },
        config: {
          duration: 1000,
          tension: 170,
          mass: 1,
          friction: 26,
          velocity: 0,
          precision: 0.01,
        },
      },
    },
    {
      id: 'image-2',
      image:
        'https://cnvs.s3.eu-central-1.amazonaws.com/cdn/site-assets/products/product-1.jpg',
      animation: {
        from: {
          opacity: 1,
          width: 200,
          x: -300,
          y: 45,
          height: 300,
          cornerRadius: 12,
        },
        enter: { opacity: 1, x: 45 },
        config: { duration: 1300 },
        delay: 800,
      },
    },
    {
      id: 'image-3',
      image:
        'https://cnvs.s3.eu-central-1.amazonaws.com/cdn/site-assets/products/product-3.jpg',
      animation: {
        from: {
          opacity: 1,
          width: 200,
          height: 300,
          x: -300,
          y: 390,
          cornerRadius: 12,
        },
        enter: { opacity: 1, x: 45 },
        config: { duration: 1300 },
        delay: 800,
      },
    },
    {
      id: 'image-4',
      image:
        'https://cnvs.s3.eu-central-1.amazonaws.com/cdn/site-assets/products/product-4.jpg',
      animation: {
        from: {
          opacity: 1,
          width: 200,
          height: 300,
          x: 45,
          y: 1350,
          cornerRadius: 12,
        },
        enter: { opacity: 1, y: 735, x: 45 },
        config: { duration: 1300 },
        delay: 800,
      },
    },
    {
      id: 'image-5',
      image:
        'https://cnvs.s3.eu-central-1.amazonaws.com/cdn/site-assets/products/product-5.jpg',
      animation: {
        from: {
          opacity: 1,
          width: 200,
          height: 300,
          x: 835,
          y: -300,
          cornerRadius: 12,
        },
        enter: { opacity: 1, x: 835, y: 45 },
        config: { duration: 1300 },
        delay: 800,
      },
    },
    {
      id: 'image-6',
      image:
        'https://cnvs.s3.eu-central-1.amazonaws.com/cdn/site-assets/products/product-6.jpg',
      animation: {
        from: {
          opacity: 1,
          width: 200,
          height: 300,
          x: 1200,
          y: 390,
          cornerRadius: 12,
        },
        enter: { opacity: 1, x: 835 },
        config: { duration: 1300 },
        delay: 800,
      },
    },
    {
      id: 'image-7',
      image:
        'https://cnvs.s3.eu-central-1.amazonaws.com/cdn/site-assets/products/product-7.jpg',
      animation: {
        from: {
          opacity: 1,
          width: 200,
          height: 300,
          x: 835,
          y: 1380,
          cornerRadius: 12,
        },
        enter: { opacity: 1, x: 835, y: 735 },
        config: { duration: 1300 },
        delay: 800,
      },
    },
    {
      id: 'image-8',
      image:
        'https://cnvs.s3.eu-central-1.amazonaws.com/cdn/site-assets/products/product-8.jpg',
      animation: {
        from: {
          opacity: 1,
          width: 500,
          height: 250,
          x: 290,
          y: -300,
          cornerRadius: 12,
        },
        enter: { opacity: 1, y: 45 },
        config: { duration: 1300 },
        delay: 800,
      },
    },

    {
      id: 'image-9',
      image:
        'https://cnvs.s3.eu-central-1.amazonaws.com/cdn/site-assets/products/product-9.jpg',
      animation: {
        from: {
          opacity: 1,
          width: 500,
          height: 250,
          x: 290,
          y: 1300,
          cornerRadius: 12,
        },
        enter: { opacity: 1, y: 785 },
        config: { duration: 1300 },
        delay: 800,
      },
    },
    {
      id: 'image-10',
      image:
        'https://cnvs.s3.eu-central-1.amazonaws.com/cdn/site-assets/1080x1080-siyah.jpg',
      animation: {
        from: {
          opacity: 0,
          width: 1080,
          height: 1080,
        },
        enter: { opacity: 1 },
        config: { duration: 500 },
        delay: 4000,
      },
    },
    {
      id: 'logo-1',
      image:
        'https://cnvs.s3.eu-central-1.amazonaws.com/cdn/site-assets/1080x1080icon%2Btext.svg',
      animation: {
        from: {
          opacity: 0,
          width: 560,
          height: 128,
          x: 260,
          y: 476,
        },
        enter: { opacity: 1 },
        config: { duration: 500 },
        delay: 4300,
      },
    },
  ],
  channableElems: [
    {
      id: 'image-1',
      type: 'IMAGE',
      idealSize: '1000x800',
      slot: 0,
      required: true,
    },
    {
      id: 'image-2',
      type: 'IMAGE',
      idealSize: '200x300',
      slot: 1,
      required: true,
    },
    {
      id: 'image-3',
      type: 'IMAGE',
      idealSize: '200x300',
      slot: 2,
      required: true,
    },
    {
      id: 'image-4',
      type: 'IMAGE',
      idealSize: '200x300',
      slot: 3,
      required: true,
    },
    {
      id: 'image-5',
      type: 'IMAGE',
      idealSize: '200x300',
      slot: 4,
      required: true,
    },
    {
      id: 'image-6',
      type: 'IMAGE',
      idealSize: '200x300',
      slot: 5,
      required: true,
    },
    {
      id: 'image-7',
      type: 'IMAGE',
      idealSize: '200x300',
      slot: 6,
      required: true,
    },
    {
      id: 'image-8',
      type: 'IMAGE',
      idealSize: '500x250',
      slot: 7,
      required: true,
    },
    {
      id: 'image-9',
      type: 'IMAGE',
      idealSize: '500x250',
      slot: 8,
      required: true,
    },
    {
      id: 'logo-1',
      type: 'IMAGE',
      slot: 10,
      required: true,
    },
  ],
};

const galleryAnimation = {
  id: 52,
  name: 'Gallery Animation',
  screenDimensions: {
    width: 720,
    height: 1280,
  },
  stageStyle: {
    backgroundColor: '#fff',
  },
  animation: [
    {
      images: [
        'https://cnvs.s3.eu-central-1.amazonaws.com/cdn/templates/v-1.webp',
        'https://cnvs.s3.eu-central-1.amazonaws.com/cdn/templates/v-2.webp',
        'https://cnvs.s3.eu-central-1.amazonaws.com/cdn/templates/v-3.webp',
        'https://cnvs.s3.eu-central-1.amazonaws.com/cdn/templates/v-4.webp',
        'https://cnvs.s3.eu-central-1.amazonaws.com/cdn/templates/v-5.webp',
      ],
      animation: {
        from: {
          x: 720 / 2 - 310,
          y: 50,
          width: 620,
          height: 890,
        },
        config: { duration: 1000 },
        count: 5,
      },
    },
    {
      image:
        'https://cnvs.s3.eu-central-1.amazonaws.com/cdn/templates/v-6.webp',
      animation: {
        from: {
          width: 116,
          height: 165,
          x: 50,
          y: 951,
          stroke: '#000000',
          strokeWidth: 2,
        },
        enter: {
          strokeWidth: 0,
        },
        delay: 1000,
        config: { duration: 0 },
      },
    },

    {
      image:
        'https://cnvs.s3.eu-central-1.amazonaws.com/cdn/templates/v-7.webp',
      updateInterval: 1000,
      animation: {
        from: {
          width: 116,
          height: 165,
          x: 176,
          y: 951,
          stroke: '#000000',
          strokeWidth: 0,
        },
        enter: {
          strokeWidth: 2,
        },
        update: {
          strokeWidth: 0,
        },
        config: { duration: 0 },

        delay: 1000,
      },
    },
    {
      updateInterval: 1000,
      image:
        'https://cnvs.s3.eu-central-1.amazonaws.com/cdn/templates/v-8.webp',
      animation: {
        from: {
          width: 116,
          height: 165,
          x: 302,
          y: 951,
          stroke: '#000000',
          strokeWidth: 0,
        },
        enter: {
          strokeWidth: 2,
        },
        update: {
          strokeWidth: 0,
        },
        config: { duration: 0 },

        delay: 2000,
      },
    },
    {
      updateInterval: 1000,

      image:
        'https://cnvs.s3.eu-central-1.amazonaws.com/cdn/templates/v-9.webp',
      animation: {
        from: {
          width: 116,
          height: 165,
          x: 428,
          y: 951,
          stroke: '#000000',
          strokeWidth: 0,
        },
        enter: {
          strokeWidth: 2,
        },
        update: {
          strokeWidth: 0,
        },
        config: { duration: 0 },

        delay: 3000,
      },
    },
    {
      updateInterval: 1000,

      image:
        'https://cnvs.s3.eu-central-1.amazonaws.com/cdn/templates/v-10.webp',
      animation: {
        from: {
          width: 116,
          height: 165,
          x: 554,
          y: 951,
          stroke: '#000000',
          strokeWidth: 0,
        },
        enter: {
          strokeWidth: 2,
        },
        update: {
          strokeWidth: 0,
        },
        config: { duration: 0 },

        delay: 4000,
      },
    },
    {
      image:
        'https://cnvs.s3.eu-central-1.amazonaws.com/cdn/templates/v-bottom-bar.png',
      animation: {
        from: {
          width: 582,
          height: 78,
          x: 69,
          y: 1166,
        },
        config: { duration: 0 },
      },
    },
  ],
  channableElems: [
    {
      id: 'images-1',
      type: 'IMAGES',
      slot: 0,
      imageCount: 5,
      required: true,
      idealSize: '620x890',
    },
    {
      id: 'thumbImage-1',
      type: 'thumbImage',
      slot: 1,
      required: true,
      idealSize: '116x165',
    },
    {
      id: 'thumbImage-2',
      type: 'thumbImage',
      slot: 2,
      required: true,
      idealSize: '116x165',
    },
    {
      id: 'thumbImage-3',
      type: 'thumbImage',
      slot: 3,
      required: true,
      idealSize: '116x165',
    },
    {
      id: 'thumbImage-4',
      type: 'thumbImage',
      slot: 4,
      required: true,
      idealSize: '116x165',
    },
    {
      id: 'thumbImage-5',
      type: 'thumbImage',
      slot: 5,
      required: true,
      idealSize: '116x165',
    },
  ],
};

const gridAnimation = {
  id: 10,
  name: 'Grid Animation',
  screenDimensions: {
    width: 720,
    height: 900,
  },
  channableElems: [
    {
      id: 'image-1',
      slot: 2,
      required: true,
      placeholderImage: 'Image 1',
      image: null,
    },
    {
      id: 'image-2',
      slot: 3,
      required: true,
      placeholderImage: 'Image 1',
      image: null,
    },
    {
      id: 'image-3',
      slot: 4,
      required: true,
      placeholderImage: 'Image 1',
      image: null,
    },
    {
      id: 'image-4',
      slot: 5,
      required: true,
      placeholderImage: 'Image 1',
      image: null,
    },
    {
      id: 'image-5',
      slot: 6,
      required: true,
      placeholderImage: 'Image 1',
      image: null,
    },
    {
      id: 'image-6',
      slot: 7,
      required: true,
      placeholderImage: 'Image 1',
      image: null,
    },
    {
      id: 'image-7',
      slot: 8,
      required: true,
      placeholderImage: 'Image 1',
      image: null,
    },
    {
      id: 'image-8',
      slot: 9,
      required: true,
      placeholderImage: 'Image 1',
      image: null,
    },
  ],
  stageStyle: {
    backgroundColor: '#fff',
  },
  animation: [
    {
      text: 'Rising',
      animation: {
        from: {
          fontSize: 30,
          fontFamily: 'Lato',
          color: 'gray',
          opacity: 0,
          x: 298,
          y: 429,
          textAlign: 'left',
        },
        enter: {
          opacity: 1,
          color: 'gray',
          y: 900 / 2 - 20,
        },
        update: {
          color: 'black',
        },
        config: { duration: 900 },
        delay: 200,
      },
    },
    {
      text: 'Up',
      animation: {
        from: {
          fontSize: 30,
          fontFamily: 'Lato',
          color: 'gray',
          opacity: 0,
          x: 385,
          y: 429,
          textAlign: 'left',
        },
        enter: {
          opacity: 1,
          color: 'gray',
          y: 900 / 2 - 20,
        },
        update: {
          color: 'black',
        },

        config: { duration: 900 },
        delay: 1100,
      },
    },
    {
      image:
        'https://cnvs.s3.eu-central-1.amazonaws.com/cdn/site-assets/products/product-10.png',

      animation: {
        from: {
          x: 40,
          y: 40,
          opacity: 0,
          width: 200,
          height: 260,
        },
        enter: { opacity: 1 },
        update: { opacity: 1 },
        config: { duration: 400 },
        delay: 2000,
      },
    },
    {
      image:
        'https://cnvs.s3.eu-central-1.amazonaws.com/cdn/site-assets/products/product-11.png',

      animation: {
        from: {
          x: 260,
          y: 40,
          opacity: 0,
          width: 200,
          height: 260,
        },
        enter: { opacity: 1 },
        update: { opacity: 1 },
        config: { duration: 400 },
        delay: 2150,
      },
    },
    {
      image:
        'https://cnvs.s3.eu-central-1.amazonaws.com/cdn/site-assets/products/product-12.png',

      animation: {
        from: {
          x: 480,
          y: 40,
          opacity: 0,
          width: 200,
          height: 260,
        },
        enter: { opacity: 1 },
        update: { opacity: 1 },
        config: { duration: 400 },
        delay: 2300,
      },
    },
    {
      image:
        'https://cnvs.s3.eu-central-1.amazonaws.com/cdn/site-assets/products/product-13.png',

      animation: {
        from: {
          x: 480,
          y: 320,
          opacity: 0,
          width: 200,
          height: 260,
        },
        enter: { opacity: 1 },
        update: { opacity: 1 },
        config: { duration: 400 },
        delay: 2450,
      },
    },
    {
      image:
        'https://cnvs.s3.eu-central-1.amazonaws.com/cdn/site-assets/products/product-14.png',

      animation: {
        from: {
          x: 480,
          y: 600,
          opacity: 0,
          width: 200,
          height: 260,
        },
        enter: { opacity: 1 },
        update: { opacity: 1 },
        config: { duration: 400 },
        delay: 2600,
      },
    },
    {
      image:
        'https://cnvs.s3.eu-central-1.amazonaws.com/cdn/site-assets/products/product-15.png',

      animation: {
        from: {
          x: 260,
          y: 600,
          opacity: 0,
          width: 200,
          height: 260,
        },
        enter: { opacity: 1 },
        update: { opacity: 1 },
        config: { duration: 400 },
        delay: 2750,
      },
    },
    {
      image:
        'https://cnvs.s3.eu-central-1.amazonaws.com/cdn/site-assets/products/product-16.png',

      animation: {
        from: {
          x: 40,
          y: 600,
          opacity: 0,
          width: 200,
          height: 260,
        },
        enter: { opacity: 1 },
        config: { duration: 400 },
        delay: 2900,
      },
    },
    {
      image:
        'https://cnvs.s3.eu-central-1.amazonaws.com/cdn/site-assets/products/product-17.png',

      animation: {
        from: {
          x: 40,
          y: 320,
          opacity: 0,
          width: 200,
          height: 260,
        },
        enter: { opacity: 1 },
        config: { duration: 400 },
        delay: 3050,
      },
    },
  ],
};

const animationKeikei = {
  id: 52,
  name: 'Volkan Change4',
  duration: 6000,
  animation: [
    {
      images: [
        'https://i6.keikei.co/cdn/products/6/66898/media-libraryOlTiEP',
        'https://i6.keikei.co/cdn/products/6/66978/media-libraryCmmdGQ',
        'https://i7.keikei.co/cdn/products/7/71172/965011_036_HA.webp',
        'https://i7.keikei.co/cdn/products/7/72376/965300_040_HA.webp',
        'https://i7.keikei.co/cdn/products/7/72426/961788_029_HA.webp',
        'https://i6.keikei.co/cdn/products/6/66962/965011_001_A.webp',
      ],
      animation: {
        from: {
          x: 50,
          y: 154,
          width: 620,
          height: 893,
          opacity: 0.9,
        },
        enter: {
          opacity: 1,
        },
        leave: {
          opacity: 0.9,
        },
        count: 6,
        config: {
          duration: 1000,
        },
      },
    },
    {
      text: 'KeiKei',
      animation: {
        from: {
          x: 570,
          y: 1127,
          fontSize: 20,
          fontFamily: 'Lato',
          fontWeight: '600',
        },
        config: {
          duration: 0,
        },
      },
    },
    {
      texts: [
        'KeiKei Siyah Saten Kolsuz Kısa Elbise',
        'KeiKei Fuşya Saten Kolsuz Kısa Elbise',
        'KeiKei Saks Mavisi Saten Kolsuz Kısa Elbise',
        'KeiKei Pudra Moonlight Uzun Kol Kısa Elbise',
        'KeiKei Altın Büyük Beden Moonlight Uzun Kol Kısa Elbise',
        'KeiKei Siyah Saten Kolsuz Kısa Elbise',
      ],
      animation: {
        from: {
          x: 50,
          y: 1127,
          fontSize: 20,
          fontFamily: 'Lato',
          fontWeight: '500',
        },
        count: 6,
        config: {
          duration: 1000,
        },
      },
    },
    {
      texts: [
        '1679.90 TRY',
        '1999.90 TRY',
        '1999.90 TRY',
        '2159.90 TRY',
        '2239.90 TRY',
        '1999.90 TRY',
      ],
      animation: {
        from: {
          x: 50,
          y: 1100,
          fontSize: 20,
          fontFamily: 'Lato',
          fontWeight: '700',
        },
        count: 6,
        config: {
          duration: 1000,
        },
      },
    },
    {
      type: 'line',
      animation: {
        from: {
          x: 50,
          y: 1157,
          fill: 'black',
          height: 620,
          points: [0, 0, 0, 620],
          stroke: 'black',
          rotation: -90,
          strokeWidth: 1,
        },
        config: {
          duration: 0,
        },
      },
    },
  ],
  stageStyle: {
    backgroundColor: '#fff',
  },
  channableElems: [
    {
      id: 'images-1',
      slot: 0,
      type: 'IMAGES',
      count: 6,
      xmlType: 'imageLink',
      required: true,
      idealSize: '620x893',
    },
    {
      id: 'text-1',
      slot: 1,
      type: 'TEXT',
      xmlType: 'brand',
    },
    {
      id: 'texts-1',
      slot: 2,
      type: 'TEXTS',
      count: 6,
      xmlType: 'title',
      required: true,
    },
    {
      id: 'texts-2',
      slot: 3,
      type: 'TEXTS',
      count: 6,
      xmlType: 'price',
      required: true,
    },
  ],
  screenDimensions: {
    width: 720,
    height: 1280,
  },
};

const animationKeikei2 = {
  id: 52,
  name: 'Volkan Change4',
  duration: 5000,
  animation: [
    {
      images: [
        'https://i6.keikei.co/cdn/products/6/66898/media-libraryOlTiEP',
        'https://i6.keikei.co/cdn/products/6/66978/media-libraryCmmdGQ',
        'https://i7.keikei.co/cdn/products/7/71172/965011_036_HA.webp',
        'https://i7.keikei.co/cdn/products/7/72376/965300_040_HA.webp',
        'https://i7.keikei.co/cdn/products/7/72426/961788_029_HA.webp',
        'https://i7.keikei.co/cdn/products/7/72607/965260_033_HA.webp',
      ],
      animation: {
        from: {
          x: 50,
          y: 154,
          width: 620,
          height: 893,
          opacity: 0.9,
        },
        enter: {
          opacity: 1,
        },
        leave: {
          opacity: 0.9,
        },
        count: 6,
        config: {
          duration: 1000,
        },
      },
    },
    {
      text: 'KeiKei',
      animation: {
        from: {
          x: 570,
          y: 1127,
          fontSize: 20,
          fontFamily: 'Lato',
          fontWeight: '600',
        },
        config: {
          duration: 1,
        },
      },
    },
    {
      texts: [
        'KeiKei Siyah Saten Kolsuz Kısa Elbise',
        'KeiKei Fuşya Saten Kolsuz Kısa Elbise',
        'KeiKei Saks Mavisi Saten Kolsuz Kısa Elbise',
        'KeiKei Pudra Moonlight Uzun Kol Kısa Elbise',
        'KeiKei Altın Büyük Beden Moonlight Uzun Kol Kısa Elbise',
        'KeiKei Black Silver Moonlight Kolsuz Uzun Elbise',
      ],
      animation: {
        from: {
          x: 50,
          y: 1127,
          fontSize: 20,
          fontFamily: 'Lato',
          fontWeight: '500',
          opacity: 0.9,
        },
        enter: {
          opacity: 1,
        },
        leave: {
          opacity: 0.9,
        },
        count: 6,
        config: {
          duration: 1000,
        },
      },
    },
    {
      texts: [
        '1679.90 TRY',
        '1999.90 TRY',
        '1999.90 TRY',
        '2159.90 TRY',
        '2239.90 TRY',
        '3039.90 TRY',
      ],
      animation: {
        from: {
          x: 50,
          y: 1100,
          fontSize: 20,
          fontFamily: 'Lato',
          fontWeight: '700',
          opacity: 0.9,
        },
        enter: { opacity: 1 },
        leave: { opacity: 0.9 },
        count: 6,
        config: {
          duration: 1000,
        },
      },
    },
    {
      type: 'line',
      animation: {
        from: {
          x: 50,
          y: 1157,
          fill: 'black',
          height: 620,
          points: [0, 0, 0, 620],
          stroke: 'black',
          rotation: -90,
          strokeWidth: 1,
        },
        config: {
          duration: 0,
        },
      },
    },
  ],
  stageStyle: {
    backgroundColor: '#fff',
  },
  channableElems: [
    {
      id: 'images-1',
      slot: 0,
      type: 'IMAGES',
      count: 6,
      xmlType: 'imageLink',
      required: true,
      idealSize: '620x893',
    },
    {
      id: 'text-1',
      slot: 1,
      type: 'TEXT',
      xmlType: 'brand',
    },
    {
      id: 'texts-1',
      slot: 2,
      type: 'TEXTS',
      count: 6,
      xmlType: 'title',
      required: true,
    },
    {
      id: 'texts-2',
      slot: 3,
      type: 'TEXTS',
      count: 6,
      xmlType: 'price',
      required: true,
    },
  ],
  screenDimensions: {
    width: 720,
    height: 1280,
  },
};
const animationV = {
  id: 52,
  name: 'Volkan Lastin lasti',
  screenDimensions: {
    width: 720,
    height: 1280,
  },
  stageStyle: {
    backgroundColor: '#fff',
  },
  productCount: 6,
  channableElems: [
    {
      id: 'images-1',
      slot: 0,
      type: 'IMAGES',
      imageCount: 6,
      count: 6,
      idealSize: '500x640',
    },
    {
      id: 'texts-1',
      slot: 1,
      type: 'TEXTS',
      imageCount: 6,
      count: 6,
    },
  ],
  animation: [
    {
      id: 'images-1',
      images: [
        'https://cnvs.s3.eu-central-1.amazonaws.com/cdn/site-assets/highlights-1.png',
        'https://cnvs.s3.eu-central-1.amazonaws.com/cdn/site-assets/highlights-2.png',
        'https://cnvs.s3.eu-central-1.amazonaws.com/cdn/site-assets/highlights-3.png',
        'https://cnvs.s3.eu-central-1.amazonaws.com/cdn/site-assets/highlights-4.png',
        'https://cnvs.s3.eu-central-1.amazonaws.com/cdn/site-assets/highlights-5.png',
        'https://cnvs.s3.eu-central-1.amazonaws.com/cdn/site-assets/highlights-6.png',
      ],
      animation: {
        from: {
          width: 500,
          height: 640,
          x: 720 / 2 - 250,
          y: 1280 / 2 - 320,
        },
        enter: {
          opacity: 0.9,
        },

        leave: {
          opacity: 0.9,
        },

        config: { duration: 2000 },
        count: 6,
        delay: 2000,
      },
    },
    {
      // updateInterval: 2000,
      id: 'texts-1',
      texts: [
        'Prod Name 1',
        'Prod Name 2',
        'Prod Name 3',
        'Prod Name 4',
        'Prod Name 5',
        'Prod Name 6',
      ],
      animation: {
        from: {
          x: 0,
          y: 1010,
          fontSize: 30,
          fontFamily: 'Lato',
          fontWeight: '500',
          width: 720,
          align: 'center',
        },
        enter: {
          opacity: 0.9,
        },

        leave: {
          opacity: 0.9,
        },
        config: { duration: 2000 },
        delay: 2000,
        count: 6,
      },
    },
    {
      text: 'HIGHLIGHTS',
      updateInterval: 1000,
      animation: {
        from: {
          x: 0,
          y: 520,
          fontSize: 50,
          fontFamily: 'Lato',
          fontWeight: '700',
          width: 720,
          align: 'center',
          opacity: 0,
        },
        enter: {
          opacity: 0.9,
        },
        update: {
          opacity: 1,
          y: 150,
        },
        config: { duration: 1000 },
        delay: 0,
      },
    },

    {
      text: 'BY',
      updateInterval: 1000,
      animation: {
        from: {
          x: 0,
          y: 610,
          fontSize: 50,
          fontFamily: 'Lato',
          fontWeight: '500',
          fontStyle: 'italic',
          width: 720,
          align: 'center',
          opacity: 0,
        },
        enter: {
          opacity: 0.9,
        },
        update: {
          opacity: 0,
        },
        config: { duration: 1000 },
        delay: 0,
      },
    },
    {
      text: 'NEW COLLECTION',
      updateInterval: 1000,
      animation: {
        from: {
          x: 0,
          y: 700,
          fontSize: 50,
          fontFamily: 'Lato',
          fontWeight: '700',
          width: 720,
          align: 'center',
          opacity: 0,
        },
        enter: {
          opacity: 0.9,
        },
        update: {
          opacity: 1,
          y: 1100,
        },
        config: { duration: 1000 },
        delay: 0,
      },
    },
  ],
};

export const weekLook = {
  id: '2a4d0976-65a8-435a-8a4f-d781665f95f4',
  name: null,
  type: 'VIDEO',
  template: {
    id: 1,
    name: 'Week Look3',
    duration: 7000,
    animation: [
      {
        id: 'images-1',
        images: [
          'https://cnvs.s3.eu-central-1.amazonaws.com/cdn/templates/Frame-17.webp',
          'https://cnvs.s3.eu-central-1.amazonaws.com/cdn/templates/Frame-18.webp',
          'https://cnvs.s3.eu-central-1.amazonaws.com/cdn/templates/Frame-19.webp',
          'https://cnvs.s3.eu-central-1.amazonaws.com/cdn/templates/Frame-20.webp',
          'https://cnvs.s3.eu-central-1.amazonaws.com/cdn/templates/Frame-21.webp',
          'https://cnvs.s3.eu-central-1.amazonaws.com/cdn/templates/Frame-22.webp',
          'https://cnvs.s3.eu-central-1.amazonaws.com/cdn/templates/Frame-23.webp',
        ],
        animation: {
          from: {
            x: 150,
            y: 210,
            width: 420,
            height: 860,
            opacity: 0.9,
          },
          enter: {
            opacity: 1,
          },
          leave: {
            opacity: 0.9,
          },
          count: 7,
          config: {
            duration: 1000,
          },
        },
      },
      {
        texts: ['01', '02', '03', '04', '05', '06', '07'],
        animation: {
          from: {
            x: 343,
            y: 138,
            fontSize: 30,
            fontFamily: 'Lato',
            fontWeight: '500',
            opacity: 0.9,
          },
          enter: {
            opacity: 1,
          },
          leave: {
            opacity: 0.9,
          },
          count: 7,
          config: {
            duration: 1000,
          },
        },
      },
      {
        text: 'KEI',
        animation: {
          from: {
            x: 90,
            y: 0,
            fill: '#7F7F7F',
            fontSize: 18,
            rotation: 90,
            fontFamily: 'Lato',
          },
          config: {
            duration: 0,
          },
        },
      },
      {
        text: 'KEI',
        animation: {
          from: {
            x: 90,
            y: 1250,
            fill: '#7F7F7F',
            fontSize: 18,
            rotation: 90,
            fontFamily: 'Lato',
          },
          config: {
            duration: 0,
          },
        },
      },
      {
        text: '#BRANDCOLLECTION',
        animation: {
          from: {
            x: 207,
            y: 1100,
            fontSize: 30,
            fontFamily: 'Lato',
            fontWeight: '500',
          },
          config: {
            duration: 0,
          },
        },
      },
      {
        text: 'TOTAL LOOK',
        animation: {
          from: {
            x: 269,
            y: 96,
            fontSize: 30,
            fontFamily: 'Lato',
            fontWeight: '500',
          },
          config: {
            duration: 0,
          },
        },
      },
      {
        text: 'MONDAY',
        animation: {
          from: {
            x: 90,
            y: 210,
            fill: '#000000',
            fontSize: 20,
            rotation: 90,
          },
          delay: 1000,
          enter: {
            fill: '#7F7F7F',
          },
          config: {
            duration: 0,
          },
        },
      },
      {
        text: 'TUESDAY',
        animation: {
          from: {
            x: 90,
            y: 335,
            fill: '#7F7F7F',
            fontSize: 20,
            rotation: 90,
          },
          delay: 1000,
          enter: {
            fill: '#000000',
          },
          config: {
            duration: 0,
          },
          update: {
            fill: '#7F7F7F',
          },
        },
        updateInterval: 1000,
      },
      {
        text: 'WEDNESDAY',
        animation: {
          from: {
            x: 90,
            y: 459,
            fill: '#7F7F7F',
            fontSize: 20,
            rotation: 90,
          },
          delay: 2000,
          enter: {
            fill: '#000000',
          },
          config: {
            duration: 0,
          },
          update: {
            fill: '#7F7F7F',
          },
        },
        updateInterval: 1000,
      },
      {
        text: 'THURSDAY',
        animation: {
          from: {
            x: 90,
            y: 616,
            fill: '#7F7F7F',
            fontSize: 20,
            rotation: 90,
          },
          delay: 3000,
          enter: {
            fill: '#000000',
          },
          config: {
            duration: 0,
          },
          update: {
            fill: '#7F7F7F',
          },
        },
        updateInterval: 1000,
      },
      {
        text: 'FRIDAY',
        animation: {
          from: {
            x: 90,
            y: 755,
            fill: '#7F7F7F',
            fontSize: 20,
            rotation: 90,
          },
          delay: 4000,
          enter: {
            fill: '#000000',
          },
          config: {
            duration: 0,
          },
          update: {
            fill: '#7F7F7F',
          },
        },
        updateInterval: 1000,
      },
      {
        text: 'SATURDAY',
        animation: {
          from: {
            x: 90,
            y: 861,
            fill: '#7F7F7F',
            fontSize: 20,
            rotation: 90,
          },
          delay: 5000,
          enter: {
            fill: '#000000',
          },
          config: {
            duration: 0,
          },
          update: {
            fill: '#7F7F7F',
          },
        },
        updateInterval: 1000,
      },
      {
        text: 'SUNDAY',
        animation: {
          from: {
            x: 90,
            y: 997,
            fill: '#7F7F7F',
            fontSize: 20,
            rotation: 90,
          },
          delay: 6000,
          enter: {
            fill: '#000000',
          },
          config: {
            duration: 0,
          },
          update: {
            fill: '#7F7F7F',
          },
        },
        updateInterval: 1000,
      },
    ],
    stageStyle: {
      backgroundColor: '#fff',
    },
    productCount: 7,
    channableElems: [
      {
        id: 'images-1',
        slot: 0,
        type: 'IMAGES',
        count: 7,
        required: true,
        idealSize: '420x860',
        imageCount: 7,
      },
    ],
    screenDimensions: {
      width: 720,
      height: 1280,
    },
  },
  previewPath:
    'https://cnvs.s3.eu-central-1.amazonaws.com/cdn/design/video/weekday.gif',
  createdAt: '2023-09-01T11:11:50.369Z',
  updatedAt: '2023-09-01T11:11:50.369Z',
  deletedAt: null,
  previewUpdate: null,
};

const gridNew = {
  id: 10,
  name: 'Grid Animation',
  duration: 3500,
  animation: [
    {
      text: 'Rising',
      animation: {
        from: {
          x: 298,
          y: 429,
          color: 'gray',
          opacity: 0,
          fontSize: 30,
          textAlign: 'left',
          fontFamily: 'Lato',
        },
        delay: 200,
        enter: {
          y: 430,
          color: 'gray',
          opacity: 1,
        },
        config: {
          duration: 900,
        },
        update: {
          color: 'black',
        },
      },
    },
    {
      text: 'Up',
      animation: {
        from: {
          x: 385,
          y: 429,
          color: 'gray',
          opacity: 0,
          fontSize: 30,
          textAlign: 'left',
          fontFamily: 'Lato',
        },
        delay: 1100,
        enter: {
          y: 430,
          color: 'gray',
          opacity: 1,
        },
        config: {
          duration: 900,
        },
        update: {
          color: 'black',
        },
      },
    },
    {
      id: 'image-1',
      image:
        'https://cnvs.s3.eu-central-1.amazonaws.com/cdn/site-assets/products/product-10.png',
      animation: {
        from: {
          x: 40,
          y: 40,
          width: 200,
          height: 260,
          opacity: 0,
        },
        delay: 2000,
        enter: {
          opacity: 1,
        },
        config: {
          duration: 400,
        },
      },
    },
    {
      id: 'image-2',
      image:
        'https://cnvs.s3.eu-central-1.amazonaws.com/cdn/site-assets/products/product-11.png',
      animation: {
        from: {
          x: 260,
          y: 40,
          width: 200,
          height: 260,
          opacity: 0,
        },
        delay: 2150,
        enter: {
          opacity: 1,
        },
        config: {
          duration: 400,
        },
      },
    },
    {
      id: 'image-3',
      image:
        'https://cnvs.s3.eu-central-1.amazonaws.com/cdn/site-assets/products/product-12.png',
      animation: {
        from: {
          x: 480,
          y: 40,
          width: 200,
          height: 260,
          opacity: 0,
        },
        delay: 2300,
        enter: {
          opacity: 1,
        },
        config: {
          duration: 400,
        },
      },
    },
    {
      id: 'image-4',
      image:
        'https://cnvs.s3.eu-central-1.amazonaws.com/cdn/site-assets/products/product-13.png',
      animation: {
        from: {
          x: 480,
          y: 320,
          width: 200,
          height: 260,
          opacity: 0,
        },
        delay: 2450,
        enter: {
          opacity: 1,
        },
        config: {
          duration: 400,
        },
      },
    },
    {
      id: 'image-5',
      image:
        'https://cnvs.s3.eu-central-1.amazonaws.com/cdn/site-assets/products/product-14.png',
      animation: {
        from: {
          x: 480,
          y: 600,
          width: 200,
          height: 260,
          opacity: 0,
        },
        delay: 2600,
        enter: {
          opacity: 1,
        },
        config: {
          duration: 400,
        },
      },
    },
    {
      id: 'image-6',
      image:
        'https://cnvs.s3.eu-central-1.amazonaws.com/cdn/site-assets/products/product-15.png',
      animation: {
        from: {
          x: 260,
          y: 600,
          width: 200,
          height: 260,
          opacity: 0,
        },
        delay: 2750,
        enter: {
          opacity: 1,
        },
        config: {
          duration: 400,
        },
      },
    },
    {
      id: 'image-7',
      image:
        'https://cnvs.s3.eu-central-1.amazonaws.com/cdn/site-assets/products/product-16.png',
      animation: {
        from: {
          x: 40,
          y: 600,
          width: 200,
          height: 260,
          opacity: 0,
        },
        delay: 2900,
        enter: {
          opacity: 1,
        },
        config: {
          duration: 400,
        },
      },
    },
    {
      id: 'image-8',
      image:
        'https://cnvs.s3.eu-central-1.amazonaws.com/cdn/site-assets/products/product-17.png',
      animation: {
        from: {
          x: 40,
          y: 320,
          width: 200,
          height: 260,
          opacity: 0,
        },
        delay: 3050,
        enter: {
          opacity: 1,
        },
        config: {
          duration: 400,
        },
      },
    },
  ],
  stageStyle: {
    backgroundColor: '#fff',
  },
  productCount: 8,
  channableElems: [
    {
      id: 'image-1',
      slot: 2,
      type: 'IMAGE',
      image: null,
      required: true,
      placeholderImage: 'Image 1',
    },
    {
      id: 'image-2',
      slot: 3,
      type: 'IMAGE',
      image: null,
      required: true,
      placeholderImage: 'Image 1',
    },
    {
      id: 'image-3',
      slot: 4,
      type: 'IMAGE',
      image: null,
      required: true,
      placeholderImage: 'Image 1',
    },
    {
      id: 'image-4',
      slot: 5,
      type: 'IMAGE',
      image: null,
      required: true,
      placeholderImage: 'Image 1',
    },
    {
      id: 'image-5',
      slot: 6,
      type: 'IMAGE',
      image: null,
      required: true,
      placeholderImage: 'Image 1',
    },
    {
      id: 'image-6',
      slot: 7,
      type: 'IMAGE',
      image: null,
      required: true,
      placeholderImage: 'Image 1',
    },
    {
      id: 'image-7',
      slot: 8,
      type: 'IMAGE',
      image: null,
      required: true,
      placeholderImage: 'Image 1',
    },
    {
      id: 'image-8',
      slot: 9,
      type: 'IMAGE',
      image: null,
      required: true,
      placeholderImage: 'Image 1',
    },
  ],
  screenDimensions: {
    width: 720,
    height: 900,
  },
};
const newGallery = {
  id: 523,
  name: 'Gallery Animation New4 ',
  duration: 4500,
  animation: [
    {
      id: 'images1',
      images: [
        'https://cnvs.s3.eu-central-1.amazonaws.com/cdn/templates/v-1.webp',
        'https://cnvs.s3.eu-central-1.amazonaws.com/cdn/templates/v-2.webp',
        'https://cnvs.s3.eu-central-1.amazonaws.com/cdn/templates/v-3.webp',
        'https://cnvs.s3.eu-central-1.amazonaws.com/cdn/templates/v-4.webp',
        'https://cnvs.s3.eu-central-1.amazonaws.com/cdn/templates/v-5.webp',
      ],
      animation: {
        from: {
          x: 50,
          y: 50,
          width: 620,
          height: 890,
          opacity: 0.9,
        },
        enter: {
          opacity: 1,
        },
        leave: {
          opacity: 0.9,
        },
        count: 5,
        config: {
          duration: 1000,
        },
      },
    },
    {
      id: 'thumbImage-1',
      image:
        'https://cnvs.s3.eu-central-1.amazonaws.com/cdn/templates/v-6.webp',
      animation: {
        from: {
          x: 50,
          y: 951,
          width: 116,
          height: 165,
          stroke: '#000000',
          strokeWidth: 2,
        },

        enter: {
          strokeWidth: 0,
        },
        config: {
          duration: 1,
        },
        delay: 1000,
      },
    },
    {
      id: 'thumbImage-2',
      image:
        'https://cnvs.s3.eu-central-1.amazonaws.com/cdn/templates/v-7.webp',
      animation: {
        from: {
          x: 176,
          y: 951,
          width: 116,
          height: 165,
          stroke: '#000000',
          strokeWidth: 0,
        },
        delay: 1000,
        enter: {
          strokeWidth: 2,
        },
        config: {
          duration: 1,
        },
        update: {
          strokeWidth: 0,
        },
      },
      updateInterval: 1000,
    },
    {
      id: 'thumbImage-3',
      image:
        'https://cnvs.s3.eu-central-1.amazonaws.com/cdn/templates/v-8.webp',
      animation: {
        from: {
          x: 302,
          y: 951,
          width: 116,
          height: 165,
          stroke: '#000000',
          strokeWidth: 0,
        },
        delay: 2000,
        enter: {
          strokeWidth: 2,
        },
        config: {
          duration: 1,
        },
        update: {
          strokeWidth: 0,
        },
      },
      updateInterval: 1000,
    },
    {
      id: 'thumbImage-4',
      image:
        'https://cnvs.s3.eu-central-1.amazonaws.com/cdn/templates/v-9.webp',
      animation: {
        from: {
          x: 428,
          y: 951,
          width: 116,
          height: 165,
          stroke: '#000000',
          strokeWidth: 0,
        },
        delay: 3000,
        enter: {
          strokeWidth: 2,
        },
        config: {
          duration: 1,
        },
        update: {
          strokeWidth: 0,
        },
      },
      updateInterval: 1000,
    },
    {
      id: 'thumbImage-5',
      image:
        'https://cnvs.s3.eu-central-1.amazonaws.com/cdn/templates/v-10.webp',
      animation: {
        from: {
          x: 554,
          y: 951,
          width: 116,
          height: 165,
          stroke: '#000000',
          strokeWidth: 0,
        },
        delay: 4000,
        enter: {
          strokeWidth: 2,
        },
        config: {
          duration: 1,
        },
        update: {
          strokeWidth: 0,
        },
      },
      updateInterval: 1000,
    },
    {
      image:
        'https://cnvs.s3.eu-central-1.amazonaws.com/cdn/templates/v-bottom-bar.png',
      animation: {
        from: {
          x: 69,
          y: 1166,
          width: 582,
          height: 78,
        },
        config: {
          duration: 1,
        },
      },
    },
  ],
  stageStyle: {
    backgroundColor: '#fff',
  },
  productCount: 5,
  channableElems: [
    {
      id: 'images-1',
      slot: 0,
      type: 'IMAGES',
      count: 5,
      required: true,
      idealSize: '620x890',
    },
    {
      id: 'thumbImage-1',
      slot: 1,
      type: 'thumbImage',
      required: true,
      idealSize: '116x165',
    },
    {
      id: 'thumbImage-2',
      slot: 2,
      type: 'thumbImage',
      required: true,
      idealSize: '116x165',
    },
    {
      id: 'thumbImage-3',
      slot: 3,
      type: 'thumbImage',
      required: true,
      idealSize: '116x165',
    },
    {
      id: 'thumbImage-4',
      slot: 4,
      type: 'thumbImage',
      required: true,
      idealSize: '116x165',
    },
    {
      id: 'thumbImage-5',
      slot: 5,
      type: 'thumbImage',
      required: true,
      idealSize: '116x165',
    },
  ],
  screenDimensions: {
    width: 720,
    height: 1280,
  },
};

const saleAnim = {
  id: 525,
  name: 'Sale Animation',
  duration: 3600,
  productCount: 12,
  animation: [
    {
      text: 'SALE',
      animation: {
        from: {
          x: 14,
          y: 0,
          fill: 'white',
          stroke: '#F18CB1',
          fontSize: 300,
          fontFamily: 'Lato',
          fontWeight: 500,
          strokeWidth: 2,
        },
        loop: true,
        enter: {
          fill: '#F18CB1',
        },
        update: {
          fill: 'white',
        },
      },
      updateInterval: 300,
    },
    {
      text: 'SALE',
      animation: {
        from: {
          x: 14,
          y: 328,
          fill: 'white',
          stroke: '#F18CB1',
          fontSize: 300,
          fontFamily: 'Lato',
          fontWeight: 500,
          strokeWidth: 2,
        },
        delay: 600,
        enter: {
          fill: '#F18CB1',
        },
        update: {
          fill: 'white',
        },
      },
      updateInterval: 300,
    },
    {
      text: 'SALE',
      animation: {
        from: {
          x: 14,
          y: 654,
          fill: 'white',
          stroke: '#F18CB1',
          fontSize: 300,
          fontFamily: 'Lato',
          fontWeight: 500,
          strokeWidth: 2,
        },
        delay: 900,
        enter: {
          fill: '#F18CB1',
        },
        update: {
          fill: 'white',
        },
      },
      updateInterval: 300,
    },
    {
      text: 'SALE',
      animation: {
        from: {
          x: 14,
          y: 980,
          fill: 'white',
          stroke: '#F18CB1',
          fontSize: 300,
          fontFamily: 'Lato',
          fontWeight: 500,
          strokeWidth: 2,
        },
        delay: 1200,
        enter: {
          fill: '#F18CB1',
        },
        update: {
          fill: 'white',
        },
      },
      updateInterval: 300,
    },
    {
      text: 'SALE',
      animation: {
        from: {
          x: 14,
          y: 0,
          fill: 'white',
          stroke: '#F18CB1',
          fontSize: 300,
          fontFamily: 'Lato',
          fontWeight: 500,
          strokeWidth: 2,
        },
        loop: true,
        enter: {
          fill: '#F18CB1',
        },
        update: {
          fill: 'white',
        },
      },
      updateInterval: 300,
    },
    {
      text: 'SALE',
      animation: {
        from: {
          x: 14,
          y: 328,
          fill: 'white',
          stroke: '#F18CB1',
          fontSize: 300,
          fontFamily: 'Lato',
          fontWeight: 500,
          strokeWidth: 2,
        },
        delay: 600,
        enter: {
          fill: '#F18CB1',
        },
        update: {
          fill: 'white',
        },
      },
      updateInterval: 300,
    },
    {
      text: 'SALE',
      animation: {
        from: {
          x: 14,
          y: 654,
          fill: 'white',
          stroke: '#F18CB1',
          fontSize: 300,
          fontFamily: 'Lato',
          fontWeight: 500,
          strokeWidth: 2,
        },
        delay: 900,
        enter: {
          fill: '#F18CB1',
        },
        update: {
          fill: 'white',
        },
      },
      updateInterval: 300,
    },
    {
      text: 'SALE',
      animation: {
        from: {
          x: 14,
          y: 980,
          fill: 'white',
          stroke: '#F18CB1',
          fontSize: 300,
          fontFamily: 'Lato',
          fontWeight: 500,
          strokeWidth: 2,
        },
        delay: 1200,
        enter: {
          fill: '#F18CB1',
        },
        update: {
          fill: 'white',
        },
      },
      updateInterval: 300,
    },
    {
      id: 'image-1',
      delay: 1000,
      image:
        'https://cnvs.s3.eu-central-1.amazonaws.com/cdn/templates/sale-1.png',
      config: {
        duration: 1000,
      },
      animation: {
        from: {
          x: 50,
          y: 120,
          width: 174,
          height: 183,
          scaleX: 0,
          scaleY: 0,
        },
        enter: {
          scaleX: 1.2,
          scaleY: 1.2,
        },
        update: {
          scaleX: 1,
          scaleY: 1,
        },
      },
      updateInterval: 1000,
    },
    {
      id: 'image-2',
      delay: 1000,
      image:
        'https://cnvs.s3.eu-central-1.amazonaws.com/cdn/templates/sale-2.png',
      config: {
        duration: 1000,
      },
      animation: {
        from: {
          x: 273,
          y: 120,
          width: 174,
          height: 183,
          scaleX: 0,
          scaleY: 0,
        },
        enter: {
          scaleX: 1.2,
          scaleY: 1.2,
        },
        update: {
          scaleX: 1,
          scaleY: 1,
        },
      },
      updateInterval: 1000,
    },
    {
      id: 'image-3',
      delay: 1000,
      image:
        'https://cnvs.s3.eu-central-1.amazonaws.com/cdn/templates/sale-3.png',
      config: {
        duration: 1000,
      },
      animation: {
        from: {
          x: 496,
          y: 120,
          width: 174,
          height: 183,
          scaleX: 0,
          scaleY: 0,
        },
        enter: {
          scaleX: 1.2,
          scaleY: 1.2,
        },
        update: {
          scaleX: 1,
          scaleY: 1,
        },
      },
      updateInterval: 1000,
    },
    {
      id: 'image-4',
      delay: 1000,
      image:
        'https://cnvs.s3.eu-central-1.amazonaws.com/cdn/templates/sale-4.png',
      config: {
        duration: 1000,
      },
      animation: {
        from: {
          x: 50,
          y: 373,
          width: 174,
          height: 183,
          scaleX: 0,
          scaleY: 0,
        },
        enter: {
          scaleX: 1.2,
          scaleY: 1.2,
        },
        update: {
          scaleX: 1,
          scaleY: 1,
        },
      },
      updateInterval: 1000,
    },
    {
      id: 'image-5',
      delay: 1000,
      image:
        'https://cnvs.s3.eu-central-1.amazonaws.com/cdn/templates/sale-5.png',
      config: {
        duration: 1000,
      },
      animation: {
        from: {
          x: 273,
          y: 373,
          width: 174,
          height: 183,
          scaleX: 0,
          scaleY: 0,
        },
        enter: {
          scaleX: 1.2,
          scaleY: 1.2,
        },
        update: {
          scaleX: 1,
          scaleY: 1,
        },
      },
      updateInterval: 1000,
    },
    {
      id: 'image-6',
      delay: 1000,
      image:
        'https://cnvs.s3.eu-central-1.amazonaws.com/cdn/templates/sale-6.png',
      config: {
        duration: 1000,
      },
      animation: {
        from: {
          x: 496,
          y: 373,
          width: 174,
          height: 183,
          scaleX: 0,
          scaleY: 0,
        },
        enter: {
          scaleX: 1.2,
          scaleY: 1.2,
        },
        update: {
          scaleX: 1,
          scaleY: 1,
        },
      },
      updateInterval: 1000,
    },
    {
      id: 'image-7',
      delay: 1000,
      image:
        'https://cnvs.s3.eu-central-1.amazonaws.com/cdn/templates/sale-7.png',
      config: {
        duration: 1000,
      },
      animation: {
        from: {
          x: 50,
          y: 626,
          width: 174,
          height: 183,
          scaleX: 0,
          scaleY: 0,
        },
        enter: {
          scaleX: 1.2,
          scaleY: 1.2,
        },
        update: {
          scaleX: 1,
          scaleY: 1,
        },
      },
      updateInterval: 1000,
    },
    {
      id: 'image-8',
      delay: 1000,
      image:
        'https://cnvs.s3.eu-central-1.amazonaws.com/cdn/templates/sale-8.png',
      config: {
        duration: 1000,
      },
      animation: {
        from: {
          x: 273,
          y: 626,
          width: 174,
          height: 183,
          scaleX: 0,
          scaleY: 0,
        },
        enter: {
          scaleX: 1.2,
          scaleY: 1.2,
        },
        update: {
          scaleX: 1,
          scaleY: 1,
        },
      },
      updateInterval: 1000,
    },
    {
      id: 'image-9',
      delay: 1000,
      image:
        'https://cnvs.s3.eu-central-1.amazonaws.com/cdn/templates/sale-9.png',
      config: {
        duration: 1000,
      },
      animation: {
        from: {
          x: 496,
          y: 626,
          width: 174,
          height: 183,
          scaleX: 0,
          scaleY: 0,
        },
        enter: {
          scaleX: 1.2,
          scaleY: 1.2,
        },
        update: {
          scaleX: 1,
          scaleY: 1,
        },
      },
      updateInterval: 1000,
    },
    {
      id: 'image-10',
      delay: 1000,
      image:
        'https://cnvs.s3.eu-central-1.amazonaws.com/cdn/templates/sale-10.png',
      config: {
        duration: 1000,
      },
      animation: {
        from: {
          x: 50,
          y: 879,
          width: 174,
          height: 183,
          scaleX: 0,
          scaleY: 0,
        },
        enter: {
          scaleX: 1.2,
          scaleY: 1.2,
        },
        update: {
          scaleX: 1,
          scaleY: 1,
        },
      },
      updateInterval: 1000,
    },
    {
      id: 'image-11',
      delay: 1000,
      image:
        'https://cnvs.s3.eu-central-1.amazonaws.com/cdn/templates/sale-11.png',
      config: {
        duration: 1000,
      },
      animation: {
        from: {
          x: 273,
          y: 879,
          width: 174,
          height: 183,
          scaleX: 0,
          scaleY: 0,
        },
        enter: {
          scaleX: 1.2,
          scaleY: 1.2,
        },
        update: {
          scaleX: 1,
          scaleY: 1,
        },
      },
      updateInterval: 1000,
    },
    {
      id: 'image-12',
      delay: 1000,
      image:
        'https://cnvs.s3.eu-central-1.amazonaws.com/cdn/templates/sale-12.png',
      config: {
        duration: 1000,
      },
      animation: {
        from: {
          x: 496,
          y: 879,
          width: 174,
          height: 183,
          scaleX: 0,
          scaleY: 0,
        },
        enter: {
          scaleX: 1.2,
          scaleY: 1.2,
        },
        update: {
          scaleX: 1,
          scaleY: 1,
        },
      },
      updateInterval: 1000,
    },
  ],
  stageStyle: {
    backgroundColor: '#fff',
  },
  channableElems: [
    {
      id: 'image-1',
      slot: 4,
      type: 'IMAGE',
      image: null,
      required: true,
      idealSize: '174x183',
    },
    {
      id: 'image-2',
      slot: 5,
      type: 'IMAGE',
      required: true,
      image: null,
      idealSize: '174x183',
    },
    {
      id: 'image-3',
      slot: 6,
      type: 'IMAGE',
      required: true,
      image: null,
      idealSize: '174x183',
    },
    {
      id: 'image-4',
      slot: 7,
      type: 'IMAGE',
      required: true,
      image: null,
      idealSize: '174x183',
    },
    {
      id: 'image-5',
      slot: 8,
      type: 'IMAGE',
      required: true,
      image: null,
      idealSize: '174x183',
    },
    {
      id: 'image-6',
      slot: 9,
      type: 'IMAGE',
      required: true,
      image: null,
      idealSize: '174x183',
    },
    {
      id: 'image-7',
      slot: 10,
      type: 'IMAGE',
      required: true,
      image: null,
      idealSize: '174x183',
    },
    {
      id: 'image-8',
      slot: 11,
      type: 'IMAGE',
      required: true,
      image: null,
      idealSize: '174x183',
    },
    {
      id: 'image-9',
      slot: 12,
      type: 'IMAGE',
      required: true,
      image: null,
      idealSize: '174x183',
    },
    {
      id: 'image-10',
      slot: 13,
      type: 'IMAGE',
      required: true,
      image: null,
      idealSize: '174x183',
    },
    {
      id: 'image-11',
      slot: 14,
      type: 'IMAGE',
      required: true,
      image: null,
      idealSize: '174x183',
    },
    {
      id: 'image-12',
      slot: 15,
      type: 'IMAGE',
      required: true,
      image: null,
      idealSize: '174x183',
    },
  ],
  screenDimensions: {
    width: 720,
    height: 1280,
  },
};

gridAnimation.duration = calculateDuration(gridAnimation.animation);
animation7.duration = calculateDuration(animation7.animation);
galleryAnimation.duration = calculateDuration(galleryAnimation.animation);
animationV.duration = 14000;

export const animlast = {
  id: 'bf26a2f8-b76b-4931-89f1-2b054060dc68',
  name: null,
  type: 'VIDEO',
  template: {
    id: 52,
    name: 'Volkan Change4',
    duration: 5000,
    productCount: 6,
    animation: [
      {
        images: [
          'https://i6.keikei.co/cdn/products/6/66898/media-libraryOlTiEP',
          'https://i6.keikei.co/cdn/products/6/66978/media-libraryCmmdGQ',
          'https://i7.keikei.co/cdn/products/7/71172/965011_036_HA.webp',
          'https://i7.keikei.co/cdn/products/7/72376/965300_040_HA.webp',
          'https://i7.keikei.co/cdn/products/7/72426/961788_029_HA.webp',
          'https://i7.keikei.co/cdn/products/7/72607/965260_033_HA.webp',
        ],
        animation: {
          from: {
            x: 50,
            y: 154,
            width: 620,
            height: 893,
            opacity: 0.9,
          },
          count: 6,
          enter: {
            opacity: 1,
          },
          leave: {
            opacity: 0.9,
          },
          config: {
            duration: 1000,
          },
        },
      },
      {
        text: 'KeiKei',
        animation: {
          from: {
            x: 570,
            y: 1127,
            fontSize: 20,
            fontFamily: 'Lato',
            fontWeight: '600',
          },
          config: {
            duration: 1,
          },
        },
      },
      {
        texts: [
          'KeiKei Siyah Saten Kolsuz Kısa Elbise',
          'KeiKei Fuşya Saten Kolsuz Kısa Elbise',
          'KeiKei Saks Mavisi Saten Kolsuz Kısa Elbise',
          'KeiKei Pudra Moonlight Uzun Kol Kısa Elbise',
          'KeiKei Altın Büyük Beden Moonlight Uzun Kol Kısa Elbise',
          'KeiKei Black Silver Moonlight Kolsuz Uzun Elbise',
        ],
        animation: {
          from: {
            x: 50,
            y: 1127,
            opacity: 0.9,
            fontSize: 20,
            fontFamily: 'Lato',
            fontWeight: '500',
          },
          count: 6,
          enter: {
            opacity: 1,
          },
          leave: {
            opacity: 0.9,
          },
          config: {
            duration: 1000,
          },
        },
      },
      {
        texts: [
          '1679.90 TRY',
          '1999.90 TRY',
          '1999.90 TRY',
          '2159.90 TRY',
          '2239.90 TRY',
          '3039.90 TRY',
        ],
        animation: {
          from: {
            x: 50,
            y: 1100,
            opacity: 0.9,
            fontSize: 20,
            fontFamily: 'Lato',
            fontWeight: '700',
          },
          count: 6,
          enter: {
            opacity: 1,
          },
          leave: {
            opacity: 0.9,
          },
          config: {
            duration: 1000,
          },
        },
      },
      {
        type: 'line',
        animation: {
          from: {
            x: 50,
            y: 1157,
            fill: 'black',
            height: 620,
            points: [0, 0, 0, 620],
            stroke: 'black',
            rotation: -90,
            strokeWidth: 1,
          },
          config: {
            duration: 0,
          },
        },
      },
    ],
    stageStyle: {
      backgroundColor: '#fff',
    },
    channableElems: [
      {
        id: 'images-1',
        slot: 0,
        type: 'IMAGES',
        count: 6,
        xmlType: 'imageLink',
        required: true,
        idealSize: '620x893',
      },
      {
        id: 'text-1',
        slot: 1,
        type: 'TEXT',
        xmlType: 'brand',
      },
      {
        id: 'texts-1',
        slot: 2,
        type: 'TEXTS',
        count: 6,
        xmlType: 'title',
        required: true,
      },
      {
        id: 'texts-2',
        slot: 3,
        type: 'TEXTS',
        count: 6,
        xmlType: 'price',
        required: true,
      },
    ],
    screenDimensions: {
      width: 720,
      height: 1280,
    },
  },
  previewPath:
    'https://cnvs.s3.eu-central-1.amazonaws.com/cdn/site-assets/gif/changeProduct.gif',
  createdAt: '2023-09-29T12:21:57.525Z',
  updatedAt: '2023-09-29T12:21:57.525Z',
  deletedAt: null,
  previewUpdate: null,
};

const animations = [
  animationV,
  weekLook.template,
  gridNew,
  animationKeikei2,
  newGallery,
  {
    id: 52,
    name: 'Dress Up',
    screenDimensions: {
      width: 720,
      height: 1280,
    },
    stageStyle: {
      backgroundColor: '#fff',
    },
    productCount: 6,
    duration: 6000,
    channableElems: [
      {
        id: 'images-1',
        slot: 0,
        type: 'IMAGES',
        count: 6,
        required: true,
        idealSize: '720x1280',
      },
    ],
    animation: [
      {
        id: 'images-1',
        images: [
          'https://cnvs.s3.eu-central-1.amazonaws.com/cdn/templates/Frame42.webp',
          'https://cnvs.s3.eu-central-1.amazonaws.com/cdn/templates/Frame42-1.webp',
          'https://cnvs.s3.eu-central-1.amazonaws.com/cdn/templates/Frame42-2.webp',
          'https://cnvs.s3.eu-central-1.amazonaws.com/cdn/templates/Frame42-3.webp',
          'https://cnvs.s3.eu-central-1.amazonaws.com/cdn/templates/Frame42-4.webp',
          'https://cnvs.s3.eu-central-1.amazonaws.com/cdn/templates/Frame42-5.webp',
        ],
        animation: {
          from: {
            x: 0,
            y: 0,
            width: 720,
            height: 1280,
            opacity: 0.9,
          },
          enter: {
            opacity: 1,
          },
          leave: {
            opacity: 0.9,
          },
          config: { duration: 1000 },
          count: 6,
        },
      },
      {
        text: 'D',
        animation: {
          from: {
            x: 150,
            y: 600,
            fontSize: 80,
            fontFamily: 'Lato',
            fontWeight: '700',
            fill: '#000000',
            opacity: 0,
          },
          enter: { opacity: 1 },
          config: { duration: 100 },
        },
      },
      {
        text: 'R',
        setInterval: 1000,
        animation: {
          from: {
            x: 210,
            y: 600,
            fontSize: 80,
            fontFamily: 'Lato',
            fontWeight: '700',
            fill: '#000000',
            opacity: 0,
          },
          enter: { opacity: 1 },
          config: { duration: 100 },
          delay: 500,
        },
      },
      {
        text: 'E',
        setInterval: 1000,
        animation: {
          from: {
            x: 265,
            y: 600,
            fontSize: 80,
            fontFamily: 'Lato',
            fontWeight: '700',
            fill: '#000000',
            opacity: 0,
          },
          enter: { opacity: 1 },
          config: { duration: 100 },
          delay: 1000,
        },
      },
      {
        text: 'S',
        setInterval: 1000,
        animation: {
          from: {
            x: 315,
            y: 600,
            fontSize: 80,
            fontFamily: 'Lato',
            fontWeight: '700',
            fill: '#000000',
            opacity: 0,
          },
          enter: { opacity: 1 },
          config: { duration: 100 },
          delay: 1500,
        },
      },
      {
        text: 'S',
        setInterval: 1000,
        animation: {
          from: {
            x: 360,
            y: 600,
            fontSize: 80,
            fontFamily: 'Lato',
            fontWeight: '700',
            fill: '#000000',
            opacity: 0,
          },
          enter: { opacity: 1 },
          config: { duration: 100 },
          delay: 2000,
        },
      },
      {
        text: 'U',
        setInterval: 1000,
        animation: {
          from: {
            x: 450,
            y: 600,
            fontSize: 80,
            fontFamily: 'Lato',
            fontWeight: '700',
            fill: '#000000',
            opacity: 0,
            Zindex: 1000,
          },
          enter: { opacity: 1 },
          config: { duration: 100 },
          delay: 2500,
        },
      },
      {
        text: 'P',
        setInterval: 1000,
        animation: {
          from: {
            x: 510,
            y: 600,
            fontSize: 80,
            fontFamily: 'Lato',
            fontWeight: '700',
            fill: '#000000',
            opacity: 0,
          },
          enter: { opacity: 1 },
          config: { duration: 100 },
          delay: 3000,
        },
      },
      {
        text: 'Brand',
        setInterval: 1000,
        animation: {
          from: {
            x: 155,
            y: 670,
            fontSize: 20,
            fontFamily: 'Lato',
            fontWeight: '500',
            fill: '#000000',
            opacity: 0,
          },
          enter: { opacity: 1 },
          config: { duration: 1000 },
          delay: 4000,
        },
      },
      {
        text: '2023',
        setInterval: 1000,
        animation: {
          from: {
            x: 345,
            y: 670,
            fontSize: 20,
            fontFamily: 'Lato',
            fontWeight: '500',
            fill: '#000000',
            opacity: 0,
          },
          enter: { opacity: 1 },
          config: { duration: 1000 },
          delay: 4500,
        },
      },
      {
        text: 'Collection',
        setInterval: 1000,
        animation: {
          from: {
            x: 515,
            y: 670,
            fontSize: 20,
            fontFamily: 'Lato',
            fontWeight: '500',
            fill: '#000000',
            opacity: 0,
          },
          enter: { opacity: 1 },
          config: { duration: 1000 },
          delay: 5000,
        },
      },
    ],
  },
  {
    id: 25,
    name: 'Volkan Change5',
    screenDimensions: {
      width: 720,
      height: 1280,
    },
    stageStyle: {
      backgroundColor: '#fff',
    },
    animation: [
      {
        id: 52,
        name: 'Volkan Change5',
        screenDimensions: {
          width: 720,
          height: 1280,
        },
        stageStyle: {
          backgroundColor: '#fff',
        },
        animation: [
          {
            images: [
              'https://cnvs.s3.eu-central-1.amazonaws.com/cdn/templates/Frame42.webp',
              'https://cnvs.s3.eu-central-1.amazonaws.com/cdn/templates/Frame42-1.webp',
              'https://cnvs.s3.eu-central-1.amazonaws.com/cdn/templates/Frame42-2.webp',
              'https://cnvs.s3.eu-central-1.amazonaws.com/cdn/templates/Frame42-3.webp',
              'https://cnvs.s3.eu-central-1.amazonaws.com/cdn/templates/Frame42-4.webp',
              'https://cnvs.s3.eu-central-1.amazonaws.com/cdn/templates/Frame42-5.webp',
            ],
            animation: {
              from: {
                x: 0,
                y: 0,
                width: 720,
                height: 1280,
              },
              config: { duration: 1000 },
              count: 6,
            },
          },
          {
            text: 'D',
            animation: {
              from: {
                x: 150,
                y: 600,
                fontSize: 80,
                fontFamily: 'Lato',
                fontWeight: '700',
                fill: '#000000',
                opacity: 0,
              },
              enter: { opacity: 1 },
              config: { duration: 100 },
            },
          },
          {
            text: 'R',
            setInterval: 1000,
            animation: {
              from: {
                x: 210,
                y: 600,
                fontSize: 80,
                fontFamily: 'Lato',
                fontWeight: '700',
                fill: '#000000',
                opacity: 0,
              },
              enter: { opacity: 1 },
              config: { duration: 100 },
              delay: 500,
            },
          },
          {
            text: 'E',
            setInterval: 1000,
            animation: {
              from: {
                x: 265,
                y: 600,
                fontSize: 80,
                fontFamily: 'Lato',
                fontWeight: '700',
                fill: '#000000',
                opacity: 0,
              },
              enter: { opacity: 1 },
              config: { duration: 100 },
              delay: 1000,
            },
          },
          {
            text: 'S',
            setInterval: 1000,
            animation: {
              from: {
                x: 315,
                y: 600,
                fontSize: 80,
                fontFamily: 'Lato',
                fontWeight: '700',
                fill: '#000000',
                opacity: 0,
              },
              enter: { opacity: 1 },
              config: { duration: 100 },
              delay: 1500,
            },
          },
          {
            text: 'S',
            setInterval: 1000,
            animation: {
              from: {
                x: 360,
                y: 600,
                fontSize: 80,
                fontFamily: 'Lato',
                fontWeight: '700',
                fill: '#000000',
                opacity: 0,
              },
              enter: { opacity: 1 },
              config: { duration: 100 },
              delay: 2000,
            },
          },
          {
            text: 'U',
            setInterval: 1000,
            animation: {
              from: {
                x: 450,
                y: 600,
                fontSize: 80,
                fontFamily: 'Lato',
                fontWeight: '700',
                fill: '#000000',
                opacity: 0,
                Zindex: 1000,
              },
              enter: { opacity: 1 },
              config: { duration: 100 },
              delay: 2500,
            },
          },
          {
            text: 'P',
            setInterval: 1000,
            animation: {
              from: {
                x: 510,
                y: 600,
                fontSize: 80,
                fontFamily: 'Lato',
                fontWeight: '700',
                fill: '#000000',
                opacity: 0,
              },
              enter: { opacity: 1 },
              config: { duration: 100 },
              delay: 3000,
            },
          },
          {
            text: 'Brand',
            setInterval: 1000,
            animation: {
              from: {
                x: 155,
                y: 670,
                fontSize: 20,
                fontFamily: 'Lato',
                fontWeight: '500',
                fill: '#000000',
                opacity: 0,
              },
              enter: { opacity: 1 },
              config: { duration: 1000 },
              delay: 4000,
            },
          },
          {
            text: '2023',
            setInterval: 1000,
            animation: {
              from: {
                x: 345,
                y: 670,
                fontSize: 20,
                fontFamily: 'Lato',
                fontWeight: '500',
                fill: '#000000',
                opacity: 0,
              },
              enter: { opacity: 1 },
              config: { duration: 1000 },
              delay: 4500,
            },
          },
          {
            text: 'Collection',
            setInterval: 1000,
            animation: {
              from: {
                x: 515,
                y: 670,
                fontSize: 20,
                fontFamily: 'Lato',
                fontWeight: '500',
                fill: '#000000',
                opacity: 0,
              },
              enter: { opacity: 1 },
              config: { duration: 1000 },
              delay: 5000,
            },
          },
        ],
      },
      {
        image:
          'https://cnvs.s3.eu-central-1.amazonaws.com/cdn/site-assets/animation-v-1.png',
        animation: {
          from: {
            x: 9,
            y: 269,
            width: 228,
            height: 328,
          },
          enter: { opacity: 1 },
          update: { opacity: 1 },
          config: { duration: 1000 },
        },
      },
      {
        setInterval: 3000,
        image:
          'https://cnvs.s3.eu-central-1.amazonaws.com/cdn/site-assets/animation-v-11.png',
        animation: {
          from: {
            x: 9,
            y: 269,
            width: 228,
            height: 328,
            opacity: 0,
          },
          enter: { opacity: 1 },
          update: { opacity: 0 },
          leave: { opacity: 0 },
          config: { duration: 1000 },
          delay: 2000,
        },
      },
      {
        image:
          'https://cnvs.s3.eu-central-1.amazonaws.com/cdn/site-assets/animation-v-2.png',
        animation: {
          from: {
            x: 246,
            y: 269,
            width: 228,
            height: 328,
          },
          enter: { opacity: 1 },
          update: { opacity: 1 },
          config: { duration: 1000 },
        },
      },
      {
        setInterval: 3000,
        image:
          'https://cnvs.s3.eu-central-1.amazonaws.com/cdn/site-assets/animation-v-1.png',
        animation: {
          from: {
            x: 246,
            y: 269,
            width: 228,
            height: 328,
            opacity: 0,
          },
          enter: { opacity: 1 },
          update: { opacity: 0 },
          config: { duration: 1000 },
          delay: 8000,
        },
      },
      {
        image:
          'https://cnvs.s3.eu-central-1.amazonaws.com/cdn/site-assets/animation-v-3.png',
        animation: {
          from: {
            x: 483,
            y: 269,
            width: 228,
            height: 328,
          },
          enter: { opacity: 1 },
          update: { opacity: 1 },
          config: { duration: 1000 },
        },
      },
      {
        setInterval: 3000,
        image:
          'https://cnvs.s3.eu-central-1.amazonaws.com/cdn/site-assets/animation-v-13.png',
        animation: {
          from: {
            x: 483,
            y: 269,
            width: 228,
            height: 328,
            opacity: 0,
          },
          enter: { opacity: 1 },
          update: { opacity: 0 },
          config: { duration: 1000 },
          delay: 7000,
        },
      },
      {
        image:
          'https://cnvs.s3.eu-central-1.amazonaws.com/cdn/site-assets/animation-v-4.png',
        animation: {
          from: {
            x: 9,
            y: 606,
            width: 228,
            height: 328,
          },
          enter: { opacity: 1 },
          update: { opacity: 1 },
          config: { duration: 1000 },
        },
      },
      {
        setInterval: 3000,
        image:
          'https://cnvs.s3.eu-central-1.amazonaws.com/cdn/site-assets/animation-v-5.png',
        animation: {
          from: {
            x: 9,
            y: 606,
            width: 228,
            height: 328,
            opacity: 0,
          },
          enter: { opacity: 1 },
          update: { opacity: 0 },
          config: { duration: 1000 },
          delay: 8000,
        },
      },
      {
        image:
          'https://cnvs.s3.eu-central-1.amazonaws.com/cdn/site-assets/animation-v-5.png',
        animation: {
          from: {
            x: 246,
            y: 606,
            width: 228,
            height: 328,
          },
          enter: { opacity: 1 },
          update: { opacity: 1 },
          config: { duration: 1000 },
        },
      },
      {
        setInterval: 3000,
        image:
          'https://cnvs.s3.eu-central-1.amazonaws.com/cdn/site-assets/animation-v-14.png',
        animation: {
          from: {
            x: 246,
            y: 606,
            width: 228,
            height: 328,
            opacity: 0,
          },
          enter: { opacity: 1 },
          update: { opacity: 0 },
          config: { duration: 1000 },
          delay: 3000,
        },
      },
      {
        setInterval: 3000,
        image:
          'https://cnvs.s3.eu-central-1.amazonaws.com/cdn/site-assets/animation-v-16.png',
        animation: {
          from: {
            x: 246,
            y: 606,
            width: 228,
            height: 328,
            opacity: 0,
          },
          enter: { opacity: 1 },
          update: { opacity: 0 },
          config: { duration: 1000 },
          delay: 6000,
        },
      },
      {
        image:
          'https://cnvs.s3.eu-central-1.amazonaws.com/cdn/site-assets/animation-v-6.png',
        animation: {
          from: {
            x: 483,
            y: 606,
            width: 228,
            height: 328,
          },
          enter: { opacity: 1 },
          update: { opacity: 1 },
          config: { duration: 1000 },
        },
      },
      {
        setInterval: 3000,
        image:
          'https://cnvs.s3.eu-central-1.amazonaws.com/cdn/site-assets/animation-v-9.png',
        animation: {
          from: {
            x: 483,
            y: 606,
            width: 228,
            height: 328,
            opacity: 0,
          },
          enter: { opacity: 1 },
          update: { opacity: 0 },
          config: { duration: 1000 },
          delay: 8000,
        },
      },
      {
        image:
          'https://cnvs.s3.eu-central-1.amazonaws.com/cdn/site-assets/animation-v-7.png',
        animation: {
          from: {
            x: 9,
            y: 943,
            width: 228,
            height: 328,
          },
          enter: { opacity: 1 },
          update: { opacity: 1 },
          config: { duration: 1000 },
        },
      },
      {
        setInterval: 3000,
        image:
          'https://cnvs.s3.eu-central-1.amazonaws.com/cdn/site-assets/animation-v-17.png',
        animation: {
          from: {
            x: 9,
            y: 943,
            width: 228,
            height: 328,
            opacity: 0,
          },
          enter: { opacity: 1 },
          update: { opacity: 0 },
          config: { duration: 1000 },
          delay: 5000,
        },
      },
      {
        image:
          'https://cnvs.s3.eu-central-1.amazonaws.com/cdn/site-assets/animation-v-8.png',
        animation: {
          from: {
            x: 246,
            y: 943,
            width: 228,
            height: 328,
          },
          enter: { opacity: 1 },
          update: { opacity: 1 },
          config: { duration: 1000 },
        },
      },
      {
        setInterval: 3000,
        image:
          'https://cnvs.s3.eu-central-1.amazonaws.com/cdn/site-assets/animation-v-7.png',
        animation: {
          from: {
            x: 246,
            y: 943,
            width: 228,
            height: 328,
            opacity: 0,
          },
          enter: { opacity: 1 },
          update: { opacity: 0 },
          config: { duration: 1000 },
          delay: 8000,
        },
      },
      {
        image:
          'https://cnvs.s3.eu-central-1.amazonaws.com/cdn/site-assets/animation-v-9.png',
        animation: {
          from: {
            x: 483,
            y: 943,
            width: 228,
            height: 328,
          },
          enter: { opacity: 1 },
          update: { opacity: 1 },
          config: { duration: 1000 },
        },
      },
      {
        setInterval: 3000,
        image:
          'https://cnvs.s3.eu-central-1.amazonaws.com/cdn/site-assets/animation-v-19.png',
        animation: {
          from: {
            x: 483,
            y: 943,
            width: 228,
            height: 328,
            opacity: 0,
          },
          enter: { opacity: 1 },
          update: { opacity: 0 },
          config: { duration: 1000 },
          delay: 4000,
        },
      },
    ],
  },
  {
    id: 525,
    name: 'Volkan Lastın Lastı',
    screenDimensions: {
      width: 1080,
      height: 1080,
    },
    duration: 12000,
    stageStyle: {
      backgroundColor: '#fff',
    },
    productCount: 12,
    channableElems: [
      {
        id: 'images-1',
        slot: 0,
        type: 'IMAGES',
        imageCount: 6,
        count: 6,
        idealSize: '530x740',
      },
      {
        id: 'images-2',
        slot: 1,
        type: 'IMAGES',
        imageCount: 6,
        count: 6,
        idealSize: '500x500',
      },
    ],
    animation: [
      {
        id: 'images-1',
        images: [
          'https://cnvs.s3.eu-central-1.amazonaws.com/cdn/site-assets/sale-double-1.png',
          'https://cnvs.s3.eu-central-1.amazonaws.com/cdn/site-assets/sale-double-2.png',
          'https://cnvs.s3.eu-central-1.amazonaws.com/cdn/site-assets/sale-double-3.png',
          'https://cnvs.s3.eu-central-1.amazonaws.com/cdn/site-assets/sale-double-4.png',
          'https://cnvs.s3.eu-central-1.amazonaws.com/cdn/site-assets/sale-double-5.png',
          'https://cnvs.s3.eu-central-1.amazonaws.com/cdn/site-assets/sale-double-6.png',
        ],
        animation: {
          from: {
            width: 530,
            height: 740,
            x: 50,
            y: 50,
            opacity: 0.9,
          },
          enter: {
            opacity: 1,
          },
          update: {
            opacity: 1,
          },
          leave: {
            opacity: 0.9,
          },
          config: { duration: 2000 },
          count: 6,
        },
      },
      {
        id: 'images-2',
        images: [
          'https://cnvs.s3.eu-central-1.amazonaws.com/cdn/site-assets/sale-double-7.png',
          'https://cnvs.s3.eu-central-1.amazonaws.com/cdn/site-assets/sale-double-8.png',
          'https://cnvs.s3.eu-central-1.amazonaws.com/cdn/site-assets/sale-double-9.png',
          'https://cnvs.s3.eu-central-1.amazonaws.com/cdn/site-assets/sale-double-10.png',
          'https://cnvs.s3.eu-central-1.amazonaws.com/cdn/site-assets/sale-double-11.png',
          'https://cnvs.s3.eu-central-1.amazonaws.com/cdn/site-assets/sale-double-12.png',
        ],
        animation: {
          from: {
            width: 500,
            height: 500,
            x: 580,
            y: 580,
            opacity: 0.9,
          },
          enter: {
            opacity: 1,
          },
          update: {
            opacity: 1,
          },
          leave: {
            opacity: 0.9,
          },
          config: { duration: 2000 },
          count: 6,
        },
      },
      {
        image:
          'https://cnvs.s3.eu-central-1.amazonaws.com/cdn/site-assets/animation-v-logo.png',
        animation: {
          from: {
            x: 107,
            y: 920,
            width: 417,
            height: 80,
            opacity: 0,
          },
          enter: { opacity: 1, y: 895 },
          update: { opacity: 1 },
          config: { duration: 1000 },
        },
      },
      {
        text: 'Trends',
        animation: {
          from: {
            x: 710,
            y: 106,
            fontSize: 80,
            fontStyle: '700',
            fontFamily: 'Lato',
            fill: '#000',
          },
          config: { duration: 1000 },
        },
      },
      {
        text: 'Category Specific',
        animation: {
          from: {
            x: 714,
            y: 216,
            fontSize: 30,
            fontStyle: '400',
            fontFamily: 'Lato',
            fill: '#000',
          },
          config: { duration: 1000 },
        },
      },
      {
        text: '%10',
        animation: {
          from: {
            x: 720,
            y: 276,
            fill: '#931C23',
            width: 200,
            align: 'center',
            fontFamily: 'Lato',
            fontStyle: '700',
            fontSize: 80,
            transformOrigin: 'center',
          },
          config: { duration: 1000 },
        },
      },
      {
        text: 'SALE',
        animation: {
          from: {
            x: 730,
            y: 356,
            fill: '#931C23',
            align: 'center',
            fontFamily: 'Lato',
            fontStyle: '700',
            fontSize: 80,
            transformOrigin: 'center',
          },
          config: { duration: 1000 },
        },
      },
      {
        text: 'Discount Code:',
        animation: {
          from: {
            x: 704,
            y: 484,
            fill: '#000',
            fontFamily: 'Lato',
            fontStyle: '400',
            fontSize: 20,
          },
          config: { duration: 1000 },
        },
      },
      {
        text: 'LoremIpsum',
        animation: {
          from: {
            x: 844,
            y: 484,
            fill: '#000',
            fontFamily: 'Lato',
            fontStyle: '700',
            fontSize: 20,
          },
          config: { duration: 1000 },
        },
      },
    ],
  },
  {
    id: 25,
    name: 'Shine',
    duration: 8000,
    productCount: 20,
    screenDimensions: {
      width: 720,
      height: 1280,
    },
    stageStyle: {
      backgroundColor: '#fff',
    },
    animation: [
      {
        id: 'logo-1',
        image:
          'https://cnvs.s3.eu-central-1.amazonaws.com/cdn/site-assets/animation-v-logo.png',
        animation: {
          from: {
            x: 152,
            y: 134,
            width: 417,
            height: 80,
            opacity: 0,
          },
          enter: { opacity: 1, y: 94 },
          update: { opacity: 1 },
          config: { duration: 1000 },
        },
      },
      {
        id: 'image-2',
        image:
          'https://cnvs.s3.eu-central-1.amazonaws.com/cdn/site-assets/animation-v-1.png',
        animation: {
          from: {
            x: 9,
            y: 269,
            width: 228,
            height: 328,
          },
          enter: { opacity: 1 },
          update: { opacity: 1 },
          config: { duration: 1000 },
        },
      },
      {
        id: 'image-3',
        setInterval: 3000,
        image:
          'https://cnvs.s3.eu-central-1.amazonaws.com/cdn/site-assets/animation-v-11.png',
        animation: {
          from: {
            x: 9,
            y: 269,
            width: 228,
            height: 328,
            opacity: 0,
          },
          enter: { opacity: 1 },
          update: { opacity: 0 },
          leave: { opacity: 0 },
          config: { duration: 1000 },
          delay: 2000,
        },
      },
      {
        id: 'image-4',
        image:
          'https://cnvs.s3.eu-central-1.amazonaws.com/cdn/site-assets/animation-v-2.png',
        animation: {
          from: {
            x: 246,
            y: 269,
            width: 228,
            height: 328,
          },
          enter: { opacity: 1 },
          update: { opacity: 1 },
          config: { duration: 1000 },
        },
      },
      {
        id: 'image-5',
        setInterval: 3000,
        image:
          'https://cnvs.s3.eu-central-1.amazonaws.com/cdn/site-assets/animation-v-1.png',
        animation: {
          from: {
            x: 246,
            y: 269,
            width: 228,
            height: 328,
            opacity: 0,
          },
          enter: { opacity: 1 },
          update: { opacity: 0 },
          config: { duration: 1000 },
          delay: 8000,
        },
      },
      {
        id: 'image-6',
        image:
          'https://cnvs.s3.eu-central-1.amazonaws.com/cdn/site-assets/animation-v-3.png',
        animation: {
          from: {
            x: 483,
            y: 269,
            width: 228,
            height: 328,
          },
          enter: { opacity: 1 },
          update: { opacity: 1 },
          config: { duration: 1000 },
        },
      },
      {
        id: 'image-7',
        setInterval: 3000,
        image:
          'https://cnvs.s3.eu-central-1.amazonaws.com/cdn/site-assets/animation-v-13.png',
        animation: {
          from: {
            x: 483,
            y: 269,
            width: 228,
            height: 328,
            opacity: 0,
          },
          enter: { opacity: 1 },
          update: { opacity: 0 },
          config: { duration: 1000 },
          delay: 7000,
        },
      },
      {
        id: 'image-8',
        image:
          'https://cnvs.s3.eu-central-1.amazonaws.com/cdn/site-assets/animation-v-4.png',
        animation: {
          from: {
            x: 9,
            y: 606,
            width: 228,
            height: 328,
          },
          enter: { opacity: 1 },
          update: { opacity: 1 },
          config: { duration: 1000 },
        },
      },
      {
        id: 'image-9',
        setInterval: 3000,
        image:
          'https://cnvs.s3.eu-central-1.amazonaws.com/cdn/site-assets/animation-v-5.png',
        animation: {
          from: {
            x: 9,
            y: 606,
            width: 228,
            height: 328,
            opacity: 0,
          },
          enter: { opacity: 1 },
          update: { opacity: 0 },
          config: { duration: 1000 },
          delay: 8000,
        },
      },
      {
        id: 'image-10',
        image:
          'https://cnvs.s3.eu-central-1.amazonaws.com/cdn/site-assets/animation-v-5.png',
        animation: {
          from: {
            x: 246,
            y: 606,
            width: 228,
            height: 328,
          },
          enter: { opacity: 1 },
          update: { opacity: 1 },
          config: { duration: 1000 },
        },
      },
      {
        id: 'image-11',
        setInterval: 3000,
        image:
          'https://cnvs.s3.eu-central-1.amazonaws.com/cdn/site-assets/animation-v-14.png',
        animation: {
          from: {
            x: 246,
            y: 606,
            width: 228,
            height: 328,
            opacity: 0,
          },
          enter: { opacity: 1 },
          update: { opacity: 0 },
          config: { duration: 1000 },
          delay: 3000,
        },
      },
      {
        id: 'image-12',
        setInterval: 3000,
        image:
          'https://cnvs.s3.eu-central-1.amazonaws.com/cdn/site-assets/animation-v-16.png',
        animation: {
          from: {
            x: 246,
            y: 606,
            width: 228,
            height: 328,
            opacity: 0,
          },
          enter: { opacity: 1 },
          update: { opacity: 0 },
          config: { duration: 1000 },
          delay: 6000,
        },
      },
      {
        id: 'image-13',
        image:
          'https://cnvs.s3.eu-central-1.amazonaws.com/cdn/site-assets/animation-v-6.png',
        animation: {
          from: {
            x: 483,
            y: 606,
            width: 228,
            height: 328,
          },
          enter: { opacity: 1 },
          update: { opacity: 1 },
          config: { duration: 1000 },
        },
      },
      {
        id: 'image-14',
        setInterval: 3000,
        image:
          'https://cnvs.s3.eu-central-1.amazonaws.com/cdn/site-assets/animation-v-9.png',
        animation: {
          from: {
            x: 483,
            y: 606,
            width: 228,
            height: 328,
            opacity: 0,
          },
          enter: { opacity: 1 },
          update: { opacity: 0 },
          config: { duration: 1000 },
          delay: 8000,
        },
      },
      {
        id: 'image-15',
        image:
          'https://cnvs.s3.eu-central-1.amazonaws.com/cdn/site-assets/animation-v-7.png',
        animation: {
          from: {
            x: 9,
            y: 943,
            width: 228,
            height: 328,
          },
          enter: { opacity: 1 },
          update: { opacity: 1 },
          config: { duration: 1000 },
        },
      },
      {
        id: 'image-16',
        setInterval: 3000,
        image:
          'https://cnvs.s3.eu-central-1.amazonaws.com/cdn/site-assets/animation-v-17.png',
        animation: {
          from: {
            x: 9,
            y: 943,
            width: 228,
            height: 328,
            opacity: 0,
          },
          enter: { opacity: 1 },
          update: { opacity: 0 },
          config: { duration: 1000 },
          delay: 5000,
        },
      },
      {
        id: 'image-17',
        image:
          'https://cnvs.s3.eu-central-1.amazonaws.com/cdn/site-assets/animation-v-8.png',
        animation: {
          from: {
            x: 246,
            y: 943,
            width: 228,
            height: 328,
          },
          enter: { opacity: 1 },
          update: { opacity: 1 },
          config: { duration: 1000 },
        },
      },
      {
        id: 'image-18',
        setInterval: 3000,
        image:
          'https://cnvs.s3.eu-central-1.amazonaws.com/cdn/site-assets/animation-v-7.png',
        animation: {
          from: {
            x: 246,
            y: 943,
            width: 228,
            height: 328,
            opacity: 0,
          },
          enter: { opacity: 1 },
          update: { opacity: 0 },
          config: { duration: 1000 },
          delay: 8000,
        },
      },
      {
        id: 'image-19',
        image:
          'https://cnvs.s3.eu-central-1.amazonaws.com/cdn/site-assets/animation-v-9.png',
        animation: {
          from: {
            x: 483,
            y: 943,
            width: 228,
            height: 328,
          },
          enter: { opacity: 1 },
          update: { opacity: 1 },
          config: { duration: 1000 },
        },
      },
      {
        id: 'image-20',
        setInterval: 3000,
        image:
          'https://cnvs.s3.eu-central-1.amazonaws.com/cdn/site-assets/animation-v-19.png',
        animation: {
          from: {
            x: 483,
            y: 943,
            width: 228,
            height: 328,
            opacity: 0,
          },
          enter: { opacity: 1 },
          update: { opacity: 0 },
          config: { duration: 1000 },
          delay: 4000,
        },
      },
    ],
    channableElems: [
      {
        id: 'logo-1',
        slot: 0,
        type: 'IMAGE',
        image: null,
        required: true,
        placeholderImage: 'Image 1',
      },
      {
        id: 'image-2',
        slot: 1,
        type: 'IMAGE',
        image: null,
        required: true,
        placeholderImage: 'Image 1',
      },
      {
        id: 'image-3',
        slot: 2,
        type: 'IMAGE',
        image: null,
        required: true,
        placeholderImage: 'Image 1',
      },
      {
        id: 'image-4',
        slot: 3,
        type: 'IMAGE',
        image: null,
        required: true,
        placeholderImage: 'Image 1',
      },
      {
        id: 'image-5',
        slot: 4,
        type: 'IMAGE',
        image: null,
        required: true,
        placeholderImage: 'Image 1',
      },
      {
        id: 'image-6',
        slot: 5,
        type: 'IMAGE',
        image: null,
        required: true,
        placeholderImage: 'Image 1',
      },
      {
        id: 'image-7',
        slot: 6,
        type: 'IMAGE',
        image: null,
        required: true,
        placeholderImage: 'Image 1',
      },
      {
        id: 'image-8',
        slot: 7,
        type: 'IMAGE',
        image: null,
        required: true,
        placeholderImage: 'Image 1',
      },
      {
        id: 'image-9',
        slot: 8,
        type: 'IMAGE',
        image: null,
        required: true,
        placeholderImage: 'Image 1',
      },
      {
        id: 'image-10',
        slot: 9,
        type: 'IMAGE',
        image: null,
        required: true,
        placeholderImage: 'Image 1',
      },
      {
        id: 'image-11',
        slot: 10,
        type: 'IMAGE',
        image: null,
        required: true,
        placeholderImage: 'Image 1',
      },
      {
        id: 'image-12',
        slot: 11,
        type: 'IMAGE',
        image: null,
        required: true,
        placeholderImage: 'Image 1',
      },
      {
        id: 'image-13',
        slot: 12,
        type: 'IMAGE',
        image: null,
        required: true,
        placeholderImage: 'Image 1',
      },
      {
        id: 'image-14',
        slot: 13,
        type: 'IMAGE',
        image: null,
        required: true,
        placeholderImage: 'Image 1',
      },
      {
        id: 'image-15',
        slot: 14,
        type: 'IMAGE',
        image: null,
        required: true,
        placeholderImage: 'Image 1',
      },
      {
        id: 'image-16',
        slot: 15,
        type: 'IMAGE',
        image: null,
        required: true,
        placeholderImage: 'Image 1',
      },
      {
        id: 'image-17',
        slot: 16,
        type: 'IMAGE',
        image: null,
        required: true,
        placeholderImage: 'Image 1',
      },
      {
        id: 'image-18',
        slot: 17,
        type: 'IMAGE',
        image: null,
        required: true,
        placeholderImage: 'Image 1',
      },
      {
        id: 'image-19',
        slot: 18,
        type: 'IMAGE',
        image: null,
        required: true,
        placeholderImage: 'Image 1',
      },
      {
        id: 'image-20',
        slot: 19,
        type: 'IMAGE',
        image: null,
        required: true,
        placeholderImage: 'Image 1',
      },
    ],
  },
];

export default animations;

import React, { memo } from 'react';
import {
  AnimatedLine,
  AnimatedText,
  AnimatedTexts,
  AnimatedImages,
  AnimatedImage,
} from 'components/Video';

const VideoCanvas = memo(({ animation }) => (
  <>
    {animation.map((product, index) => {
        if (product.type === 'line') {
          return <AnimatedLine key={index} animation={product.animation} />;
        }

        if (product.text) {
          return (
            <AnimatedText
              key={index}
              text={product.text}
              animation={product.animation}
              updateInterval={product.updateInterval}
            />
          );
        }
        if (product.texts) {
          return (
            <AnimatedTexts
              key={index}
              texts={product.texts}
              animation={product.animation}
            />
          );
        }
        if (product.images) {
          return (
            <AnimatedImages
              key={index}
              order={index}
              imageURL={product.images}
              animation={product.animation}
            />
          );
        }
        if (product.image) {
          return (
            <AnimatedImage
              key={index}
              order={index}
              imageURL={product.image}
              animation={product.animation}
              updateInterval={product.updateInterval}
            />
          );
        }
      })}
  </>
  ));

export default VideoCanvas;

import React from 'react';
import { Route, Routes } from 'react-router-dom';
import {
  Brand,
  DesignApp,
  FeedOptimisation,
  ForgotPassword,
  Home,
  Landing,
  Onboarding,
  ResetPassword,
  Signin,
  Signup,
  VideoCreate,
  VideoRecorder,
  VideoSelect,
  Profile,
} from 'pages';
import PDFBook from 'components/Catalog';
import { AppProvider } from 'context/AppContext';
import { useAuth } from 'context/AuthContext';
import { getRoute } from 'utils';
import { ProtectedRoute } from '../Auth';

const Index = () => {
  const [token] = useAuth((store) => store.token);

  return (
    <Routes>
      <Route path="/" element={token ? <Home /> : <Landing />} />
      <Route path="/pdf-reader" element={<PDFBook />} />

      <Route path={getRoute('signup')} element={<Signup />} />
      <Route path={getRoute('signin')} element={<Signin />} />
      <Route path={getRoute('forgotPassword')} element={<ForgotPassword />} />
      <Route path={getRoute('resetPassword')} element={<ResetPassword />} />
      <Route
        path={getRoute('brand')}
        element={(
          <ProtectedRoute>
            <Brand />
          </ProtectedRoute>
        )}
      />
      <Route
        path={getRoute('feedOptimisation')}
        element={<FeedOptimisation />}
      />
      <Route
        path={getRoute('profile')}
        element={(
          <ProtectedRoute>
            <Profile />
          </ProtectedRoute>
        )}
      />
      <Route
        path={getRoute('videoSelect')}
        element={(
          <ProtectedRoute>
            <VideoSelect />
          </ProtectedRoute>
        )}
      />
      <Route
        path={getRoute('videoRecorder')}
        element={(
          <ProtectedRoute>
            <VideoRecorder />
          </ProtectedRoute>
        )}
      />
      <Route
        path={getRoute('videoCreate')}
        element={(
          <ProtectedRoute>
            <VideoCreate />
          </ProtectedRoute>
        )}
      />
      <Route
        path={getRoute('design')}
        element={(
          <ProtectedRoute>
            <AppProvider>
              <DesignApp />
            </AppProvider>
          </ProtectedRoute>
        )}
      />

      <Route
        path={getRoute('onboarding')}
        element={(
          <ProtectedRoute>
            <Onboarding />
          </ProtectedRoute>
        )}
      />
    </Routes>
  );
};

export default Index;

import React from 'react';
import styled from 'styled-components';

import { Logo, TextLogo } from 'icons';

const LoginFormLogo = () => (
  <LogoWrapper>
    <LogoContainer href="/">
      <IconLogoWrapper>
        <IconLogoContainer>
          <Logo />
        </IconLogoContainer>
      </IconLogoWrapper>
      <TextLogoWrapper>
        <TextLogo fill="#fff" />
        <Beta>BETA</Beta>
      </TextLogoWrapper>
    </LogoContainer>
  </LogoWrapper>
  );

export default LoginFormLogo;

const LogoWrapper = styled.div`
  position: fixed;
  top: 30px;
  left: 50px;
  @media (max-width: 768px) {
    top: 20px;
    left: 20px;
  }
`;
const LogoContainer = styled.a`
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 10px;
  text-decoration: none;
`;
const IconLogoContainer = styled.div`
  width: 34px;
  height: 34px;
  background-color: #675ee0;
  border-radius: 8px;
  padding: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
`;
const TextLogoWrapper = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 5px;
`;

const IconLogoWrapper = styled.div`
  width: 36px;
  height: 36px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #fff;
  border-radius: 8px;
  filter: drop-shadow(0px 8px 8px rgba(255, 255, 255, 0.373));
`;

const Beta = styled.div`
  width: 43px;
  height: 24px;
  border-radius: 4px;
  background: #000;
  color: #fff;
  text-align: center;
  font-feature-settings: "liga" off;
  font-family: Lato;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px; /* 200% */
  letter-spacing: 0.5px;
`;

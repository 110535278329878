import React, {useEffect} from 'react';
import styled from 'styled-components';
import {useCookies} from 'react-cookie';
import {useInfiniteQuery, useQuery} from '@tanstack/react-query';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCropSimple } from '@fortawesome/pro-light-svg-icons';

import {AuthServices, DesignServices} from 'services';
import { useAuth } from 'context/AuthContext';
import {
  CreateProjectItem,
  LatestProjectItem,
  Layout,
} from 'components/Dashboard';
import {Headline3, Headline4} from 'components/Shared';

import InstagramPost from 'assets/images/InstaPost.png';
import InstagramStory from 'assets/images/InstaStory.png';
import FacebookPost from 'assets/images/FacebookPost.png';
import FacebookStory from 'assets/images/FacbookStory.png';
import TikTok from 'assets/images/TikTok.png';

const Index = () => {
  const [token, set] = useAuth((store) => store.token);
  const [cookies, setCookie] = useCookies(['user']);

  const { data: meData } = useQuery({
      queryFn: () => AuthServices.me(token),
      queryKey: ['me'],
  });

    useEffect(() => {
        const data = Array.isArray(meData) ? meData[0] : meData;
        if (data) {
            if (data.userRoles[0].company.name) {
                const tmpData = {
                    id: data.id,
                    email: data.email,
                    firstName: data.firstName,
                    lastName: data.lastName,
                    companyName: data.userRoles[0].company.name,
                };
                setCookie('user', JSON.stringify(tmpData));
                set({
                    user: tmpData,
                });
            }
        }
    }, [meData]);

    const {
        data,
        fetchNextPage,
        isFetchingNextPage,
    } = useInfiniteQuery({
        gueryKey: ['getDesignDrafts'],
        queryFn: ({ pageParam = 1 }) => DesignServices.getDesignDrafts(token, pageParam),
        getNextPageParam: ({ data }) => data?.meta?.nextPage,
        });

    const handleScroll = () => {
        const bottom = Math.ceil(window.innerHeight + window.scrollY) >= document.documentElement.scrollHeight;

        if (bottom) {
            fetchNextPage();
        }
    };
    useEffect(() => {
        window.addEventListener('scroll', handleScroll, {
            passive: true,
        });

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    return (
      <Layout>
        <Container>
          <Headline3>Create a new project</Headline3>
          <CardContainer>
            <CreateProjectItem
              title="Custom Size"
              custom
              size={{
              width: 1920,
              height: 1080,
            }}
              img={<FontAwesomeIcon icon={faCropSimple} color="#675EE0" />}
            />
            <CreateProjectItem
              title="Instagram Story"
              size={{
              width: 1080,
              height: 1920,
            }}
              img={<img src={InstagramStory} alt="Instagram Story" />}
              align="end"
            />
            <CreateProjectItem
              title="Instagram Post"
              size={{
              width: 1080,
              height: 1080,
            }}
              img={<img src={InstagramPost} alt="Instagram Post" />}
            />
            <CreateProjectItem
              title="Facebook Story"
              size={{
              width: 1200,
              height: 1200,
            }}
              img={<img src={FacebookStory} alt="Facebook Story" />}
              align="end"
            />
            <CreateProjectItem
              title="Facebook Post"
              size={{
              width: 1200,
              height: 1200,
            }}
              img={<img src={FacebookPost} alt="Facebook Post" />}
            />
            <CreateProjectItem
              title="TikTok"
              size={{
              width: 1200,
              height: 1200,
            }}
              img={<img src={TikTok} alt="TikTok" />}
              align="end"
            />
          </CardContainer>
          <Headline3 style={{ marginTop: '41px' }}>Latest Design</Headline3>
          <CardContainer>
            {data?.pages.map((page, p) => (
              <React.Fragment key={p}>
                {page.data?.data?.map((item) => (
                  <LatestProjectItem key={item.id} item={item} />
                      ))}
              </React.Fragment>
              ))}
          </CardContainer>
          {
                isFetchingNextPage && (
                <LoadingContainer>
                  <Headline4>Loading...</Headline4>
                </LoadingContainer>
                )
            }
        </Container>
      </Layout>
  );
};

export default Index;

const Container = styled.div`
  width: 85%;
  margin: 41px auto;
`;
const CardContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
  gap: ${(props) => props.gap};
  margin-top: 20px;
  gap: 20px;
`;
const LoadingContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
`;

import * as React from 'react';

const NoneEffectIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={35}
    height={40}
    fill="none"
    {...props}
  >
    <path
      fill="#000"
      d="M19.234 3.664A1.872 1.872 0 0 0 17.5 2.5c-.766 0-1.445.46-1.734 1.164L3.484 33.75H1.875A1.87 1.87 0 0 0 0 35.625 1.87 1.87 0 0 0 1.875 37.5h8.75a1.87 1.87 0 0 0 1.875-1.875 1.87 1.87 0 0 0-1.875-1.875H7.539l2.555-6.25h14.82l2.555 6.25h-3.094a1.87 1.87 0 0 0-1.875 1.875 1.87 1.87 0 0 0 1.875 1.875h8.75A1.87 1.87 0 0 0 35 35.625a1.87 1.87 0 0 0-1.875-1.875h-1.61L19.236 3.664Zm4.149 20.086H11.617L17.5 9.336l5.883 14.414Z"
    />
  </svg>
);
export default NoneEffectIcon;

import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useApp } from 'context/AppContext';
import { Subtitle2 } from 'components/Shared';
import {
  Divider,
  OpacityEdit,
  TextButton,
  TextSpacing,
  TopMenuButton,
} from 'components/Design';
import {
  faAlignCenter,
  faBold,
  faFontCase,
  faItalic,
  faUnderline,
  faMinus,
  faPlus,
  faAlignLeft,
  faAlignRight,
  faA,
  faAngleDown,
} from '@fortawesome/pro-light-svg-icons';

const TextEdit = () => {
  const {
    changeShape,
    selectedShape,
    onSidebarContentChange,
    currentSidebarContent,
  } = useApp();
  const [fontSize, setFontSize] = useState(selectedShape?.fontSize);
  const [opacity, setOpacity] = useState(selectedShape?.opacity * 100 || 100);
  const [letterSpacing, setLetterSpacing] = useState(
    selectedShape?.letterSpacing || 0,
  );
  const [lineHeight, setLineHeight] = useState(selectedShape?.lineHeight || 1);

  const onSizeChange = (e) => {
    setFontSize(Number(e.target.value));
    changeShape({ fontSize: Number(e.target.value) });
  };

  const increaseFontSize = () => {
    setFontSize(fontSize + 1);
    changeShape({ fontSize: fontSize + 1 });
  };

  const decreaseFontSize = () => {
    setFontSize(fontSize - 1);
    changeShape({ fontSize: fontSize - 1 });
  };

  const onBoldClick = () => {
    if (selectedShape.fontStyle === 'bold') {
      changeShape({ fontStyle: 'normal' });
      return;
    }
    changeShape({ fontStyle: 'bold' });
  };

  const onUnderlineClick = () => {
    if (selectedShape.textDecoration === 'underline') {
      changeShape({ textDecoration: '' });
      return;
    }
    changeShape({ textDecoration: 'underline' });
  };

  const onItalicClick = () => {
    if (selectedShape.fontStyle === 'italic') {
      changeShape({ fontStyle: 'normal' });
      return;
    }
    changeShape({ fontStyle: 'italic' });
  };

  const onUpperCaseClick = () => {
    if (selectedShape.textTransform === 'uppercase') {
      changeShape({
        text: selectedShape.text.toLowerCase(),
        textTransform: 'none',
      });
      return;
    }
    changeShape({
      text: selectedShape.text.toUpperCase(),
      textTransform: 'uppercase',
    });
  };

  const onAlignmentClick = () => {
    if (selectedShape.align === 'left') {
      changeShape({ align: 'center' });
      return;
    } if (selectedShape.align === 'center') {
      changeShape({ align: 'right' });
      return;
    }
    changeShape({ align: 'left' });
  };

  const renderAlignmentIcon = () => {
    if (selectedShape.align === 'left') {
      return faAlignLeft;
    } if (selectedShape.align === 'center') {
      return faAlignCenter;
    }
    return faAlignRight;
  };

  useEffect(() => {
    if (selectedShape.fontSize === fontSize) {
      return;
    }
    setFontSize(selectedShape?.fontSize);
  }, [selectedShape.fontSize]);

  // useEffect(() => {
  //   changeShape({
  //     opacity: opacity / 100,
  //     letterSpacing: letterSpacing,
  //     lineHeight: lineHeight,
  //   });
  // }, [opacity, letterSpacing, lineHeight]);

  return (
    <Wrapper id="color-picker-root">
      <FontFamilySelect onClick={() => onSidebarContentChange('fontFamily')}>
        <span>
          {' '}
          {selectedShape.fontFamily}
        </span>
        <FontAwesomeIcon icon={faAngleDown} />
      </FontFamilySelect>
      <SizeInput>
        <button onClick={decreaseFontSize}>
          <FontAwesomeIcon icon={faMinus} size="lg" />
        </button>
        <input type="number" value={fontSize} onChange={onSizeChange} />
        <button onClick={increaseFontSize}>
          <FontAwesomeIcon icon={faPlus} size="lg" />
        </button>
      </SizeInput>
      <TopMenuButton
        onClick={() => onSidebarContentChange('colorPicker')}
        data-tip="Color"
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <FontAwesomeIcon icon={faA} size="lg" />
        <ColorPreview background={selectedShape?.fill} />
      </TopMenuButton>
      <OpacityEdit opacity={opacity} setOpacity={setOpacity} />
      <TopMenuButton
        onClick={onBoldClick}
        data-tip="Bold"
        selected={selectedShape.fontStyle === 'bold'}
      >
        <FontAwesomeIcon icon={faBold} size="lg" />
      </TopMenuButton>
      <TopMenuButton
        selected={selectedShape.fontStyle === 'italic'}
        data-tip="Italic"
        onClick={onItalicClick}
      >
        <FontAwesomeIcon icon={faItalic} size="lg" />
      </TopMenuButton>
      <TopMenuButton
        selected={selectedShape.textDecoration === 'underline'}
        data-tip="Underline"
        onClick={onUnderlineClick}
      >
        <FontAwesomeIcon icon={faUnderline} size="lg" />
      </TopMenuButton>
      <TopMenuButton
        selected={selectedShape.textTransform === 'uppercase'}
        data-tip="Uppercase"
        onClick={onUpperCaseClick}
      >
        <FontAwesomeIcon icon={faFontCase} size="lg" />
      </TopMenuButton>
      <Divider />
      <TopMenuButton onClick={onAlignmentClick} data-tip="Alignment">
        <FontAwesomeIcon icon={renderAlignmentIcon()} size="lg" />
      </TopMenuButton>

      <TextSpacing
        lineHeight={lineHeight}
        letterSpacing={letterSpacing}
        setLineHeight={setLineHeight}
        setLetterSpacing={setLetterSpacing}
      />
      <Divider />

      <TextButton
        onClick={() => onSidebarContentChange('effects')}
        selected={currentSidebarContent === 'effects'}
      >
        <Subtitle2>Effects</Subtitle2>
      </TextButton>
      <Divider />

      <TextButton
        onClick={() => onSidebarContentChange('position')}
        selected={currentSidebarContent === 'position'}
      >
        <Subtitle2>Position</Subtitle2>
      </TextButton>
      <Divider />
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex: 1;
  position: relative;
`;

const FontFamilySelect = styled.button`
  max-width: 140px;
  width: 140px;
  height: 32px;
  border-radius: 4px;
  border: 1px solid rgba(103, 94, 224, 0.2);
  outline: none;
  background-color: white;
  text-align: left;
  padding: 0 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const SizeInput = styled.div`
  display: flex;
  margin-left: 5px;
  input {
    width: 48px;
    border: 1px solid rgba(103, 94, 224, 0.2);
    height: 32px;
    text-align: center;
    position: relative;
    border-radius: 0;
    -moz-appearance: textfield;
  }
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  button {
    background: none;
    cursor: pointer;
    border: none;
    width: 32px;
    height: 32px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 0px 4px 4px 0px;
    border-top: 1px solid rgba(103, 94, 224, 0.2);
    border-right: 1px solid rgba(103, 94, 224, 0.2);
    border-bottom: 1px solid rgba(103, 94, 224, 0.2);
  }
  button:first-of-type {
    border-radius: 4px 0px 0px 4px;
    border-top: 1px solid rgba(103, 94, 224, 0.2);
    border-bottom: 1px solid rgba(103, 94, 224, 0.2);
    border-left: 1px solid rgba(103, 94, 224, 0.2);
    border-right: none;
  }
`;

const ColorPreview = styled.div`
  width: 20px;
  height: 5px;
  border-radius: 4px;
  border: 1px solid #e6e6e6;
  background: ${({ background }) => background || '#ffffff'};
`;
export default TextEdit;

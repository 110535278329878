import React from 'react';
import styled from 'styled-components';
import { faEllipsis } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FacebookCommentIcon, FacebookLikeIcon, FacebookShareIcon } from 'icons';
import {useAuth} from '../../../../context/AuthContext';

const Index = ({ base64 }) => {
    const [user] = useAuth((store) => store.user);

    return (
      <Container>
        <Top>
          <LeftSide>
            <ProfilePicture />
            <BrandName>
              {user?.companyName || 'Brand Name'}
            </BrandName>
          </LeftSide>
          <FontAwesomeIcon icon={faEllipsis} color="white" />
        </Top>
        <PostText>
          Your post text
        </PostText>
        <Content>
          <img src={`data:image/jpeg;base64,${base64}`} alt="facebook-post" />
        </Content>
        <Bottom>
          <Line />
          <ActionsWrapper>
            <ActionItem>
              <FacebookLikeIcon />
              <Text>Like</Text>
            </ActionItem>
            <ActionItem content="center">
              <FacebookCommentIcon />
              <Text>Comment</Text>
            </ActionItem>
            <ActionItem content="flex-end">
              <FacebookShareIcon />
              <Text>Share</Text>
            </ActionItem>
          </ActionsWrapper>
        </Bottom>
      </Container>
    );
};

const Container = styled.div`
  background: black;
  width: 320.218px;
  height: 416px;
  display: flex;
  flex-direction: column;
`;
const Top = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding: 13px 12px;
`;
const ProfilePicture = styled.div`
  width: 36px;
  height: 36px;
  border-radius: 50%;
  background: #fff;
`;
const BrandName = styled.div`
  color: #FFF;
  font-family: Inter;
  font-size: 10.84px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`;
const LeftSide = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
`;
const PostText = styled.div`
  color: #FFF;   
  font-size: 8.72px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin: 0 15px 10px;
`;
const Content = styled.div`
  flex:1;
  
  img {
    width: 100%;
    height: 100%;
  }
`;
const Bottom = styled.div`
  background: black;
  padding: 12px 20px 15px;
`;
const Line = styled.div`
  height: 0.5px;
  width: 100%;
  background: #666;
  margin-bottom: 10px;
`;
const ActionsWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;
const ActionItem = styled.div`
  flex: 1;
  display: flex;
  justify-content: ${(props) => (props.content ? props.content : 'flex-start')};
  align-items: center;
  gap: 6px;
  height: 19px;
`;
const Text = styled.div`
  color: #FFF;
  font-family: Inter;
  font-size: 10.02px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`;
export default Index;

import React from 'react';
import styled from 'styled-components';

const InputWrapper = ({
  width,
  mobile,
  background,
  name,
  label,
  children,
  error,
}) => (
  <StyledInputWrapper
    width={width}
    mobile={mobile}
    background={background}
    error={error}
  >
    <label htmlFor={name}>{label}</label>
    {children}
  </StyledInputWrapper>
  );

const StyledInputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: ${(props) => props.width || 'auto'};
  position: relative;
  margin: 10px 0;
  @media (max-width: 1024px) {
    width: ${(props) => props.mobile || props.width};
  }
  label {
    font-size: 15px;
    font-weight: 800;
    line-height: 1;
    letter-spacing: 0.2px;
  }

  input {
    font-family: "Lato", sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    width: ${(props) => props.width || '375px'};
    height: 52px;
    border: ${({ error }) => (error ? '2px solid #FF2100' : '2px solid #DDDBF5')};
    outline: none;
    background: ${(props) => props.background || '#FAFAFA'};
    border-radius: 8px;
    margin: 0;
    padding: 13px 20px;
    ::placeholder {
      padding-left: 0;
      font-size: 14px;
      font-weight: 500;
      line-height: 1;
      letter-spacing: 0.2px;
      color: #a0a0a0;
      font-family: "Lato", sans-serif;
    }
    :-webkit-autofill {
      -webkit-box-shadow: 0 0 0 30px #fafafa inset !important;
    }
    @media (max-width: 1024px) {
      width: ${(props) => props.mobile || props.width};
    }

    :focus {
      outline: none;
    }
  }

  svg {
    position: absolute;
    right: 20px;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
  }
`;

export default InputWrapper;

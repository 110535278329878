import React, { useEffect, useRef, useState } from 'react';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useClickAway } from 'react-use';
import styled from 'styled-components';
import { toast } from 'react-toastify';
import { v4 as uuidv4 } from 'uuid';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMagnifyingGlass } from '@fortawesome/pro-light-svg-icons';
import { OnboardingServices } from 'services';
import { useAuth } from 'context/AuthContext';
import {
  Layout,
  TableFilterGroupItem,
  TableEditMenu,
} from 'components/Dashboard';
import {
  Headline3,
  Headline4,
  Subtitle2,
  Button,
  FeedOptimisationModal,
  LoadingModal,
  Subtitle3,
} from 'components/Shared';

const FeedOptimisation = () => {
  const [token] = useAuth((store) => store.token);
  const queryClient = useQueryClient();
  const { data, isLoading } = useQuery({
    queryKey: ['getSavedFilters'],
    queryFn: () => OnboardingServices.getSavedFilters(token),
  });
  const refactorFilter = (filters, name, id) => {
    const allFilters = [];

    Object.keys(filters).map((item) => {
      Object.keys(filters[item].and).map((andItem, index) => {
        if (andItem) {
          allFilters.push({
            id: index === 0 ? '0' : uuidv4(),
            key: andItem,
            type: 'and',
            label: null,
            included: item === 'includes' ? 'included' : 'non_included',
            value: filters[item].and[andItem][Object.keys(filters[item].and[andItem])[0]],
            filter: null,
            filterType: Object.keys(filters[item].and[andItem])[0],
          });
        }
      });
      Object.keys(filters[item].or).map((orItem) => {
        if (orItem) {
          allFilters.push({
            id: uuidv4(),
            key: orItem,
            type: 'or',
            label: null,
            included: item === 'includes' ? 'included' : 'non_included',
            value: filters[item].or[orItem][Object.keys(filters[item].or[orItem])[0]],
            filter: null,
            filterType: Object.keys(filters[item].or[orItem])[0],
          });
        }
      });
      const tmp = {...dataGroup};
      tmp.filters = allFilters;
      tmp.name = name;
      tmp.id = id;
      setDataGroup(tmp);
    });
  };

  const { mutate: saveFilterMutate } = useMutation({
    mutationKey: ['saveFilters'],
    mutationFn: (data) => OnboardingServices.saveFilters(data, token),
    onSuccess: () => {
      toast.success('New Group Created Successfully.');
      queryClient.invalidateQueries(['getSavedFilters']);
    },
  });

  const { mutate: editFilterMutate } = useMutation({
    mutationKey: ['editFilter'],
    mutationFn: (data, id) => OnboardingServices.editFilter(token, data, dataGroup?.id),
    onSuccess: () => {
      queryClient.invalidateQueries(['getSavedFilters']);
    },
  });

  const { mutate } = useMutation({
    mutationFn: (data) => OnboardingServices.deleteFilter(token, data),
    mutationKey: ['deleteFilter'],
    onSuccess: ({ data }) => {
      toast.success('Filter Deleted Successfully');
      queryClient.invalidateQueries('getSavedFilters');
    },
  });

  const [searchValue, setSearchValue] = useState('');
  const searchRef = useRef(null);
  const [isSearchOpen, setIsSearchOpen] = useState(false);
  const [isFeedOptimisationOpen, setIsFeedOptimisationOpen] = useState(false);
  const [dataGroup, setDataGroup] = useState(null);

  const dateFormatter = (date) => {
    const today = new Date(date);
    const yyyy = today.getFullYear();
    let mm = today.getMonth() + 1; // Months start at 0!
    let dd = today.getDate();

    if (dd < 10) dd = `0${dd}`;
    if (mm < 10) mm = `0${mm}`;

    return `${dd}.${mm}.${yyyy}`;
  };

  const timeFormatter = (date) => {
    const today = new Date(date);
    const hours = today.getUTCHours();
    const minutes = today.getUTCMinutes();
    const seconds = today.getUTCSeconds();

    let amOrPm = 'am';
    let twelveHour = hours;
    if (hours >= 12) {
      amOrPm = 'pm';
      twelveHour = hours === 12 ? 12 : hours - 12;
    }
    if (twelveHour === 0) {
      twelveHour = 12; // 12:00 PM yerine 12:00 AM olarak göster
    }

    return `${twelveHour}:${minutes.toString().padStart(2, '0')}:${seconds
        .toString()
        .padStart(2, '0')} ${amOrPm}`;
  };

  const searchFilter = () => data?.filter((item) => {
      if (item.name.toLowerCase().includes(searchValue.toLowerCase())) {
        return item;
      }
    });

  useClickAway(searchRef, () => {
    setIsSearchOpen(false);
  });

  useEffect(() => {
    if (searchValue.length > 0) {
      setIsSearchOpen(true);
    }
  }, [searchValue]);

  const saveFilter = ({ name, filter }) => {
    if (dataGroup !== null) {
      editFilterMutate({
          name,
          filter,
      });
    } else {
      saveFilterMutate({
        name,
        filter,
      });
    }
  };

  const editOnClick = (allFilters, name, id) => {
    refactorFilter(allFilters, name, id);
    setIsFeedOptimisationOpen(true);
  };

  const tableData = searchValue ? searchFilter() : data;

  if (isLoading) return <Layout><LoadingModal /></Layout>;

  return (
    <Layout>
      <Container>
        <Top>
          <Headline3>All Product Groups</Headline3>
          <SearchContainer>
            <FontAwesomeIcon icon={faMagnifyingGlass} />
            <SearchInput
              placeholder="Product Group Search by Name or ID"
              value={searchValue}
              onChange={(e) => {
                          setSearchValue(e.target.value);
                          searchFilter();
                        }}
              disabled={data?.length === 0}
            />
          </SearchContainer>
        </Top>
        <Table hasProduct={data || data?.length > 0}>
          <TableRow>
            <TableCell header>
              <Subtitle2 style={{ textAlign: 'center' }}>
                Product Group
              </Subtitle2>
            </TableCell>
            <TableCell header>
              <Subtitle2>Number of Products</Subtitle2>
            </TableCell>
            <TableCell header>
              <Subtitle2>Last Update</Subtitle2>
            </TableCell>
            <TableCell header>
              <Subtitle2>Transactions</Subtitle2>
            </TableCell>
          </TableRow>
          <Tbody hasProduct={tableData?.length > 0}>
            {tableData?.length > 0 ? (
              <>
                {tableData?.map((item) => (
                  <TableRow key={item.id}>
                    <TableCell>
                      <TableFilterGroupItem
                        name={item.name}
                        createdDate={dateFormatter(item.createdAt)}
                        time={timeFormatter(item.createdAt)}
                      />
                    </TableCell>
                    <TableCell>
                      <Headline4>{item.productCount ? item.productCount : '0'}</Headline4>
                    </TableCell>
                    <TableCell>
                      <Headline4>{dateFormatter(item.updatedAt)}</Headline4>
                      <Subtitle3 style={{ color: '#7F7F7F' }}>
                        {timeFormatter(item.updatedAt)}
                      </Subtitle3>
                    </TableCell>
                    <TableCell>
                      <TableEditMenu item={item} deleteMutation={mutate} editOnClick={editOnClick} />
                    </TableCell>
                  </TableRow>
                          ))}
              </>
                    ) : (
                      <Headline4 style={{ color: '#7F7F7F' }}>
                        {searchValue ? 'No Product Group Found' : 'You have not yet created a product group.'}
                      </Headline4>
                    )}
          </Tbody>
        </Table>
        <ButtonContainer>
          <Button
            type="primary"
            size="medium"
            content="Create Product Group"
            handleClick={() => setIsFeedOptimisationOpen(true)}
          />
        </ButtonContainer>
      </Container>
      <FeedOptimisationModal
        isOpen={isFeedOptimisationOpen}
        setIsOpen={setIsFeedOptimisationOpen}
        onApply={saveFilter}
        dataGroup={dataGroup}
        buttonTitle="Create Product Group"
      />
    </Layout>
  );
};

export default FeedOptimisation;

const Container = styled.div`
  width: 85%;
  margin: 41px auto;
`;
const Top = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 33px;
`;
const SearchContainer = styled.div`
  position: relative;
  border-radius: 8px;
  border: 0.5px solid #000;
  width: 247px;
  height: 40px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 7px 10px;
  z-index: 1 !important;
`;
const SearchInput = styled.input`
  background: transparent;
  border: none;
  outline: none;
  margin-left: 10px;
  width: 100%;
  ::placeholder {
    color: #7f7f7f;
    font-family: Lato;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px; /* 200% */
  }
  :focus {
    outline: none;
  }
`;
const SearchResultContainer = styled.div`
  z-index: 3;
  width: 247px;
  height: 112px;
  border-radius: 8px;
  border: 1px solid rgba(103, 94, 224, 0.2);
  background: #fafafa;
  box-shadow: 0 4px 8px 0 rgba(91, 204, 124, 0.16);
  padding: 6px;
  overflow-y: scroll;
  position: absolute;
  left: -1px;
  top: 45px;

  ::-webkit-scrollbar {
    width: 4px;
  }
  ::-webkit-scrollbar-track {
    margin: 5px 0 10px;
    background: #fafafa;
    border-radius: 8px;
  }
  ::-webkit-scrollbar-thumb {
    background: #e5e5e5;
    border-radius: 8px;
  }
  ::-webkit-scrollbar-thumb:hover {
    background: #e5e5e5;
  }
`;
const SearchOption = styled.div`
  padding: 4px;
  border-radius: 8px;
  background: #fafafa;
  cursor: pointer;

  :hover {
    background: #f0effc;
  }
`;
const Table = styled.div`
  border-radius: 16px;
  border: ${(props) => (props.hasProduct ? '3px solid #FFF' : '3px solid #F2F4F8')};
  width: 100%;
  background: #fafafa;
`;
const TableCell = styled.div`
  padding: 10px;
  flex: 1;

  h4 {
    color: ${(props) => (props.header ? '#7F7F7F' : '#000')};
  }
`;
const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: 30px;
`;
const Tbody = styled.div`
  background: #f2f4f8;
  height: 352px;
  overflow-y: auto;
  width: 100%;
  border-radius: 0 0 16px 16px;
  display: flex;
  flex-direction: column;
  justify-content: ${(props) => !props.hasProduct && 'center'};
  align-items: ${(props) => !props.hasProduct && 'center'};

  ::-webkit-scrollbar {
    width: 4px;
  }
  ::-webkit-scrollbar-track {
    margin: 5px 0 10px;
    background: #f2f4f8;
    border-radius: 8px;
  }
  ::-webkit-scrollbar-thumb {
    background: #e5e5e5;
    border-radius: 8px;
  }
  ::-webkit-scrollbar-thumb:hover {
    background: #e5e5e5;
  }
`;
const TableRow = styled.div`
  display: flex;

  > * {
    &:first-child {
      flex: 30% !important;
    }
  }
`;

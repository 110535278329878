import * as React from 'react';

const CorruptionEffectIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={37}
    height={42}
    fill="none"
    {...props}
  >
    <g clipPath="url(#a)">
      <path
        fill="#2DFFFE"
        d="M19.766 3.164A1.872 1.872 0 0 1 21.5 2c.766 0 1.445.46 1.734 1.164L35.516 33.25h1.609A1.87 1.87 0 0 1 39 35.125 1.87 1.87 0 0 1 37.125 37h-8.75a1.87 1.87 0 0 1-1.875-1.875 1.87 1.87 0 0 1 1.875-1.875h3.086L28.906 27h-14.82l-2.555 6.25h3.094a1.87 1.87 0 0 1 1.875 1.875A1.87 1.87 0 0 1 14.625 37h-8.75A1.87 1.87 0 0 1 4 35.125a1.87 1.87 0 0 1 1.875-1.875h1.61l12.28-30.086ZM15.617 23.25h11.766L21.5 8.836 15.617 23.25Z"
      />
      <path
        fill="#FD28FC"
        d="M15.766 5.664A1.872 1.872 0 0 1 17.5 4.5c.766 0 1.445.46 1.734 1.164L31.516 35.75h1.609A1.87 1.87 0 0 1 35 37.625a1.87 1.87 0 0 1-1.875 1.875h-8.75a1.87 1.87 0 0 1-1.875-1.875 1.87 1.87 0 0 1 1.875-1.875h3.086l-2.555-6.25h-14.82L7.53 35.75h3.094a1.87 1.87 0 0 1 1.875 1.875 1.87 1.87 0 0 1-1.875 1.875h-8.75A1.87 1.87 0 0 1 0 37.625a1.87 1.87 0 0 1 1.875-1.875h1.61l12.28-30.086ZM11.617 25.75h11.766L17.5 11.336 11.617 25.75Z"
      />
    </g>
    <path
      fill="#000"
      d="M17.766 3.664A1.872 1.872 0 0 1 19.5 2.5c.766 0 1.445.46 1.734 1.164L33.516 33.75h1.609A1.87 1.87 0 0 1 37 35.625a1.87 1.87 0 0 1-1.875 1.875h-8.75a1.87 1.87 0 0 1-1.875-1.875 1.87 1.87 0 0 1 1.875-1.875h3.086l-2.555-6.25h-14.82L9.53 33.75h3.094a1.87 1.87 0 0 1 1.875 1.875 1.87 1.87 0 0 1-1.875 1.875h-8.75A1.87 1.87 0 0 1 2 35.625a1.87 1.87 0 0 1 1.875-1.875h1.61l12.28-30.086ZM13.617 23.75h11.766L19.5 9.336 13.617 23.75Z"
    />
    <defs>
      <clipPath id="a">
        <path fill="#fff" d="M35 2H0v40h35z" />
      </clipPath>
    </defs>
  </svg>
);
export default CorruptionEffectIcon;

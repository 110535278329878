import React from 'react';
import { useLottie } from 'lottie-react';
import loadingAnimation from 'assets/animations/loadingAnimation.json';

const Loading = () => {
  const options = {
    animationData: loadingAnimation,
    loop: true,
    border: 'none',
    style: {
      padding: 150,
    },
  };

  const { View } = useLottie(options);

  return <>{View}</>;
};

export default Loading;

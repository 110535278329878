import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { toast } from 'react-toastify';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFiles, faXmark } from '@fortawesome/pro-light-svg-icons';

import { TextLogo } from 'icons';
import {
  Body1,
  Headline3,
  Subtitle1,
  RightModal,
  Logo,
  Body2,
  Subtitle2,
} from 'components/Shared';
import { CommonServices, DesignServices } from 'services';
import { useQuery } from '@tanstack/react-query';
import { useAuth } from 'context/AuthContext';

const DownloadXmlUrlModal = ({ isOpen, onClose, xmlID }) => {
  const childrenContainerRef = useRef(null);
  const [token] = useAuth((store) => store.token);
  const [completed, setCompleted] = useState(false);

  const { data } = useQuery({
    queryKey: ['getXML', xmlID],
    queryFn: () => CommonServices.getXML(xmlID, token),
    enabled: !!xmlID && !completed,
    refetchInterval: 400,
  });
  const { data: downloadData } = useQuery({
    queryKey: ['downloadDesign'],
    queryFn: () => DesignServices.downloadDesign(xmlID, token),
    enabled: !!xmlID && completed,
  });
  console.log(downloadData?.data?.url.Location, 'safdasdfasdfasdfasdfasdfasdf');
  const xmlLink = data?.data?.url || '';
  const processRate = data?.data?.processRate || 0;

  const copyToClipboard = async () => {
    if ('clipboard' in navigator) {
      await navigator.clipboard.writeText(xmlLink);
    } else {
      document.execCommand('copy', true, xmlLink);
    }
    toast.success('Copied to clipboard!');
  };

  useEffect(() => {
    if (data?.data?.status === 'SUCCESS') setCompleted(true);
  }, [data?.data?.status]);

  return (
    <RightModal isOpen={isOpen}>
      <RightModalContainer ref={childrenContainerRef}>
        <Top>
          <LogoWrapper>
            <Logo />
            <TextLogo />
          </LogoWrapper>
          <CloseButton
            onClick={() => {
              onClose();
              setCompleted(false);
            }}
          >
            <FontAwesomeIcon icon={faXmark} />
          </CloseButton>
        </Top>
        <Content>
          <Headline3 style={{ textAlign: 'center', marginTop: '48px' }}>
            Your product data is being converted into ad creatives,
            <br />
            please do not close the page!
          </Headline3>

          <Container>
            <CenteredRow>
              <BigText>
                {Math.round(processRate)}
                {' '}
                %
              </BigText>
            </CenteredRow>
            <CenteredRow>
              <Headline3>You Are All Set!</Headline3>
              <Body1 style={{ margin: '30px 0' }}>
                🎉 You've transformed your product data into stunning ad
                creatives. 🎉
              </Body1>
              <CopyLinkContainer disabled={!completed}>
                <LinkArea>
                  <Subtitle1
                    style={{ cursor: 'pointer' }}
                    onClick={copyToClipboard}
                  >
                    {xmlLink.substring(0, 34).concat('...')}
                  </Subtitle1>
                </LinkArea>
                <CopyIcon onClick={copyToClipboard} disabled={!completed}>
                  <FontAwesomeIcon icon={faFiles} color="white" />
                </CopyIcon>
              </CopyLinkContainer>
              <Body2 style={{ color: '#7F7F7F', margin: '30px 0 36px' }}>
                OR
              </Body2>

              <GradientButton disabled={!downloadData?.data?.url.Location}>
                <GradientCover />
                <a href={downloadData?.data?.url.Location} download>
                  <Subtitle2>Download All</Subtitle2>
                </a>
              </GradientButton>
            </CenteredRow>
          </Container>
        </Content>
      </RightModalContainer>
    </RightModal>
  );
};

const RightModalContainer = styled.div`
  background: #f2f4f8;
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
`;
const Top = styled.div`
  padding: 10px 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
const CloseButton = styled.button`
  outline: none;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  background: transparent;
  cursor: pointer;
`;
const LogoWrapper = styled.div`
  gap: 10px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;
const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 70px;
  flex-grow: 1;
`;
const Content = styled.div`
  padding: 10px 20px;
  height: calc(100% - 107px);
  overflow-y: scroll;
  flex: 1;
  display: flex;
  flex-direction: column;

  ::-webkit-scrollbar {
    width: 5px;
  }

  ::-webkit-scrollbar-thumb {
    background-color: #888;
  }

  scrollbar-width: thin;
  scrollbar-color: #888 transparent;
`;
const CenteredRow = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  flex: 1;
`;
const BigText = styled.div`
  color: #675ee0;
  font-family: Lato;
  font-size: 200px;
  font-style: normal;
  font-weight: 600;
  line-height: 212px; /* 106% */
`;

const CopyLinkContainer = styled.button`
  border-radius: 8px;
  border: 1px solid #050000;
  background: #fafafa;
  width: 345px;
  height: 56px;
  position: relative;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  :disabled {
    border-color: #cecece;
  }
`;
const LinkArea = styled.div`
  border-radius: 4px 0 0 4px;
  background: white;
  margin-left: 15px;
`;
const CopyIcon = styled.button`
  border-radius: 0 7px 7px 0;
  background: #675ee0;
  padding: 17px;
  height: 100%;
  width: 53px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  right: -1px;
  top: 0;
  border: none;
  :disabled {
    background: #ccc;
  }
`;
const GradientButton = styled.button`
  border-radius: 8px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  cursor: pointer;
  border: none;
  transition: transform 0.3s ease;
  padding: 8px 16px;
  width: 345px;
  height: 56px;

  h4 {
    position: relative;
    z-index: 2;
  }
  &&::before {
    content: "";
    position: absolute;
    width: 110%;
    height: 350%;
    background: linear-gradient(90deg, #675ee0 0%, #5bcc7c 100%);
  }
  :hover {
    transform: scale(1.03);
  }
  a {
    text-decoration: none;
  }
  :disabled {
    background-color: #cecece;
    &&::before {
      background: #cecece;
    }
  }
`;
const GradientCover = styled.div`
  position: absolute;
  background-color: #fff;
  width: calc(100% - 3px);
  height: calc(100% - 2px);
  border-radius: 7px;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
`;

export default DownloadXmlUrlModal;

import React, { useRef, useState } from 'react';
import styled from 'styled-components';
import { Stage, Layer, Rect } from 'react-konva';
import { useMutation } from '@tanstack/react-query';
import { VideoServices } from 'services';
import { useAuth } from 'context/AuthContext';
import { VideoCanvas } from 'components/Video';
import animations, { animlast } from 'lib/Videos/animations';

const VideoCreate = () => {
  const stageRef = useRef();
  const [selectedAnimation, setSelectedAnimation] = useState(null);
  const [token] = useAuth((store) => store.token);

  const { mutate } = useMutation({
    mutationFn: (data) => VideoServices.addNewTemplate(data),
  });

  const { mutate: editMutate } = useMutation({
    mutationFn: (data) => VideoServices.addNewTemplate(data),
  });

  const addTemplate = () => {
    editMutate({
      id: animlast.id,
      token,
      data: animlast,
    });
  };

  return (
    <Wrapper>
      <SideBar>
        <h1>Videos</h1>
        <AnimationContainer>
          {animations.map((anim) => (
            <button onClick={() => setSelectedAnimation(anim)}>
              {anim.name}
            </button>
            ))}
        </AnimationContainer>
      </SideBar>

      {selectedAnimation ? (
        <AnimationWrapper id="animation_container">
          <Stage
            ref={stageRef}
            width={selectedAnimation.screenDimensions.width}
            height={selectedAnimation.screenDimensions.height}
            // style={{
            //   width: selectedAnimation.screenDimensions.width,
            // }}
          >
            <Layer>
              <Rect
                x={0}
                y={0}
                width={selectedAnimation.screenDimensions.width}
                height={selectedAnimation.screenDimensions.height}
                fill={selectedAnimation.stageStyle.backgroundColor}
              />
              <VideoCanvas animation={selectedAnimation.animation} />
            </Layer>
          </Stage>
        </AnimationWrapper>
      ) : (
        <p>SELECT A ANIMATION</p>
      )}
      <ExportButton onClick={addTemplate} />
    </Wrapper>
  );
};

const Wrapper = styled.div`
  width: 100%;
  max-height: 100vh;
  position: relative;
  overflow: hidden;
  display: grid;
  grid-template-columns: 1fr 2fr;
`;
const AnimationWrapper = styled.div`
  transform: scale(0.4);
  transform-origin: top;
  width: fit-content;
  height: fit-content;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #cecece;
  margin-top: 20px;
  padding: 0;
`;
const SideBar = styled.div`
  position: relative;
  background-color: #cecece;
  padding: 20px;
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
`;

const AnimationContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 10px;
  margin-top: 20px;
  button {
    width: 180px;
    height: 180px;
    border: none;
    border-radius: 16px;
  }
`;

const ExportButton = styled.button`
  position: absolute;
  bottom: 40px;
  right: 20px;
  background-color: black;
  color: white;
  padding: 10px 20px;
  border: none;
`;

export default VideoCreate;

import React, { useRef, useState } from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faAngleDown,
  faEquals,
  faGreaterThan,
  faGreaterThanEqual,
  faLessThan,
  faLessThanEqual,
} from '@fortawesome/pro-light-svg-icons';

import { Caption1, Subtitle3 } from 'components/Shared';

const numericOptions = {
  gt: 'Greater than',
  gte: 'Greater than or equal',
  lt: 'Less than',
  lte: 'Less than or equal',
  equal: 'Equal',
};
const numericIcons = {
  gt: faGreaterThan,
  gte: faGreaterThanEqual,
  lt: faLessThan,
  lte: faLessThanEqual,
  equal: faEquals,
};

const NumericOption = ({
  label,
  filterTypeList,
  selectedFilterType,
  setSelectedFilterType,
  selectedOptions,
  setSelectedOptions,
}) => {
  const filterTypeRef = useRef(null);

  const [filterTypeIsOpen, setFilterTypeIsOpen] = useState(false);

  return (
    <NumericContainer>
      <Caption1>{label}</Caption1>
      <NumericRow>
        <SelectContainer ref={filterTypeRef}>
          <Select
            isOpen={filterTypeIsOpen}
            onClick={() => setFilterTypeIsOpen(!filterTypeIsOpen)}
          >
            <Subtitle3>
              {selectedFilterType
                ? numericOptions[selectedFilterType]
                : 'Select'}
            </Subtitle3>
            <FontAwesomeIcon icon={faAngleDown} />
          </Select>
          {filterTypeIsOpen && (
            <OptionsContainer>
              {filterTypeList.map((type, t) => (
                <Option
                  key={t}
                  selected={selectedFilterType && selectedFilterType === type}
                  onClick={() => {
                      setSelectedFilterType(type);
                      setFilterTypeIsOpen(false);
                    }}
                >
                  <FontAwesomeIcon icon={numericIcons[type]} />
                  {' '}
                  {numericOptions[type]}
                </Option>
                ))}
            </OptionsContainer>
          )}
        </SelectContainer>
        <Input
          placeholder="Price"
          width="97px"
          value={selectedOptions}
          onChange={(e) => setSelectedOptions(e.target.value)}
        />
      </NumericRow>
    </NumericContainer>
  );
};

const NumericContainer = styled.div`
  position: absolute;
  z-index: 4;
  top: 45px;
  right: 0;
  width: 320px;
  max-height: 156px;
  border-radius: 8px;
  border: 1px solid rgba(103, 94, 224, 0.2);
  background: #fafafa;
  box-shadow: 0 4px 8px 0 rgba(91, 204, 124, 0.16);
  padding: 10px 15px;
`;
const NumericRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;

  input {
    margin-top: 0;
  }
`;
const SelectContainer = styled.div`
  position: relative;
  width: 100%;
  background: #fafafa;
`;
const Select = styled.div`
  width: 100%;
  height: 40px;
  border-radius: 8px;
  border: 0.5px solid #000;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 15px;
  cursor: pointer;

  svg {
    transition: transform 0.1s linear;
    transform: ${(props) => (props.isOpen
        ? 'rotate(180deg) translateY(0)'
        : 'rotate(0) translateY(0)')};
  }
`;
const OptionsContainer = styled.div`
  position: absolute;
  z-index: 4;
  top: 45px;
  width: 100%;
  max-height: 310px;
  overflow-y: auto;
  border-radius: 8px;
  border: 1px solid rgba(103, 94, 224, 0.2);
  background: #fafafa;
  box-shadow: 0 4px 8px 0 rgba(91, 204, 124, 0.16);
  padding: 10px 0;

  ::-webkit-scrollbar {
    width: 4px;
  }
  ::-webkit-scrollbar-track {
    margin: 5px 0;
    background: #fafafa;
    border-radius: 8px;
  }
  ::-webkit-scrollbar-thumb {
    background: #e5e5e5;
    border-radius: 8px;
  }
  ::-webkit-scrollbar-thumb:hover {
    background: #e5e5e5;
  }
`;
const Option = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 5px 10px;
  margin: 0 6px;
  min-height: 32px;
  border-radius: 8px;
  border: ${(props) => (props.selected ? '1px solid #675EE0' : '1px solid #FAFAFA')};
  background: ${(props) => (props.selected ? '#F0EFFC' : '#FAFAFA')};
  overflow: hidden;
  text-overflow: ellipsis;

  :hover {
    background: #f0effc;
  }
`;
const Input = styled.input`
  border-radius: 8px;
  border: 0.5px solid #000;
  width: ${(props) => (props.width ? props.width : '277px')};
  height: 40px;
  padding: 7px 15px;
  margin-top: 5px;
  background: #fafafa;

  ::placeholder {
    color: #7f7f7f;
    font-family: "Lato", sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: ${({ theme }) => theme.sizes.s};
    line-height: 24px;
    letter-spacing: 1px;
  }

  :focus {
    outline: none;
  }
`;
export default NumericOption;

import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { Stage, Layer } from 'react-konva';
import Konva from 'konva';
import { useQuery } from '@tanstack/react-query';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faMagnifyingGlassMinus,
  faMagnifyingGlassPlus,
} from '@fortawesome/pro-light-svg-icons';
import { useSearchParams } from 'react-router-dom';
import { DesignServices } from 'services';
import { calculateZoomLevel } from 'utils';
import { useAuth } from 'context/AuthContext';
import { useApp } from 'context/AppContext';
import { LoadingModal, Headline4 } from 'components/Shared';
import {
  SideBar,
  TopMenu,
  Header,
  RightSideBar,
  Text,
  Image,
} from 'components/Design';
import { useDeepCompareEffect } from 'react-use';

const GUIDELINE_OFFSET = 10;

const DesignApp = () => {
  const {
    shapes,
    selectedId,
    stageSize,
    selectShape,
    stageStyle,
    changeShape,
    handleContextMenuClose,
    showCropImage,
    isCropApplied,
    setIsCropApplied,
    resetSidebarContent,
    setInitialDesign,
    copyShape,
    removeShape,
    selectedShape,
    pasteShape,
    copiedElem,
    undo,
    redo,
    canUndo,
    canRedo,
    setDesignReady,
  } = useApp();
  const [searchParams] = useSearchParams();
  const [token] = useAuth((store) => store.token);
  const id = searchParams.get('id');
  const [designName, setDesignName] = useState(null);
  const [designData, setDesignData] = useState(null);

  const { data, isLoading } = useQuery({
    queryKey: ['getDesign', id],
    queryFn: () => DesignServices.getDesign(id, token),
    enabled: !!id,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
  });

  useEffect(() => {
    if (data?.data) {
      setDesignData(data?.data);
      setInitialDesign(data?.data);
      setDesignName(data?.data?.name);
      setDesignReady(true);
    }
  }, [data]);

  const [zoomLevel, setZoomLevel] = useState(40);
  const canvasRef = useRef();
  const stageRef = useRef();
  const layerRef = useRef();

  const handleZoomIn = () => {
    if (zoomLevel < 200) {
      setZoomLevel(zoomLevel + 10);
    }
  };

  const handleZoomOut = () => {
    if (zoomLevel > 10) {
      setZoomLevel(zoomLevel - 10);
    }
  };

  const checkDeselect = (e) => {
    const clickedOnEmpty = e.target === e.target.getStage();

    if (clickedOnEmpty) {
      selectShape(null);
      handleContextMenuClose();
      resetSidebarContent();
    }
  };

  const handleTrackpadZoom = (e) => {
    const zoomFactor = e.ctrlKey ? 1 - e.deltaY / 100 : 1;
    const newZoomLevel = Math.min(200, Math.max(10, zoomLevel * zoomFactor));
    setZoomLevel(Math.floor(newZoomLevel));
  };

  useEffect(() => {
    const handleGlobalWheel = (e) => {
      if (e.ctrlKey) {
        e.preventDefault();
      }
    };

    window.addEventListener('wheel', handleGlobalWheel, { passive: false });

    return () => {
      window.removeEventListener('wheel', handleGlobalWheel);
    };
  }, []);

  useDeepCompareEffect(() => {
    const level = calculateZoomLevel(stageSize.height);
    setZoomLevel(level);
  }, [stageSize]);

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (!selectedShape) return;
      if (event.key === 'y' && (event.ctrlKey || event.metaKey)) {
        event.preventDefault();
        if (canRedo) {
          redo();
        }
      }
      if (event.key === 'Delete') {
        removeShape();
      } else if (event.key === 'ArrowRight') {
        event.preventDefault();
        changeShape({ x: selectedShape.x + 10 });
      } else if (event.key === 'ArrowLeft') {
        event.preventDefault();
        changeShape({ x: selectedShape.x - 10 });
      } else if (event.key === 'ArrowUp') {
        event.preventDefault();
        changeShape({ y: selectedShape.y - 10 });
      } else if (event.key === 'ArrowDown') {
        event.preventDefault();
        changeShape({ y: selectedShape.y + 10 });
      } else if (event.key === 'c' && (event.ctrlKey || event.metaKey)) {
        copyShape(selectedShape);
      } else if (event.key === 'v' && (event.ctrlKey || event.metaKey)) {
        pasteShape();
      } else if (
        event.key === 'z'
        && (event.ctrlKey || event.metaKey)
        && canUndo
      ) {
        undo();
      }
    };

    window.addEventListener('keydown', handleKeyDown);

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [selectedShape, copiedElem]);

  const getLineGuideStops = (skipShape) => {
    const stage = skipShape.getStage();
    if (!stage) return { vertical: [], horizontal: [] };

    const vertical = [0, stage.width() / 2, stage.width()];
    const horizontal = [0, stage.height() / 2, stage.height()];

    stage.find('.object').forEach((guideItem) => {
      if (guideItem === skipShape) {
        return;
      }
      const box = guideItem.getClientRect();
      vertical.push(box.x, box.x + box.width, box.x + box.width / 2);
      horizontal.push(box.y, box.y + box.height, box.y + box.height / 2);
    });
    return {
      vertical,
      horizontal,
    };
  };

  const getObjectSnappingEdges = React.useCallback((node) => {
    const box = node.getClientRect();
    const absPos = node.absolutePosition();

    return {
      vertical: [
        {
          guide: Math.round(box.x),
          offset: Math.round(absPos.x - box.x),
          snap: 'start',
        },
        {
          guide: Math.round(box.x + box.width / 2),
          offset: Math.round(absPos.x - box.x - box.width / 2),
          snap: 'center',
        },
        {
          guide: Math.round(box.x + box.width),
          offset: Math.round(absPos.x - box.x - box.width),
          snap: 'end',
        },
      ],
      horizontal: [
        {
          guide: Math.round(box.y),
          offset: Math.round(absPos.y - box.y),
          snap: 'start',
        },
        {
          guide: Math.round(box.y + box.height / 2),
          offset: Math.round(absPos.y - box.y - box.height / 2),
          snap: 'center',
        },
        {
          guide: Math.round(box.y + box.height),
          offset: Math.round(absPos.y - box.y - box.height),
          snap: 'end',
        },
      ],
    };
  }, []);

  const getGuides = React.useCallback((lineGuideStops, itemBounds) => {
    const resultV = [];

    const resultH = [];

    lineGuideStops.vertical.forEach((lineGuide) => {
      itemBounds.vertical.forEach((itemBound) => {
        const diff = Math.abs(lineGuide - itemBound.guide);
        if (diff < GUIDELINE_OFFSET) {
          resultV.push({
            lineGuide,
            diff,
            snap: itemBound.snap,
            offset: itemBound.offset,
          });
        }
      });
    });

    lineGuideStops.horizontal.forEach((lineGuide) => {
      itemBounds.horizontal.forEach((itemBound) => {
        const diff = Math.abs(lineGuide - itemBound.guide);
        if (diff < GUIDELINE_OFFSET) {
          resultH.push({
            lineGuide,
            diff,
            snap: itemBound.snap,
            offset: itemBound.offset,
          });
        }
      });
    });

    const guides = [];

    const minV = resultV.sort((a, b) => a.diff - b.diff)[0];
    const minH = resultH.sort((a, b) => a.diff - b.diff)[0];

    if (minV) {
      guides.push({
        lineGuide: minV.lineGuide,
        offset: minV.offset,
        orientation: 'V',
        snap: minV.snap,
      });
    }

    if (minH) {
      guides.push({
        lineGuide: minH.lineGuide,
        offset: minH.offset,
        orientation: 'H',
        snap: minH.snap,
      });
    }

    return guides;
  }, []);

  const drawGuides = React.useCallback(
    (guides, layer) => {
      guides.forEach((lg) => {
        if (lg.orientation === 'H') {
          const line = new Konva.Line({
            points: [-6000, 0, 6000, 0],
            stroke: '#F3367A',
            strokeWidth: (2 * 100) / zoomLevel,
            name: 'guid-line',
            dash: [4, 6],
          });
          layer.add(line);
          line.absolutePosition({
            x: 0,
            y: lg.lineGuide,
          });
        } else if (lg.orientation === 'V') {
          const line = new Konva.Line({
            points: [0, -6000, 0, 6000],
            stroke: '#F3367A',
            strokeWidth: (2 * 100) / zoomLevel,
            name: 'guid-line',
            dash: [4, 6],
          });
          layer.add(line);
          line.absolutePosition({
            x: lg.lineGuide,
            y: 0,
          });
        }
      });
    },
    [zoomLevel],
  );

  const onDragMove = React.useCallback(
    (e) => {
      const layer = e.target.getLayer();

      layer.find('.guid-line').forEach((l) => l.destroy());

      const lineGuideStops = getLineGuideStops(e.target);
      const itemBounds = getObjectSnappingEdges(e.target);

      const guides = getGuides(lineGuideStops, itemBounds);

      if (!guides.length) {
        return;
      }

      drawGuides(guides, layer);

      const absPos = e.target.absolutePosition();
      guides.forEach((lg) => {
        switch (lg.snap) {
          case 'start': {
            switch (lg.orientation) {
              case 'V': {
                absPos.x = lg.lineGuide + lg.offset;
                break;
              }
              case 'H': {
                absPos.y = lg.lineGuide + lg.offset;
                break;
              }
            }
            break;
          }
          case 'center': {
            switch (lg.orientation) {
              case 'V': {
                absPos.x = lg.lineGuide + lg.offset;
                break;
              }
              case 'H': {
                absPos.y = lg.lineGuide + lg.offset;
                break;
              }
            }
            break;
          }
          case 'end': {
            switch (lg.orientation) {
              case 'V': {
                absPos.x = lg.lineGuide + lg.offset;
                break;
              }
              case 'H': {
                absPos.y = lg.lineGuide + lg.offset;
                break;
              }
            }
            break;
          }
        }
      });
      e.target.absolutePosition(absPos);
    },
    [drawGuides, getGuides, getObjectSnappingEdges],
  );

  const onDragEnd = (e) => {
    const layer = e.target.getLayer();
    layer.find('.guid-line').forEach((l) => l.destroy());
  };

  if (isLoading) return <LoadingModal />;

  return (
    <Layout>
      {isLoading && <LoadingModal />}
      <Header designName={designName} designData={designData} />
      <ZoomInOut>
        <button onClick={handleZoomOut}>
          <FontAwesomeIcon icon={faMagnifyingGlassMinus} size="lg" />
        </button>
        <Headline4>
          %
          {zoomLevel}
        </Headline4>
        <button onClick={handleZoomIn}>
          <FontAwesomeIcon icon={faMagnifyingGlassPlus} size="lg" />
        </button>
      </ZoomInOut>

      <SideBar shapes={shapes} stage={stageRef.current} />
      <CanvasWrapper onWheel={handleTrackpadZoom} name="canvasWrapper">
        <TopMenu />
        <Canvas
          ref={canvasRef}
          bigger={zoomLevel > 100}
          onClick={(e) => {
            if (e.target.tagName === 'DIV') {
              selectShape(null);
              handleContextMenuClose();
              resetSidebarContent();
            }
          }}
          width={
            zoomLevel > 100
              ? stageSize.width * (zoomLevel / 100)
              : stageSize.width
          }
        >
          <StageWrapper
            zoomLevel={zoomLevel}
            width={stageSize.width}
            height={stageSize.height}
          >
            <Stage
              ref={stageRef}
              width={stageSize.width}
              height={stageSize.height}
              style={{ position: 'relative', ...stageStyle }}
              onMouseDown={checkDeselect}
              onTouchStart={checkDeselect}
              onClick={checkDeselect}
              onTap={checkDeselect}
            >
              <Layer ref={layerRef}>
                {shapes?.map((shape) => {
                  switch (shape.type) {
                    case 'TEXT':
                      return (
                        <Text
                          shape={shape}
                          selectShape={selectShape}
                          selectedId={selectedId}
                          changeShape={changeShape}
                          zoomLevel={zoomLevel}
                          onDragEnd={onDragEnd}
                          onDragMove={onDragMove}
                        />
                      );
                    case 'IMAGE':
                      return (
                        <Image
                          shape={shape}
                          selectShape={selectShape}
                          selectedId={selectedId}
                          changeShape={changeShape}
                          zoomLevel={zoomLevel}
                          canvas={canvasRef.current}
                          layerRef={layerRef.current}
                          showCropImage={showCropImage}
                          isCropApplied={isCropApplied}
                          setIsCropApplied={setIsCropApplied}
                          onDragEnd={onDragEnd}
                          onDragMove={onDragMove}
                        />
                      );

                    default:
                      return null;
                  }
                })}
              </Layer>
            </Stage>
          </StageWrapper>
        </Canvas>
      </CanvasWrapper>
      <RightSideBar />
    </Layout>
  );
};

const Layout = styled.main`
  display: grid;
  height: 100vh;
  width: 100vw;
  gap: 0;
  margin-top: 0;
  background: #f2f2f2;
  grid-template-columns: auto 1fr 100px;
  grid-template-rows: 80px auto;
  grid-template-areas:
    "header header header"
    "sidebar canvas rightSidebar";
`;
const CanvasWrapper = styled.div`
  flex: 1;
  overflow: scroll;
  position: relative;
  overflow-x: scroll;
`;
const Canvas = styled.div`
  min-width: 100%;
  position: relative;
  overflow: scroll;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: ${({ bigger }) => (bigger ? '100px' : '50px 0px')};
  width: ${({ width }) => `${width}px`};
`;
const StageWrapper = styled.div`
  transform: scale(${({ zoomLevel }) => zoomLevel / 100});
  transform-origin: top center;
  transition: transform 0.5s ease-in-out;
  width: ${({ width }) => width}px;
  height: ${({ height }) => height}px;
`;

const ZoomInOut = styled.div`
  position: fixed;
  bottom: 30px;
  right: 180px;

  z-index: 99;
  padding: 12px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 8px;
  background: #fff;
  border: 1px solid #e4e4e4;
  h4 {
    padding: 0 15px;
  }

  button {
    border: none;
    background: none;
  }
`;
export default DesignApp;

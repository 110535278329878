import {
 useCallback, useReducer, useRef,
} from 'react';

const initialUseHistoryStateState = {
  past: [],
  present: null,
  future: [],
};

const useHistoryStateReducer = (state, action) => {
  const { past, present, future } = state;
  if (action.type === 'UNDO') {
    return {
      past: past.slice(0, past.length - 1),
      present: past[past.length - 1],
      future: [present, ...future],
    };
  } if (action.type === 'REDO') {
    return {
      past: [...past, present],
      present: future[0],
      future: future.slice(1),
    };
  } if (action.type === 'SET') {
    const { newPresent } = action;

    if (action.newPresent === present) {
      return state;
    }
    if (JSON.stringify(newPresent) === JSON.stringify(present)) {
      return state;
    }

    return {
      past: [...past, present],
      present: newPresent,
      future: [],
    };
  } if (action.type === 'CLEAR') {
    return {
      ...initialUseHistoryStateState,
      present: action.initialPresent,
    };
  }
    throw new Error('Unsupported action type');
};

export function useHistoryState(initialPresent = {}) {
  const initialPresentRef = useRef(initialPresent);

  const [state, dispatch] = useReducer(useHistoryStateReducer, {
    ...initialUseHistoryStateState,
    present: initialPresentRef.current,
  });

  const canUndo = state.past.length > 1;
  const canRedo = state.future.length !== 0;

  const undo = useCallback(() => {
    if (canUndo) {
      dispatch({ type: 'UNDO' });
    }
  }, [canUndo]);

  const redo = useCallback(() => {
    if (canRedo) {
      dispatch({ type: 'REDO' });
    }
  }, [canRedo]);

  const set = useCallback(
    (newPresent) => dispatch({ type: 'SET', newPresent }),
    [],
  );

  const clear = useCallback(
    () => dispatch({ type: 'CLEAR', initialPresent: initialPresentRef.current }),
    [],
  );

  return {
 state: state.present, set, undo, redo, clear, canUndo, canRedo,
};
}

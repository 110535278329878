const CloseIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.width || '12px'}
    height={props.width || '12px'}
    viewBox="0 0 12 12"
    fill="none"
    {...props}
  >
    <path
      fill={props.fill || '#17181A'}
      d="M11.823 11.823a.6.6 0 0 1-.849 0L6 6.847l-4.976 4.976a.6.6 0 1 1-.848-.849L5.153 6 .177 1.024a.6.6 0 1 1 .848-.848L6 5.153 10.976.177a.6.6 0 1 1 .848.848L6.847 6l4.976 4.976a.595.595 0 0 1 0 .847Z"
      opacity={0.44}
    />
  </svg>
);
export default CloseIcon;

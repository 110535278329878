import React from 'react';
import styled from 'styled-components';

import { Caption1 } from 'components/Shared';

const CustomOption = ({ label, onChange, value }) => (
  <CustomContainer>
    <Caption1>{label}</Caption1>
    <TextArea
      placeholder="Comma-separated product ID list"
      rows={3}
      value={value}
      onChange={onChange}
    />
  </CustomContainer>
  );

const CustomContainer = styled.div`
  position: absolute;
  z-index: 4;
  top: 45px;
  right: 0;
  width: 320px;
  max-height: 156px;
  border-radius: 8px;
  border: 1px solid rgba(103, 94, 224, 0.2);
  background: #fafafa;
  box-shadow: 0 4px 8px 0 rgba(91, 204, 124, 0.16);
  padding: 10px 15px;
`;
const TextArea = styled.textarea`
  border-radius: 8px;
  border: 0.5px solid #7f7f7f;
  width: 100%;
  padding: 7px 15px;
  margin: 9px 0 20px;
  resize: none;

  :focus {
    outline: none;
  }
  ::placeholder {
    color: #7f7f7f;
  }
`;
export default CustomOption;

import React, { useEffect, useRef, useState } from 'react';
import { Html } from 'react-konva-utils';
import { Text as KonvaText, Transformer } from 'react-konva';
import { calculateTransformerStyle, getNeonEffectProperties } from 'utils';

const RETURN_KEY = 13;
const ESCAPE_KEY = 27;

const Text = ({
  shape,
  onMouseMove,
  changeShape,
  selectedId,
  selectShape,
  zoomLevel,
  onDragEnd,
  onDragMove,
  ...rest
}) => {
  const shapeRef = useRef();
  const trRef = useRef();
  const [isDragging, setIsDragging] = useState(false);

  const [isEditing, setIsEditing] = useState(false);
  const [elemSizes, setElemSizes] = useState({
    width: 0,
    height: 0,
    show: false,
    x: 0,
    y: 0,
  });
  const [text, setText] = useState(
    shape?.text || 'Click to resize. Double click to edit.',
  );

  const textareaRef = useRef(null);
  const isSelected = selectedId === shape.id;

  const onTextChange = (e) => {
    setText(e.target.value);
    changeShape({ text: e.target.value });
  };

  const onSelect = () => selectShape(shape.id);

  function handleEscapeKeys(e) {
    if ((e.keyCode === RETURN_KEY && !e.shiftKey) || e.keyCode === ESCAPE_KEY) {
      setIsEditing(false);
    }
  }

  useEffect(() => {
    if (isSelected) {
      trRef.current.nodes([shapeRef.current]);
      trRef.current.getLayer().draw();
    } else {
      setIsEditing(false);
    }
  }, [isSelected, shape.fontFamily]);

  const extractAppliedEffects = (effect) => {
    let effects = {};

    if (!effect || effect.name === 'NONE') return {};

    if (effect.name === 'NEON') {
      effects = getNeonEffectProperties(
        Number(effect.features[0].initialValue),
        shape?.baseColor || shape.fill,
      );
      return effects;
    }

    effect.features.forEach((effect) => {
      effects[effect.name] = effect.initialValue;
    });
    return effects;
  };

  useEffect(() => {
    if (textareaRef.current) {
      textareaRef.current.style.height = 'auto'; // Önce yüksekliği sıfırla
      textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`; // İçeriğe uygun yüksekliği ayarla
    }
  }, [text, isEditing]);

  if (isEditing) {
    return (
      <Html
        groupProps={{ x: shape.x, y: shape.y }}
        divProps={{
          style: {
            position: 'absolute',
            zIndex: 10,
            top: 0,
            left: 0,
            transform: `translate(${shape.x}px, ${shape.y}px) rotate(${shape.rotation}deg)`,
            transformOrigin: 'left top',
            margin: 0,
            padding: shape.padding,
            border: '2px solid #675EE0',
            width: `${shape.width}px`,
            height: 'auto',
          },
        }}
      >
        <textarea
          value={text}
          ref={textareaRef}
          style={{
            border: 'none',
            top: 0,
            left: 0,
            position: 'relative',
            margin: 0,
            fontFamily: shape.fontFamily,
            transformOrigin: 'left top',
            // overflow: 'hidden',
            background: 'none',
            outline: 'none',
            resize: 'none',
            userSelect: 'text',
            wordBreak: 'normal',
            width: '100%',
            height: '100%',
            fontSize: shape.fontSize,
            lineHeight: shape.lineHeight,
            textAlign: shape.align,
            color: shape.fill,
            fontStyle: shape.fontStyle,
            letterSpacing: shape.letterSpacing,
          }}
          onChange={onTextChange}
          onKeyDown={handleEscapeKeys}
        />
      </Html>
    );
  }
  const zoomReverse = 100 / zoomLevel;

  return (
    <>
      <KonvaText
        onClick={onSelect}
        onTap={onSelect}
        onTouchStart={() => selectShape(shape.id)}
        onMouseDown={() => selectShape(shape.id)}
        ref={shapeRef}
        text={text}
        name="object"
        {...shape}
        {...rest}
        {...extractAppliedEffects(shape?.appliedEffect)}
        onDragMove={onDragMove}
        onDragStart={() => setIsDragging(true)}
        onDragEnd={(e) => {
          onDragEnd(e);
          setIsDragging(false);

          changeShape(
            {
              x: e.target.x(),
              y: e.target.y(),
            },
            shape.id,
          );
        }}
        onDblClick={(e) => {
          setIsEditing(true);
        }}
        onMouseMove={onMouseMove}
        onTransformStart={(e) => {
          const node = e.target;
          const mouseX = node.getStage().getPointerPosition().x + 40;
          const mouseY = node.getStage().getPointerPosition().y + 40;

          setElemSizes({
            width: Math.floor(node.width()),
            height: Math.floor(node.height()),
            show: true,
            x: mouseX,
            y: mouseY,
          });
        }}
        onTransform={(e) => {
          const node = e.target;
          const scaleX = node.scaleX();
          const scaleY = node.scaleY();
          const newWidth = Math.floor(node.width() * scaleX);
          const newHeight = Math.floor(node.height() * scaleY);
          const mouseX = node.getStage().getPointerPosition().x + 40;
          const mouseY = node.getStage().getPointerPosition().y + 40;
          onDragMove(e);
          setElemSizes({
            width: newWidth,
            height: newHeight,
            show: true,
            x: mouseX,
            y: mouseY,
          });
        }}
        onTransformEnd={(e) => {
          const node = e.target;
          const scaleX = node.scaleX();
          const scaleY = node.scaleY();
          const isCorner = Math.abs(scaleX - scaleY) < 0.1;
          const newWidth = node.width() * scaleX;
          let newFontSize = node.fontSize();
          onDragEnd(e);

          if (isCorner) {
            newFontSize = Math.floor(
              Math.min(newFontSize * scaleX, newFontSize * scaleY),
            );
          }

          node.fontSize(newFontSize);
          node.scaleX(1);
          node.scaleY(1);
          node.width(newWidth);

          changeShape({
            fontSize: newFontSize,
            width: newWidth,
            height: 'auto',
            x: node.x(),
            y: node.y(),
            verticalAlign: 'middle',
            rotation: node.rotation(),
          });
          setElemSizes({
            width: newWidth,
            height: 200,
            show: false,
          });
        }}
      />

      {elemSizes.show && (
        <Html
          // groupProps={{ x: shape.x + 200, y: shape.y + 200 }}
          divProps={{
            style: {
              position: 'absolute',
              zIndex: 10,
              top: 0,
              left: 0,
              transform: `translate(${elemSizes.x}px, ${elemSizes.y}px) rotate(0deg)`,
              backgroundColor: '#7F7F7F',
              color: '#fff',
              fontFamily: 'Lato',
              padding: `${6 * zoomReverse}px`,
              borderRadius: `${4 * zoomReverse}px`,
              fontSize: `${16 * zoomReverse}px`,
            },
          }}
        >
          <span>
            w:
            {' '}
            {elemSizes.width}
            h:
            {' '}
            {elemSizes.height}
          </span>
        </Html>
      )}
      {isSelected && (
        <Transformer
          ref={trRef}
          enabledAnchors={
            zoomLevel < 20
              ? ['top-left']
              : [
                  'middle-left',
                  'middle-right',
                  'bottom-right',
                  'top-right',
                  'top-left',
                  'bottom-left',
                ]
          }
          borderStroke="#675EE0"
          anchorStroke="#675EE0"
          rotateEnabled={!(isDragging || zoomLevel < 20)}
          borderStrokeWidth={calculateTransformerStyle(2, zoomLevel)}
          anchorCornerRadius={calculateTransformerStyle(12, zoomLevel)}
          anchorStrokeWidth={calculateTransformerStyle(2, zoomLevel)}
          anchorSize={isDragging ? 0 : calculateTransformerStyle(12, zoomLevel)}
          flipEnabled={false}
          boundBoxFunc={(oldBox, newBox) => {
            if (newBox.width < 5 || newBox.height < 5) {
              return oldBox;
            }
            return newBox;
          }}
        />
      )}
    </>
  );
};

export default Text;

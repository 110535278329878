import React from 'react';
import { Navigate, useLocation } from 'react-router';
import { useAuth } from 'context/AuthContext';
import {getRoute} from 'utils';

const ProtectedRoute = ({ children }) => {
  const [token] = useAuth((store) => store.token);
  const location = useLocation();
  if (!token) {
    return <Navigate to={getRoute('signin')} state={{ from: location }} replace />;
  }

  return children;
};

export default ProtectedRoute;

import React, { useRef } from 'react';
import styled from 'styled-components';
import { useHoverDirty } from 'react-use';
import { Headline3, Subtitle1 } from 'components/Shared';

const FeatureCard = ({ feature }) => {
  const cardRef = useRef(null);
  const isHovering = useHoverDirty(cardRef);
  return (
    <Wrapper ref={cardRef}>
      <IconWrapper>
        <img
          src={isHovering ? feature.icon.uncolored : feature.icon.colored}
          style={{
            width: feature.icon.width,
            height: feature.icon.height,
          }}
          alt="icon"
        />
      </IconWrapper>
      <Headline3 style={{ marginTop: '20px' }}>{feature.title}</Headline3>
      <Subtitle1 style={{ marginTop: '20px', letterSpacing: '0' }}>{feature.description}</Subtitle1>
      <FeatureList>
        {feature.features.map((feature) => (
          <li>
            <Subtitle1>{feature}</Subtitle1>
          </li>
          ))}
      </FeatureList>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 380px;
  background: #f2f4f8;
  border-radius: 16px;
  padding: 20px 20px 92px 20px;
  cursor: pointer;
  transition: all 0.5s ease-in-out;
  align-items: flex-start;
  text-align: start;
  div {
    :before {
      content: "";
      position: absolute;
      width: 100%;
      height: 100%;
      border-radius: 8px;
      background-color: #f2f4f8;
      z-index: -1;
      transition: all 0.5s ease-in-out;
      opacity: 0;
    }
  }
  :hover {
    background-color: white;
    box-shadow: 0px 8px 8px 0px rgba(103, 94, 224, 0.08);
    transform: scale(1.18);
    div {
      border-radius: 8px;
      transition: all 0.5s ease-in-out;
      :before {
        content: "";
        position: absolute;
        width: 100%;
        height: 100%;
        border-radius: 8px;
        background-color: #675ee0;
        z-index: 1;
        transition: all 0.1s ease-in-out;
        opacity: 1;
      }
    }
  }
  @media (max-width: 768px) {
    margin-top: 20px;
    max-width: 280px;
    :hover {
      transform: scale(1.05);
      box-shadow: 0px 8px 8px 0px rgba(103, 94, 224, 0.08);
      background: #fff;
    }
  }
`;
const IconWrapper = styled.div`
  width: 60px;
  height: 60px;
  background-color: white;
  display: flex;
  align-items: center;
  border-radius: 8px;
  justify-content: center;
  position: relative;
  img {
    position: static;
    z-index: 2;
    height: auto !important;
  }
`;
const FeatureList = styled.ul`
  display: flex;
  flex-direction: column;
  padding-left: 12px;
  margin-top: 30px;
`;
export default FeatureCard;

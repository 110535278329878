import { useMutation } from '@tanstack/react-query';
import {
 useState, useContext, createContext, useEffect,
} from 'react';
import { v4 as uuidv4 } from 'uuid';
import { DesignServices } from 'services';
import { useAuth } from 'context/AuthContext';
import { useSearchParams } from 'react-router-dom';
import { useHistoryState } from 'hooks/useHistoryState';
import { resizeStage } from 'utils/Design/resizeStage';

const AppContext = createContext();

export function useApp() {
  return useContext(AppContext);
}

export const AppProvider = ({ children }) => {
  const [token] = useAuth((store) => store.token);

  const {
 state, set, undo, redo, canUndo, canRedo,
} = useHistoryState({
    shapes: [],
    stageSize: { width: 1080, height: 1920 },
    stageStyle: { backgroundColor: '#fff' },
  });

  const [searchParams] = useSearchParams();
  const id = searchParams.get('id');
  const [currentSidebarContent, setCurrentSidebarContent] = useState('xmlElems');
  const { shapes, stageSize, stageStyle } = state;
  const setShapes = (shapes) => {
    set({
      ...state,
      shapes,
    });
  };
  const setStageSize = (targetSize) => {
    const resizedShapes = resizeStage(shapes, stageSize, targetSize);
    set({
      ...state,
      shapes: resizedShapes,
      stageSize: targetSize,
    });
  };

  const setStageStyle = (stageStyle) => {
    set({
      ...state,
      stageStyle,
    });
  };
  const [showEditImage, setShowEditImage] = useState(false);
  const [showCropImage, setShowCropImage] = useState(false);
  const [isCropApplied, setIsCropApplied] = useState(false);
  const [showEditText, setShowEditText] = useState(false);
  const [selectedId, selectShape] = useState(null);
  const [selectedContextMenu, setSelectedContextMenu] = useState(null);
  const [copiedElem, setCopiedElem] = useState(null);
  const selectedShape = shapes.find((shape) => shape.id === selectedId);
  const [lastItemPosition, setLastItemPosition] = useState(null);
  const [designReady, setDesignReady] = useState(false);
  const { mutate: saveMutate, status } = useMutation({
    mutationFn: (data) => DesignServices.saveDesign({ data, token, id }),
    mutationKey: ['saveDesign'],
  });

  useEffect(() => {
    if (!designReady) return;
    saveMutate({
      type: 0,
      template: {
        stage: {
          width: state?.stageSize?.width,
          height: state?.stageSize?.height,
          background: state?.stageStyle?.backgroundColor,
        },
        design: state?.shapes,
      },
    });
  }, [state, id]);

  const findPositionForNewElem = () => {
    if (lastItemPosition === null) {
      return { x: stageSize.width / 2, y: stageSize.height / 2 };
    }
    if (lastItemPosition.y > stageSize.height - 300) {
      return {
        x: lastItemPosition.x,
        y: stageSize.height / 2 + 50,
      };
    }

    return {
      x: lastItemPosition.x,
      y: lastItemPosition.y + 100,
    };
  };

  const addShape = (shape) => {
    const id = uuidv4();
    const { x, y } = findPositionForNewElem();
    setShapes([...shapes, {
 id, x, y, ...shape,
}]);
    selectShape(id);
    setLastItemPosition({
      x,
      y,
    });
  };

  const onSidebarContentChange = (content) => {
    setCurrentSidebarContent(content);
  };

  const resetSidebarContent = () => setCurrentSidebarContent('');

  const changeShape = (newAttrs, shapeID) => {
    const shapesCopy = shapes.slice();
    let index;

    if (shapeID) {
      index = shapesCopy.findIndex((shape) => shape.id === shapeID);
    } else {
      index = shapesCopy.findIndex((shape) => shape.id === selectedId);
    }
    shapesCopy[index] = { ...shapesCopy[index], ...newAttrs };
    setShapes(shapesCopy);
  };

  const moveShape = (direction, shape) => {
    switch (direction) {
      case 'up':
        changeShape({ y: shape.y - 10 });
        break;
      case 'down':
        changeShape({ y: shape.y - 10 });
        break;
      case 'left':
        changeShape({ x: shape.x - 10 });
        break;
      case 'right':
        changeShape({ x: 200 }, selectedId);
        break;
      default:
        break;
    }
  };

  const removeShape = () => {
    const shapesCopy = shapes.slice();
    const index = shapesCopy.findIndex((shape) => shape.id === selectedId);
    shapesCopy.splice(index, 1);
    setShapes(shapesCopy);
  };

  const copyShape = (shape) => {
    const copiedShape = {
      ...shape,
      id: uuidv4(),
      x: shape.x + 20,
      y: shape.y + 20,
    };
    setCopiedElem(copiedShape);
  };

  const pasteShape = () => {
    if (copiedElem) {
      const shapesCopy = shapes.slice();
      shapesCopy.push(copiedElem);
      setShapes(shapesCopy);
      copyShape(copiedElem);
      selectShape(copiedElem.id);
    }
  };

  const duplicateShape = () => {
    const shapesCopy = shapes.slice();
    const index = shapesCopy.findIndex((shape) => shape.id === selectedId);
    const shape = shapesCopy[index];
    shapesCopy.splice(index + 1, 0, { ...shape, id: uuidv4() });
    setShapes(shapesCopy);
  };

  const onShowEditImage = () => {
    setShowEditImage(true);
  };
  const onToggleCropImage = () => {
    setShowCropImage(!showCropImage);
  };

  const onHideEditImage = () => {
    setShowEditImage(false);
  };

  function handleContextMenu(e) {
    e.evt.preventDefault(true); // NB!!!! Remember the ***TRUE***
    const mousePosition = e.target.getStage().getPointerPosition();
    setSelectedContextMenu({
      type: 'START',
      position: mousePosition,
    });
  }

  function onStageStyleChange(newAttrs) {
    setStageStyle({ ...stageStyle, ...newAttrs });
  }

  function handleContextMenuClose() {
    setSelectedContextMenu(null);
  }

  const onStageSizeChange = (size) => {
    const [width, height] = size.split('x');
    setStageSize({ width: Number(width), height: Number(height) });
  };
  const setInitialDesign = (data) => {
    set({
      shapes: data?.template.design,
      stageStyle: {
        backgroundColor: data?.template.stage.background,
      },
      stageSize: {
        width: data?.template.stage.width,
        height: data?.template.stage.height,
      },
    });
  };

  const toJSON = () => JSON.stringify(shapes);
  const value = {
    shapes: state.shapes,
    selectedId,
    set,
    copyShape,
    pasteShape,
    selectedShape,
    stageSize,
    selectedContextMenu,
    stageStyle,
    showEditImage,
    showCropImage,
    showEditText,
    isCropApplied,
    canRedo,
    lastItemPosition,
    canUndo,
    currentSidebarContent,
    copiedElem,
    autoSaveStatus: status,
    setInitialDesign,
    setDesignReady,
    setIsCropApplied,
    onSidebarContentChange,
    undo,
    redo,
    setStageSize,
    resetSidebarContent,
    onStageSizeChange,
    moveShape,
    onShowEditImage,
    onHideEditImage,
    onToggleCropImage,
    onStageStyleChange,
    handleContextMenuClose,
    handleContextMenu,
    addShape,
    changeShape,
    duplicateShape,
    removeShape,
    setShapes,
    selectShape,
    toJSON,
  };

  return <AppContext.Provider value={value}>{children}</AppContext.Provider>;
};

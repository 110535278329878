import Modal from 'react-modal';
import styled from 'styled-components';
import { CloseIcon } from 'icons';

const customStyles = {
  overlay: {
    zIndex: '9999',
    backgroundColor: 'rgba(0,0,0,0.9)',
    width: '100%',
    height: '100%',
    position: 'fixed',
    top: '0',
    left: '0',
  },
};

const VideoModal = ({ isModalOpen, onModalClose }) => (
  <StyledModal
    isOpen={isModalOpen}
    onRequestClose={onModalClose}
    style={customStyles}
    contentLabel="Video Modal"
    overlayClassName="video-modal-overlay"
  >
    <ModalHeader>
      <CloseButton onClick={onModalClose}>
        <CloseIcon width="20px" fill="#fff" />
      </CloseButton>
    </ModalHeader>
    <ModalContent>
      <iframe
        width="840"
        height="472"
        src="https://www.youtube.com/embed/zBluNAMhNVE?si=OPX5gJ8KPyE2T5-3&amp;autoplay=1;&amp;rel=0&amp;showinfo=0&amp;modestbranding=1&amp;loop=1&amp;playlist=zBluNAMhNVE"
        title="YouTube video player"
        frameborder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        allowfullscreen
      />
    </ModalContent>
  </StyledModal>
  );
const StyledModal = styled(Modal)`
  outline: none;
`;
const ModalHeader = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const CloseButton = styled.button`
  cursor: pointer;
  background: transparent;
  border: none;
  outline: none;
  position: absolute;
  top: 15px;
  right: 15px;
`;
const ModalContent = styled.div`
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  @media (max-width: 1024px) {
    height: 100dvh;
  }
`;
export default VideoModal;

import React from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLayerGroup } from '@fortawesome/pro-solid-svg-icons';

import { Headline4, Subtitle3 } from 'components/Shared';

const TableFilterGroupItem = ({ name, createdDate, time }) => (
  <TableFilterGroupItemContainer>
    <IconContainer>
      <FontAwesomeIcon icon={faLayerGroup} color="#675EE0" />
    </IconContainer>
    <TextContainer>
      <Headline4>
        {name.length > 24 ? name.substring(0, 24).concat('...') : name}
      </Headline4>
      <DateContainer>
        <Subtitle3>{createdDate}</Subtitle3>
        <Subtitle3 style={{ color: '#7F7F7F' }}>{time}</Subtitle3>
        <Subtitle3 style={{ color: '#7F7F7F' }}>| Created Date</Subtitle3>
      </DateContainer>
    </TextContainer>
  </TableFilterGroupItemContainer>
  );

const TableFilterGroupItemContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 15px;
  padding-left: 30px;
`;
const IconContainer = styled.div`
  height: 28px;
  display: flex;
  justify-content: center;
  align-items: center;
`;
const TextContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
`;

const DateContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 2px;
`;

export default TableFilterGroupItem;

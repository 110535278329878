import React, { useState } from 'react';
import styled from 'styled-components';
import { useApp } from 'context/AppContext';
import { Subtitle2 } from 'components/Shared';
import { OpacityEdit } from 'components/Design';

const ShapeEdit = () => {
  const { changeShape, selectedShape } = useApp();
  const [opacity, setOpacity] = useState(selectedShape?.opacity * 100 || 100);

  const onTurn = () => {
    let rotation = selectedShape?.rotation || 0;
    rotation += 90;
    if (rotation === 360) rotation = 0;
    changeShape({ rotation });
  };

  const onOpacityChange = (value) => {
    setOpacity(value);
    changeShape({
      opacity: value / 100,
    });
  };

  return (
    <Wrapper id="color-picker-root">
      <Button onClick={onTurn}>
        <Subtitle2>Turn</Subtitle2>
      </Button>
      {/* <CropImage /> */}
      <OpacityEdit opacity={opacity} setOpacity={onOpacityChange} />
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  width: 80%;
  position: relative;
  align-items: center;
`;
const Button = styled.button`
  background: none;
  border: none;
  padding: 0 10px;
  cursor: pointer;
  border-radius: 8px;
  :hover {
    background-color: #e2e2e2;
  }
`;
export default ShapeEdit;

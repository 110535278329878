import React, { useEffect, useRef, useState } from 'react';
import { Stage, Layer, Rect } from 'react-konva';
import styled from 'styled-components';
import { useSearchParams } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import { VideoServices } from 'services';
import { useAuth } from 'context/AuthContext';
import { useVideoConverter } from 'hooks';
import { VideoCanvas } from 'components/Video';
import {
 Headline3, Headline5, Logo, Subtitle2,
} from 'components/Shared';
import { TextLogo } from 'icons';

const VideoRecorder = () => {
  const stageRef = useRef();
  const [searchParams] = useSearchParams();
  const [token] = useAuth((store) => store.token);
  const [selectedAnimation, setSelectedAnimation] = useState(null);
  const [counter, setCounter] = useState(10);
  const [recordStatus, setRecordStatus] = useState(undefined);
  const id = searchParams.get('videoID');
  const [downloadUrl, progress, setInputVideo] = useVideoConverter();
  const { data } = useQuery({
    queryKey: ['getVideo'],
    queryFn: () => VideoServices.getVideo(id, token),
    enabled: !!id,
  });

  const exportVideo = () => {
    const canvas = document.querySelector('canvas');
    const frameArray = [];
    const totalFrameCount = Math.round(
      (selectedAnimation.duration * 30) / 1000,
    );

    async function captureFrame() {
      const frame = canvas.toDataURL('image/jpeg');
      const bufferData = await fetch(frame)
        .then((b) => b.arrayBuffer())
        .then((buff) => new Int8Array(buff));
      frameArray.push(bufferData);
    }

    const interval = setInterval(async () => {
      await captureFrame();
      if (frameArray.length === totalFrameCount) {
        clearInterval(interval);
        setInputVideo(frameArray);
      }
    }, 1000 / 30);
  };

  useEffect(() => {
    if (counter !== 0 && selectedAnimation) {
      const interval = setInterval(() => {
        setCounter(counter - 1);
      }, 1000);

      return () => clearInterval(interval);
    }
  }, [counter, selectedAnimation]);
  useEffect(() => {
    if (data?.data.template) {
      setSelectedAnimation(data?.data.template);
      setRecordStatus('ready');
    }
  }, [data]);

  useEffect(() => {
    if (stageRef.current) {
      if (recordStatus === 'ready') {
        setRecordStatus('started');
        setTimeout(() => {
          exportVideo();
        }, 10);
      }
    }
  }, [stageRef.current, recordStatus, selectedAnimation]);

  return (
    <Wrapper>
      <Top>
        <LogoWrapper>
          <Logo />
          <TextLogo />
        </LogoWrapper>
      </Top>
      <Headline3
        style={{ maxWidth: '727px', textAlign: 'center', margin: '0 auto' }}
      >
        Your product data is being converted into ad creatives, please do not
        close the page!
      </Headline3>

      <StyledRow>
        {counter === 0 ? (
          <Percentage>
            {progress}
            {' '}
            %
          </Percentage>
        ) : (
          <Subtitle2
            style={{
              color: '#675ee0',
              fontSize: 32,
            }}
          >
            Video creation will kick off in just
            {' '}
            {counter}
            {' '}
            seconds.
          </Subtitle2>
        )}

        <StyledColumn>
          <Headline3>You Are All Set!</Headline3>
          <Headline5>
            🎉 You've transformed your product data into stunning ad creatives.
            🎉
          </Headline5>
          <DownloadButton href={downloadUrl} download>
            <button disabled={!downloadUrl}>Download</button>
          </DownloadButton>
        </StyledColumn>
      </StyledRow>

      {selectedAnimation && (
        <AnimationWrapper id="animation_container">
          <Stage
            ref={stageRef}
            width={selectedAnimation.screenDimensions.width}
            height={selectedAnimation.screenDimensions.height}
            style={{
              width: selectedAnimation.screenDimensions.width,
            }}
          >
            {recordStatus === 'started' && (
              <Layer>
                <Rect
                  x={0}
                  y={0}
                  width={selectedAnimation.screenDimensions.width}
                  height={selectedAnimation.screenDimensions.height}
                  fill={selectedAnimation.stageStyle.backgroundColor}
                />
                <VideoCanvas animation={selectedAnimation.animation} />
              </Layer>
            )}
          </Stage>
        </AnimationWrapper>
      )}
    </Wrapper>
  );
};
const Top = styled.div`
  padding: 10px 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 48px;
`;

const LogoWrapper = styled.div`
  gap: 10px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;
const Wrapper = styled.div`
  width: 100%;
  height: 100vh;
  position: relative;
  background: #f2f4f8;
  overflow: hidden;
`;
const StyledRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 150px;
  margin-top: 100px;
`;
const StyledColumn = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
`;
const DownloadButton = styled.a`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  width: fit-content;
  text-decoration: none;
  cursor: pointer;

  button {
    width: 100%;
    padding: 17px 134px;
    border-radius: 8px;
    border: 1px solid #675ee0;
    background: #fff;
    outline: none;
    font-family: "Lato";
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    cursor: pointer;

    :disabled {
      background: #cecece;
    }
  }
`;
const Percentage = styled.span`
  color: #675ee0;
  font-family: Lato;
  font-size: 200px;
  font-style: normal;
  font-weight: 600;
  line-height: 212px;
`;
const AnimationWrapper = styled.div`
  visibility: hidden;
  width: fit-content;
  height: fit-content;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #cecece;
  margin-top: 20px;
  padding: 0;
`;

export default VideoRecorder;

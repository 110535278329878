import React, {
  useRef,
  createContext,
  useContext,
  useCallback,
  useSyncExternalStore,
} from 'react';
import { useCookies } from 'react-cookie';

const AuthContext = createContext(null);

function useStoreData() {
  const [cookies] = useCookies(['userToken', 'refreshToken', 'user']);

  const store = useRef({
    token: cookies.userToken || '',
    refreshToken: cookies.refreshToken || '',
    user: cookies.user || {},
  });

  const get = useCallback(() => store.current, []);

  const subscribers = useRef(new Set());

  const set = useCallback((value) => {
    store.current = { ...store.current, ...value };
    subscribers.current.forEach((callback) => callback());
  }, []);

  const subscribe = useCallback((callback) => {
    subscribers.current.add(callback);
    return () => subscribers.current.delete(callback);
  }, []);

  return {
    get,
    set,
    subscribe,
  };
}

export const AuthProvider = ({ children }) => (
  <AuthContext.Provider value={useStoreData()}>
    {children}
  </AuthContext.Provider>
  );

export function useAuth(selector) {
  const store = useContext(AuthContext);

  if (!store) {
    throw new Error('Store not found');
  }

  const state = useSyncExternalStore(store.subscribe, () => selector(store.get()));

  return [state, store.set];
}

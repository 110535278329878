import styled from 'styled-components';

export const Headline1 = styled.h1`
  font-family: "Lato", sans-serif;
  font-weight: 400;
  font-size: ${({ theme }) => theme.sizes.largest};
  line-height: 72px;
  color: #000000;
  letter-spacing: 1px;
`;

export const Headline2 = styled.h1`
  font-family: "Lato", sans-serif;
  font-weight: 500;
  font-size: ${({ theme }) => theme.sizes.xxl};
  line-height: 60px;
  letter-spacing: 1px;
  color: #000000;
`;

export const Headline3 = styled.h3`
  font-family: "Lato", sans-serif;
  font-weight: 500;
  font-size: ${({ theme }) => theme.sizes.xl};
  line-height: 40px;
  letter-spacing: 1px;
  color: #000000;
`;
export const Headline6 = styled.h6`
  font-family: "Lato", sans-serif;
  color: #000;
  text-align: center;
  font-feature-settings: "liga" off;
  font-family: Lato;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 32px;
  letter-spacing: 1px;
`;

export const Headline4 = styled.h4`
  font-family: "Lato", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: ${({ theme }) => theme.sizes.base};
  line-height: 28px;
  letter-spacing: 1px;
  color: #000000;
`;
export const Headline5 = styled.h5`
  font-family: "Lato", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: ${({ theme }) => theme.sizes.m};
  line-height: 26px;
  letter-spacing: 1px;
  color: #000000;
`;
export const Subtitle1 = styled.h4`
  font-family: "Lato", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: ${({ theme }) => theme.sizes.m};
  line-height: 26px;
  letter-spacing: 1px;
  color: #000000;
`;
export const Subtitle2 = styled.h4`
  font-family: "Lato", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: ${({ theme }) => theme.sizes.m};
  line-height: 26px;
  letter-spacing: 1px;
  color: #000000;
`;
export const Subtitle3 = styled.p`
  font-family: "Lato", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: ${({ theme }) => theme.sizes.s};
  line-height: 24px;
  letter-spacing: 1px;
  color: #000000;
`;

export const Caption1 = styled.h1`
  font-family: "Lato", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: ${({ theme }) => theme.sizes.xs};
  line-height: 22px;
  color: #000000;
`;

export const Caption2 = styled.h1`
  font-family: "Lato", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: ${({ theme }) => theme.sizes.xs};
  line-height: 22px;
  color: #000000;
`;

export const Body1 = styled.h1`
  font-family: "Lato", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: ${({ theme }) => theme.sizes.l};
  line-height: 30px;
  color: #000000;
`;
export const Body2 = styled.h1`
  font-family: "Lato", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: ${({ theme }) => theme.sizes.base};
  line-height: 28px;
  color: #000000;
`;
export const Body3 = styled.p`
  font-family: "Lato", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: ${({ theme }) => theme.sizes.s};
  line-height: 24px;
  color: #000000;
`;

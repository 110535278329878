import { client } from '../client';

const DesignServices = {
  getAssets: (token) => client.get('files/elements', {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }),

  createXML: ({ data, token }) => client.post('/canvas/create-xml', data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }),
  getUploads: (token) => client.get('files/image', {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }),

  getBrandKit: (token) => client.get('company', {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }),

  getTemplates: (token, size = '') => client.get(`design?size=${size}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }),
  createDesign: ({ data, token }) => client.post('design', data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }),
  saveDesign: ({ data, token, id }) => client.put(`design/${id}`, data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }),
  imageUpload: (data, token) => client.post('files/image', data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }),

  getDesign: (id, token) => client.get(`design/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }),
  downloadDesign: (id, token) => client.get(`xml/download-image/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }),

  getXMLElements: (token) => client.get('files/xml-preview', {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }),

  getFonts: (token) => client.get('files/font-list', {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }),

  getXMLPreview: (id, token) => client.get(`design/chexk-xml-status/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }),

  getDesignDrafts: (token, page) => client.get(`design/company?pageSize=12&pageNumber=${page}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }),
  removeDesignDrafts: (id, token) => client.delete(`design/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }),
  editDesignDrafts: (data, id, token) => client.put(`design/${id}`, data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }),
  createDesignPreview: (data, token) => client.post('canvas/create-preview', data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }),
  createDesignWithFilters: (data, token) => client
      .post('xml/group', data, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => res),
};

export default DesignServices;

import React from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCropSimple } from '@fortawesome/pro-light-svg-icons';

import { useApp } from 'context/AppContext';

const CropImage = () => {
  const { onToggleCropImage } = useApp();
  return (
    <Button onClick={onToggleCropImage}>
      <FontAwesomeIcon icon={faCropSimple} />
    </Button>
  );
};

export default CropImage;

const Button = styled.button`
  background: none;
  border: none;
  padding: 10px;
  cursor: pointer;
  border-radius: 8px;
  :hover {
    background-color: #e2e2e2;
  }
`;

import React, { useRef } from 'react';
import { useClickAway } from 'react-use';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClose, faSlidersUp } from '@fortawesome/pro-light-svg-icons';
import { Button, Caption1 } from 'components/Shared';

const Index = ({
  isOpen,
  setIsOpen,
  left,
  width,
  setWidth,
  height,
  setHeight,
  onClick,
}) => {
  const createDesignRef = useRef(null);

  useClickAway(createDesignRef, () => {
    setIsOpen(false);
  });

  const handleClick = () => {
    onClick(width, height);
    setIsOpen(false);
  };

  if (isOpen) {
    return (
      <CreateDesignMenu ref={createDesignRef} left={left}>
        <Top>
          <Icon>
            <FontAwesomeIcon icon={faSlidersUp} color="white" />
          </Icon>
          <Caption1 style={{ color: '#7F7F7F' }}>Custom Size</Caption1>
          <CloseButton
            onClick={(e) => {
              e.stopPropagation();
              setIsOpen(false);
            }}
          >
            <FontAwesomeIcon icon={faClose} />
          </CloseButton>
        </Top>
        <Form>
          <Col>
            <Caption1>Width</Caption1>
            <Input value={width} onChange={(e) => setWidth(e.target.value)} />
          </Col>
          <Col>
            <Caption1>Height</Caption1>
            <Input value={height} onChange={(e) => setHeight(e.target.value)} />
          </Col>
        </Form>
        <Button
          type="primary"
          size="medium"
          content="Create"
          handleClick={handleClick}
          disabled={!width || !height}
        />
      </CreateDesignMenu>
    );
  }
};

const CreateDesignMenu = styled.div`
  position: absolute;
  right: 0;
  left: ${(props) => props.left};
  top: 47px;
  width: 285px;
  border-radius: 8px;
  border: 1px solid rgba(103, 94, 224, 0.2);
  background: #fafafa;
  box-shadow: 0 4px 8px 0 rgba(91, 204, 124, 0.16);
  z-index: 9;

  button {
    width: calc(100% - 30px);
    margin: 0 auto 15px;
  }
`;
const Top = styled.div`
  position: relative;
  border-bottom: 1px solid rgba(103, 94, 224, 0.2);
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 5px;
  padding: 13px 9px;
`;
const Icon = styled.div`
  position: relative;
  width: 14px;
  height: 14px;
  border-radius: 50%;
  background: #7f7f7f;

  svg {
    position: absolute;
    width: 8px;
    height: 8px;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
`;
const CloseButton = styled.div`
  cursor: pointer;
  border: none;
  background: transparent;
  position: absolute;
  right: 5px;
  top: 5px;
  width: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
`;
const Form = styled.div`
  display: flex;
  gap: 10px;
  padding: 11px 15px 0;
  margin-bottom: 20px;
`;
const Col = styled.div`
  flex: 1;

  h1 {
    color: #7f7f7f;
  }
`;
const Input = styled.input`
  border-radius: 4px;
  outline: none;
  border: 1px solid #7f7f7f;
  width: 100%;
  height: 40px;
  padding: 0 4px;

  :focus {
    outline: none;
  }
`;
export default Index;

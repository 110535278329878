import React from 'react';
import styled from 'styled-components';

const RangeInput = ({
 value, min = 0, max = 100, onChange,
}) => {
  const percentage = ((value - min) / (max - min)) * 100;
  return (
    <StyledInput
      type="range"
      min={min}
      max={max}
      percentage={percentage}
      value={value}
      onChange={onChange}
      step={0.1}
    />
  );
};

const StyledInput = styled.input`
  appearance: none;
  background: transparent;
  cursor: pointer;
  width: 100%;

  ::-webkit-slider-runnable-track {
    background: linear-gradient(
      to right,
      #675ee0 ${(props) => props.percentage}%,
      rgba(103, 94, 224, 0.2) ${(props) => props.percentage}%
    );
    border-radius: 0.5rem;
    height: 0.5rem;
  }
  ::-webkit-slider-thumb {
    -webkit-appearance: none; /* Override default look */
    appearance: none;
    margin-top: -4px; /* Centers thumb on the track */
    background-color: white;
    border-radius: 8px;
    border: 1px solid rgba(103, 94, 224, 0.2);
    height: 16px;
    width: 16px;
  }
  ::-moz-range-track {
    background: linear-gradient(
      to right,
      #675ee0 ${(props) => props.percentage}%,
      rgba(103, 94, 224, 0.2) ${(props) => props.percentage}%
    );
    border-radius: 0.5rem;
    height: 0.5rem;
  }
  ::-moz-range-thumb {
    background-color: #808080;
    border: none; /*Removes extra border that FF applies*/
    border-radius: 0.5rem;
  }
  :focus::-moz-range-thumb {
    outline: 3px solid #808080;
    outline-offset: 0.125rem;
  }
`;

export default RangeInput;

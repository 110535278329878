import React, { useRef, useState } from 'react';
import styled from 'styled-components';
import { SketchPicker } from 'react-color';
import { useClickAway } from 'react-use';

const BrandListColorPicker = ({
  color: defaultColor,
  count,
  onChange,
  index,
  ...props
}) => {
  const [showPicker, setShowPicker] = useState(false);
  const [color, setColor] = useState(
    defaultColor === '#test' ? '#FFFFFF' : defaultColor,
  );
  const colorPickerRef = useRef(null);

  useClickAway(colorPickerRef, () => {
    onChange(color, index);
    setShowPicker(false);
  });

  const openPicker = () => {
    setShowPicker(true);
  };

  const handleChange = (color) => {
    setColor(color.hex);
  };

  const handleComplete = (color) => {
    setColor(color.hex);
  };

  return (
    <ColorPicker
      onClick={openPicker}
      color={color}
      defaultColor={defaultColor}
      count={count}
      index={index}
      showPicker={showPicker}
      {...props}
    >
      {showPicker && (
        <div ref={colorPickerRef} style={{ position: 'absolute', top: '40px' }}>
          <SketchPicker
            color={color}
            onChange={handleChange}
            onChangeComplete={handleComplete}
            styles={{ zIndex: 99 }}
          />
        </div>
      )}
    </ColorPicker>
  );
};

export default BrandListColorPicker;

const ColorPicker = styled.div`
  cursor: pointer;
  position: relative;
  border-radius: 8px;
  border: ${({showPicker}) => (showPicker ? '1px solid #675EE0' : '1px solid #e4e4e4')};
  max-width: 40px;
  max-height: 40px;
  width: ${(props) => (177 - (props.count - 1) * 14) / props.count}px;
  height: ${(props) => (177 - (props.count - 1) * 14) / props.count}px;
  background-color: ${(props) => props.color || '#FFFFFF'};
  z-index: ${(props) => (props.index === 0 ? 9 : 9 - props.index)};

  :after {
    content: "";
    display: ${(props) => (props.color === '#FFFFFF'
      && props.defaultColor === '#test'
      && !props.showPicker
        ? 'block'
        : 'none')};
    position: absolute;
    transform: rotate(-45deg);
    height: 130%;
    width: 3px;
    background: #675ee0;
    left: 0;
    right: 0;
    top: -6px;
    margin-left: auto;
    margin-right: auto;
  }
`;

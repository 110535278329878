import React, { useState } from 'react';
import styled from 'styled-components';
import ReactTooltip from 'react-tooltip';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { useApp } from 'context/AppContext';
import {
  TextEdit,
  ShapeEdit,
  ColorPicker,
  TopMenuButton,
  Divider,
  TextButton,
} from 'components/Design';
import {
  faLock,
  faSquarePlus,
  faTrash,
  faUnlock,
} from '@fortawesome/pro-light-svg-icons';
import { Subtitle2 } from 'components/Shared';

const TopMenu = () => {
  const {
    selectedShape,
    removeShape,
    onStageStyleChange,
    showCropImage,
    setShowCropImage,
    onToggleCropImage,
    setIsCropApplied,
    changeShape,
    onSidebarContentChange,
  } = useApp();

  const [color, setColor] = useState(selectedShape?.fill || '#ffffff');
  const isDraggable = selectedShape?.draggable;
  const onLockPosition = () => changeShape({ draggable: !isDraggable });

  const onColorChange = (color) => {
    setColor(color);
    onStageStyleChange({ backgroundColor: color });
  };

  if (selectedShape === undefined) {
    return (
      <Container>
        <ColorPicker color={color} setColor={onColorChange} />
        {showCropImage && (
          <>
            <TopMenuButton
              onClick={() => {
                setIsCropApplied(true);
                setShowCropImage(false);
              }}
            >
              Save
            </TopMenuButton>
            <TopMenuButton onClick={onToggleCropImage}>cancel</TopMenuButton>
          </>
        )}
      </Container>
    );
  }

  return (
    <Container>
      {selectedShape?.type === 'TEXT' ? <TextEdit /> : <ShapeEdit />}
      <RightSide>
        <TextButton
          onClick={() => onSidebarContentChange('xmlElemsEdit')}
          data-tip="Set as XML"
          style={{
            background: '#F0EFFC',
            borderRadius: '4px',
            width: 136,
          }}
        >
          <FontAwesomeIcon icon={faSquarePlus} />
          <Subtitle2>XML Elements</Subtitle2>
        </TextButton>
        <Divider />
        <TopMenuButton onClick={onLockPosition} data-tip="Lock Position">
          <FontAwesomeIcon icon={isDraggable ? faUnlock : faLock} size="xl" />
        </TopMenuButton>
        <TopMenuButton onClick={removeShape} data-tip="Delete Shape">
          <FontAwesomeIcon icon={faTrash} size="xl" />
        </TopMenuButton>
        <ReactTooltip place="bottom" />
      </RightSide>
    </Container>
  );
};
const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 50px;
  padding: 8px 20px;
  position: sticky;
  background-color: #fafafa;
  left: 0;
  top: 0;
  border-bottom: 1px solid #e6e6e6;
  z-index: 1;
`;
const RightSide = styled.div`
  display: flex;
  align-self: flex-end;
`;

export default TopMenu;

import React from 'react';
import styled from 'styled-components';
import { faMagnifyingGlass } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const SearchBar = ({ placeholder = 'Search', value, setValue }) => (
  <Container>
    <SearchInput
      placeholder={placeholder}
      value={value}
      onChange={setValue}
    />
    <FontAwesomeIcon
      icon={faMagnifyingGlass}
      style={{
          position: 'absolute',
          top: '30%',
          left: 15,
        }}
    />
  </Container>
  );

const Container = styled.div`
  position: relative;
`;
const SearchInput = styled.input`
  border-radius: 8px;
  border: 0.5px solid #000;
  width: 100%;
  height: 40px;
  padding-left: 41px;
  color: #7f7f7f;
  font-family: Lato;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
`;
export default SearchBar;

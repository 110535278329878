import React from 'react';
import styled from 'styled-components';
import {BetaLogo, CreateDesignModal, ProfileButton} from 'components/Shared';

const Header = () => (
  <Container>
    <BetaLogo />
    <RightButtons>
      <CreateDesignContainer id="create-design-button">
        <CreateDesignModal />
      </CreateDesignContainer>
      <ProfileButton />
    </RightButtons>
  </Container>
  );

export default Header;

const Container = styled.header`
  width: 100%;
  height: 100px;
  padding: 27px 39px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #675ee0;
  background: #fff;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 2;
`;
const RightButtons = styled.div`
  flex-grow: 1;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 30px;
`;
const CreateDesignContainer = styled.div`
  position: relative;
`;

import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { getRoute } from 'utils';

import {
  Headline5,
  Headline6,
  Logo,
  GradientBorderButton,
} from 'components/Shared';

const Header = () => {
  const [scrollPosition, setScrollPosition] = useState(0);
  const handleScroll = () => {
    const position = window.pageYOffset;
    setScrollPosition(position);
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll, { passive: true });

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <HeaderWrapper scrolled={scrollPosition > 40}>
      <Link to="/">
        <LogoContainer>
          <Logo />
          <LogoText>
            <Headline6>conversion</Headline6>
            <Line>line</Line>
          </LogoText>
        </LogoContainer>
      </Link>
      <Nav>
        <a href="#waitlist">
          <Headline5>Access Beta</Headline5>
        </a>

        <a href={getRoute('signin')}>
          <GradientBorderButton content="Sign In" />
        </a>
      </Nav>
    </HeaderWrapper>
  );
};

export default Header;

const HeaderWrapper = styled.header`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: ${({ scrolled }) => (scrolled ? '13px 55px' : '15px 60px')};
  width: 100%;
  max-width: 100%;
  background: ${({ scrolled }) => (scrolled ? '#fff' : 'transparent')};
  z-index: 99;
  position: fixed;
  top: 0;
  left: 0;
  transition: all 0.3s ease-in-out;
  a {
    text-decoration: none;
  }
  @media (max-width: 768px) {
    padding: ${({ scrolled }) => (scrolled ? '13px 20px' : '15px 20px')};
  }
`;

const LogoContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  gap: 10px;

  @media (max-width: 768px) {
    gap: 8px;
  }
`;
const LogoText = styled.div`
  display: flex;
  align-items: center;
  text-decoration: none;
  @media (max-width: 768px) {
    h6 {
      font-size: 18px;
    }
  }
`;
const Line = styled.p`
  color: #000;
  font-feature-settings: "liga" off;
  font-family: Lato;
  font-size: 24px;
  font-style: normal;
  font-weight: 300;
  line-height: 32px;
  letter-spacing: 1px;
  @media (max-width: 768px) {
    font-size: 18px;
  }
`;
const Nav = styled.nav`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  max-width: max-content;
  gap: 40px;
  a {
    cursor: pointer;
  }

  h5 {
    transition: all 0.3s ease-in-out;
    :hover {
      color: #675ee0;
    }
    @media (max-width: 768px) {
      display: none;
    }
  }

  /* @media (max-width: 768px) {
  } */
`;

import React, { useState, useEffect, useMemo } from 'react';
import useMeasure from 'react-use-measure';
import styled from 'styled-components';
import { useTransition, a } from '@react-spring/web';
import shuffle from 'lodash.shuffle';
import useMedia from 'hooks/useMedia';
import { Headline2, Headline4 } from 'components/Shared';

const data = [
  {
    css: 'https://cnvs.s3.eu-central-1.amazonaws.com/cdn/site-assets/library-asset/library-1.webp',
    height: 1350,
  },
  {
    css: 'https://cnvs.s3.eu-central-1.amazonaws.com/cdn/site-assets/library-asset/library-2.webp',
    height: 990,
  },
  {
    css: 'https://cnvs.s3.eu-central-1.amazonaws.com/cdn/site-assets/library-asset/library-3.webp',
    height: 1350,
  },
  {
    css: 'https://cnvs.s3.eu-central-1.amazonaws.com/cdn/site-assets/library-asset/library-4.webp',
    height: 990,
  },
  {
    css: 'https://cnvs.s3.eu-central-1.amazonaws.com/cdn/site-assets/library-asset/library-5.webp',
    height: 990,
  },
  {
    css: 'https://cnvs.s3.eu-central-1.amazonaws.com/cdn/site-assets/library-asset/library-6.webp',
    height: 1350,
  },
  {
    css: 'https://cnvs.s3.eu-central-1.amazonaws.com/cdn/site-assets/library-asset/library-7.webp',
    height: 990,
  },
  {
    css: 'https://cnvs.s3.eu-central-1.amazonaws.com/cdn/site-assets/library-asset/library-8.webp',
    height: 990,
  },
  {
    css: 'https://cnvs.s3.eu-central-1.amazonaws.com/cdn/site-assets/library-asset/library-9.webp',
    height: 990,
  },
  {
    css: 'https://cnvs.s3.eu-central-1.amazonaws.com/cdn/site-assets/library-asset/library-10.webp',
    height: 1350,
  },
  {
    css: 'https://cnvs.s3.eu-central-1.amazonaws.com/cdn/site-assets/library-asset/library-11.webp',
    height: 990,
  },
  {
    css: 'https://cnvs.s3.eu-central-1.amazonaws.com/cdn/site-assets/library-asset/library-12.webp',
    height: 1350,
  },
  {
    css: 'https://cnvs.s3.eu-central-1.amazonaws.com/cdn/site-assets/library-asset/library-13.webp',
    height: 1350,
  },
  {
    css: 'https://cnvs.s3.eu-central-1.amazonaws.com/cdn/site-assets/library-asset/library-14.mp4',
    height: 1920,
    typeof: 'video',
  },
  {
    css: 'https://cnvs.s3.eu-central-1.amazonaws.com/cdn/site-assets/library-asset/library-15.mp4',
    height: 1080,
    typeof: 'video',
  },
  {
    css: 'https://cnvs.s3.eu-central-1.amazonaws.com/cdn/site-assets/library-asset/library-16.mp4',
    height: 1920,
    typeof: 'video',
  },
  {
    css: 'https://cnvs.s3.eu-central-1.amazonaws.com/cdn/templates/template-17.mp4',
    height: 1720,
    typeof: 'video',
  },
  {
    css: 'https://cnvs.s3.eu-central-1.amazonaws.com/cdn/templates/template-18.jpeg',
    height: 1720,
  },
  {
    css: 'https://cnvs.s3.eu-central-1.amazonaws.com/cdn/templates/template-19.jpeg',
    height: 1720,
  },
  {
    css: 'https://cnvs.s3.eu-central-1.amazonaws.com/cdn/templates/template-20.mp4',
    height: 1720,
    typeof: 'video',
  },
  {
    css: 'https://cnvs.s3.eu-central-1.amazonaws.com/cdn/templates/template-21.jpeg',
    height: 1720,
  },
  {
    css: 'https://cnvs.s3.eu-central-1.amazonaws.com/cdn/templates/template-22.mp4',
    height: 1720,
    typeof: 'video',
  },
  {
    css: 'https://cnvs.s3.eu-central-1.amazonaws.com/cdn/templates/template-23.mp4',
    height: 1720,
    typeof: 'video',
  },
  {
    css: 'https://cnvs.s3.eu-central-1.amazonaws.com/cdn/templates/template-24.jpeg',
    height: 1720,
  },
  {
    css: 'https://cnvs.s3.eu-central-1.amazonaws.com/cdn/templates/template-25.jpeg',
    height: 1720,
  },
  {
    css: 'https://cnvs.s3.eu-central-1.amazonaws.com/cdn/templates/template-26.mp4',
    height: 1720,
    typeof: 'video',
  },
];

const Masonry = () => {
  const isMobile = useMedia(['(max-width: 1024px)'], [true], false);
  const mediaHeight = isMobile ? 5 : 2.7;
  const columns = useMedia(
    ['(min-width: 1500px)', '(min-width: 1000px)', '(min-width: 600px)'],
    [5, 4, 3],
    2,
  );
  // Hook2: Measure the width of the container element
  const [ref, { width }] = useMeasure();
  // Hook3: Hold items
  const [items, set] = useState(data);
  // Hook4: shuffle data every 2 seconds
  useEffect(() => {
    const t = setInterval(() => set(shuffle), 5000);
    return () => clearInterval(t);
  }, []);
  // Hook5: Form a grid of stacked items using width & columns we got from hooks 1 & 2
  const [heights, gridItems] = useMemo(() => {
    const heights = new Array(columns).fill(0); // Each column gets a height starting with zero
    const gridItems = items.map((child, i) => {
      const column = heights.indexOf(Math.min(...heights)); // Basic masonry-grid placing, puts tile into the smallest column using Math.min
      const x = (width / columns) * column; // x = container width / number of columns * column index,
      const y = (heights[column] += child.height / mediaHeight)
        - child.height / mediaHeight; // y = it's just the height of the current column
      return {
        ...child,
        x,
        y,
        width: width / columns,
        height: child.height / mediaHeight,
      };
    });
    return [heights, gridItems];
  }, [columns, items, width]);
  // Hook6: Turn the static grid values into animated transitions, any addition, removal or change will be animated
  const transitions = useTransition(gridItems, {
    key: (item) => item.css,
    from: ({
 x, y, width, height,
}) => ({
 x, y, width, height, opacity: 0,
}),
    enter: ({
 x, y, width, height,
}) => ({
 x, y, width, height, opacity: 1,
}),
    update: ({
 x, y, width, height,
}) => ({
 x, y, width, height,
}),
    leave: { height: 0, opacity: 0 },
    config: { mass: 5, tension: 500, friction: 100 },
    trail: 25,
  });
  // Render the grid
  return (
    <Container id="ad-library">
      <Title>
        Drive
        {' '}
        <strong>Growth and Efficiency</strong>
        {' '}
        Across Diverse Brands
      </Title>
      <Headline4 style={{ marginBottom: '60px' }}>
        Explore inspiring examples👇
      </Headline4>
      <Wrapper ref={ref} style={{ height: Math.max(...heights) }}>
        {transitions((style, item) => (
          <a.div style={style}>
            {item.typeof === 'video' ? (
              <video
                style={{
                  width: '100%',
                  height: '100%',
                  objectFit: 'cover',
                  borderRadius: '8px',
                }}
                autoPlay
                loop
                muted
                playsInline
              >
                <source src={item.css} type="video/mp4" />
                Your browser does not support the video tag.
              </video>
            ) : (
              <div
                style={{
                  backgroundImage: `url(${item.css}?auto=compress&dpr=2&h=500&w=500)`,
                }}
              />
            )}
          </a.div>
        ))}
      </Wrapper>
    </Container>
  );
};
const Container = styled.div`
  padding: 60px 0;
  text-align: center;
  @media (max-width: 1024px) {
    padding: 30px 0;
    h1 {
      margin-bottom: 20px !important;
    }
    h4 {
      margin-bottom: 30px !important;
    }
  }
`;
const Title = styled(Headline2)`
  margin: 0 auto;
  max-width: 800px;
  text-align: center;
  margin-bottom: 30px;
  strong {
    font-weight: 400;
    color: #5bcc7c;
  }
  @media (max-width: 1024px) {
    font-size: 22px;
    font-style: normal;
    font-weight: 500;
    line-height: 34px;
    padding: 0 35px;
  }
`;
const Wrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;

  div {
    position: absolute;
    will-change: transform, width, height, opacity;
    padding: 15px;
  }

  div > div {
    position: relative;
    background-size: cover;
    background-position: center center;
    width: 100%;
    height: 100%;
    overflow: hidden;
    text-transform: uppercase;
    font-size: 10px;
    line-height: 10px;
    border-radius: 4px;
    box-shadow: 0px 10px 50px -10px rgba(0, 0, 0, 0.2);
  }
`;
export default Masonry;

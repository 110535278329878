import styled from 'styled-components';
import React, { useState } from 'react';
import Modal from 'react-modal';
import { Loading } from 'components/Shared';

Modal.setAppElement('#root');

const customStyles = {
  overlay: {
    zIndex: '999',
    background: 'rgba(255, 255, 255, 1)',
    opacity: '0.7',
    position: 'absolute',
    width: '100vw',
    height: '100vh',
  },
};
const LoadingModal = () => {
  const [isOpen, setIsOpen] = useState(true);
  return (
    <StyledModal
      isOpen={isOpen}
      style={customStyles}
      contentLabel="Pop up Modal"
      shouldCloseOnOverlayClick
    >
      <Loading />
    </StyledModal>
  );
};
const StyledModal = styled(Modal)`
  position: absolute;
  outline: none;
  border: none;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: "rgba(255, 255, 255, 0.8)";

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-items: center;
`;
export default LoadingModal;

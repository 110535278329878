import React from 'react';
import styled from 'styled-components';

const OnboardingInput = ({
 placeholder, onChange, value, ...props
}) => (
  <Input
    placeholder={placeholder}
    value={value || ''}
    onChange={onChange}
    {...props}
  />
  );

export default OnboardingInput;

const Input = styled.input`
  width: 345px;
  height: 56px;
  border-radius: 8px;
  background: #e4e4e4;
  border: none;
  padding: 17px 20px;
  color: black;

  :focus {
    outline: none;
  }

  ::placeholder {
    color: #7f7f7f;
  }
`;

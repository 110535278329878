import React from 'react';

const Index = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    {...props}
  >
    <path
      fill="#fff"
      fillRule="evenodd"
      d="M22.077 3.963a1.177 1.177 0 0 0-1.027-.586L2.958 3.39c-.504 0-.94.311-1.115.775-.126.336-.098.701.074 1.003l.003.005.002.004c.065.106.148.206.248.294l6.886 5.939 1.679 8.916c.091.494.474.862.97.94.49.08.973-.152 1.225-.578L22.07 5.15l-.203-.353.204.352c.217-.37.219-.82.006-1.187Zm-.824-.232-.203-.354.203.354ZM10.091 9.68 5.078 5.352h12.596L10.091 9.68Zm2.2 8.196-1.222-6.492 7.598-4.334-6.376 10.826Z"
      clipRule="evenodd"
    />
  </svg>
    );

export default Index;

import React, { useState } from 'react';
import styled from 'styled-components';
import Modal from 'react-modal';
import { OpacityIcon } from 'icons';

import { Subtitle3 } from 'components/Shared';
import { RangeInput } from 'components/Design';

const customStyles = {
  overlay: {
    zIndex: '999',
    background: 'transparent',
    position: 'absolute',
    width: '100vw',
    height: '100vh',
  },
  content: {
    position: 'absolute',
    top: '40px',
    left: '10px',
    width: '204px',
    height: 'fit-content',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '11px',
    borderRadius: '8px',
    background: '#fafafa',
    border: '1px solid rgba(103, 94, 224, 0.20)',
  },
};

const OpacityEdit = ({ opacity, setOpacity }) => {
  const [isOpen, setIsOpen] = useState(false);
  const openModal = () => {
    setIsOpen(true);
  };
  const closeModal = () => {
    setIsOpen(false);
  };
  const onOpacityChange = (e) => {
    const inputValue = e.target.value;

    // Rakam ve boşluk karakterlerini kabul etmek için regex kullanımı
    if (/^(100|[1-9]?\d|)$/.test(inputValue)) {
      // Eğer boş bir değerse, opacity'yi null yap
      if (inputValue === '') {
        setOpacity(null);
      } else {
        // İstenen aralıkta bir değer olduğunu kontrol et
        const opacityValue = Number(inputValue);
        if (opacityValue >= 0 && opacityValue <= 100) {
          setOpacity(opacityValue);
        }
      }
    }
  };

  return (
    <Wrapper id="opacity-edit">
      <Button onClick={openModal} data-tip="Transparency">
        <OpacityIcon />
      </Button>
      <Modal
        shouldCloseOnOverlayClick
        isOpen={isOpen}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Pop up Modal"
        parentSelector={() => document.querySelector('#opacity-edit')}
      >
        <Row>
          <Subtitle3>Opacity</Subtitle3>
          <div>
            {/* <Subtitle3>{opacity}</Subtitle3> */}
            <input type="text" value={opacity} onChange={onOpacityChange} />
          </div>
        </Row>

        <RangeInput
          min="0"
          max="100"
          value={opacity}
          onChange={(e) => setOpacity(Number(e.target.value))}
        />
      </Modal>
    </Wrapper>
  );
};
const Button = styled.button`
  background: none;
  border: none;
  width: 32px;
  height: 32px;
  border-radius: 4px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  :hover {
    background: #ececec;
  }
  z-index: 10;
`;
const Row = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 12px;

  input {
    border-radius: 4px;
    border: 1px solid rgba(103, 94, 224, 0.2);
    padding: 0 12px;
    width: fit-content;
    max-width: 50px;
    text-align: center;
    height: 22px;
  }
`;
const Wrapper = styled.div`
  position: relative;
`;

export default OpacityEdit;

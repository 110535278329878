import React, { memo, useState } from 'react';
import useImage from 'use-image';
import { animated, useSpring, useTransition } from '@react-spring/konva';
import { useInterval } from 'react-use';

const ImageComp = ({ image, animation, updateInterval }) => {
  const [updated, setUpdated] = useState(false);
  const transitions = useTransition([image], {
    key: image,
    ...animation,
  });
  useInterval(() => {
    if (updateInterval) setUpdated(true);
  }, updateInterval);
  return transitions((style, index) => <animated.Image key={index} image={image} {...style} />);
};

const AnimatedImage = memo(({
  imageURL,
  animation,
  updateInterval,
}) => {
  const [image] = useImage(imageURL, 'anonymous');

  if (!image) {
    return null;
  }

  return (
    <ImageComp
      image={image}
      animation={animation}
      updateInterval={updateInterval}
    />
  );
});

export const AnimatedText = ({ text, animation, updateInterval }) => {
  const [updated, setUpdated] = useState(false);

  const transitions = useTransition([animation?.order || 0], {
    key: animation?.order,
    ...animation,
  });

  useInterval(() => {
    if (updateInterval) setUpdated(true);
  }, updateInterval);

  return transitions((style, index) => <animated.Text key={index} text={text} {...style} />);
};

export const AnimatedText2 = ({ text, animation }) => {
  const [props] = useSpring(() => animation, []);
  return <animated.Text text={text} {...props} />;
};

export const AnimatedLine = ({ points, animation }) => {
  const transitions = useTransition([0], animation);
  return transitions((style, index) => <animated.Line key={index} {...style} />);
};

export default AnimatedImage;

import React, { useState } from 'react';
import styled from 'styled-components';
import Modal from 'react-modal';
import { HexColorInput, HexColorPicker } from 'react-colorful';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faA } from '@fortawesome/sharp-solid-svg-icons';

const customStyles = {
  overlay: {
    zIndex: '999',
    background: 'transparent',
    position: 'absolute',
    width: '100vw',
    height: '100vh',
  },
  content: {
    position: 'absolute',
    top: '30px',
    left: '0',
    width: '220px',
    height: '220px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '3px',
    padding: '10px',
  },
};

const ColorPicker = ({ color, setColor }) => {
  const [isOpen, setIsOpen] = useState(false);
  const openModal = () => {
    setIsOpen(true);
  };
  const closeModal = () => {
    setIsOpen(false);
  };
  return (
    <Wrapper id="color-picker">
      <Button onClick={openModal} data-tip="Color">
        <FontAwesomeIcon icon={faA} size="lg" />
        <ColorPreview background={color} />
      </Button>
      <Modal
        shouldCloseOnOverlayClick
        isOpen={isOpen}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Pop up Modal"
        parentSelector={() => document.querySelector('#color-picker')}
      >
        <HexColorPicker color={color} onChange={setColor} />
        <InputWrapper>
          <HexColorInput
            color={color}
            onChange={setColor}
            prefixed
            placeholder="Hex Code"
          />
          <Color background={color} />
        </InputWrapper>
      </Modal>
    </Wrapper>
  );
};
const Button = styled.button`
  background: none;
  border: none;
  width: 32px;
  height: 32px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  cursor: pointer;
  :hover {
    background: #ececec;
  }
  z-index: 10;
`;
const Wrapper = styled.div`
  position: relative;
  button {
    border: none;
    background: none;
    align-self: flex-end;
  }
`;
const InputWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-top: 10px;
`;
const Color = styled.span`
  width: 20px;
  height: 20px;
  background: ${(props) => props.background};
`;
const ColorPreview = styled.div`
  width: 20px;
  height: 5px;
  border-radius: 4px;
  border: 1px solid #e6e6e6;
  background: ${({ background }) => background || '#ffffff'};
`;
export default ColorPicker;

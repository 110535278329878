import React from 'react';
import styled from 'styled-components';
import { Header, LeftMenu } from 'components/Dashboard';

const Layout = ({ children }) => (
  <Container>
    <Header />
    <LeftMenu />
    <Children>{children}</Children>
  </Container>
  );

export default Layout;

const Container = styled.div`
  position: relative;
`;
const Children = styled.div`
  margin-left: 90px;
  margin-top: 100px;
  overflow-y: auto;
  background: #f2f4f8;
  min-height: calc(100vh - 100px);
`;

import React from 'react';
import styled from 'styled-components';
import { useApp } from 'context/AppContext';

const TextOption = ({ title, size }) => {
  const textProps = {
    fontSize: size * 3,
    fontFamily: 'Lato',
    fill: '#000000',
    padding: size / 4,
    align: 'center',
    verticalAlign: 'center',
    width: 400,
    height: 'auto',
    text: title,
    draggable: true,
  };

  const { addShape } = useApp();

  const onClick = (content) => {
    addShape({ type: 'TEXT', ...textProps });
  };

  return (
    <TextButton size={size} onClick={onClick}>
      {title}
    </TextButton>
  );
};
const TextButton = styled.div`
  width: 100%;
  font-size: ${(props) => `${props.size}px`};
  font-family: "Lato", sans-serif;
  color: #000000;
  margin-top: 10px;
  padding: 13px 15px;
  border-radius: 8px;
  background: #f0effc;
  cursor: pointer;
  :hover {
    opacity: 0.8;
  }
`;
export default TextOption;

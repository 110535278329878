import React from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark } from '@fortawesome/pro-light-svg-icons';

import {
 Body1, Body2, Headline4, Subtitle2, Button,
} from 'components/Shared';

const ExportRightModal = ({ isOpen, setIsOpen, handleFilterButton }) => (
  <ExportRightModalContainer isOpen={isOpen}>
    <RightModal>
      <Top>
        <Headline4>Export Your Design</Headline4>
        <CloseButton onClick={() => setIsOpen(false)}>
          <FontAwesomeIcon icon={faXmark} />
        </CloseButton>
      </Top>
      <Content>
        <Body1 style={{ marginBottom: '30px', textAlign: 'center' }}>
          Almost there! 👀
          {' '}
        </Body1>
        <Button type="primary" size="xlarge" content="Apply All" />
        <Body2
          style={{
              color: '#7F7F7F',
              textAlign: 'center',
              padding: '30px 0 36px',
            }}
        >
          OR
        </Body2>
        <GradientButton onClick={handleFilterButton}>
          <GradientCover />
          <Subtitle2>Filter Ad Collection</Subtitle2>
        </GradientButton>
      </Content>
    </RightModal>
  </ExportRightModalContainer>
  );

export default ExportRightModal;

const ExportRightModalContainer = styled.div`
  background: rgba(0, 0, 0, 0.2);
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  display: ${(props) => (props.isOpen ? 'flex' : 'none')};
  transition: all 0.3s ease-in-out;
  justify-content: flex-end;
`;
const RightModal = styled.div`
  background: white;
  width: 425px;
  height: 100vh;
`;
const Top = styled.div`
  padding: 10px 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
const CloseButton = styled.button`
  outline: none;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  background: white;
  cursor: pointer;
`;
const Content = styled.div`
  padding: 10px 20px;
  margin-top: 133px;
`;
const GradientButton = styled.button`
  width: 100%;
  height: 56px;
  border-radius: 8px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  cursor: pointer;
  border: none;
  transition: transform 0.3s ease;
  padding: 8px 16px;
  h4 {
    position: relative;
    z-index: 2;
  }
  &&::before {
    content: "";
    position: absolute;
    width: 110%;
    height: 350%;
    background: linear-gradient(90deg, #675ee0 0%, #5bcc7c 100%);
  }
  :hover {
    transform: scale(1.03);
  }
`;
const GradientCover = styled.div`
  position: absolute;
  background-color: #fff;
  width: calc(100% - 3px);
  height: calc(100% - 2px);
  border-radius: 7px;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
`;

import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { toast } from 'react-toastify';
import { useClickAway, useDeepCompareEffect } from 'react-use';
import { useMutation, useQuery } from '@tanstack/react-query';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDown, faXmark } from '@fortawesome/pro-light-svg-icons';
import { faLassoSparkles } from '@fortawesome/pro-solid-svg-icons';
import { v4 as uuidv4 } from 'uuid';
import { useAuth } from 'context/AuthContext';
import { OnboardingServices } from 'services';
import {
  Caption1,
  Headline4,
  Subtitle2,
  Subtitle3,
  Button,
  RightModal,
  SelectBox,
  FilterProducts,
} from 'components/Shared';

const FeedOptimisationModal = ({
  isOpen,
  setIsOpen,
  productCount,
  onApply,
  buttonTitle,
  type,
  dataGroup,
}) => {
  const childrenContainerRef = useRef(null);
  const [token] = useAuth((store) => store.token);
  const [collapse, setCollapse] = useState(false);
  const [step, setStep] = useState(null);
  const [filterGroupName, setFilterGroupName] = useState('');
  const [selectedVideoProducts, setSelectedVideoProducts] = useState([]);
  const [allFilters, setAllFilters] = useState([
    {
      id: '0',
      key: '',
      type: 'and',
      label: '',
      included: '',
      value: [],
      filter: {},
    },
  ]);

  const [selectedProducts, setSelectedProducts] = useState([]);
  const [resultData, setResultData] = useState();

  useClickAway(childrenContainerRef, () => {
    closeModal();
  });

  const { data } = useQuery({
    queryKey: ['getFilters'],
    queryFn: () => OnboardingServices.getFilters(token),
    onError: (error) => {
      if (error?.response?.data?.errors) {
        Object.keys(error.response.data.errors).map((key) => toast.error(error.response.data.errors[key]));
      } else {
        toast.error('Something went wrong, please try again later');
      }
    },
  });

  const { mutate, data: products } = useMutation({
    mutationKey: ['setFilters'],
    mutationFn: (data) => OnboardingServices.setFilters(data, token),
    onSuccess: ({ data }) => {
      setSelectedProducts(data);
    },
  });

  const onAddNew = (type) => {
    const tmp = [...allFilters];
    tmp.push({ id: uuidv4(), type, value: [] });
    setAllFilters(tmp);
  };

  const onRemove = (id) => {
    setAllFilters(allFilters.filter((filter) => filter.id !== id));
  };

  const closeModal = () => {
    setIsOpen(false);
    setCollapse(false);
    setStep(null);
    setAllFilters([
      {
        id: '0',
        key: '',
        type: 'and',
        label: '',
        included: '',
        value: [],
        filter: {},
      },
    ]);
    setFilterGroupName('');
  };

  const applyFilter = () => {
    const includes = [];
    const nonIncludes = [];

    Object.keys(allFilters).map((key) => {
      if (allFilters[key].included === 'included') {
        includes.push(allFilters[key]);
      }
      if (allFilters[key].included === 'non included') {
        nonIncludes.push(allFilters[key]);
      }
    });

    let nonIncludesOr = {};
    let nonIncludesAnd = {};

    if (nonIncludes?.length > 0) {
      nonIncludes.map((data, i) => {
        if (data.type === 'and') {
          nonIncludesAnd = {
            ...nonIncludesAnd,
            [data.key]: { [data.filterType]: data.value },
          };
        }
        if (data.type === 'or') {
          nonIncludesOr = {
            ...nonIncludesOr,
            [data.key]: { [data.filterType]: data.value },
          };
        }
      });
    }

    let includesOr = {};
    let includesAnd = {};

    if (includes?.length > 0) {
      includes.map((data, i) => {
        if (data.type === 'and') {
          includesAnd = {
            ...includesAnd,
            [data.key]: { [data.filterType]: data.value },
          };
        }
        if (data.type === 'or') {
          includesOr = {
            ...includesOr,
            [data.key]: { [data.filterType]: data.value },
          };
        }
      });
    }

    const resultData = {
      includes: {
        and: includesAnd,
        or: includesOr,
      },
      non_includes: {
        and: nonIncludesAnd,
        or: nonIncludesOr,
      },
    };

    setResultData(resultData);
  };

  useDeepCompareEffect(() => {
    applyFilter();
  }, [allFilters]);

  useDeepCompareEffect(() => {
    if (resultData) {
      mutate(resultData);
    }
  }, [resultData]);

  useEffect(() => {
    if (dataGroup?.filters && dataGroup?.name) {
      setFilterGroupName(dataGroup.name);
      const tmp = [...dataGroup.filters];
      dataGroup.filters.map((item, index) => {
        tmp[index] = {
          ...tmp[index],
          filter: data[item.key],
          label: data[item.key].label,
        };
      });

      setAllFilters(tmp);
    }
  }, [dataGroup]);

  const isProductSelected = (id) => selectedProducts.some((product) => product['g:id'] === id);

  const toggleSelectedProducts = (item) => {
    if (isProductSelected(item['g:id'])) {
      setSelectedProducts(
        selectedProducts.filter((elem) => elem['g:id'] !== item['g:id']),
      );
    } else {
      const tmp = [...selectedProducts];
      tmp.push(item);
      setSelectedProducts(tmp);
    }
  };

  const checkObjectProperties = () => {
    for (let i = 0; i < allFilters?.length; i++) {
      const item = allFilters[i];
      if (
        !item.key
        || !item.label // label boş veya undefined ise
        || !item.filterType // filterType boş veya undefined ise
        || item.included === '' // included boş bir string ise
        || item?.value?.length === (undefined || 0)
      ) {
        return false;
      }
    }

    return true;
  };

  const toggleSelectedVideoProducts = (product) => {
    if (selectedVideoProducts?.includes(product)) {
      setSelectedVideoProducts((prevProducts) => prevProducts.filter((p) => p !== product));
    } else if (selectedVideoProducts?.length < productCount) {
        setSelectedVideoProducts((prevProducts) => [...prevProducts, product]);
      } else {
        alert("You can't select more products.");
      }
  };

  const handleClick = () => {
    if (type === 'video') {
      onApply(selectedVideoProducts);
      setSelectedVideoProducts(null);
      closeModal();
    } else if (filterGroupName) {
        onApply({
          name: filterGroupName,
          filter: resultData,
        });
        closeModal();
      }
  };

  return (
    <RightModal isOpen={isOpen} setIsOpen={setIsOpen}>
      <RightModalContainer
        ref={childrenContainerRef}
        isPublish={step === 'publish'}
      >
        {data && (
          <>
            {type === 'video' ? (
              <></>
            ) : (
              <Top>
                <Headline4>New Product Group</Headline4>
                <CloseButton onClick={closeModal}>
                  <FontAwesomeIcon icon={faXmark} />
                </CloseButton>
              </Top>
            )}

            <Content>
              {type === 'video' ? (
                <Headline4>
                  This video needs
                  {' '}
                  {productCount}
                  {' '}
                  product image
                </Headline4>
              ) : (
                <ProductGroupContent>
                  <Caption1 style={{ paddingLeft: '15px' }}>
                    Product group name
                  </Caption1>
                  <Input
                    placeholder="Set the name of your product group"
                    value={filterGroupName}
                    onChange={(e) => setFilterGroupName(e.target.value)}
                  />
                </ProductGroupContent>
              )}
              <Collapse>
                <CollapseTitle
                  isOpen={collapse}
                  onClick={() => setCollapse(!collapse)}
                >
                  <Subtitle2 style={{ color: collapse ? '#7F7F7F' : 'black' }}>
                    Filters
                  </Subtitle2>
                  <FontAwesomeIcon icon={faAngleDown} />
                </CollapseTitle>
                {collapse && (
                  <>
                    <FlexRow>
                      <SelectBox
                        id="0"
                        filterList={data}
                        allFilters={allFilters}
                        setAllFilters={setAllFilters}
                        filter={allFilters[0]}
                        type="and"
                      />
                      <TypeButton
                        onClick={() => onAddNew('and')}
                        disabled={!checkObjectProperties()}
                      >
                        <Caption1>+</Caption1>
                        <Caption1>And</Caption1>
                      </TypeButton>
                      <TypeButton
                        onClick={() => onAddNew('or')}
                        disabled={!checkObjectProperties()}
                      >
                        <Caption1>+</Caption1>
                        <Caption1>Or</Caption1>
                      </TypeButton>
                    </FlexRow>
                    {allFilters?.length > 0
                      && allFilters.map((item, index) => {
                        if (index > 0) {
                          return (
                            <SubFlexRow
                              key={`sublist-${item.type.toLowerCase()}-${index}`}
                            >
                              <SubFlexRowLeft>
                                <Subtitle3
                                  style={{
                                    color: '#7F7F7F',
                                    textTransform: 'capitalize',
                                  }}
                                >
                                  {item.type}
                                </Subtitle3>
                              </SubFlexRowLeft>
                              <SelectContent>
                                <SelectBox
                                  id={item.id}
                                  filter={allFilters[index]}
                                  filterList={data}
                                  allFilters={allFilters}
                                  setAllFilters={setAllFilters}
                                  type={item.type.toLowerCase()}
                                />
                              </SelectContent>
                              <SubFlexRowRight>
                                <IconButton onClick={() => onRemove(item.id)}>
                                  <FontAwesomeIcon icon={faXmark} />
                                </IconButton>
                              </SubFlexRowRight>
                            </SubFlexRow>
                          );
                        }
                      })}
                  </>
                )}
              </Collapse>
              <FilterProducts
                products={products}
                type={type}
                selectedVideoProducts={selectedVideoProducts}
                isProductSelected={isProductSelected}
                selectProduct={
                  type === 'video'
                    ? toggleSelectedVideoProducts
                    : toggleSelectedProducts
                }
              />
            </Content>
            <Footer>
              <FooterGroup>
                <FontAwesomeIcon icon={faLassoSparkles} color="#675EE0" />
                <Subtitle2>
                  {type === 'video'
                    ? `${selectedVideoProducts?.length} / ${productCount} products selected`
                    : `${selectedProducts.length} products selected`}
                </Subtitle2>
              </FooterGroup>
              <FooterGroup>
                <Button
                  size="small"
                  type="secondary"
                  content="Cancel"
                  handleClick={closeModal}
                />
                <Button
                  size="small"
                  type="primary"
                  content={buttonTitle}
                  handleClick={handleClick}
                  disabled={type === 'video' ? false : !filterGroupName}
                />
              </FooterGroup>
            </Footer>
          </>
        )}
      </RightModalContainer>
    </RightModal>
  );
};

const RightModalContainer = styled.div`
  background: #fafafa;
  width: ${(props) => (props.isPublish ? '595px' : '50%')};
  height: 100vh;
  display: flex;
  flex-direction: column;
`;
const Top = styled.div`
  padding: 10px 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
const CloseButton = styled.button`
  outline: none;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  background: white;
  cursor: pointer;
`;
const Content = styled.div`
  padding: 10px 20px;
  height: calc(100% - 107px);
  overflow-y: scroll;
  flex: 1;

  ::-webkit-scrollbar {
    width: 5px;
  }

  ::-webkit-scrollbar-thumb {
    background-color: #888;
  }

  scrollbar-width: thin;
  scrollbar-color: #888 transparent;
`;
const ProductGroupContent = styled.div`
  margin-top: 40px;
`;
const Input = styled.input`
  border-radius: 8px;
  border: 0.5px solid #000;
  width: ${(props) => (props.width ? props.width : '277px')};
  height: 40px;
  padding: 7px 15px;
  margin-top: 5px;
  background: #fafafa;

  ::placeholder {
    color: #7f7f7f;
    font-family: "Lato", sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: ${({ theme }) => theme.sizes.s};
    line-height: 24px;
    letter-spacing: 1px;
  }

  :focus {
    outline: none;
  }
`;
const Collapse = styled.div`
  width: 100%;
  border-radius: 8px;
  background: #fff;
  box-shadow: 0 4px 4px 0 rgba(91, 204, 124, 0.16);
  margin-top: 30px;
  padding: 10px 12px;
`;
const CollapseTitle = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  margin-bottom: ${(props) => (props.isOpen ? '25px' : '0')};

  svg {
    transition: transform 0.1s linear;
    transform: ${(props) => (props.isOpen
        ? 'rotate(180deg) translateY(0)'
        : 'rotate(0) translateY(0)')};
  }
`;
const FlexRow = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
  width: 100%;

  svg {
    height: 13px;
  }
`;
const SubFlexRow = styled.div`
  display: flex;
  margin-top: 10px;
  align-items: center;
`;
const SubFlexRowLeft = styled.div`
  width: 62px;
  text-align: center;
`;
const SubFlexRowRight = styled.div`
  padding: 0 22px;
`;
const IconButton = styled.div`
  cursor: pointer;
`;
const SelectContent = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  flex: 1;
`;

const Footer = styled.div`
  padding: 20px 52px 20px 17px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: white;
`;
const FooterGroup = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
`;
const TypeButton = styled.button`
  border-radius: 8px;
  border: none;
  padding: 0 9px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 4px;
  height: 32px;

  cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};
  background: ${(props) => (props.disabled ? '#BEBEBE' : '#E4E4E4')};
  h1 {
    color: ${(props) => (props.disabled ? '#7F7F7' : '#675EE0')};
  }
`;

export default FeedOptimisationModal;

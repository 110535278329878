import React, { useState } from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCloud } from '@fortawesome/pro-regular-svg-icons';
import {
  faCheck,
  faEllipsis,
  faPencil,
} from '@fortawesome/pro-light-svg-icons';
import { useApp } from 'context/AppContext';
import { Subtitle2 } from 'components/Shared';

const Breadcrumb = ({ items, onNameInputChange, onSaveDesignName }) => {
  const { autoSaveStatus } = useApp();
  const navigate = useNavigate();

  const [isEditInputVisible, setIsEditInputVisible] = useState(false);

  const renderIcon = () => {
    if (autoSaveStatus === 'success') {
      return faCheck;
    }
      return faEllipsis;
  };

  const isLastItem = (index) => index === items.length - 1;

  const onClick = (index, path) => {
    if (!isLastItem(index)) {
      return navigate(path);
    }
  };
  return (
    <Wrapper>
      {items.map((item, index) => (
        <ListItem
          key={index}
          onClick={() => onClick(index, item.path)}
          isLastItem={isLastItem(index)}
        >
          {isEditInputVisible && isLastItem(index) ? (
            <NameInput
              value={item.name}
              onChange={onNameInputChange}
              onFocus={(e) => e.stopPropagation()}
              onClick={(e) => e.stopPropagation()}
              onKeyDown={(e) => {
                  if (e.key === 'Enter') {
                    e.stopPropagation();
                    setIsEditInputVisible(false);
                    onSaveDesignName();
                  }
                }}
              onBlur={(e) => {
                  e.stopPropagation();
                  setIsEditInputVisible(false);
                  onSaveDesignName();
                }}
            />
            ) : (
              <Subtitle2
                style={{
                  color: index !== items.length - 1 ? '#7F7F7F' : '#000000',
                }}
              >
                {item.name}
              </Subtitle2>
            )}
          <Subtitle2>{index !== items.length - 1 ? '>' : ''}</Subtitle2>
          {isLastItem(index) && !isEditInputVisible && (
          <EditIcon
            onClick={(e) => {
                  e.stopPropagation();
                  setIsEditInputVisible(true);
                }}
          >
            <FontAwesomeIcon icon={faPencil} />
          </EditIcon>
            )}
        </ListItem>
        ))}
      <SaveIcon>
        <FontAwesomeIcon
          icon={faCloud}
          size="xl"
          color={autoSaveStatus === 'success' ? '#7F7F7F' : '#675EE0'}
        />
        <StatusWrapper>
          <FontAwesomeIcon
            icon={renderIcon()}
            color={autoSaveStatus === 'success' ? '##7F7F7F' : '#675EE0'}
          />
        </StatusWrapper>
      </SaveIcon>
    </Wrapper>
  );
};

const Wrapper = styled.ul`
  display: flex;
  align-items: center;
  gap: 5px;
`;
const ListItem = styled.li`
  list-style: none;
  margin-right: 5px;
  display: flex;
  gap: 5px;
  cursor: pointer;

  button {
    display: ${(props) => (props.isLastItem ? 'flex' : 'none')};
  }

  :hover {
    button {
      svg {
        display: ${(props) => (props.isLastItem ? 'flex' : 'none')};
      }
    }
  }
`;
const EditIcon = styled.button`
  width: 15px;
  background: transparent;
  border: none;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  svg {
    display: none;
  }
`;
const NameInput = styled.input`
  background: #fafafa;
  border: 1px solid rgba(103, 94, 224, 0.2);
  width: 100%;
  border-radius: 4px;
  padding: 3px 6px;

  :focus {
    outline: none;
  }
`;
const SaveIcon = styled.div`
  position: relative;
  margin-left: 20px;
`;
const StatusWrapper = styled.div`
  position: absolute;
  top: 3px;
  left: 8px;
`;
export default Breadcrumb;

import React, { useRef, useState } from 'react';
import { useNavigate } from 'react-router';
import { useClickAway } from 'react-use';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import styled from 'styled-components';
import { toast } from 'react-toastify';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEllipsis } from '@fortawesome/pro-regular-svg-icons';
import {
  faArrowDownToLine,
  faLinkHorizontal,
  faPencil,
  faTrash,
} from '@fortawesome/pro-light-svg-icons';

import { Caption1, Headline4, Subtitle3 } from 'components/Shared';
import { DesignServices } from 'services';
import { useAuth } from 'context/AuthContext';
import {getRoute} from 'utils';

const LatestProjectItem = ({ item }) => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const [token] = useAuth((store) => store.token);
  const menuRef = useRef(null);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isEditTitle, setIsEditTitle] = useState(false);
  const [title, setTitle] = useState(item.name);

  useClickAway(menuRef, () => {
    if (isEditTitle) {
      editDesign();
      setIsEditTitle(false);
    }
    setIsMenuOpen(false);
  });

  const { mutate: deleteDesignMutate } = useMutation({
    mutationKey: ['deleteDesign'],
    mutationFn: (data) => DesignServices.removeDesignDrafts(data, token),
    onSuccess: async () => {
      await queryClient.invalidateQueries('getDesignDrafts');
      toast.success('Design deleted!');
    },
  });
  const { mutate: editDesignMutate } = useMutation({
    mutationKey: ['editDesign'],
    mutationFn: (data) => DesignServices.editDesignDrafts(data, item.id, token),
    onSuccess: async () => {
      await queryClient.invalidateQueries('getDesignDrafts');
      toast.success('Design edited!');
    },
  });

  const deleteDesign = () => {
    deleteDesignMutate(item.id);
    setIsMenuOpen(false);
  };
  const editDesign = () => {
    editDesignMutate({ name: title });
  };

  const calculateDate = (date) => {
    const today = new Date();

    const createdDate = new Date(date);

    return Math.floor((today - createdDate) / (1000 * 60 * 60 * 24));
  };

  const copyToClipboard = async (link) => {
    if ('clipboard' in navigator) {
      await navigator.clipboard.writeText(link);
    } else {
      document.execCommand('copy', true, link);
    }
    setIsMenuOpen(false);
    toast.success('Copied to clipboard!');
  };

  return (
    <LatestProjectCard
      onClick={() => navigate(`${getRoute('design')}?id=${item.id}`)}
      data-product-id={item.id}
      className="design-card"
    >
      <LatestProjectCardImage isMenuOpen={isMenuOpen}>
        <MenuButton
          isMenuOpen={isMenuOpen}
          onClick={(e) => {
            e.stopPropagation();
            setIsMenuOpen(true);
          }}
        >
          <FontAwesomeIcon icon={faEllipsis} width={15} />
        </MenuButton>
        {isMenuOpen && (
          <MenuContainer ref={menuRef} onClick={(e) => e.stopPropagation()}>
            <Top>
              {isEditTitle ? (
                <TitleInput
                  value={title}
                  onChange={(e) => {
                    e.stopPropagation();
                    setTitle(e.target.value);
                  }}
                  onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                      setIsEditTitle(false);
                      editDesign();
                    }
                  }}
                  onClick={(e) => e.stopPropagation()}
                  onBlur={(e) => {
                    e.stopPropagation();
                    editDesign();
                    setIsEditTitle(false);
                  }}
                />
              ) : (
                <TitleContainer>
                  <Subtitle3>{title || 'add a title'}</Subtitle3>
                  <EditButton
                    onClick={(e) => {
                      e.stopPropagation();
                      setIsEditTitle(true);
                    }}
                  >
                    <FontAwesomeIcon icon={faPencil} />
                  </EditButton>
                </TitleContainer>
              )}

              <Caption1 style={{ color: '#7F7F7F' }}>
                {calculateDate(item.createdAt) === 0
                  ? 'Created today'
                  : `Created ${calculateDate(item.createdAt)} days ago`}
              </Caption1>
            </Top>
            <Wrapper>
              <MenuItem
                onClick={() => copyToClipboard(
                    `${window.location.origin}${getRoute('design')}?id=${item.id}`,
                  )}
              >
                <FontAwesomeIcon icon={faLinkHorizontal} />
                <Caption1>Copy Link</Caption1>
              </MenuItem>
              <MenuItem onClick={deleteDesign}>
                <FontAwesomeIcon icon={faTrash} />
                <Caption1>Delete</Caption1>
              </MenuItem>
            </Wrapper>
          </MenuContainer>
        )}
        <img src={item.previewPath} alt="design preview" />
      </LatestProjectCardImage>
      <Headline4>{title || 'add title'}</Headline4>
    </LatestProjectCard>
  );
};

const LatestProjectCard = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  max-width: 176px;
  flex-basis: 20%;

  h4 {
    cursor: pointer;

    :hover {
      text-decoration: underline;
    }
  }
`;

const LatestProjectCardImage = styled.div`
  position: relative;
  border-radius: 8px;
  padding: 0 8px 8px;
  border: 2px solid #fff;
  background: #fafafa;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: end;
  width: 100%;
  height: 240px;
  margin-bottom: 10px;
  transition: all 0.2s ease-in-out;

  img {
    width: 160px;
    height: 190px;
    border-radius: 8px;
  }

  :hover {
    //filter: contrast(0.5);
    button {
      display: flex;
    }

    ::after {
      display: ${(props) => (props.isMenuOpen ? 'none' : 'block')};
    }
  }

  ::after {
    content: "";
    display: none;
    position: absolute;
    top: -2px;
    left: -2px;
    width: calc(100% + 4px);
    height: calc(100% + 4px);
    background: rgba(0, 0, 0, 0.2);
    border-radius: 8px;
  }
`;
const MenuButton = styled.button`
  cursor: pointer;
  height: 24px;
  width: 24px;
  position: absolute;
  top: 5px;
  right: 5px;
  border: none;
  z-index: 9;
  display: ${(props) => (props.isMenuOpen ? 'flex' : 'none')};
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  background: #f0effc;
`;
const MenuContainer = styled.div`
  position: absolute;
  top: 34px;
  right: 5px;
  width: 100%;
  height: 222px;
  border-radius: 8px;
  border: 1px solid rgba(103, 94, 224, 0.2);
  background: #fafafa;
  box-shadow: 0 4px 8px 0 rgba(91, 204, 124, 0.16);
  cursor: default;
`;
const Top = styled.div`
  border-bottom: 1px solid rgba(103, 94, 224, 0.2);
  padding: 10px 10px 0;
`;
const TitleInput = styled.input`
  background: #fafafa;
  border: 1px solid rgba(103, 94, 224, 0.2);
  width: 100%;
  border-radius: 4px;
  padding: 3px 6px;

  :focus {
    outline: none;
  }
`;
const TitleContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 5px;
`;
const EditButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  background: transparent;
  border: none;
  cursor: pointer;
`;
const Wrapper = styled.div`
  padding: 17px 6px;
`;
const MenuItem = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 5px;
  border-radius: 8px;
  border: ${(props) => (props.active ? '1px solid #675EE0' : '1px solid #FAFAFA')};
  padding: 5px 7px;
  background: ${(props) => (props.active ? '#F0EFFC' : '#FAFAFA')};
  cursor: pointer !important;

  svg {
    flex-basis: 20px;
  }

  :hover {
    background: #f0effc;
  }
`;
export default LatestProjectItem;

export const getRoute = (name) => {
    const list = {
        signup: '/signup',
        signin: '/signin',
        forgotPassword: '/forgotPassword',
        resetPassword: '/resetPassword',
        brand: '/brand',
        feedOptimisation: '/feed-optimisation',
        videoSelect: '/video-select',
        videoRecorder: '/video-recorder',
        videoCreate: '/video-create',
        design: '/design',
        onboarding: '/onboarding',
        profile: '/profile',
    };
    return list[name];
};

import styled from 'styled-components';
import React, { useRef, useState } from 'react';
import HTMLFlipBook from 'react-pageflip';
import { pdfjs, Document, Page as ReactPdfPage } from 'react-pdf';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faAngleDown,
  faAngleLeft,
  faAngleRight,
  faAngleUp,
  faClose,
  faDownload,

  faArrowUpRightFromSquare,
  faMinus,
  faPlus,
} from '@fortawesome/pro-regular-svg-icons';
import { useSearchParams } from 'react-router-dom';

import { useWindowSize } from 'react-use';
import Slider from 'rc-slider';
import { TransformWrapper, TransformComponent } from 'react-zoom-pan-pinch';
import { Headline3, Headline4, Subtitle2 } from 'components/Shared';
import useExtractTOC from 'hooks/extractTOC';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const Page = React.forwardRef(({ pageNumber, scale }, ref) => (
  <div ref={ref}>
    <ReactPdfPage
      pageNumber={pageNumber}
      scale={scale}
      renderAnnotationLayer={false}
      renderTextLayer={false}
    />
  </div>
  ));

const PDFBook = () => {
  const [searchParams] = useSearchParams();
  const pdfURL = searchParams.get('pdfURL');
  const [zoom, setZoom] = useState(1);
  const toc = useExtractTOC(pdfURL);
  const [totalPages, setTotalPages] = useState(0);
  const [menuIsOpen, setMenuIsOpen] = useState(false);
  const transformComponentRef = useRef(null);
  const bookRef = useRef();
  const { width } = useWindowSize();
  let scaleFactor = width / 1440;
  const newWidth = width < 768 ? 420 * scaleFactor * 1.2 : 420 * scaleFactor;
  const newHeight = width < 768 ? 594 * scaleFactor * 1.2 : 594 * scaleFactor;

  const share = async () => {
    if (navigator.share) {
      try {
        await navigator.share({
          title: 'Sharing',
          text: 'Share PDF',
          url: window.location.href,
        });
      } catch (error) {
        console.error('Paylaşma işlemi başarısız oldu:', error);
      }
    } else {
      console.log('Paylaşma API desteği bulunmuyor.');
    }
  };
  return (
    <Wrapper>
      <MobileMenuButton onClick={() => setMenuIsOpen(!menuIsOpen)} mobile>
        <FontAwesomeIcon
          icon={menuIsOpen ? faAngleUp : faAngleDown}
          size="xl"
          style={{ color: 'black' }}
        />
        <Headline4>Menü</Headline4>
        {menuIsOpen && (
          <MobileMenu>
            <Row>
              <Headline3>Menü</Headline3>
              <FontAwesomeIcon icon={faClose} size="xl" />
            </Row>
            {toc.map((item) => (
              <button
                onClick={() => {
                  bookRef.current.pageFlip().flip(item.startPage);
                  setMenuIsOpen(false);
                }}
              >
                <Subtitle2>{item.startPage}</Subtitle2>
                <Subtitle2>
                  {' '}
                  {item.title}
                </Subtitle2>
              </button>
            ))}
          </MobileMenu>
        )}
      </MobileMenuButton>
      <MagasineContainer>
        <PdfContainer menuIsOpen={menuIsOpen}>
          <MenuButton onClick={() => setMenuIsOpen(!menuIsOpen)}>
            <FontAwesomeIcon
              icon={menuIsOpen ? faAngleUp : faAngleDown}
              size="xl"
              style={{ color: 'white' }}
            />
            <Headline4>Menü</Headline4>
          </MenuButton>
          {menuIsOpen && (
            <Menu>
              {toc.map((item) => (
                <button
                  onClick={() => {
                    bookRef.current.pageFlip().flip(item.startPage);
                    setMenuIsOpen(false);
                  }}
                >
                  <Subtitle2>{item.startPage}</Subtitle2>
                  <Subtitle2>
                    {' '}
                    {item.title}
                  </Subtitle2>
                </button>
              ))}
            </Menu>
          )}
          <Button
            onClick={() => bookRef.current.pageFlip().flipPrev()}
            left
            absolute
          >
            <FontAwesomeIcon
              icon={faAngleLeft}
              size="2xl"
              style={{ color: 'white' }}
            />
          </Button>
          <TransformWrapper
            initialScale={zoom}
            disabled={zoom === 1}
            ref={transformComponentRef}
            minScale={1}
            maxScale={1.5}
            doubleClick={{
              disabled: true,
            }}
            panning={{
              disabled: false,
              velocityDisabled: true,
            }}
            pinch={{
              disabled: true,
            }}
            wheel={{
              disabled: true,
              wheelDisabled: false,
            }}
          >
            <TransformComponent>
              <Magasine
                zoom={zoom}
                style={{
                  width: newWidth * 2,
                }}
              >
                <Document
                  file={pdfURL}
                  onLoadSuccess={(pdf) => setTotalPages(pdf.numPages)}
                  className="pdf-book"
                >
                  <HTMLFlipBook
                    ref={bookRef}
                    showCover
                    width={newWidth}
                    height={newHeight}
                    maxShadowOpacity={0.2}
                    size="stretch"
                    disableFlipByClick
                    renderOnlyPageLengthChange
                    useMouseEvents={zoom === 1}
                  >
                    {Array.from(Array(totalPages).keys()).map((page, index) => (
                      <Page
                        key={index}
                        pageNumber={index + 1}
                        scale={width < 768 ? 1.2 * scaleFactor : scaleFactor}
                      />
                    ))}
                  </HTMLFlipBook>
                </Document>
              </Magasine>
            </TransformComponent>
          </TransformWrapper>
          <Button
            onClick={() => bookRef.current.pageFlip().flipNext()}
            right
            absolute
          >
            <FontAwesomeIcon
              icon={faAngleRight}
              size="2xl"
              style={{ color: 'white' }}
            />
          </Button>
        </PdfContainer>
        <BottomBar width={newWidth}>
          <IconContainer display="none">
            <Button>
              <FontAwesomeIcon
                icon={faMinus}
                color="#fff"
                onClick={() => {
                  if (zoom > 1) {
                    setZoom(zoom - 0.1);
                  }
                }}
              />
            </Button>
            <Button>
              <FontAwesomeIcon
                icon={faPlus}
                color="#fff"
                onClick={() => {
                  if (zoom < 1.5) {
                    setZoom(zoom + 0.1);
                  }
                }}
              />
            </Button>
          </IconContainer>
          <SliderContainer>
            <Slider
              min={1}
              max={1.5}
              step={0.1}
              value={zoom}
              onChange={(value) => setZoom(value)}
            />
            {' '}
          </SliderContainer>
          <IconContainer>
            <Button>
              <FontAwesomeIcon
                icon={faArrowUpRightFromSquare}
                color="#fff"
                onClick={share}
              />
            </Button>
            <Button>
              <a
                download="https://www.keikei.com/cdn/fervente/catalog/fervente-catalog.pdf?2234234"
                href="https://www.keikei.com/cdn/fervente/catalog/fervente-catalog.pdf?2234234"
              >
                <FontAwesomeIcon icon={faDownload} color="#fff" />
              </a>
            </Button>
          </IconContainer>
        </BottomBar>
      </MagasineContainer>
    </Wrapper>
  );
};
const Wrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100dvh;
`;
const MagasineContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 5px;
  padding: 5px;
  border-radius: 40px;
  border: 10px solid #373b3e;
  background: #525659;
`;
const PdfContainer = styled.div`
  position: relative;
  padding: 20px;
  @media (min-width: 1024px) {
    padding: 40px;
    padding-left: ${({ menuIsOpen }) => (menuIsOpen ? '225px' : '60px')};
  }
`;
const BottomBar = styled.div`
  display: flex;
  align-items: center;
  width: ${(props) => props.width}px;
  gap: 50px;
  @media (max-width: 768px) {
    gap: 20px;
    width: 80% !important;
    justify-content: flex-end;
  }
`;
const MobileMenu = styled.div`
  position: fixed;
  background-color: white;
  width: 100dvw;
  height: 100dvh;
  top: 0;
  left: 0;
  z-index: 999;
  display: flex;
  flex-direction: column;
  padding: 20px;
  gap: 10px;
  button {
    border: none;
    background-color: transparent;
    display: flex;
    gap: 10px;
    cursor: pointer;
    h4 {
      color: black;
    }
    h3 {
      text-align: left;
      margin-bottom: 10px;
    }
  }
`;
const IconContainer = styled.div`
  display: flex;
  gap: 30px;
  @media (max-width: 768px) {
    gap: 20px;
    display: ${(props) => props.display};
  }
`;
const SliderContainer = styled.div`
  width: 100%;
  @media (max-width: 1024px) {
    display: none;
  }
`;

const Magasine = styled.div`
  width: 100%;
  .pdf-book {
    transform: ${({ zoom }) => `scale(${zoom})`};
  }
`;
const MenuButton = styled.button`
  background-color: transparent;
  border: none;
  outline: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 10px;
  position: absolute;
  top: 0;
  left: 60px;
  z-index: 999;
  cursor: pointer;
  h4 {
    color: white;
  }
  @media (max-width: 768px) {
    display: none;
  }
`;
const MobileMenuButton = styled(MenuButton)`
  left: 20px;
  top: 20px;
  h4 {
    color: black;
  }

  @media (min-width: 768px) {
    display: none;
  }
  @media (max-width: 768px) {
    display: flex;
  }
`;
const Button = styled.button`
  background-color: transparent;
  border: none;
  outline: none;
  cursor: pointer;
  position: ${({ absolute }) => (absolute ? 'absolute' : 'unset')};
  left: ${({ left }) => (left ? '0' : 'unset')};
  right: ${({ right }) => (right ? '0' : 'unset')};
  top: ${({ absolute }) => (absolute ? '50%' : 'unset')};
  transform: ${({ absolute }) => (absolute ? 'translateY(-50%)' : 'unset')};
  z-index: 999;
`;
const Menu = styled.div`
  display: flex;
  flex-direction: column;
  color: white;
  width: fit-content;
  position: absolute;
  left: 60px;
  z-index: 999;
  button {
    border: none;
    background-color: transparent;
    display: flex;
    gap: 10px;
    cursor: pointer;
    h4 {
      color: white;
    }
  }
  @media (max-width: 768px) {
    display: none;
  }
`;
const Row = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
export default PDFBook;

import React, { useEffect, useState } from 'react';
import useImage from 'use-image';
import { animated, useTransition } from '@react-spring/konva';

export const AnimatedImages = ({ imageURL, animation }) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [image] = useImage(imageURL[currentIndex], 'anonymous');
  const [isStarted, setIsStarted] = useState(false);

  const transitions = useTransition(currentIndex, {
    key: currentIndex,
    ...animation,
  });

  useEffect(() => {
    if (!isStarted) return;
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % imageURL.length);
    }, animation.config.duration);

    return () => clearInterval(interval);
  }, [imageURL.length, isStarted]);

  useEffect(() => {
    if (!animation.delay) {
      setIsStarted(true);
      return;
    }
    if (animation?.delay) {
      setTimeout(() => setIsStarted(true), animation.delay);
    }
  }, [animation]);

  if (!isStarted) return;
  return transitions((style, index) => <animated.Image key={index} image={image} {...style} />);
};

export const AnimatedTexts = ({ texts, animation }) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isStarted, setIsStarted] = useState(false);

  const transitions = useTransition(currentIndex, {
    key: currentIndex,
    ...animation,
  });

  useEffect(() => {
    if (!isStarted) return;
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % texts.length);
    }, animation.config.duration);

    return () => clearInterval(interval);
  }, [texts.length, isStarted]);

  useEffect(() => {
    if (!animation.delay) {
      setIsStarted(true);
      return;
    }
    if (animation?.delay) {
      setTimeout(() => setIsStarted(true), animation.delay);
    }
  }, [animation]);

  if (!isStarted) return;

  return transitions((style, index) => <animated.Text key={index} text={texts[currentIndex]} {...style} />);
};

import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useSearchParams } from 'react-router-dom';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useApp } from 'context/AppContext';
import { useAuth } from 'context/AuthContext';
import { DesignServices } from 'services';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faArrowTurnDownLeft,
  faArrowTurnDownRight,
} from '@fortawesome/pro-regular-svg-icons';
import { Breadcrumb, PreviewModalLayout, StageSize } from 'components/Design';
import {
  Button,
  LoadingModal,
  Logo,
  GradientBorderButton,
  DownloadXmlUrlModal,
  FeedOptimisationModal,
  ProfileButton,
} from 'components/Shared';
import {toast} from 'react-toastify';

const Header = ({ designName, designData }) => {
  const {
 undo, redo, canRedo, canUndo, stageSize,
} = useApp();
  const [token] = useAuth((store) => store.token);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isDownloadModalOpen, setIsDownloadModalOpen] = useState(false);
  const [xmlID, setXMLID] = useState('');
  const [searchParams] = useSearchParams();
  const designId = searchParams.get('id');
  const loading = false;
  const [name, setName] = useState(designName || null);
  const [isPreviewModalOpen, setIsPreviewModalOpen] = useState(false);
  const queryClient = useQueryClient();
const [base64, setBase64] = useState('');

  const { mutate } = useMutation({
    mutationKey: ['createDesignWithFilters'],
    mutationFn: (data) => DesignServices.createDesignWithFilters(data, token),
    onSuccess: ({ data }) => {
      setXMLID(data.id);
    },
    onMutate: () => {
      queryClient.invalidateQueries(['getXML']);
    },
  });

  const { mutate: createDesignPreviewMutate } = useMutation({
    mutationKey: ['createDesignPreview'],
    mutationFn: (data) => DesignServices.createDesignPreview(data, token),
    onSuccess: ({ data }) => {
      if (data?.data?.base64) {
        setBase64(data?.data?.base64);
      }
    },
    onError: () => {
      toast.error('Something went wrong, please try again later');
    },
  });

  useEffect(() => {
    setName(designName);
  }, [designName]);

  const onDesignCreate = ({ name, filter }) => {
    setName(name);
    mutate({
      name,
      filter,
      designId,
    });
    setIsDownloadModalOpen(true);
  };

  const { mutate: editDesignMutate } = useMutation({
    mutationKey: ['editDesign'],
    mutationFn: (data) => DesignServices.editDesignDrafts(data, designId, token),
  });

  const onSaveDesignName = () => {
    editDesignMutate({ name });
  };

  const onNameInputChange = (e) => {
    e.stopPropagation();
    setName(e.target.value);
  };
  const onModalClose = () => {
    setIsModalOpen(false);
    setIsDownloadModalOpen(false);
    setXMLID('');
  };

  const createDesignPreview = () => {
    setIsPreviewModalOpen(true);

    const tmp = designData.template;
    tmp.stage.width = parseInt(tmp.stage.width, 10);
    tmp.stage.height = parseInt(tmp.stage.height, 10);

    console.log(999, {
      template: tmp,
    });
    createDesignPreviewMutate({
      template: tmp,
    });
  };

  return (
    <>
      {loading && <LoadingModal />}

      <HeaderWrapper>
        <LogoWrapper>
          <LogoContainer>
            <Logo />
            <Beta>BETA</Beta>
          </LogoContainer>
          <Breadcrumb
            items={[
              { name: 'Home', path: '/' },
              { name: 'Projects', path: '/' },
              { name: name !== null ? name : 'My Project', path: '/' },
            ]}
            onNameInputChange={onNameInputChange}
            onSaveDesignName={onSaveDesignName}
          />
        </LogoWrapper>
        <Row>
          <UndoRedo>
            <button onClick={undo} disabled={!canUndo ? true : undefined}>
              <FontAwesomeIcon
                icon={faArrowTurnDownLeft}
                size="xl"
                style={{ color: canUndo ? '#000000' : '#e4e4e4  ' }}
              />
            </button>
            <button onClick={redo} disabled={!canRedo ? true : undefined}>
              <FontAwesomeIcon
                icon={faArrowTurnDownRight}
                size="xl"
                style={{ color: canRedo ? '#000000' : '#e4e4e4  ' }}
              />
            </button>
          </UndoRedo>
          <StageSize />
        </Row>
        <Row>
          <GradientBorderButton
            content="PREVIEW"
            onClick={createDesignPreview}
          />
          <Button
            type="primary"
            content="EXPORT"
            size="small"
            handleClick={() => setIsModalOpen(true)}
          />
          <ProfileButton />
        </Row>
        <FeedOptimisationModal
          isOpen={isModalOpen}
          setIsOpen={setIsModalOpen}
          onApply={onDesignCreate}
          buttonTitle="Create Design"
        />
        <DownloadXmlUrlModal
          isOpen={isDownloadModalOpen}
          setIsOpen={setIsDownloadModalOpen}
          onClose={onModalClose}
          xmlID={xmlID}
        />
        <PreviewModalLayout isOpen={isPreviewModalOpen} setIsOpen={setIsPreviewModalOpen} size={stageSize} base64={base64} />
      </HeaderWrapper>
    </>
  );
};

const HeaderWrapper = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 37px 34px;
  background: #ffffff;
  border: 1px solid #675ee0;
  width: 100%;
  grid-area: header;
`;
const LogoWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
`;
const LogoContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: max-content;
  gap: 10px;

  @media (max-width: 768px) {
    gap: 8px;
  }
`;
const Beta = styled.div`
  width: 43px;
  height: 24px;
  border-radius: 4px;
  background: #000;
  color: #fff;
  text-align: center;
  font-feature-settings: "liga" off;
  font-family: Lato;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0.5px;
  text-decoration: none;
`;
const Row = styled.div`
  display: flex;
  align-items: center;
  gap: 40px;
`;

const UndoRedo = styled.div`
  display: flex;
  gap: 24px;
  button {
    background: transparent;
    border: none;
    cursor: pointer;
  }
`;

export default Header;

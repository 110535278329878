import React, { useEffect, useRef, useState } from 'react';
import useImage from 'use-image';
import { Image as ImageKonva, Transformer } from 'react-konva';
import { Html } from 'react-konva-utils';
import { calculateTransformerStyle } from 'utils';

const Image = ({
  shape,
  onMouseMove,
  changeShape,
  selectedId,
  selectShape,
  canvas,
  layerRef,
  showCropImage,
  isCropApplied,
  setIsCropApplied,
  zoomLevel,
  onDragEnd,
  onDragMove,
  ...rest
}) => {
  const shapeRef = useRef();
  const trRef = useRef();
  const rectTrRef = useRef();
  const rectRef = useRef();

  const [isDragging, setIsDragging] = useState(false);

  const [elemSizes, setElemSizes] = useState({
    width: 0,
    height: 0,
    show: false,
    x: 0,
    y: 0,
  });
  const [image] = useImage(shape?.url);

  const isSelected = selectedId === shape.id;

  useEffect(() => {
    if (isSelected) {
      trRef.current.nodes([shapeRef.current]);
      trRef.current.getLayer().draw();
    }
    if (showCropImage) {
      rectTrRef.current.nodes([rectRef.current]);
      rectTrRef.current.getLayer().draw();
    }
  }, [isSelected, showCropImage]);
  const zoomReverse = 100 / zoomLevel;
  return (
    <>
      <ImageKonva
        image={image}
        name="object"
        onClick={() => selectShape(shape.id)}
        onTap={() => selectShape(shape.id)}
        onTouchStart={() => selectShape(shape.id)}
        onMouseDown={() => selectShape(shape.id)}
        ref={shapeRef}
        {...shape}
        {...rest}
        canvas={canvas}
        onDragStart={() => setIsDragging(true)}
        onDragMove={onDragMove}
        onDragEnd={(e) => {
          onDragEnd(e);
          changeShape({
            x: e.target.x(),
            y: e.target.y(),
          });
          setElemSizes({ show: false });
          setIsDragging(false);
        }}
        onTransformStart={(e) => {
          const node = e.target;
          const mouseX = node.getStage().getPointerPosition().x + 40;
          const mouseY = node.getStage().getPointerPosition().y + 40;

          setElemSizes({
            width: Math.floor(node.width()),
            height: Math.floor(node.height()),
            show: true,
            x: mouseX,
            y: mouseY,
          });
        }}
        onTransform={(e) => {
          const node = e.target;
          const scaleX = node.scaleX();
          const scaleY = node.scaleY();
          const newWidth = Math.floor(node.width() * scaleX);
          const newHeight = Math.floor(node.height() * scaleY);
          const mouseX = node.getStage().getPointerPosition().x + 40;
          const mouseY = node.getStage().getPointerPosition().y + 40;
          onDragMove(e);
          setElemSizes({
            width: newWidth,
            height: newHeight,
            show: true,
            x: mouseX,
            y: mouseY,
          });
        }}
        onTransformEnd={(e) => {
          setElemSizes({
            ...elemSizes,
            show: false,
          });
          const node = e.target;
          const scaleX = node.scaleX();
          const scaleY = node.scaleY();
          const newWidth = node.width() * scaleX;
          const newHeight = node.height() * scaleY;
          node.scaleX(1);
          node.scaleY(1);
          node.width(newWidth);
          node.height(newHeight);
          onDragEnd(e);
          changeShape({
            x: node.x(),
            y: node.y(),
            width: newWidth,
            height: newHeight,
          });
        }}
      />
      {elemSizes.show && (
        <Html
          // groupProps={{ x: shape.x + 200, y: shape.y + 200 }}
          divProps={{
            style: {
              position: 'absolute',
              zIndex: 10,
              top: 0,
              left: 0,
              transform: `translate(${elemSizes.x}px, ${elemSizes.y}px) rotate(0deg)`,
              backgroundColor: '#7F7F7F',
              color: '#fff',
              fontFamily: 'Lato',
              padding: `${6 * zoomReverse}px`,
              borderRadius: `${4 * zoomReverse}px`,
              fontSize: `${16 * zoomReverse}px`,
            },
          }}
        >
          <span>
            w:
            {' '}
            {elemSizes.width}
            {' '}
            {'  '}
            h:
            {' '}
            {elemSizes.height}
          </span>
        </Html>
      )}
      {isSelected && (
        <Transformer
          ref={trRef}
          borderStroke="#675EE0"
          anchorStroke="#675EE0"
          rotateEnabled={!(isDragging || zoomLevel < 20)}
          rotateAnchorOffset={calculateTransformerStyle(30, zoomLevel)}
          borderStrokeWidth={calculateTransformerStyle(2, zoomLevel)}
          anchorCornerRadius={calculateTransformerStyle(12, zoomLevel)}
          anchorStrokeWidth={calculateTransformerStyle(2, zoomLevel)}
          anchorSize={isDragging ? 0 : calculateTransformerStyle(12, zoomLevel)}
          boundBoxFunc={(oldBox, newBox) => {
            if (newBox.width < 5 || newBox.height < 5) {
              return oldBox;
            }
            return newBox;
          }}
        />
      )}
    </>
  );
};

export default Image;

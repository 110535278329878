import React from 'react';
import styled from 'styled-components';
import { toast } from 'react-toastify';
import { useMutation } from '@tanstack/react-query';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import { AuthServices } from 'services';
import { Layout } from 'components/Dashboard';
import {
  Headline3,
  Headline4,
  GradientBorderButton,
  LoadingModal,
} from 'components/Shared';
import { InputWrapper } from 'components/Auth';
import { useAuth } from 'context/AuthContext';
import { registerSchema } from 'lib/validationSchemas';

const Index = () => {
  const [{ user, token }] = useAuth((store) => store);

  const { mutate, isLoading } = useMutation({
    mutationFn: (data) => AuthServices.editProfile(token, data),
    mutationKey: ['editProfile'],
    onSuccess: ({ data }) => {
      toast.success('Profile updated successfully!');
    },
    onError: (error) => {
      if (error?.response?.data?.errors) {
        Object.keys(error.response.data.errors).map((key) => toast.error(error.response.data.errors[key]));
      } else {
        toast.error('Something went wrong, please try again later');
      }
    },
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
    getValues,
  } = useForm({
    defaultValues: {
      email: user.email,
    },
    resolver: yupResolver(registerSchema),
  });

  const onSubmit = (data) => {
    if (data.password === data.confirmPassword) {
      mutate({
        email: data.email,
        password: data.password,
      });
    } else {
      toast.error('Passwords do not match!');
    }
  };

  const changeEmail = () => {
    mutate({
      email: getValues('email'),
    });
  };

  if (isLoading) {
 return (
   <Layout>
     <LoadingModal />
   </Layout>
    );
}

  return (
    <Layout>
      <Container>
        <Headline3>Change Personal Information</Headline3>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <BoxWrapper>
            <Box>
              <Headline4>Change E-mail</Headline4>
              <InputWrapper width="100%" name="email">
                <input
                  type="email"
                  name="email"
                  placeholder="Email"
                  {...register('email')}
                  style={{
                    border: '2px solid #F0EFFC',
                  }}
                />
              </InputWrapper>
              <GradientBorderButton
                content="Update"
                noAnimation
                type="button"
                onClick={changeEmail}
              />
            </Box>
            <div>
              <Box>
                <Headline4>Change Password</Headline4>
                <InputWrapper width="100%" name="password">
                  <input
                    type="password"
                    name="password"
                    placeholder="New Password"
                    {...register('password')}
                    style={{
                      border: '2px solid #F0EFFC',
                    }}
                  />
                </InputWrapper>
                <InputWrapper width="100%" name="confirmPassword">
                  <input
                    type="password"
                    name="confirmPassword"
                    placeholder="Confirm New Password"
                    {...register('confirmPassword')}
                    style={{
                      border: '2px solid #F0EFFC',
                      margin: '10px 0 -10px',
                    }}
                  />
                </InputWrapper>
              </Box>
              <ButtonWrapper>
                <input type="submit" value="Save Changes" />
              </ButtonWrapper>
            </div>
          </BoxWrapper>
        </Form>
      </Container>
    </Layout>
  );
};

const Container = styled.div`
  width: 85%;
  margin: 41px auto;
`;
const BoxWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 50px;
  margin-top: 30px;
`;
const Box = styled.div`
  min-width: 375px;
  border-radius: 8px;
  border: 2px solid #fff;
  padding: 15px;

  h4 {
    margin-bottom: 15px;
  }

  button {
    margin-top: 20px;
    width: 100%;
    height: 52px;
  }
`;
const Form = styled.form`
  width: 100%;
  input[type="submit"] {
    width: auto;
    height: auto;
    padding: 8px 16px;
    letter-spacing: 1px;
    background: #675ee0;
    border-radius: 8px;
    border: none;
    color: white;
    margin-top: 20px;
    cursor: pointer;
    font-family: "Lato", sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: ${({ theme }) => theme.sizes.xs};
    line-height: 22px;
  }
`;
const ButtonWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
`;
export default Index;

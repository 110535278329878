import React from 'react';
import styled from 'styled-components';
import { useSearchParams } from 'react-router-dom';
import { ProfileButton } from 'components/Shared';
import { Logo, TextLogo } from 'icons';

import {
  WelcomePage,
  AdsPage,
  UploadContentPage,
  CreateAssetPage,
  ResultsPage,
} from 'components/Onboarding';

const Onboarding = () => {
  const [searchParams] = useSearchParams();
  const activeStep = searchParams.get('step');

  const renderContent = () => {
    switch (activeStep) {
      case null:
        return <CreateAssetPage />;
      case 'ads':
        return <AdsPage />;
      case 'upload':
        return <UploadContentPage />;
      case 'results':
        return <ResultsPage />;
      default:
        return <WelcomePage />;
    }
  };

  return (
    <Page>
      <Header step={activeStep}>
        <LogoContainer href="/">
          <IconLogoWrapper>
            <OnboardingLogoWrapper>
              <Logo />
            </OnboardingLogoWrapper>
          </IconLogoWrapper>
          <TextLogoWrapper>
            <TextLogo />
            <Beta>BETA</Beta>
          </TextLogoWrapper>
        </LogoContainer>
        {activeStep !== null && (
          <HeaderItemsContainer>
            <ProfileButton />
          </HeaderItemsContainer>
        )}
      </Header>
      <PageLayout
        page={activeStep === null ? 'welcome' : null}
        isAds={activeStep === 'ads'}
      >
        <Container>{renderContent()}</Container>
      </PageLayout>
    </Page>
  );
};

export default Onboarding;

const Page = styled.div`
  min-height: 100vh;
  background-color: #fff;
  display: flex;
  flex-direction: column;
`;
const Header = styled.header`
  display: flex;
  background-color: #fff;
  justify-content: space-between;
  align-items: center;
  max-height: 100px;
  padding: ${(props) => (props.step === null ? '28px 50px 0' : '28px 50px')};
  border-bottom: ${(props) => props.step !== null && '1px solid #675EE0'};
`;
const LogoContainer = styled.a`
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 10px;
  z-index: 2;
  :active {
    text-decoration: none;
  }
`;
const TextLogoWrapper = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 5px;
  text-decoration: none;
`;
const Beta = styled.div`
  width: 43px;
  height: 24px;
  border-radius: 4px;
  background: #000;
  color: #fff;
  text-align: center;
  font-feature-settings: "liga" off;
  font-family: Lato;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px; /* 200% */
  letter-spacing: 0.5px;
`;
const IconLogoWrapper = styled.div`
  width: 45px;
  height: 45px;
  background-color: #fafafa;
  box-shadow: 0 6px 11px 0 #675ee052;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
`;
const OnboardingLogoWrapper = styled.div`
  width: 34px;
  height: 34px;
  background-color: #675ee0;
  border-radius: 8px;
  padding: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
`;
const HeaderItemsContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 30px;
`;
const HeaderItem = styled.div`
  border-radius: 8px;
  height: 40px;
  cursor: pointer;
  background: #fafafa;
  padding: 7px 14px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 18px;
  border: 1px solid rgba(103, 94, 224, 0.2);
`;
const PageLayout = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-grow: 1;
  background-color: ${(props) => (props.page === 'welcome' ? '#fff' : '#F2F4F8')};
  padding-top: ${(props) => (props.page === 'welcome' ? '0' : '30px')};
`;
const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  position: relative;
  max-width: 1440px;
  margin: 0 auto;
`;

import React, { useState } from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Caption1 } from 'components/Shared';

import {
  faAngleDown,
  faAngleRight,
  faCheck,
} from '@fortawesome/pro-light-svg-icons';

const NestedList = ({
  data,
  parentKey,
  onMultiSelect,
  isSelected,
  selectedOptionLabels,
  setSelectedOptionLabels,
  nested,
}) => {
  const [isOpen, setIsOpen] = useState(!!nested);
  const [openedElems, setOpenedElems] = useState([]);

  const setLabels = (option) => {
    const array = [...selectedOptionLabels];
    const i = array.indexOf(option);
    if (i > -1) {
      array.splice(i, 1);
    } else {
      array.push(option);
    }
    setSelectedOptionLabels(array);
  };

  const onToggleOpen = (id) => {
    if (openedElems.includes(id)) {
      setIsOpen(false);
      setOpenedElems(openedElems.filter((elem) => elem !== id));
    } else {
      setIsOpen(true);
      setOpenedElems([...openedElems, id]);
    }
  };
  return (
    <NestedListContainer>
      {Object.keys(data).map((key) => {
        const isNested = typeof data[key] === 'object' && !Array.isArray(data[key]);
        const isOpen = openedElems.includes(data[key].path);

        if (key === 'path') {
          return null; // path anahtarını gösterme
        }

        return (
          <li key={key}>
            <Row>
              <ChevronButton onClick={() => onToggleOpen(data[key].path)}>
                {isOpen ? (
                  <FontAwesomeIcon icon={faAngleDown} />
                ) : (
                  <FontAwesomeIcon icon={faAngleRight} />
                )}
              </ChevronButton>
              <CheckBoxOption
                selected={isSelected(data[key].path)}
                onClick={() => {
                  onMultiSelect(data[key].path);
                  setLabels(key);
                  // handleAddFilter(filter);
                }}
              >
                <CheckBox selected={isSelected(data[key].path)}>
                  {isSelected(data[key].path) && (
                  <FontAwesomeIcon icon={faCheck} color="#675EE0" />
                    )}
                </CheckBox>
                <Caption1>{`${key}`}</Caption1>
              </CheckBoxOption>
            </Row>
            {isOpen && (
              <NestedList
                data={data[key]}
                parentKey={`${parentKey} > ${key}`}
                isSelected={isSelected}
                nested={isNested}
                onMultiSelect={onMultiSelect}
                selectedOptionLabels={selectedOptionLabels}
                setSelectedOptionLabels={setSelectedOptionLabels}
              />
            )}
          </li>
        );
      })}
    </NestedListContainer>
  );
};
const NestedListContainer = styled.ul`
  ul {
    padding-left: 10px;
  }
  ul,
  li {
    list-style-type: none;
  }
`;
const CheckBoxOption = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 5px 8px;
  min-height: 32px;
  border-radius: 8px;
  border: 1px solid #fafafa;
  background: #fafafa;
  overflow: hidden;
  text-overflow: ellipsis;
`;
const CheckBox = styled.div`
  width: 16px;
  height: 16px;
  border-radius: 4px;
  border: ${(props) => (props.selected ? '1px solid #675EE0' : '1px solid #7F7F7F')};
  background: #fafafa;
  display: flex;
  justify-content: center;
  align-items: center;
`;
const ChevronButton = styled.button`
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  background: white;
`;
const Row = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-left: 10px;
`;
export default NestedList;

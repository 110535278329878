import React, { useRef, useState } from 'react';
import styled from 'styled-components';
import { useClickAway } from 'react-use';
import { useQuery } from '@tanstack/react-query';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMagnifyingGlass } from '@fortawesome/pro-light-svg-icons';
import { OnboardingServices } from 'services';
import { useAuth } from 'context/AuthContext';
import { Headline4, Subtitle1 } from 'components/Shared';

const BrandListFontPicker = ({ value, onChange, bottom }) => {
  const fontPickerRef = useRef();
  const [isOpen, setIsOpen] = useState(false);

  const [searchKey, setSearchKey] = useState('');

  const [token] = useAuth((store) => store.token);

  useClickAway(fontPickerRef, () => {
    setIsOpen(false);
  });

  const { data: fontList, isLoading } = useQuery({
    queryKey: ['getFonts'],
    queryFn: () => OnboardingServices.getFonts(token),
  });

  const list = searchKey
    ? fontList
      && fontList.filter((o) => o.family.toLowerCase().includes(searchKey.toLowerCase()))
    : fontList;

  const toggleOpen = () => {
    setIsOpen(true);
  };

  return (
    <>
      <Title onClick={toggleOpen}>
        {value ? (
          <Container>
            <Headline4>{value?.family ? value.family : value}</Headline4>
            {
                value === 'Lato' && <Headline4 style={{color: '#675EE0'}}>A?</Headline4>
              }
          </Container>

        ) : (
          <Subtitle1
            style={{ textAlign: 'center', color: '#7F7F7F', width: '100%' }}
          >
            Choose Fonts
          </Subtitle1>
        )}
      </Title>
      <BrandListFontPickerContainer isOpen={isOpen} ref={fontPickerRef} bottom={bottom}>
        <InputWrapper>
          <FontAwesomeIcon icon={faMagnifyingGlass} />
          <Input
            placeholder="Search fonts"
            onChange={(e) => setSearchKey(e.target.value)}
          />
        </InputWrapper>
        <SelectBox>
          {!isLoading
            && fontList
            && fontList.length > 0
            && list.map((font, f) => (
              <Option
                key={f.toString()}
                family={font.family}
                category={font.category}
                onClick={() => {
                    onChange(font);
                    setIsOpen(false);
                  }}
                selected={
                    value?.family
                      ? value.family === font.family
                      : value?.name && value.name === font.name
                  }
              >
                {font.family || font.name}
              </Option>
              ))}
        </SelectBox>
      </BrandListFontPickerContainer>
    </>
  );
};

export default BrandListFontPicker;

const BrandListFontPickerContainer = styled.div`
  display: ${(props) => (props.isOpen ? 'block' : 'none')};
  position: absolute;
  width: 300px;
  height: 300px;
  right: ${(props) => (!props.bottom && '-315px')};
  top: ${(props) => (props.bottom ? '74px' : '-100%')};
  left: ${(props) => (props.bottom && '0')};
  border-radius: 8px;
  background: white;
  box-shadow: 0 4px 8px 0 rgba(91, 204, 124, 0.16);
  padding: 10px 5px;
  z-index: 99;

  :before {
    content: "";
    position: absolute;
    left: ${(props) => (props.bottom ? '7px' : '-10px;')};
    top: ${(props) => (props.bottom ? '-10px' : '50px')};
    width: 0;
    height: 0;
    border-style: solid;
    border-width: ${(props) => (props.bottom ? '0 10px 10px 10px' : '10px 10px 10px 0')};
    border-color: ${(props) => (props.bottom ? 'transparent transparent white' : 'transparent white transparent transparent')};
  }
`;
const Title = styled.div`
  height: 62px;
  width: 100%;
`;
const SelectBox = styled.div`
  width: 290px;
  height: 225px;
  overflow-y: scroll;
  
  ::-webkit-scrollbar {
    width: 4px;
  }
  ::-webkit-scrollbar-track {
    margin: 5px 0;
    background: #fafafa;
    border-radius: 8px;
  }
  ::-webkit-scrollbar-thumb {
    background: #e5e5e5;
    border-radius: 8px;
  }
  ::-webkit-scrollbar-thumb:hover {
    background: #e5e5e5;
  }
`;
const Option = styled.div`
  font-size: 16px;
  cursor: pointer;
  padding: 5px 12px;
  height: 32px;
  border-radius: 8px;
  border: ${(props) => (props.selected ? '1px solid #675EE0' : '1px solid white')};
  background: ${(props) => (props.selected ? '#F0EFFC' : 'white')};
  font-family: ${(props) => (props.family && props.category
      ? `${props.family}, ${props.category}`
      : 'inherit')};

  :hover {
    background: #f0effc;
  }
`;
const InputWrapper = styled.div`
  height: 40px;
  width: 100%;
  border-radius: 8px;
  border: 0.5px solid #000;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
  background: white;
  padding: 12px 15px;
  margin-bottom: 10px;
`;
const Input = styled.input`
  border: none;
  width: 100%;
  flex: 1;

  :focus {
    outline: none;
  }
`;
const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

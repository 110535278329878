import React, { useState } from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router';
import { useMutation } from '@tanstack/react-query';
import { useForm } from 'react-hook-form';
import {toast} from 'react-toastify';
import { AuthServices } from 'services';
import { useAuth } from 'context/AuthContext';
import { InputWrapper, LoginFormLogo } from 'components/Auth';
import { Headline3, Headline4, Loading } from 'components/Shared';
import {getRoute} from 'utils';

const ForgotPasswordForm = () => {
  const [store, set] = useAuth((store) => store);
  const [instructionsSent, setInstructionsSent] = useState(false);
  const navigate = useNavigate();

  const goSignInPage = () => {
    navigate(getRoute('signin'));
  };

  const { mutate, isLoading } = useMutation({
    mutationFn: AuthServices.forgotPassword,
    mutationKey: ['forgotPassword'],
    onSuccess: ({ data }) => {
      set(data);
      setInstructionsSent(true);
    },
    onError: (error) => {
      if (error?.response?.data?.errors) {
        Object.keys(error.response.data.errors).map((key) => toast.error(error.response.data.errors[key]));
      } else {
        toast.error('Something went wrong, please try again later');
      }
    },
  });

  const { register, handleSubmit } = useForm();
  const onSubmit = (data) => mutate({ ...data });

  return (
    <FormWrapper>
      <LoginFormLogo />
      {!instructionsSent ? (
        <>
          <Headline3>Forgot Password?</Headline3>
          <Row>
            <Headline4>Remembered your password? </Headline4>
            <Redirection href={getRoute('signin')}>
              <Headline4>Login</Headline4>
              {' '}
            </Redirection>
          </Row>
          <FormContainer>
            <Form onSubmit={handleSubmit(onSubmit)}>
              <InputWrapper width="100%" name="email">
                <input
                  type="email"
                  name="email"
                  placeholder="Your email"
                  {...register('email')}
                />
              </InputWrapper>
              {isLoading && <Loading />}
              <ButtonWrapper>
                <input type="submit" value="Send Reset Instructions" />
              </ButtonWrapper>
            </Form>
          </FormContainer>
        </>
      ) : (
        <>
          <Headline3>Instructions have been sent to your inbox.</Headline3>
          <Column>
            <Headline4>Please check your inbox and spam folder.</Headline4>
            <Headline4>
              If you don’t receive the email within 1-2 minutes,
            </Headline4>
            <Headline4>
              please contact us at
              {' '}
              <a href="mailto: team@conversionline.io">
                team@conversionline.io
              </a>
              {' '}
            </Headline4>
          </Column>
          <ButtonWrapper>
            <input
              type="submit"
              value="Back to Sign In Page"
              onClick={goSignInPage}
            />
          </ButtonWrapper>
        </>
      )}
    </FormWrapper>
  );
};
const FormWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 100%;
  height: 100%;
  grid-area: form;
  h1 {
    margin-bottom: 30px;
  }
  h3 {
    color: #675ee0;
    margin-bottom: 10px;
    text-align: center;
  }
  h4 {
    text-align: center;
  }
  @media (max-width: 1024px) {
    padding: 20px 0;
    position: relative;
    margin-bottom: 0;
    height: max-content;

    h1 {
      font-size: 22px;
      font-style: normal;
      font-weight: 500;
      line-height: 34px;
      margin-bottom: 15px;
      margin-top: 15px;
    }
    h4 {
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 24px;
    }
  }
`;
const Row = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;
const Column = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  a {
    color: #675ee0;
    text-decoration: none;
  }
`;
const Redirection = styled.a`
  cursor: pointer;
  outline: none;
  text-decoration: none;
  h4 {
    color: #675ee0;
    margin-left: 5px;
  }
`;

const FormContainer = styled.div`
  width: 100%;
  max-width: 345px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  margin-top: 10px;
  @media (max-width: 1024px) {
    margin-top: 10px;
    max-width: 287px;
    padding-bottom: 50px;
    overflow: hidden;
  }
`;

const Form = styled.form`
  width: 100%;
`;
const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  a {
    text-decoration: none;
  }
  input[type="submit"] {
    width: 100%;
    max-width: 345px;
    height: 56px;
    background: #675ee0;
    border-radius: 8px;
    border: none;
    color: white;
    font-size: 16px;
    line-height: 1;
    letter-spacing: 0.2px;
    margin-top: 20px;
    cursor: pointer;
    font-family: "Lato", sans-serif;
    font-style: normal;
    font-weight: 500;
  }
  @media (max-width: 1024px) {
    input[type="submit"] {
      max-width: 287px;
    }
  }
`;
export default ForgotPasswordForm;

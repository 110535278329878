import React from 'react';

const Index = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    {...props}
  >
    <path
      fill="#fff"
      fillRule="evenodd"
      d="m21.295 21.159-1.079-4.022a.96.96 0 0 1 .076-.68c.94-1.887 1.255-4.141.676-6.492-.787-3.182-3.27-5.736-6.444-6.58a10.106 10.106 0 0 0-2.545-.336c-5.93 0-10.562 5.564-8.962 11.755.742 2.864 3.8 5.829 6.677 6.527.778.19 1.543.279 2.285.279 1.595 0 3.09-.411 4.402-1.119a.99.99 0 0 1 .468-.123c.08 0 .16.009.239.03l3.915 1.048a.237.237 0 0 0 .292-.287Zm-2.316-3.69.61 2.276-2.17-.58a2.243 2.243 0 0 0-.57-.074c-.371 0-.747.097-1.083.278a7.892 7.892 0 0 1-3.787.959 8.464 8.464 0 0 1-1.984-.243c-2.435-.592-5.118-3.208-5.736-5.604-.646-2.5-.137-5.068 1.392-7.044a7.95 7.95 0 0 1 6.328-3.107c.734 0 1.48.098 2.219.296a7.767 7.767 0 0 1 5.528 5.648c.482 1.945.283 3.885-.579 5.613a2.237 2.237 0 0 0-.168 1.582Z"
      clipRule="evenodd"
    />
    <path
      fill="#fff"
      fillRule="evenodd"
      d="M3.361 14.715c-1.54-5.961 2.915-11.31 8.618-11.31a9.75 9.75 0 0 1 2.455.324c3.046.81 5.433 3.265 6.188 6.322.559 2.267.255 4.435-.649 6.247a1.315 1.315 0 0 0-.1.933l1.024 3.819-3.714-.994h-.002a1.24 1.24 0 0 0-.332-.044c-.223 0-.445.062-.638.167a8.89 8.89 0 0 1-4.232 1.075c-.714 0-1.45-.085-2.2-.268-1.362-.33-2.791-1.206-3.976-2.36-1.184-1.155-2.091-2.558-2.442-3.91ZM11.98 4.686a7.594 7.594 0 0 0-6.047 2.97c-1.46 1.884-1.948 4.338-1.329 6.737.288 1.116 1.07 2.314 2.091 3.312 1.021.997 2.247 1.758 3.385 2.035a8.109 8.109 0 0 0 1.9.233c1.275 0 2.49-.308 3.618-.917a2.65 2.65 0 0 1 1.252-.32c.227 0 .448.03.66.086h.002l1.575.42-.45-1.68a2.592 2.592 0 0 1 .193-1.834c.822-1.649 1.014-3.502.552-5.368a7.411 7.411 0 0 0-5.275-5.39h-.001a8.176 8.176 0 0 0-2.126-.284Z"
      clipRule="evenodd"
    />
    <path
      fill="#fff"
      fillRule="evenodd"
      d="M11.979 2.693c-6.158 0-10.966 5.779-9.307 12.2.392 1.511 1.385 3.023 2.635 4.242 1.25 1.218 2.787 2.174 4.303 2.542a9.965 9.965 0 0 0 2.369.289 9.6 9.6 0 0 0 4.57-1.161h.002a.635.635 0 0 1 .298-.081c.053 0 .1.006.143.018l3.93 1.051.011.002h.003a.593.593 0 0 0 .703-.725l-1.079-4.024v-.003a.606.606 0 0 1 .05-.427c.978-1.962 1.302-4.302.703-6.736-.817-3.307-3.397-5.961-6.697-6.839h-.002a10.461 10.461 0 0 0-2.635-.348Zm0 .712c-5.703 0-10.158 5.349-8.618 11.31.351 1.353 1.258 2.756 2.442 3.91 1.185 1.155 2.614 2.03 3.975 2.36.75.184 1.487.27 2.201.27a8.89 8.89 0 0 0 4.232-1.076c.194-.105.415-.167.638-.167.106 0 .217.012.332.044h.002l3.714.994-1.024-3.818v-.001a1.315 1.315 0 0 1 .1-.933c.904-1.812 1.208-3.98.65-6.247-.756-3.057-3.143-5.511-6.19-6.322a9.75 9.75 0 0 0-2.454-.324Z"
      clipRule="evenodd"
    />
  </svg>
    );

export default Index;

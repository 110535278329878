import React from 'react';

const Index = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="17"
    height="15"
    fill="none"
    viewBox="0 0 17 15"
    {...props}
  >
    <path
      stroke="#fff"
      strokeMiterlimit="10"
      strokeWidth="1.15"
      d="M15.449 4.43v4.692a3.197 3.197 0 01-3.198 3.197H4.524c-.771 0-1.505.311-2.053.846l-.585.598a.523.523 0 01-.896-.374V4.42A3.197 3.197 0 014.188 1.22h8.075a3.197 3.197 0 013.198 3.197l-.012.013z"
    />
  </svg>
    );

export default Index;

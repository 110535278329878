import React from 'react';
import styled from 'styled-components';
import { useApp } from 'context/AppContext';
import { Subtitle3 } from 'components/Shared';
import {
  faObjectsAlignBottom,
  faObjectsAlignCenterHorizontal,
  faObjectsAlignCenterVertical,
  faObjectsAlignLeft,
  faObjectsAlignRight,
  faObjectsAlignTop,
} from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const POSITIONS = [
  {
    name: 'top',
    title: 'Top',
    icon: faObjectsAlignTop,
    value: 'y',
  },
  {
    name: 'left',
    title: 'Left',
    icon: faObjectsAlignLeft,
    value: 'x',
  },
  {
    name: 'middleVertical',
    title: 'Middle',
    icon: faObjectsAlignCenterVertical,
    value: 'y',
  },
  {
    name: 'middleHorizontal',
    title: 'Middle',
    icon: faObjectsAlignCenterHorizontal,
    value: 'x',
  },
  {
    name: 'lower',
    title: 'Lower',
    icon: faObjectsAlignBottom,
    value: 'y',
  },
  {
    name: 'right',
    title: 'Right',
    icon: faObjectsAlignRight,
    value: 'x',
  },
];

const Position = ({ stage }) => {
  const {
 changeShape, stageSize, selectedShape, selectedId,
} = useApp();

  const findMiddleVertical = (elHeight) => stageSize.height / 2 - elHeight / 2;
  const findMiddleHorizontal = (elWidth) => stageSize.width / 2 - elWidth / 2;
  const findRight = (elWidth) => stageSize.width - elWidth;
  const findLower = (elHeight) => stageSize.height - elHeight;
  const calculatePosition = (position) => {
    switch (position) {
      case 'top':
        return {
          y: 0,
        };
      case 'left':
        return {
          x: 0,
        };
      case 'middleVertical':
        return {
          y: findMiddleVertical(
            stage.find(`#${selectedId}`)[0].getClientRect().height,
          ),
        };
      case 'middleHorizontal':
        return {
          x: findMiddleHorizontal(selectedShape?.width),
        };
      case 'lower':
        return {
          y: findLower(stage.find(`#${selectedId}`)[0].getClientRect().height),
        };
      case 'right':
        return {
          x: findRight(selectedShape?.width),
        };
      default:
        return {
          x: 0,
          y: 0,
        };
    }
  };

  return (
    <Wrapper>
      {POSITIONS.map((position) => (
        <Button
          key={position?.name}
          selected={
              selectedShape?.[position?.value]
              === calculatePosition(position?.name)[position?.value]
            }
          onClick={() => changeShape(calculatePosition(position.name))}
        >
          <FontAwesomeIcon icon={position.icon} />
          <Subtitle3>{position.title}</Subtitle3>
        </Button>
        ))}
    </Wrapper>
  );
};
const Wrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
`;

const Button = styled.button`
  background: none;
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  width: 100px;
  margin-top: 10px;
  p {
    color: ${(props) => (props.selected ? '#bebebe' : '#000')};
    margin-left: 10px;
  }
  svg {
    color: ${(props) => (props.selected ? '#bebebe' : '#000')};
  }
`;
export default Position;

import React, {useRef, useState} from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Headline4 } from 'components/Shared';
import {
  BrandListImageInput,
  BrandListColorPicker,
  BrandListSelect,
  BrandListFontPicker,
} from 'components/Onboarding';
import {useClickAway} from 'react-use';

const BrandListItem = ({
  title,
  icon,
  inputType,
  data,
  setData,
  selectedCompetitor,
  setSelectedCompetitor,
  selectedIndustry,
  setSelectedIndustry,
  isHome,
    bottom,
}) => {
  const {
 url, name, logo, fonts, industry, colors, competitor, svg,
} = data;

  const [previewImage, setPreviewImage] = useState(null);
  const [colorList, setColorList] = useState(colors);

  const [isInputWrapperActive, setIsInputWrapperActive] = useState(false);
  const inputWrapperRef = useRef();
  const onTextInputChange = (e) => {
    const tmpData = { ...data };
    if (title === 'Brand Name') {
      tmpData.name = e.target.value;
      setData(tmpData);
    } else if (title === 'Brand Url') {
      tmpData.url = e.target.value;
      setData(tmpData);
    } else {
      tmpData.competitor = e.target.value;
      setData(tmpData);
    }
  };

  const selectValue = title === 'Competitor' ? competitor : industry;

  const getBase64 = (file) => new Promise((resolve) => {
      let baseURL = '';
      // Make new FileReader
      const reader = new FileReader();

      // Convert the file to base64 text
      reader.readAsDataURL(file);

      // on reader load somthing...
      reader.onload = () => {
        // Make a fileInfo Object
        baseURL = reader.result;
        resolve(baseURL);
      };
    });
  const onImageInputChange = async (files) => {
    setPreviewImage(URL.createObjectURL(files[0]));
    const tmp = { ...data };
    const base64 = await getBase64(files[0]);
    tmp.logo = base64;
    setData(tmp);
  };

  const onFontInputChange = (font) => {
    const tmp = { ...data };
    tmp.fonts = font;
    setData(tmp);
    setTimeout(() => {
      setIsInputWrapperActive(false);
    }, 10);
  };

  const onColorInputChange = (color, index) => {
    const tmp = [...colors];
    tmp[index] = color;
    const tmpData = { ...data };
    tmpData.colors = tmp;
    setColorList(tmp);
    setData(tmpData);
  };

  const onSelectInputChange = (option) => {
    if (title === 'Competitor') {
      setSelectedCompetitor(option);
    } else {
      setSelectedIndustry(option);
      setTimeout(() => {
        setIsInputWrapperActive(false);
      }, 10);
    }
  };

  useClickAway(inputWrapperRef, () => {
    setIsInputWrapperActive(false);
  });

  return (
    <BrandListItemContainer isHome={isHome}>
      <TitleWrapper>
        <IconWrapper>
          <FontAwesomeIcon icon={icon} />
        </IconWrapper>
        <Headline4>{title}</Headline4>
      </TitleWrapper>
      <InputWrapper
        isActive={inputType === 'color' ? false : isInputWrapperActive}
        onClick={() => setIsInputWrapperActive(true)}
        onBlur={() => setIsInputWrapperActive(false)}
        pointer={inputType === 'font'}
        border={
          (inputType === 'image' && !logo)
          || (title === 'Brand Industry' && !selectedIndustry)
          || (title === 'Brand Url' && !url)
          || (title === 'Brand Name' && !name)
        }
        inputType={inputType}
        ref={inputWrapperRef}
      >
        {inputType === 'text' && (
          <Input
            value={title === 'Brand Name' ? name : url}
            onChange={onTextInputChange}
          />
        )}
        {inputType === 'font' && (
          <BrandListFontPicker
            value={fonts ? (Array.isArray(fonts) ? fonts[0] : fonts) : null}
            // value={fonts ? fonts[0] : null}
            onChange={onFontInputChange}
            bottom={bottom}
          />
        )}
        {inputType === 'image' && (
          <BrandListImageInput
            img={previewImage || logo}
            svg={svg}
            onChange={onImageInputChange}
          />
        )}
        {inputType === 'select' && (
          <BrandListSelect
            options={Array.isArray(selectValue) ? selectValue : [selectValue]}
            title={title}
            onChange={onSelectInputChange}
            value={
              title === 'Competitor'
                ? selectedCompetitor !== null
                  ? selectedCompetitor
                  : Array.isArray(competitor)
                  ? competitor[0]
                  : competitor
                : selectedIndustry
            }
          />
        )}
        {inputType === 'color' && (
          <ColorPickerWrapper>
            {colorList.map((color, c) => (
              <BrandListColorPicker
                id={`color-${c}`}
                key={`color-${color}-${c.toString()}`}
                color={color}
                count={colors.length}
                onChange={onColorInputChange}
                index={c}
              />
            ))}
          </ColorPickerWrapper>
        )}
      </InputWrapper>
    </BrandListItemContainer>
  );
};

export default BrandListItem;

const BrandListItemContainer = styled.div`
  padding: 14px 14px 14px 7px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 8px;
  border: 3px solid white;
  background: ${({ isHome }) => (isHome ? '#F2F4F8' : '#fafafa')};
  width: 474px;
`;
const TitleWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 20px;
`;
const IconWrapper = styled.div`
  width: 48px;
  height: 48px;
  border-radius: 8px;
  padding: 9px;
  background: white;
  display: flex;
  justify-content: center;
  align-items: center;

  svg {
    height: 30px;
    width: auto;
    color: #675ee0;
  }
`;
const InputWrapper = styled.div`
  cursor: ${({ pointer }) => (pointer ? 'pointer' : 'default')};
  position: relative;
  border-radius: 8px;
  background: white;
  height: 62px;
  width: 211px;
  padding: 20px 17px;
  display: ${({ inputType }) => (inputType === 'image' ? 'block' : 'flex')};
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  border: ${({ isActive }) => (isActive ? '1px solid #675EE0' : '1px solid white')};
  
`;
const ColorPickerWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 14px;
  height: 100%;
  width: 100%;
`;
const Input = styled.input`
  background: white;
  height: 100%;
  width: 100%;
  border: none;

  font-family: "Lato", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: ${({ theme }) => theme.sizes.base};
  line-height: 28px;
  letter-spacing: 1px;
  color: #000000;

  :focus {
    outline: none;
  }
`;

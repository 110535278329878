import React, { useEffect } from 'react';
import { ToastContainer } from 'react-toastify';
import ReactGA from 'react-ga4';
import { useLocation } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { AuthProvider } from 'context/AuthContext';
import theme from 'style/themes';
import Navigation from './Navigation';

import 'react-toastify/dist/ReactToastify.css';
import 'style/index.css';
import 'style/animate.css';

ReactGA.initialize('G-22PD758DRC');
const queryClient = new QueryClient();

const App = () => {
  const location = useLocation();

  useEffect(() => {
    window.gtag('event', 'page_view', {
      page_path: location.pathname + location.search + location.hash,
      page_search: location.search,
      page_hash: location.hash,
    });
  }, [location]);

  return (
    <ThemeProvider theme={theme}>
      <QueryClientProvider client={queryClient}>
        <AuthProvider>
          <div className="main">
            <ToastContainer
              position="top-right"
              autoClose={5000}
              hideProgressBar={false}
              newestOnTop={false}
              closeOnClick
              rtl={false}
              theme="light"
            />
            <Navigation />
          </div>
        </AuthProvider>
      </QueryClientProvider>
    </ThemeProvider>
  );
};

export default App;

import React, { useEffect } from 'react';
import styled from 'styled-components';
import WebFont from 'webfontloader';
import { useQuery } from '@tanstack/react-query';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/pro-light-svg-icons';
import { useAuth } from 'context/AuthContext';
import { useApp } from 'context/AppContext'; // Import Web Font Loader
import { DesignServices } from 'services';

const FontFamily = ({ searchQuery }) => {
  const { selectedShape, changeShape } = useApp();
  const [token] = useAuth((store) => store.token);

  const { data, status } = useQuery({
    queryKey: ['fonts'],
    queryFn: () => DesignServices.getFonts(token),
  });

  useEffect(() => {
    if (status === 'success') {
      const fontFamilies = data?.data.map((font) => font.family);
      WebFont.load({
        google: {
          families: fontFamilies,
        },
      });
    }
  }, [data]);

  const filteredData = data?.data?.filter((item) => item.family.toLowerCase().includes(searchQuery.toLowerCase())) || [];
  return (
    <Wrapper>
      {filteredData.map((font) => {
        const isSelected = selectedShape.fontFamily === font.family;
        return (
          <Button
            family={font.family}
            onClick={() => changeShape({
                fontFamily: font.family,
              })}
            selected={isSelected}
            key={font.family.toString()}
          >
            <span>{font.family}</span>
            {isSelected && <FontAwesomeIcon icon={faCheck} />}
          </Button>
        );
      })}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  overflow-y: scroll;
  ::-webkit-scrollbar {
    display: none;
    width: 0 !important;
  }
`;

const Button = styled.button`
  border: none;
  background: none;
  outline: none;
  flex: 1;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 4px;

  span {
    font-size: 16px;
    text-align: left;
    font-family: ${(props) => props.family};
    color: #000000;
  }

  padding: 10px;
  cursor: pointer;
  :hover {
    background-color: #e2e2e2;
  }
`;

export default FontFamily;

import React from 'react';

const Index = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="19"
    fill="none"
    viewBox="0 0 20 19"
    {...props}
  >
    <path
      stroke="#fff"
      strokeLinejoin="round"
      strokeWidth="1.15"
      d="M5.672 7.98h-4.28v10.44h4.28V7.98zM18.364 10.072c.423-.262.709-.734.709-1.27 0-.833-.672-1.505-1.506-1.505h-4.603c.05-.958-.063-3.235-.162-4.728-.1-1.568-2.19-.958-2.19-.958.299 2.899-1.605 6.01-3.335 7.291-.348.261-1.605.635-1.605.635v8.373H15.85a1.503 1.503 0 001.07-2.563 1.491 1.491 0 001.294-1.48c0-.411-.161-.784-.435-1.058a1.491 1.491 0 00.585-2.75v.013z"
    />
  </svg>
    );

export default Index;

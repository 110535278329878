import React from 'react';
import styled from 'styled-components';
import { useApp } from 'context/AppContext';
import {
  faCloudUpload,
  faLayerGroup,
  faObjectsColumn,
  faText,
} from '@fortawesome/pro-light-svg-icons';
import { ControlButton, ControlContent } from 'components/Design';

const SideBar = ({ stage }) => {
  const { onSidebarContentChange, currentSidebarContent } = useApp();
  return (
    <SideBarWrapper>
      <Menu>
        <ControlButton
          icon={faLayerGroup}
          title="Add Xml Elements"
          selected={currentSidebarContent === 'xmlElems'}
          onButtonClick={() => onSidebarContentChange('xmlElems')}
        />
        <ControlButton
          icon={faObjectsColumn}
          title="Templates"
          selected={currentSidebarContent === 'templates'}
          onButtonClick={() => onSidebarContentChange('templates')}
        />
        <ControlButton
          icon={faText}
          title="Text"
          selected={currentSidebarContent === 'text'}
          onButtonClick={() => onSidebarContentChange('text')}
        />

        <ControlButton
          icon={faCloudUpload}
          title="Uploads"
          selected={currentSidebarContent === 'uploads'}
          onButtonClick={() => onSidebarContentChange('uploads')}
        />
      </Menu>
      {currentSidebarContent && (
        <ControlContent
          selected={currentSidebarContent}
          stage={stage}
          deselectControl={() => onSidebarContentChange('')}
        />
      )}
    </SideBarWrapper>
  );
};

const SideBarWrapper = styled.div`
  display: flex;
  grid-area: sidebar;
  /* grid-template-columns: 100px auto; */
  /* max-width: 420px; */
  width: min-content;
  max-height: 100%;
  z-index: 1;
  overflow-y: hidden;
`;
const Menu = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100px;
  background: #fff;
  padding: 50px 0 80px;
  border-right: 1px solid #675ee0;
  background: #fff;
`;
export default SideBar;

import styled from 'styled-components';

export const TopMenuButton = styled.button`
  background: ${(props) => (props.selected ? '#F0EFFC' : 'none')};
  border: none;
  width: 32px;
  height: 32px;
  border-radius: 4px;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  display: flex;
  align-items: center;
  justify-content: center;
  :hover {
    background: #ececec;
  }
  z-index: 10;
`;

export const TextButton = styled.button`
  gap: 7px;
  border-radius: 4px;
  border: none;
  outline: none;
  cursor: pointer;
  position: relative;
  display: flex;
  align-items: center;
  transition: all ease-in-out 0.2s;
  padding: 0px 8px;
  background-color: ${({ selected }) => (selected ? '#F0EFFC' : 'transparent')};

  :hover {
    background-color: #f2f4f8;
  }
`;

export const Divider = styled.span`
  width: 1px;
  height: 32px;
  margin: 0 10px;
  background: #e4e4e4;
`;

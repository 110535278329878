export function calculateDuration(animations) {
  let totalDuration = 0;

  animations.forEach(({ animation }) => {
    const delay = animation?.delay || 0;
    const duration = animation?.config?.duration || 0;
    const repeatCount = animation?.count || 1;
    let durationWithDelay = (duration + delay) * repeatCount;
    if (totalDuration < durationWithDelay) {
      totalDuration = durationWithDelay;
    }
  });
  return totalDuration;
}

import React from 'react';
import styled from 'styled-components';
import { Link, useLocation } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Subtitle3 } from 'components/Shared';

import {
  faGear,
  faSquareB,
  faHome,
  faCirclePlay,
} from '@fortawesome/pro-light-svg-icons';
import {
  faHome as solidFaHome,
  faSquareB as solidFaSquareB,
  faGear as solidFaGear,
  faCirclePlay as solidFaCirclePlay,
} from '@fortawesome/pro-solid-svg-icons';
import { getRoute } from 'utils';

const MenuItem = ({
 isActive, text, icon, route,
}) => (
  <ItemWrapper>
    <Link
      to={route}
      style={{
          textDecoration: 'none',
          color: 'black',
        }}
    >
      <Item active={isActive}>
        <FontAwesomeIcon icon={icon} />
      </Item>
      <Subtitle3
        style={{
            textAlign: 'center',
          }}
      >
        {text}
      </Subtitle3>
    </Link>
  </ItemWrapper>
  );
const LeftMenu = () => {
  const location = useLocation();

  const isActive = (pathname) => location.pathname === pathname;

  return (
    <Container>
      <Top>
        <MenuItem
          isActive={isActive('/')}
          text="Home"
          icon={isActive('/') ? solidFaHome : faHome}
          route="/"
        />
        <MenuItem
          isActive={isActive(getRoute('brand'))}
          text="Brand"
          icon={isActive(getRoute('brand')) ? solidFaSquareB : faSquareB}
          route={getRoute('brand')}
        />
        {/* <MenuItem
          isActive={isActive(getRoute("videoSelect"))}
          text="Video"
          icon={isActive(getRoute("videoSelect")) ? solidFaCirclePlay : faCirclePlay}
          route={getRoute("videoSelect")}
        /> */}

        <MenuItem
          isActive={isActive(getRoute('feedOptimisation'))}
          text="Feed Optimisation"
          icon={isActive(getRoute('feedOptimisation')) ? solidFaGear : faGear}
          route={getRoute('feedOptimisation')}
        />
      </Top>
    </Container>
  );
};

export default LeftMenu;

const Container = styled.div`
  width: 90px;
  height: calc(100vh - 100px);
  padding: 45px 15px;
  border-right: 1px solid #675ee0;
  background: #fff;
  position: fixed;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
`;
const ItemWrapper = styled.div`
  a {
    text-decoration: none;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
`;
const Item = styled.div`
  width: 50px;
  height: 34px;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${(props) => (props.active ? '#F0EFFC' : '#FFF')};

  :hover {
    background-color: ${(props) => !props.active && '#F2F4F8'};
  }
`;
const Top = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;
  justify-content: center;
  align-items: center;
`;

import { client } from '../client';

const CommonServices = {
  getUsageReason: () => client.get('wait-list/reasons'),
  joinWaitlist: (data) => client.post('wait-list', data),
  getXML: (id, token) => client.get(`files/xml/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }),
};

export default CommonServices;

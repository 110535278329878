import React from 'react';
import styled from 'styled-components';

import { Logo, TextLogo } from 'icons';

const BetaLogo = () => (
  <LogoContainer href="/">
    <IconLogoWrapper>
      <LogoWrapper>
        <Logo />
      </LogoWrapper>
    </IconLogoWrapper>
    <TextLogoWrapper>
      <TextLogo />
      <Beta>BETA</Beta>
    </TextLogoWrapper>
  </LogoContainer>
  );

export default BetaLogo;
const LogoContainer = styled.a`
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 10px;
  
  :focus {
    outline: none;
    text-decoration: none;
  }
`;
const TextLogoWrapper = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 5px;
`;
const Beta = styled.div`
  width: 43px;
  height: 24px;
  border-radius: 4px;
  background: #000;
  color: #fff;
  text-align: center;
  font-feature-settings: "liga" off;
  font-family: Lato;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px; /* 200% */
  letter-spacing: 0.5px;
  outline: none;
  text-decoration: none;
`;

const IconLogoWrapper = styled.div`
  width: 45px;
  height: 45px;
  background-color: #fafafa;
  box-shadow: 0 6px 11px 0 #675ee052;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
`;
const LogoWrapper = styled.div`
  width: 34px;
  height: 34px;
  background-color: #675ee0;
  border-radius: 8px;
  padding: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

import React, { useRef, useState } from 'react';
import styled from 'styled-components';
import { useClickAway } from 'react-use';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEllipsis } from '@fortawesome/pro-regular-svg-icons';
import { faPen, faTrash } from '@fortawesome/pro-light-svg-icons';

import { Caption1 } from 'components/Shared';

const TableEditMenu = ({ item, deleteMutation, editOnClick }) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [selected, setSelected] = useState('');
  const menuContainerRef = useRef(null);

  useClickAway(menuContainerRef, () => {
    setIsMenuOpen(false);
  });

  const onDelete = () => {
    deleteMutation(item.id);
  };

  return (
    <MenuIcon open={isMenuOpen} onClick={() => setIsMenuOpen(!isMenuOpen)}>
      <FontAwesomeIcon icon={faEllipsis} />
      {isMenuOpen && (
        <MenuContainer ref={menuContainerRef}>
          <Option
            selected={selected === 'edit'}
            onClick={() => {
              editOnClick(item.filter, item.name, item.id);
            }}
          >
            <FontAwesomeIcon icon={faPen} />
            <Caption1>Edit Filter</Caption1>
          </Option>

          <Option
            selected={selected === 'delete'}
            onClick={() => {
              onDelete();
            }}
          >
            <FontAwesomeIcon icon={faTrash} />
            <Caption1>Delete</Caption1>
          </Option>
        </MenuContainer>
      )}
    </MenuIcon>
  );
};

const MenuIcon = styled.div`
  position: relative;
  border-radius: 4px;
  width: 24px;
  height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  background: ${(props) => (props.open ? '#F0EFFC' : 'transparent')};

  :hover {
    background: ${(props) => !props.open && '#CAC8E2'};
  }
`;
const MenuContainer = styled.div`
  position: absolute;
  right: 0;
  top: 28px;
  width: 194px;
  /* height: 86px; */
  border-radius: 8px;
  padding: 10px 6px;
  border: 1px solid rgba(103, 94, 224, 0.2);
  background: #fafafa;
  box-shadow: 0 4px 8px 0 rgba(91, 204, 124, 0.16);
  z-index: 2;
`;
const Option = styled.div`
  cursor: pointer;
  background: ${(props) => (props.selected ? '#F0EFFC' : '#FAFAFA')};
  padding: 5px 7px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 4px;
  border-radius: 8px;
  border: ${(props) => (props.selected ? '1px solid #675EE0' : '1px solid #FAFAFA')};

  :hover {
    background: #f0effc;
  }
`;
export default TableEditMenu;

import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

const Logo = () => (
  <LogoContainer>
    <Link href="/" to="/">
      <LogoWrapper>
        <LogoBg>
          <img src="/logo.png" alt="logo" />
        </LogoBg>
      </LogoWrapper>
    </Link>
  </LogoContainer>
  );

const LogoContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  a {
    text-decoration: none;
  }
`;
const LogoWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  width: 45px;
  height: 45px;
  min-width: 45px;
  min-height: 45px;
  background: #fff;
  border-radius: 8px;
  filter: drop-shadow(0px 8px 8px rgba(103, 94, 224, 0.1));
  img {
    width: 20px;
    object-fit: cover;
  }
  @media (max-width: 768px) {
    gap: 8px;
    img {
      width: 18px;
    }
  }
`;
const LogoBg = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
  min-width: 30px;
  min-height: 30px;
  border-radius: 8px;
  padding: 5px;
  filter: drop-shadow(0px 8px 8px rgba(103, 94, 224, 0.1));
  background: #675ee0;

  @media (max-width: 768px) {
  }
`;

export default Logo;

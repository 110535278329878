import React, { memo } from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye } from '@fortawesome/pro-regular-svg-icons';
import { Subtitle2 } from 'components/Shared';

const FilterProducts = memo(({
  products,
  type,
  selectedVideoProducts,
  selectProduct,
  isProductSelected,
}) => (
  <Table>
    <thead>
      <TableRow>
        <TableHead>Preview</TableHead>
        <TableHead>Name</TableHead>
        <TableHead>Price</TableHead>
        <TableHead>Availability</TableHead>
        <TableHead>Product ID</TableHead>
        <TableHead />
      </TableRow>
    </thead>
    <tbody>
      {products?.data?.map((item) => (
        <TableRow key={item['g:id']}>
          <TableData>
            <img
              src={item['g:image_link']}
              alt="product"
              style={{opacity: isProductSelected(item['g:id']) ? '1' : '0.3'}}
            />
          </TableData>
          <TableData>
            <Subtitle2 style={{color: isProductSelected(item['g:id']) ? 'black' : '#7F7F7F'}}>
              {item?.title?.substring(0, 14).concat('...')
                    || item['g:title']?.substring(0, 14).concat('...')}
            </Subtitle2>
          </TableData>
          <TableData>
            {item['g:sale_price'] ? (
              <Price>
                <Subtitle2 style={{ color: '#675EE0' }}>
                  {item['g:sale_price']}
                </Subtitle2>
                <Subtitle2
                  style={{
                        color: '#7F7F7F',
                        textDecoration: 'line-through',
                      }}
                >
                  {item['g:price']}
                </Subtitle2>
              </Price>
                ) : (
                  <Price>
                    <Subtitle2 style={{ color: '#675EE0' }}>
                      {item['g:price']}
                    </Subtitle2>
                  </Price>
                )}
          </TableData>
          <TableData>
            <Subtitle2>{item['g:availability']}</Subtitle2>
          </TableData>
          <TableData>
            <Subtitle2>{item['g:id']}</Subtitle2>
          </TableData>
          <TableData>
            {type === 'video' ? (
              <input
                type="checkbox"
                checked={selectedVideoProducts?.includes(item)}
                onChange={() => selectProduct(item)}
              />
                ) : (
                  <EyeButton onClick={() => selectProduct(item)}>
                    <FontAwesomeIcon
                      icon={faEye}
                      width="14px"
                      color={
                        isProductSelected(item['g:id']) ? 'black' : '#7F7F7F'
                      }
                    />
                  </EyeButton>
                )}
          </TableData>
        </TableRow>
          ))}
    </tbody>
  </Table>
  ));

const Table = styled.table`
  width: 100%;
  margin-top: 25px;
`;
const TableHead = styled.th`
  font-family: "Lato", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: ${({ theme }) => theme.sizes.m};
  line-height: 26px;
  letter-spacing: 1px;
  color: #7f7f7f;
  text-align: left;
  min-width: 15px;
`;
const TableData = styled.td`
  padding-top: 10px;

  img {
    width: 50px;
    border-radius: 8px;
  }
`;
const EyeButton = styled.div`
  padding-right: 20px;
  cursor: pointer;
`;
const Price = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 7px;
`;
const TableRow = styled.tr`
  margin-top: 10px;
`;

export default FilterProducts;

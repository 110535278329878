import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useApp } from 'context/AppContext';
import { Subtitle3 } from 'components/Shared';
import { EffectCustomize } from 'components/Design';

import {
  NoneEffectIcon,
  OutlineEffectIcon,
  ShadowEffectIcon,
  CorruptionEffectIcon,
  NeonEffectIcon,
  TextBoxIcon,
} from 'icons';

const EFFECTS = {
  NONE: {
    name: 'NONE',
    features: null,
  },
  OUTLINE: {
    name: 'OUTLINE',
    features: [
      {
        name: 'strokeWidth',
        title: 'Thickness',
        type: 'number',
        initialValue: 1,
        min: 1,
        max: 100,
      },
      {
        name: 'stroke',
        title: 'Stroke',
        type: 'color',
        initialValue: '#f71e1e',
      },
    ],
  },

  SHADOW: {
    name: 'SHADOW',
    features: [
      {
        name: 'shadowOffsetX',
        title: 'Offset Horizantal',
        type: 'number',
        initialValue: 10,
        min: -180,
        max: 180,
      },
      {
        name: 'shadowOffsetY',
        title: 'Offset Vertical',
        type: 'number',
        initialValue: 10,
        min: -180,
        max: 180,
      },
      {
        name: 'shadowBlur',
        title: 'Blur',
        type: 'number',
        initialValue: 1,
        min: 0,
        max: 100,
        dividedBy: 10,
      },
      {
        name: 'shadowOpacity',
        title: 'Opacity',
        type: 'number',
        initialValue: 0.5,
        min: 0,
        max: 100,
        dividedBy: 100,
      },
      {
        name: 'shadowColor',
        title: 'Color',
        type: 'color',
        initialValue: '#f31e1e',
      },
    ],
  },
  NEON: {
    name: 'NEON',
    features: [
      {
        name: 'intensity',
        title: 'Intensity',
        type: 'number',
        initialValue: 1,
        min: 0,
        max: 100,
      },
    ],
  },
};

const TextEffects = () => {
  const { changeShape, selectedShape } = useApp();
  const [selectedEffect, setSelectedEffect] = useState(
    selectedShape?.appliedEffect || EFFECTS.NONE,
  );

  const onOutlineAdded = () => {
    const maxValue = Math.ceil(selectedShape.fontSize / 20);
    EFFECTS.OUTLINE.features[0].max = maxValue;
    setSelectedEffect(EFFECTS.OUTLINE);
  };

  const onCorruptionAdded = () => {
    changeShape({
      shadowOpacity: 1,
      shadowColor: '#e2e2e2',
      shadowOffset: {
        x: 10,
        y: 5,
      },
    });
  };

  const onValueChange = (feature) => {
    const effectCopy = { ...selectedEffect };
    effectCopy.features = effectCopy.features.map((f) => {
      if (f.name === feature.name) {
        return feature;
      }
      return f;
    });
    setSelectedEffect(effectCopy);
  };

  useEffect(() => {
    if (!selectedEffect) return;
    changeShape({ appliedEffect: selectedEffect });
  }, [selectedEffect]);

  return (
    <Wrapper>
      <Effect
        onClick={() => setSelectedEffect(EFFECTS.NONE)}
        selected={selectedEffect?.name === 'NONE'}
      >
        <div>
          <NoneEffectIcon />
        </div>
        <Subtitle3>None</Subtitle3>
      </Effect>
      <Effect
        onClick={onOutlineAdded}
        selected={selectedEffect?.name === 'OUTLINE'}
      >
        <div>
          <OutlineEffectIcon />
        </div>
        <Subtitle3>Outline</Subtitle3>
      </Effect>
      <Effect
        onClick={() => setSelectedEffect(EFFECTS.SHADOW)}
        selected={selectedEffect?.name === 'SHADOW'}
      >
        <div>
          <ShadowEffectIcon />
        </div>
        <Subtitle3>Shadow</Subtitle3>
      </Effect>
      <Effect disabled>
        <div>
          <TextBoxIcon />
        </div>
        <Subtitle3>Text Box</Subtitle3>
      </Effect>
      <Effect onClick={onCorruptionAdded} disabled>
        <div>
          <CorruptionEffectIcon />
        </div>
        <Subtitle3>Corruption</Subtitle3>
      </Effect>
      <Effect
        onClick={() => setSelectedEffect(EFFECTS.NEON)}
        selected={selectedEffect?.name === 'NEON'}
      >
        <div>
          <NeonEffectIcon />
        </div>
        <Subtitle3>Neon</Subtitle3>
      </Effect>
      {selectedEffect.name !== 'NONE'
        && selectedEffect?.features.map((feature) => (
          <EffectCustomize feature={feature} onValueChange={onValueChange} />
        ))}
      {/* <div>
        <Headline4>Shape</Headline4>
        <ShapeEditWrapper>
          <Effect onClick={() => setSelectedEffect(EFFECTS.NEON)}>
            <div>
              <NormalTextIcon />
            </div>
            <Subtitle3>Normal</Subtitle3>
          </Effect>
          <Effect>
            <div>
              <EllipsisTextIcon />
            </div>
            <Subtitle3>Ellipsis</Subtitle3>
          </Effect>
        </ShapeEditWrapper>
      </div> */}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  gap: 8px;
  flex-wrap: wrap;
  width: 100%;
`;

const Effect = styled.button`
  background: none;
  outline: none;
  border: none;
  div {
    border-radius: 4px;
    border: ${({ selected }) => (selected ? '1px solid #675EE0' : '1px solid rgba(103, 94, 224, 0.2)')};
    width: 75px;
    height: 75px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 10px;
  }
`;
const ShapeEditWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;
export default TextEffects;

import React, { useState } from 'react';
import styled from 'styled-components';
import { Subtitle1 } from 'components/Shared';

const Input = ({ placeholder, value, onChange }) => {
  const [isFocused, setIsFocused] = useState(false);
  const [inputValue, setInputValue] = useState('');

  const handleInputChange = (event) => {
    setInputValue(event.target.value);
    onChange(event);
  };

  const handleInputFocus = () => {
    setIsFocused(true);
  };

  const handleInputBlur = () => {
    setIsFocused(false);
  };

  return (
    <InputWrapper placeholder={placeholder}>
      <Label active={isFocused || inputValue}>
        <Subtitle1>{isFocused || inputValue ? '' : ''}</Subtitle1>
      </Label>
      <StyledInput
        name="inputName"
        value={inputValue}
        onChange={handleInputChange}
        onFocus={handleInputFocus}
        onBlur={handleInputBlur}
        placeholder={placeholder}
      />
    </InputWrapper>
  );
};

const InputWrapper = styled.div`
  position: relative;
`;

const Label = styled.label`
  position: absolute;
  top: ${(props) => (props.active ? '-28px' : '20px')};
  left: 16px;
  font-size: ${(props) => (props.active ? '12px' : '14px')};
  font-weight: ${(props) => (props.active ? 'bold' : 'normal')};
  color: ${(props) => (props.active ? '#000' : '#888')};
  transition: all 0.2s ease-out;
`;

const StyledInput = styled.input`
  font-family: "Lato", sans-serif;
  font-weight: 400;
  line-height: 22px;
  font-size: 14px;
  width: 345px;
  height: 56px;
  background: #e4e4e4;
  border: none;
  padding: 17px 20px;
  font-size: 16px;
  border-radius: 8px;
  outline: none;
  :-webkit-autofill {
    -webkit-box-shadow: 0 0 0 30px #e4e4e4 inset !important;
  }
  ::placeholder {
    font-family: "Lato", sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: ${({ theme }) => theme.sizes.m};
    line-height: 30px;
    color: #000000;
  }
`;

export default Input;

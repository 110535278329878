import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useMutation, useQuery } from '@tanstack/react-query';
import { useSearchParams } from 'react-router-dom';
import { OnboardingServices } from 'services';

import { useAuth } from 'context/AuthContext';
import { ResultItem } from 'components/Onboarding';

import {
  Button,
  Headline3,
  FeedOptimisationModal,
  DownloadXmlUrlModal,
  GradientBorderButton,
} from 'components/Shared';

const ResultsPage = () => {
  const [token] = useAuth((store) => store.token);

  const [searchParams] = useSearchParams();
  const uuid = searchParams.get('uuid');
  const [isDownloadModalOpen, setIsDownloadModalOpen] = useState(false);
  const [xmlLink, setXmlLink] = useState('');

  const [items, setItems] = useState(null);
  const [fetchContinue, setFetchContinue] = useState(true);
  const [isFilterModalOpen, setIsFilterModalOpen] = useState(false);

  const { mutate, status } = useMutation({
    mutationKey: ['saveFilters'],
    mutationFn: (data) => OnboardingServices.saveFilters(data, token),
    onSuccess: ({ data }) => {
      setXmlLink(data.outputXml.url);
    },
  });

  const onDesignCreate = ({ name, filter }) => {
    mutate({
      name,
      filter,
    });
    setIsDownloadModalOpen(true);
  };

  const { data, isLoading } = useQuery({
    queryKey: ['getPredictCompetitor', uuid],
    queryFn: () => OnboardingServices.getPredictCompetitor(uuid, token),
    refetchInterval: 1000,
    enabled: fetchContinue,
  });

  const { data: competitorOutput } = useQuery({
    queryKey: ['competitorOutput'],
    queryFn: () => OnboardingServices.downloadCompetitorOutput(uuid, token),
    enabled: !!uuid,
  });
  useEffect(() => {
    if (!isLoading && data?.predictItems) {
      setItems(data.predictItems);
    }
    if (data?.proccessStatus === 1 && !isLoading) {
      setFetchContinue(false);
    }
  }, [data]);
  console.log(competitorOutput?.url?.Location, 'Gasdfasfas');
  return (
    <>
      <AbsoluteButtonContainer>
        <Button
          type="primary"
          size="medium"
          content="EXPORT"
          handleClick={() => setIsFilterModalOpen(true)}
        />
        <a href={competitorOutput?.url?.Location} download>
          <Button
            type="secondary"
            size="medium"
            content="DOWNLOAD ALL"
            disabled={!competitorOutput?.url?.Location}
          />
        </a>
      </AbsoluteButtonContainer>

      <Headline3 style={{ textAlign: 'center', marginTop: '33px' }}>
        Download All with Various Sizes or
        <br />
        Customize Your Template Design in Our Playground
      </Headline3>
      <ResultItemsList>
        {items
          && items.map((item, index) => <ResultItem key={index} item={item} />)}
      </ResultItemsList>
      <DownloadXmlUrlModal
        isOpen={isDownloadModalOpen}
        setIsOpen={setIsDownloadModalOpen}
        loading={status === 'pending'}
        xmlLink={xmlLink}
      />
      <FeedOptimisationModal
        isOpen={isFilterModalOpen}
        setIsOpen={setIsFilterModalOpen}
        onApply={onDesignCreate}
        buttonTitle="Create Design & Save"
      />
    </>
  );
};

export default ResultsPage;
const ResultItemsList = styled.div`
  margin-top: 63px;
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  gap: 40px;
  margin-bottom: 63px;
`;
const AbsoluteButtonContainer = styled.div`
  display: flex;
  gap: 20px;
  position: absolute;
  right: 0;
  transform: translateX(45%);
  top: 6px;
  a {
    text-decoration: none;
  }
`;

import Modal from 'react-modal';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { CloseIcon } from 'icons';
import { Subtitle3 } from 'components/Shared';
import {getRoute} from 'utils';

const customStyles = {
  overlay: {
    zIndex: '9999',
    backgroundColor: 'transparent',
  },
};

const MenuModal = ({ isMenuOpen, onMenuClose }) => (
  <StyledModal
    isOpen={isMenuOpen}
    onRequestClose={onMenuClose}
    style={customStyles}
    contentLabel="Pop up Modal"
  >
    <ModalHeader>
      <CloseButton onClick={onMenuClose}>
        <CloseIcon />
      </CloseButton>
    </ModalHeader>
    <ModalContent>
      <Link to={getRoute('signin')}>
        <Subtitle3>Sign In</Subtitle3>
      </Link>
      <Link to="/">
        <Subtitle3>Ad Library</Subtitle3>
      </Link>
      <Link to="/">
        <Subtitle3>Blog</Subtitle3>
      </Link>
    </ModalContent>
  </StyledModal>
  );
const StyledModal = styled(Modal)`
  position: absolute;
  top: 15px;
  right: 15px;
  background: #fafafa;
  padding: 10px;
  width: 156px;
  height: max-content;
  border-radius: 16px;
`;
const ModalHeader = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const CloseButton = styled.button`
  cursor: pointer;
  background: transparent;
  border: none;
  cursor: pointer;
  outline: none;
`;
const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  a {
    text-decoration: none;
  }
  h1 {
    transition: all 0.3s ease-in-out;
    :hover {
      color: #675ee0;
    }
  }
`;
export default MenuModal;

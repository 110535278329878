import React from 'react';

const Index = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="68"
    height="16"
    fill="none"
    viewBox="0 0 68 16"
    {...props}
  >
    <rect
      width="21"
      height="10.333"
      x="43.5"
      y="2.834"
      stroke="#fff"
      opacity="0.4"
      rx="2.167"
    />
    <path
      fill="#fff"
      d="M66 6v4a2.17 2.17 0 001.333-2A2.17 2.17 0 0066 6z"
      opacity="0.5"
    />
    <rect
      width="18"
      height="7.333"
      x="45"
      y="4.334"
      fill="#fff"
      rx="1.333"
    />
    <path
      fill="#fff"
      fillRule="evenodd"
      d="M30.334 4.575a8.77 8.77 0 015.976 2.34.314.314 0 00.435-.004l1.161-1.148a.313.313 0 00-.002-.448c-4.232-3.975-10.909-3.975-15.14 0a.314.314 0 00-.004.448l1.161 1.148c.12.118.314.12.435.003a8.771 8.771 0 015.978-2.34zM30.332 8.3c1.224 0 2.404.457 3.311 1.281a.311.311 0 00.435-.006l1.161-1.177a.326.326 0 00-.005-.463 7.176 7.176 0 00-9.802 0 .325.325 0 00-.004.463l1.16 1.177c.12.12.313.123.436.006a4.921 4.921 0 013.308-1.28zm2.335 2.724a.307.307 0 01-.095.227l-2.015 1.992a.318.318 0 01-.447 0l-2.015-1.992a.307.307 0 01.01-.451 3.507 3.507 0 014.457 0 .307.307 0 01.105.224zM17.667 3.666a1 1 0 00-1-1h-1a1 1 0 00-1 1v8.667a1 1 0 001 1h1a1 1 0 001-1V3.666zM11 4.999h1a1 1 0 011 1v6.334a1 1 0 01-1 1h-1a1 1 0 01-1-1V5.999a1 1 0 011-1zM7.333 7.333h-1a1 1 0 00-1 1v4a1 1 0 001 1h1a1 1 0 001-1v-4a1 1 0 00-1-1zm-3.666 3a1 1 0 00-1-1h-1a1 1 0 00-1 1v2a1 1 0 001 1h1a1 1 0 001-1v-2z"
      clipRule="evenodd"
    />
  </svg>
    );

export default Index;

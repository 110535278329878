import React, { useState } from 'react';
import styled from 'styled-components';
import Modal from 'react-modal';
import { HexColorInput, HexColorPicker } from 'react-colorful';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBrush } from '@fortawesome/pro-solid-svg-icons';
import { TopMenuButton } from 'components/Design';

const customStyles = {
  overlay: {
    zIndex: '999',
    background: 'transparent',
    position: 'absolute',
    width: '100vw',
    height: '100vh',
  },
  content: {
    position: 'absolute',
    top: '30px',
    left: '0',
    width: '220px',
    height: '220px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '3px',
    padding: '10px',
  },
};

const BorderColor = ({ color, setColor }) => {
  const [isOpen, setIsOpen] = useState(false);

  const openModal = () => {
    setIsOpen(true);
  };

  const closeModal = () => {
    setIsOpen(false);
  };

  return (
    <Wrapper id="border-color">
      <TopMenuButton onClick={openModal} data-tip="Border Color">
        <FontAwesomeIcon icon={faBrush} size="xl" />
      </TopMenuButton>
      <Modal
        isOpen={isOpen}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Pop up Modal"
        shouldCloseOnOverlayClick
        parentSelector={() => document.querySelector('#border-color')}
      >
        <HexColorPicker color={color} onChange={setColor} />
        <InputWrapper>
          <HexColorInput
            color={color}
            onChange={setColor}
            prefixed
            placeholder="Hex Code"
          />
          <Color background={color} />
        </InputWrapper>
      </Modal>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  position: relative;
`;
const InputWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-top: 10px;
`;
const Color = styled.span`
  width: 20px;
  height: 20px;
  background: ${(props) => props.background};
`;
export default BorderColor;

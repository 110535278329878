import * as React from 'react';

const NeonEffectIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={47}
    height={47}
    fill="none"
    {...props}
  >
    <g filter="url(#a)">
      <path
        fill="#FD36F2"
        d="M25.234 7.164A1.872 1.872 0 0 0 23.5 6c-.766 0-1.445.46-1.734 1.164L9.484 37.25H7.875A1.87 1.87 0 0 0 6 39.125 1.87 1.87 0 0 0 7.875 41h8.75a1.87 1.87 0 0 0 1.875-1.875 1.87 1.87 0 0 0-1.875-1.875h-3.086L16.094 31h14.82l2.555 6.25h-3.094a1.87 1.87 0 0 0-1.875 1.875A1.87 1.87 0 0 0 30.375 41h8.75A1.87 1.87 0 0 0 41 39.125a1.87 1.87 0 0 0-1.875-1.875h-1.61L25.236 7.164Zm4.149 20.086H17.617L23.5 12.836l5.883 14.414Z"
      />
    </g>
    <path
      fill="#FD36F2"
      d="M25.234 7.164A1.872 1.872 0 0 0 23.5 6c-.766 0-1.445.46-1.734 1.164L9.484 37.25H7.875A1.87 1.87 0 0 0 6 39.125 1.87 1.87 0 0 0 7.875 41h8.75a1.87 1.87 0 0 0 1.875-1.875 1.87 1.87 0 0 0-1.875-1.875h-3.086L16.094 31h14.82l2.555 6.25h-3.094a1.87 1.87 0 0 0-1.875 1.875A1.87 1.87 0 0 0 30.375 41h8.75A1.87 1.87 0 0 0 41 39.125a1.87 1.87 0 0 0-1.875-1.875h-1.61L25.236 7.164Zm4.149 20.086H17.617L23.5 12.836l5.883 14.414Z"
    />
    <defs>
      <filter
        id="a"
        width={47}
        height={47}
        x={0}
        y={0}
        colorInterpolationFilters="sRGB"
        filterUnits="userSpaceOnUse"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feBlend in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
        <feGaussianBlur
          result="effect1_foregroundBlur_1826_9875"
          stdDeviation={3}
        />
      </filter>
    </defs>
  </svg>
);
export default NeonEffectIcon;

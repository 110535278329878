import * as React from 'react';

const OutlineEffectIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={35}
    height={40}
    fill="none"
    {...props}
  >
    <path
      stroke="#000"
      d="M3.484 34.25h.336l.127-.311L16.228 3.854c.213-.517.71-.854 1.272-.854.561 0 1.059.337 1.272.854l12.28 30.085.128.311h1.945a1.37 1.37 0 0 1 1.375 1.375A1.37 1.37 0 0 1 33.125 37h-8.75A1.37 1.37 0 0 1 23 35.625a1.37 1.37 0 0 1 1.375-1.375h3.838l-.281-.69-2.555-6.25-.127-.31H9.758l-.127.31-2.555 6.25-.281.69h3.83A1.37 1.37 0 0 1 12 35.625 1.37 1.37 0 0 1 10.625 37h-8.75A1.37 1.37 0 0 1 .5 35.625a1.37 1.37 0 0 1 1.375-1.375h1.61Zm19.899-10h.744l-.281-.689-5.883-14.414-.463-1.134-.463 1.134-5.883 14.414-.28.689h12.509Z"
    />
  </svg>
);
export default OutlineEffectIcon;

import React from 'react';
import styled from 'styled-components';

const ButtonGradient = ({ content, href }) => (
  <ButtonWrapper>
    <Button href={href}>
      {content}
      <Shadow />
    </Button>
  </ButtonWrapper>
  );

export default ButtonGradient;

const ButtonWrapper = styled.div`
  position: relative;
  margin-top: 30px;
  @media (max-width: 1024px) {
    margin-top: 20px;
  }
`;

const Shadow = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  background: #675ee0;
  border-radius: 100px;
  transition: all 0.5s ease-in-out;
  inset: 1px;
  border-radius: 100px;
  z-index: -1;
  filter: blur(20px);
  transform: scale(0);
`;

const Button = styled.a`
  position: relative;
  border-radius: 8px;
  display: flex;
  padding: 12px 24px;
  justify-content: center;
  align-items: center;
  width: max-content;
  cursor: pointer;
  border: none;
  font-family: "Lato", sans-serif;
  font-weight: 500;
  letter-spacing: 2px;
  font-size: 14px;
  text-transform: uppercase;
  color: #ffffff;
  transition: all 0.5s ease-in-out;
  text-decoration: none;
  background: linear-gradient(90deg, #675ee0 0%, #5bcc7c 100%);
  background-size: 300%;
  background-position: 0 50%;

  :hover {
    transform: scale(1.03);
    background-size: 200%;
    background-position: 55% 50%;
  }
  :active {
    outline: none;
    transform: scale(0.9);
    div {
      transform: scale(0.8);
    }
  }
`;

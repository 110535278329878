import React from 'react';
import styled from 'styled-components';
import { Caption1 } from 'components/Shared';

const GradientBorderButton = ({ content, noAnimation, ...rest }) => (
  <GradientButton noAnimation={noAnimation} {...rest}>
    <GradientCover />
    <Caption1>{content}</Caption1>
  </GradientButton>
  );

export default GradientBorderButton;

const GradientButton = styled.button`
  width: max-content;
  border-radius: 8px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  cursor: pointer;
  border: none;
  transition: transform 0.3s ease;
  padding: 8px 16px;
  h1 {
    position: relative;
    z-index: 2;
    text-transform: uppercase;
    letter-spacing: 1px;
    line-height: 16px;
  }
  &&::before {
    content: "";
    position: absolute;
    width: 110%;
    height: 350%;
    background: linear-gradient(90deg, #675ee0 0%, #5bcc7c 100%);
    animation: ${(props) => (props.noAnimation ? 'none' : 'spin 1.5s infinite linear')};
  }
  :hover {
    transform: scale(1.03);
  }
`;
const GradientCover = styled.div`
  position: absolute;
  background-color: #fff;
  width: calc(100% - 3px);
  height: calc(100% - 2px);
  border-radius: 7px;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
`;

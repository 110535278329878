import React, { useState } from 'react';
import styled from 'styled-components';
import Modal from 'react-modal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBorderAll } from '@fortawesome/pro-solid-svg-icons';

const customStyles = {
  overlay: {
    zIndex: '999',
    background: 'transparent',
    position: 'absolute',
    width: '100vw',
    height: '100vh',
  },
  content: {
    position: 'absolute',
    top: '40px',
    left: '10px',
    width: '220px',
    height: '60px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '8px',
    padding: '10px',
  },
};

const BorderWidth = ({ width, setWidth }) => {
  const [isOpen, setIsOpen] = useState(false);

  const openModal = () => {
    setIsOpen(true);
  };

  const closeModal = () => {
    setIsOpen(false);
  };

  const onBorderWidthhange = (e) => {
    setWidth(Number(e.target.value));
  };

  return (
    <Wrapper id="border-width">
      <Button onClick={openModal} data-tip="Border Width">
        <FontAwesomeIcon icon={faBorderAll} size="xl" />
      </Button>
      <Modal
        shouldCloseOnOverlayClick
        isOpen={isOpen}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Pop up Modal"
        parentSelector={() => document.querySelector('#border-width')}
      >
        <OpacityInput>
          <input
            type="range"
            min="0"
            max="20"
            value={width}
            onChange={onBorderWidthhange}
          />
          <span>
            {width}
            {' '}
            px
          </span>
        </OpacityInput>
      </Modal>
    </Wrapper>
  );
};
const Button = styled.button`
  background: none;
  border: none;
  width: 40px;
  height: 40px;
  border-radius: 4px;
  cursor: pointer;
  :hover {
    background: #ececec;
  }
  z-index: 10;
`;
const Wrapper = styled.div`
  position: relative;
  button {
    border: none;
    background: none;
    align-self: flex-end;
  }
`;
const OpacityInput = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  span {
    padding: 5px;
    margin-left: 10px;
    border: 1px solid #ececec;
    border-radius: 4px;
    font-size: 10px;
  }
`;
export default BorderWidth;

import { FFmpeg } from '@ffmpeg/ffmpeg';
import { toBlobURL } from '@ffmpeg/util';
import { useEffect, useRef, useState } from 'react';

const useVideoConverter = () => {
  const [loaded, setLoaded] = useState(false);
  const ffmpegRef = useRef(new FFmpeg());
  const [inputVideo, setInputVideo] = useState(null); // frames
  const [downloadURL, setDownloadURL] = useState(null);
  const [progress, setProgress] = useState(0);

  const load = async () => {
    const baseURL = 'https://unpkg.com/@ffmpeg/core@0.12.2/dist/umd';
    const ffmpeg = ffmpegRef.current;
    ffmpeg.on('log', ({ message }) => console.info(message));
    ffmpeg.on('progress', ({ progress, time }) => setProgress(Math.floor(progress * 100)));
    await ffmpeg.load({
      coreURL: await toBlobURL(`${baseURL}/ffmpeg-core.js`, 'text/javascript'),
      wasmURL: await toBlobURL(
        `${baseURL}/ffmpeg-core.wasm`,
        'application/wasm',
      ),
    });
    setLoaded(true);
  };
  const transcode = async (frames) => {
    const ffmpeg = ffmpegRef.current;
    await ffmpeg.createDir('images/');
    for (let i = 0; i < frames.length; i++) {
      await ffmpeg.writeFile(`images/${i + 1}.jpeg`, frames[i]);
    }
    await ffmpeg.exec([
      '-framerate',
      '30',
      '-start_number',
      '1',
      '-i',
      'images/%d.jpeg',
      '-c:v',
      'libx264',
      '-vf',
      'fps=30,format=yuv420p',
      'output.mp4',
    ]);
    const fileData = await ffmpeg.readFile('output.mp4');
    ffmpeg.terminate();
    const data = new Uint8Array(fileData);
    const newUrl = URL.createObjectURL(
      new Blob([data.buffer], { type: 'video/mp4' }),
    );
    setDownloadURL(newUrl);
  };

  useEffect(() => {
    if (inputVideo && loaded) {
      transcode(inputVideo);
    }
  }, [inputVideo, loaded]);

  useEffect(() => {
    if (!loaded) {
      load();
    }
  }, []);
  return [downloadURL, progress, setInputVideo];
};

export default useVideoConverter;

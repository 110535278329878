import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { useClickAway } from 'react-use';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDown } from '@fortawesome/pro-light-svg-icons';

import {
  Subtitle3,
  CustomOption,
  NumericOption,
  SelectOption,
  NestedList,
} from 'components/Shared';

const numericOptions = {
  gt: 'Greater than',
  gte: 'Greater than or equal',
  lt: 'Less than',
  lte: 'Less than or equal',
  equal: 'Equal',
};

const SelectBox = ({
  id,
  filter,
  filterList,
  allFilters,
  setAllFilters,
  type,
}) => {
  const selectRef = useRef(null);
  const filterTypeRef = useRef(null);
  const includesRef = useRef(null);
  const optionsRef = useRef(null);

  const [selectIsOpen, setSelectIsOpen] = useState(false);
  const [includeIsOpen, setIncludeIsOpen] = useState(false);
  const [optionsIsOpen, setOptionsIsOpen] = useState(false);
  const [filterTypeIsOpen, setFilterTypeIsOpen] = useState(false);

  const [filters, setFilters] = useState(null);
  const [selectedFilter, setSelectedFilter] = useState(filter.filter);
  const [selectedLabel, setSelectedLabel] = useState(filter.label);
  const [selectInclude, setSelectInclude] = useState(filter.included);
  /* //TODO: fix filter type */
  const [selectedFilterType, setSelectedFilterType] = useState(
    [],
  );
  const [selectedOptions, setSelectedOptions] = useState(filter.value);
  const [selectedOptionLabels, setSelectedOptionLabels] = useState([]);

  useEffect(() => {
    setFilters(
      Object.keys(filterList).map((key) => ({
          placeholder: filterList[key].label,
          options: filterList[key],
          key,
        })),
    );
  }, [filterList]);

  useEffect(() => {
    if (filter?.key) {
      const {key} = filter;
      setSelectedFilter(
          {
            placeholder: filterList[key].label,
            options: filterList[key],
            key,
          },
      );
      setSelectedFilterType(filter.filterType);
      setSelectInclude(filter.included === 'included' ? 'included' : 'non included');

      if (filter.value[0].constructor === Array) {
        setSelectedOptions([].concat.apply([], filter.value));
      } else {
        setSelectedOptions(filter.value);
      }
    }
  }, []);

  useClickAway(selectRef, () => {
    setSelectIsOpen(false);
  });

  useClickAway(filterTypeRef, () => {
    setFilterTypeIsOpen(false);
  });

  useClickAway(includesRef, () => {
    setIncludeIsOpen(false);
  });

  useClickAway(optionsRef, () => {
    setOptionsIsOpen(false);
  });

  useEffect(() => {
    if (
      !selectedFilterType
      && filter
      && filter.options?.filterType
      && filter.options?.filterType?.length > 0
    ) {
      setSelectedFilterType(filter.options?.filterType[0]);
    }

    setAllFilters(
      allFilters.map((f) => {
        if (f.id === id) {
          return {
            ...f,
            key: selectedFilter?.key,
            type,
            label: selectedLabel,
            included: selectInclude,
            value: selectedOptions,
            filter: selectedFilter,
            filterType: selectedFilterType || selectedFilter?.options?.filterType[0],
          };
        }
        return f;
      }),
    );
  }, [selectedFilter, selectInclude, selectedFilterType, selectedOptions]);

  const onMultiSelect = (option) => {
    const array = [...selectedOptions];
    const i = array.indexOf(option);
    if (i > -1) {
      array.splice(i, 1);
    } else {
      array.push(option);
    }
    setSelectedOptions(array);
  };

  const isSelected = (option) => {
    const tmp = selectedOptions || [];
    const i = tmp.indexOf(option);
    return i > -1;
  };

  return (
    <FlexRow>
      <SelectContainer ref={selectRef}>
        <Select
          isOpen={selectIsOpen}
          onClick={() => setSelectIsOpen(!selectIsOpen)}
        >
          <Subtitle3>{selectedLabel || 'Select'}</Subtitle3>
          <FontAwesomeIcon icon={faAngleDown} />
        </Select>
        {selectIsOpen && (
          <OptionsContainer>
            {filters
              && filters.map((data, index) => (
                <Option
                  key={index}
                  selected={
                      selectedLabel && selectedLabel === data.placeholder
                    }
                  onClick={() => {
                      setSelectedFilter(data);
                      setSelectedLabel(data.placeholder);
                      setSelectIsOpen(false);
                      setSelectedOptions([]);
                      setSelectInclude(null);
                    }}
                >
                  {data.placeholder}
                </Option>
                ))}
          </OptionsContainer>
        )}
      </SelectContainer>
      <SelectContainer ref={includesRef}>
        <Select
          isOpen={includeIsOpen}
          onClick={() => selectedFilter && setIncludeIsOpen(!includeIsOpen)}
        >
          <Subtitle3>{selectInclude || 'Select'}</Subtitle3>
          <FontAwesomeIcon icon={faAngleDown} />
        </Select>
        {includeIsOpen && (
          <OptionsContainer>
            <Option
              selected={selectInclude && selectInclude === 'included'}
              onClick={() => {
                setSelectInclude('included');
                setIncludeIsOpen(false);
                if (selectedFilter?.options?.field !== 'numeric') {
                  setSelectedFilterType(selectedFilter?.options?.filterType[0]);
                }
              }}
            >
              included
            </Option>
            <Option
              selected={selectInclude && selectInclude === 'non included'}
              onClick={() => {
                setSelectInclude('non included');
                setIncludeIsOpen(false);
                if (selectedFilter?.options?.field !== 'numeric') {
                  setSelectedFilterType(selectedFilter?.options?.filterType[0]);
                }
              }}
            >
              non included
            </Option>
          </OptionsContainer>
        )}
      </SelectContainer>
      <SelectContainer ref={optionsRef}>
        <Select
          isOpen={optionsIsOpen}
          onClick={() => selectedFilter && setOptionsIsOpen(!optionsIsOpen)}
        >
          <Subtitle3>
            {selectedFilter?.options?.field === 'numeric' && selectedFilterType.length > 0
              ? `${numericOptions[selectedFilterType]} ${selectedOptions}`
                  .substring(0, 13)
                  .concat('...')
              : selectedOptions?.length > 0
              ? selectedOptions.toString().length > 13
                ? selectedOptions.toString().substring(0, 13).concat('...')
                : selectedOptions.toString()
              : 'Select'}
          </Subtitle3>
          <FontAwesomeIcon icon={faAngleDown} />
        </Select>
        {optionsIsOpen && (
          <>
            {selectedFilter?.options?.field === 'custom' && (
              <CustomOption
                label={selectedLabel}
                value={selectedOptions}
                onChange={(e) => setSelectedOptions(e.target.value)}
              />
            )}
            {selectedFilter?.options?.field === 'select' && (
              <SelectOption
                optionList={selectedFilter.options.options}
                isSelected={isSelected}
                onMultiSelect={onMultiSelect}
              />
            )}
            {selectedFilter?.options?.field === 'numeric' && (
              <NumericOption
                label={selectedLabel}
                selectedOptions={selectedOptions}
                selectedFilterType={selectedFilterType}
                setSelectedFilterType={setSelectedFilterType}
                setSelectedOptions={setSelectedOptions}
                filterTypeList={selectedFilter.options.filterType}
              />
            )}
            {selectedFilter?.options?.field === 'select_nested' && (
              <OptionsContainer>
                <NestedList
                  data={selectedFilter?.options.options}
                  parentKey="Main"
                  onMultiSelect={onMultiSelect}
                  isSelected={isSelected}
                  selectedOptionLabels={selectedOptionLabels}
                  setSelectedOptionLabels={setSelectedOptionLabels}
                />
              </OptionsContainer>
            )}
          </>
        )}
      </SelectContainer>
    </FlexRow>
  );
};

const FlexRow = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
  width: 100%;

  svg {
    height: 13px;
  }
`;

const Select = styled.div`
  width: 100%;
  height: 40px;
  border-radius: 8px;
  border: 0.5px solid #000;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 15px;
  cursor: pointer;

  svg {
    transition: transform 0.1s linear;
    transform: ${(props) => (props.isOpen
        ? 'rotate(180deg) translateY(0)'
        : 'rotate(0) translateY(0)')};
  }
`;
const SelectContainer = styled.div`
  position: relative;
  width: 100%;
  background: #fafafa;
`;
const Option = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 5px 10px;
  margin: 0 6px;
  min-height: 32px;
  border-radius: 8px;
  border: ${(props) => (props.selected ? '1px solid #675EE0' : '1px solid #FAFAFA')};
  background: ${(props) => (props.selected ? '#F0EFFC' : '#FAFAFA')};
  overflow: hidden;
  text-overflow: ellipsis;

  :hover {
    background: #f0effc;
  }
`;

const OptionsContainer = styled.div`
  position: absolute;
  z-index: 4;
  top: 45px;
  width: 100%;
  max-height: 310px;
  overflow-y: auto;
  border-radius: 8px;
  border: 1px solid rgba(103, 94, 224, 0.2);
  background: #fafafa;
  box-shadow: 0 4px 8px 0 rgba(91, 204, 124, 0.16);
  padding: 10px 0;

  ::-webkit-scrollbar {
    width: 4px;
  }
  ::-webkit-scrollbar-track {
    margin: 5px 0;
    background: #fafafa;
    border-radius: 8px;
  }
  ::-webkit-scrollbar-thumb {
    background: #e5e5e5;
    border-radius: 8px;
  }
  ::-webkit-scrollbar-thumb:hover {
    background: #e5e5e5;
  }
`;

export default SelectBox;

import React from 'react';
import styled from 'styled-components';

const AdsListItem = ({
 index, isSelected, setIsSelected, data,
}) => {
  const toggleSelected = () => {
    if (isSelected) {
      localStorage.setItem('selectedAd', JSON.stringify({}));
      setIsSelected({});
    } else {
      localStorage.setItem(
        'selectedAd',
        JSON.stringify({ index, data }),
      );
      setIsSelected({ index, data });
    }
  };

  return (
    <AdsListItemContainer selected={isSelected} onClick={toggleSelected}>
      <Content>
        <img src={data.url} alt="ads preview" />
      </Content>
    </AdsListItemContainer>
  );
};

export default AdsListItem;

const AdsListItemContainer = styled.div`
  max-height: 285px;
  border-radius: 8px;
  background: #fafafa;
  padding: 8px;
  cursor: pointer;
  display: flex;
  flex-direction: column;

  border: ${(props) => (props.selected ? '2px solid  #675EE0' : '2px solid #fafafa')};
  box-shadow: ${(props) => (props.selected ? '4px 4px 4px 4px rgba(103, 94, 224, 0.08)' : 'none')};

  :hover {
    border: ${(props) => (props.selected ? '2px solid #675EE0' : '2px solid #dddbf5')};
    box-shadow: 4px 4px 4px 4px rgba(103, 94, 224, 0.08);
  }
`;
const Content = styled.div`
  width: 100%;
  height: 100%;
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  border-radius: 8px;
  overflow: hidden;
  margin-top: 6px;

  img {
    height: 250px;

    width: auto;
    object-fit: contain;
  }
`;

import React from 'react';

const Index = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={20}
    height={18}
    fill="none"
    {...props}
  >
    <path
      fill="#fff"
      d="M10 17.824c-.37 0-.724-.116-1.023-.335-2.028-1.483-6.874-5.173-8.09-7.408-1.59-2.922-.635-6.674 2.125-8.364A5.609 5.609 0 0 1 5.94.883c1.535 0 2.978.62 4.06 1.723A5.658 5.658 0 0 1 14.06.883c1.026 0 2.035.29 2.927.834 2.76 1.69 3.715 5.442 2.129 8.364-1.216 2.235-6.062 5.925-8.09 7.408a1.74 1.74 0 0 1-1.027.335ZM5.94 2.122c-.791 0-1.57.224-2.26.645C1.473 4.122.716 7.139 1.999 9.494c1.127 2.074 6.2 5.884 7.728 7 .16.115.383.115.543 0 1.527-1.12 6.6-4.93 7.728-7 1.283-2.355.526-5.376-1.683-6.727a4.304 4.304 0 0 0-2.255-.645c-1.384 0-2.68.662-3.555 1.81l-.505.67-.504-.67c-.876-1.148-2.167-1.81-3.555-1.81Z"
    />
  </svg>
    );

export default Index;

import React, { useState } from 'react';
import styled from 'styled-components';
import { useApp } from 'context/AppContext';

import {
  BrandKit,
  XMLElements,
  TextOption,
  Position,
  SubMenuContainer,
  TextEffects,
  TextFill,
  ImageUpload,
  Templates,
  FontFamily,
} from 'components/Design';

const ControlContent = ({ selected, stage, deselectControl }) => {
  const {
    selectedShape,
    currentSidebarContent,
    resetSidebarContent,
    onSidebarContentChange,
  } = useApp();

  const [templateCount, setCount] = useState(0);
  const [searchQuery, setSearchQuery] = useState('');

  if (currentSidebarContent === 'position') {
    if (!selectedShape) {
      onSidebarContentChange('xmlElems');
    }

    return (
      <SubMenuContainer title="Position" onClick={resetSidebarContent}>
        <Position stage={stage} />
      </SubMenuContainer>
    );
  }

  if (currentSidebarContent === 'effects') {
    if (!selectedShape) {
      onSidebarContentChange('xmlElems');
    }
    return (
      <SubMenuContainer title="Text Effects" onClick={resetSidebarContent}>
        <TextEffects />
      </SubMenuContainer>
    );
  }

  if (currentSidebarContent === 'fontFamily') {
    if (!selectedShape) {
      onSidebarContentChange('xmlElems');
    }
    return (
      <SubMenuContainer
        title="Fonts"
        searchPlaceholder="Search fonts"
        searchQuery={searchQuery}
        setSearchQuery={setSearchQuery}
        onClick={deselectControl}
      >
        <FontFamily searchQuery={searchQuery} />
      </SubMenuContainer>
    );
  }
  if (currentSidebarContent === 'colorPicker') {
    if (!selectedShape) {
      onSidebarContentChange('xmlElems');
    }
    return (
      <SubMenuContainer title="Text Fill" onClick={resetSidebarContent}>
        <TextFill />
      </SubMenuContainer>
    );
  }

  if (selected === 'xmlElemsEdit') {
    return (
      <SubMenuContainer
        title="Set as XML"
        info="Edit your XML element"
        onClick={deselectControl}
      >
        <XMLElements edit />
      </SubMenuContainer>
    );
  }

  if (selected === 'xmlElems') {
    return (
      <SubMenuContainer
        title="XML Elements"
        info="Add XML elements to your design."
        onClick={deselectControl}
      >
        <XMLElements />
      </SubMenuContainer>
    );
  }
  if (selected === 'text') {
    return (
      <SubMenuContainer
        title="Texts"
        info="Add a Text"
        onClick={deselectControl}
      >
        <TextOption size={16} title="Add a Title" />
        <TextOption size={14} title="Add a Subtitle" />
        <TextOption size={12} title="Add a few lines of body text" />
      </SubMenuContainer>
    );
  }

  if (selected === 'templates') {
    return (
      <SubMenuContainer
        title="Templates"
        info={
          templateCount === 0
            ? 'No template found for this size'
            : `All ${templateCount} Instagram Story Templates`
        }
        onClick={deselectControl}
      >
        <Templates setTemplateCount={setCount} />
      </SubMenuContainer>
    );
  }
  if (selected === 'uploads') {
    return (
      <SubMenuContainer title="Uploads" onClick={deselectControl}>
        <ImageUpload />
      </SubMenuContainer>
    );
  }
  if (selected === 'brandKit') {
    return (
      <SubMenuContainer title="Brand Kit" onClick={deselectControl}>
        <BrandKit />
      </SubMenuContainer>
    );
  }
  return <ContentWrapper>{selected}</ContentWrapper>;
};
const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 11px 21px 11px 20px;
  width: 100%;
  max-width: 288px;
  background: #fff;
  box-shadow: 0px 8px 8px 0px rgba(0, 0, 0, 0.08);
`;

export default ControlContent;

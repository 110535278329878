import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { Subtitle1 } from 'components/Shared';

const BrandListImageInput = ({ img, svg, onChange }) => {
  const imageInputRef = useRef(null);
  const drop = useRef(null);
  const [files, setFiles] = useState(null);

  const handleDragOver = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const handleDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();

    const { files } = e.dataTransfer;
    onChange(files);
  };

  useEffect(() => {
    drop.current?.addEventListener('dragover', handleDragOver);
    drop.current?.addEventListener('drop', handleDrop);

    return () => {
      drop.current?.removeEventListener('dragover', handleDragOver);
      drop.current?.removeEventListener('drop', handleDrop);
    };
  }, [files]);

  return (
    <>
      <ImageInput ref={drop} onClick={() => imageInputRef.current.click()}>
        {img || svg ? (
          img ? <Image src={img} /> : <SVG dangerouslySetInnerHTML={{ __html: svg }} />
        ) : (
          <Subtitle1 style={{ textAlign: 'center', color: '#7F7F7F' }}>
            Drag and drop a file here or click here
          </Subtitle1>
        )}
      </ImageInput>
      <SecretImageInput
        type="file"
        ref={imageInputRef}
        onChange={(e) => onChange(e.target.files)}
      />
    </>
  );
};

export default BrandListImageInput;

const ImageInput = styled.div`
  height: 100%;
  width: 100%;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  :focus {
    outline: none;
  }
`;
const SecretImageInput = styled.input`
  opacity: 0;
`;
const SVG = styled.div`
  height: 100%;
  width: auto;
  max-width: 177px;
  
  svg {
    height: 100%;
    width: auto;
    max-width: 177px;
  }
`;
const Image = styled.img`
  height: 100%;
  width: auto;
  max-width: 177px;
`;

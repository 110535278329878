import React, { useEffect, useRef, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styled from 'styled-components';
import { useNavigate } from 'react-router';
import { useClickAway } from 'react-use';
import { useMutation } from '@tanstack/react-query';
import { faFacebookF, faInstagram } from '@fortawesome/free-brands-svg-icons';
import { faChevronDown } from '@fortawesome/pro-solid-svg-icons';
import { faSlidersUp } from '@fortawesome/pro-light-svg-icons';
import { DesignServices } from 'services';
import { useAuth } from 'context/AuthContext';
import { Button, Caption1 } from 'components/Shared';
import { CreateDesignMenu } from 'components/Dashboard';
import { getRoute } from 'utils';

const SCREEN_OPTIONS = [
  { value: '1080x1920', label: 'Instagram Story 1080x1920', icon: faInstagram },
  { value: '1080x1080', label: 'Instagram Post 1080x1080', icon: faInstagram },
  {
    value: '1200x1200',
    label: 'Facebook Gönderisi  1200x1200',
    icon: faFacebookF,
  },
  {
 value: '1920x1080', label: 'Custom Size', icon: faSlidersUp, custom: true,
},
];

const Index = ({ stageSize, onStageSizeChange, isDesign }) => {
  const [token] = useAuth((store) => store.token);
  const navigate = useNavigate();
  const modalRef = useRef();
  const [isOpen, setIsOpen] = useState(false);
  const [isCustomMenuOpen, setIsCustomMenuOpen] = useState(false);
  const [customSize, setCustomSize] = useState({
    width: 1920,
    height: 1080,
  });

  const openModal = () => setIsOpen(true);
  const closeModal = () => setIsOpen(false);

  const [selectedWidth, setSelectedWidth] = useState(null);
  const [selectedHeight, setSelectedHeight] = useState(null);

  const [selectedOption, setSelectedOption] = useState(
    isDesign
      ? {
          value: '1080x1920',
          label: 'Instagram Story 1080x1920',
          icon: faInstagram,
        }
      : {},
  );

  useClickAway(modalRef, () => {
    closeModal();
  });

  const onChange = (option) => {
    setSelectedOption(option);
    onStageSizeChange(option.value);
  };

  const { mutate } = useMutation({
    mutationFn: DesignServices.createDesign,
    mutationKey: ['designs'],
    onSuccess: ({ data }) => {
      navigate(`${getRoute('design')}?id=${data.id}`);
    },
  });

  const createDesign = (width, height) => {
    mutate({
      data: {
        type: 0,
        template: {
          stage: {
            width,
            height,
            background: '#fff',
          },
          design: [],
        },
      },
      token,
    });
  };

  const handleCustomSizeChange = (width, height) => {
    setCustomSize({ width, height });
    if (isDesign) {
      onChange({
        value: `${width}x${height}`,
        label: 'Custom Size',
        icon: faSlidersUp,
      });
      setSelectedOption({
        value: `${width}x${height}`,
        label: `Custom Size ${width}x${height}`,
        icon: faSlidersUp,
      });
    } else {
      onStageSizeChange(`${width}x${height}`);
      createDesign(width, height);
    }
  };

  useEffect(() => {
    if (!isDesign) return;
    const currentSize = isDesign
      ? `${stageSize.width}x${stageSize.height}`
      : '';
    const option = SCREEN_OPTIONS.find(
      (option) => option.value === currentSize,
    );

    if (option) {
      setSelectedOption(option);
    } else {
      setSelectedOption({
        value: `${stageSize.width}x${stageSize.height}`,
        label: `Custom Size ${stageSize.width}x${stageSize.height}`,
        icon: faSlidersUp,
      });
    }
  }, [stageSize]);

  return (
    <>
      <Wrapper>
        {isDesign ? (
          <StyledButton onClick={openModal} data-tip="Select Stage Size">
            <IconWrapper>
              <FontAwesomeIcon icon={faInstagram} color="white" size="xs" />
            </IconWrapper>
            <Caption1 style={{ margin: '0 6px' }}>
              {selectedOption?.label}
            </Caption1>
            <FontAwesomeIcon icon={faChevronDown} size="lg" />
          </StyledButton>
        ) : (
          <Button
            type="primary"
            size="small"
            content="CREATE A DESIGN"
            onClick={openModal}
            style={{ letterSpacing: '1px' }}
          />
        )}
      </Wrapper>
      <Modal isOpen={isOpen} ref={modalRef} isDesign={isDesign}>
        {SCREEN_OPTIONS.map((option, o) => (
          <RadioButton
            key={option.value}
            isChecked={option.value === selectedOption?.value}
          >
            <input
              type="radio"
              id={option.value}
              name="stage-size"
              value={option.value}
              checked={option.value === selectedOption?.value}
              onChange={() => {
                  if (option?.custom) {
                    setIsOpen(false);
                    setIsCustomMenuOpen(true);
                  } else if (isDesign) {
                      onChange(option);
                    } else {
                      const [width, height] = option.value.split('x');
                      setSelectedWidth(width);
                      setSelectedHeight(height);
                      createDesign(width, height);
                    }
                  closeModal();
                }}
            />
            <label htmlFor={option.value}>
              <IconWrapper>
                <FontAwesomeIcon icon={option.icon} color="white" size="xs" />
              </IconWrapper>
              <Caption1>
                {option.label === 'Custom Size'
                    ? `Custom Size ${customSize.width}x${customSize.height}`
                    : option.label}
              </Caption1>
            </label>
          </RadioButton>
          ))}
      </Modal>
      <CreateDesignMenu
        isOpen={isCustomMenuOpen}
        setIsOpen={setIsCustomMenuOpen}
        onClick={handleCustomSizeChange}
        width={selectedWidth}
        setWidth={setSelectedWidth}
        height={selectedHeight}
        setHeight={setSelectedHeight}
        left={0}
      />
    </>
  );
};

const Modal = styled.div`
  display: ${(props) => (props.isOpen ? 'block' : 'none')};
  padding: 6px;
  position: absolute;
  border-radius: 8px;
  border: 1px solid rgba(103, 94, 224, 0.2);
  background: #fafafa;
  box-shadow: 0px 4px 8px 0px rgba(91, 204, 124, 0.16);
  width: ${(props) => (props.isDesign ? '100%' : '194px')};
  height: 190px;
  top: ${(props) => (props.isDesign ? '45px' : '38px')};
  z-index: 99;
`;
const Wrapper = styled.div`
  position: relative;
`;
const StyledButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 8px;
  border: 1px solid rgba(103, 94, 224, 0.2);
  background: #fafafa;
  padding: 7px 14px;
  cursor: pointer;
  z-index: 10;
  min-width: 200px;
`;
const RadioButton = styled.div`
  display: flex;
  align-items: center;
  padding: 4px;
  accent-color: red;
  border-radius: 8px;

  :hover {
    background: #f0effc;
  }

  border: ${(props) => (props.isCecked ? '1px solid #675EE0' : '1px solid transparent')};
  background: ${(props) => (props.isChecked ? '#F0EFFC' : 'transparent')};

  input {
    -webkit-appearance: none;
    -moz-appearance: none;
    -ms-appearance: none;
    -o-appearance: none;
    appearance: none;
  }
  label {
    margin-left: 4px;
    letter-spacing: 0;
    display: flex;
    align-items: center;
    cursor: pointer;
    div {
      margin-right: 4px;
    }
  }
  margin-bottom: 4px;
`;
const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  width: 16px;
  height: 16px;
  background-color: black;
`;
export default Index;

import React, { useState } from 'react';
import styled from 'styled-components';
import { createSearchParams, useNavigate } from 'react-router-dom';
import { useMutation } from '@tanstack/react-query';
import { useAuth } from 'context/AuthContext';
import { OnboardingServices } from 'services';
import { Headline2, Headline4, Button } from 'components/Shared';

import { OnboardingInput } from 'components/Onboarding';
import {getRoute} from 'utils';

const UploadContentPage = () => {
  const [token] = useAuth((store) => store.token);
  const navigate = useNavigate();

  const [xmlUrl, setXmlUrl] = useState(null);

  const { mutate, isPending } = useMutation({
    mutationFn: (data) => OnboardingServices.uploadXML(data, token),
    onSuccess: (res) => {
      const selectedAd = JSON.parse(localStorage.getItem('selectedAd'));
      if (selectedAd && selectedAd?.data) {
        localStorage.setItem('xmlId', JSON.stringify(res.id));
        if (!selectedAd.data.predict) {
          setCompetitorOutputMutate({
            designId: selectedAd.data.designId,
            xmlId: res.id,
          });
        } else {
          setCompetitorOutputMutate({
            adUrl: selectedAd.data.url,
            xmlId: res.id,
          });
        }
      }
    },
  });

  const { mutate: setCompetitorOutputMutate } = useMutation({
    mutationFn: (data) => OnboardingServices.setCompetitorOutput(data, token),
    onSuccess: (res) => {
      navigate({
        pathname: getRoute('onboarding'),
        search: createSearchParams({
          step: 'results',
          uuid: res.data?.uuid,
        }).toString(),
      });
    },
  });

  const uploadXML = () => {
    mutate({
      url: xmlUrl,
    });
  };

  const onXmlUrlChange = (e) => {
    setXmlUrl(e.target.value);
  };

  return (
    <>
      <Headline2>It’s Time to Upload Your Content</Headline2>
      <Headline4
        style={{
          textAlign: 'center',
          margin: '30px 0',
        }}
      >
        Upload your product images
        <br />
        or simply paste XML product data for a faster experience.
      </Headline4>
      <UploadContentContainer>
        <OnboardingInput
          placeholder="https://www.yourstore.com/your_product_feed.xml"
          key="onboarding-input"
          value={xmlUrl}
          onChange={onXmlUrlChange}
        />
        <Button
          type="primary"
          content="Generate"
          size="medium"
          handleClick={uploadXML}
          loading={isPending}
          disabled={!xmlUrl}
        />
      </UploadContentContainer>
    </>
  );
};

export default UploadContentPage;

const UploadContentContainer = styled.div`
  width: 800px;
  height: 361px;
  border-radius: 16px;
  border: 3px solid white;
  background: #fafafa;
  margin-bottom: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 30px 52px 47px;

  button {
    width: 345px;
    height: 56px;
    margin-top: 30px;
  }
`;

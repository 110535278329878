import { client } from '../client';

const VideoServices = {
  getVideo: (id, token) => client.get(`video/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }),
  getVideoAll: (token) => client.get('video', {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }),

  addNewTemplate: ({ data, token }) => client.post('video', data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }),
  editTemplate: ({ data, token, id }) => client.put(`video/${id}`, data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }),
  createNewVideo: ({ data, token }) => client.post('video/replace-template', data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }),
  removeVideoTemplate: (id, token) => client.delete(`design/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }),
};

export default VideoServices;

import React, { useEffect } from 'react';
import styled from 'styled-components';

const RightModal = ({ isOpen, children }) => {
  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'unset';
    }
  }, [isOpen]);

  return <ModalContainer isOpen={isOpen}>{children}</ModalContainer>;
};

export default RightModal;

const ModalContainer = styled.div`
  background: rgba(0, 0, 0, 0.2);
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  right: ${(props) => (props.isOpen ? 0 : '-100vw')};
  display: flex;
  transition: all 0.3s ease-in-out;
  justify-content: flex-end;
  z-index: 999;
`;

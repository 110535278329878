import React from 'react';
import styled from 'styled-components';
import { useQuery } from '@tanstack/react-query';
import { getImageProps } from 'utils';
import { useApp } from 'context/AppContext';
import { useAuth } from 'context/AuthContext';
import { DesignServices } from 'services';
import { Body3, Subtitle3 } from 'components/Shared';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/pro-light-svg-icons';

const XMLElements = ({ edit }) => {
  const {
 addShape, shapes, stageSize, selectedShape, changeShape,
} = useApp();
  const [token] = useAuth((store) => store.token);
  const { data, isLoading } = useQuery({
    queryKey: ['getXMLElements'],
    queryFn: () => DesignServices.getXMLElements(token),
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
  });

  const isItemInStage = (name) => {
    if (edit) {
      if (selectedShape?.xmlType === name) {
        return true;
      }
        return false;
    }
    return shapes?.some((shape) => shape?.xmlType === name);
  };

  const textProps = {
    fontSize: 48,
    fontFamily: 'Lato',
    fill: '#000000',
    padding: 48 / 4,
    align: 'center',
    verticalAlign: 'center',
    width: 300,
    height: 'auto',
    name: 'object',
    draggable: true,
  };

  const addImageShape = (item) => {
    const image = new Image();
    image.src = item.value;
    image.onload = (e) => {
      const { width, height } = e.target;
      const imageProps = getImageProps({
        image: item.value,
        imageSize: { width, height },
        stageSize,
        xmlType: item.name,
      });
      addShape(imageProps);
    };
  };

  const handleClick = (item) => {
    if (edit) {
      if (isItemInStage(item.name)) {
        changeShape({
          xmlType: '',
        });
        return;
      }
      changeShape({
        xmlType: item.name,
      });
      return;
    }
    if (item.type === 'text') {
      addShape({
        type: 'TEXT',
        text: item.value,
        xmlType: item.name,
        ...textProps,
      });
    }
    if (item.type === 'image') {
      addImageShape(item);
    }
  };

  const isDisabled = (item) => {
    if (edit) {
      if (selectedShape.type !== item.type.toUpperCase()) {
        return true;
      }
    }
  };
  if (isLoading) return <p>Loading...</p>;
  return (
    <Wrapper>
      <Subtitle3 style={{ width: '100%', marginLeft: 10 }}>General</Subtitle3>
      {data?.data?.General.map((item) => (
        <Card
          key={`general-xml-elem-${item.name}`}
          onClick={() => handleClick(item)}
          inStage={isItemInStage(item.name)}
          hide={isDisabled(item)}
        >
          <Body3>{item.title}</Body3>
          {isItemInStage(item.name) && edit && (
          <FontAwesomeIcon icon={faCheck} color="#4bb543" size="lg" />
            )}
        </Card>
        ))}
      <Subtitle3 style={{ width: '100%', marginLeft: 10 }}>Google</Subtitle3>
      {data?.data?.Google.map((item) => (
        <Card
          key={`google-xml-elem-${item.name}`}
          onClick={() => handleClick(item)}
          inStage={isItemInStage(item.name)}
          hide={isDisabled(item)}
        >
          <Body3>{item.title}</Body3>
          {isItemInStage(item.name) && edit && (
          <FontAwesomeIcon icon={faCheck} color="#4bb543" size="lg" />
            )}
        </Card>
        ))}
      <Subtitle3 style={{ width: '100%', marginLeft: 10 }}>Custom</Subtitle3>
      {data?.data?.Custom.map((item) => (
        <Card
          key={`custom-xml-elem-${item.name}`}
          onClick={() => handleClick(item)}
          inStage={isItemInStage(item.name)}
          hide={isDisabled(item)}
        >
          <Body3>{item.title}</Body3>
          {isItemInStage(item.name) && edit && (
          <FontAwesomeIcon icon={faCheck} color="#4bb543" size="lg" />
            )}
        </Card>
        ))}
    </Wrapper>
  );
};
const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  overflow-y: scroll;
  align-items: center;
  justify-content: center;
  padding: 0px;
  flex-wrap: wrap;
  gap: 20px;
  width: 100%;
  max-height: calc(100vh - 100px);
`;
const Card = styled.button`
  width: 211px;
  height: 32px;
  border: none;
  padding-left: 20px;
  padding-right: 20px;
  text-align: left;
  border-radius: 8px;
  background: ${(props) => (props.inStage ? '#675ee01a' : '#fafafa')};
  border-radius: 8px;
  align-items: center;
  justify-content: space-between;
  margin: 0;
  display: ${(props) => (props.hide ? 'none' : 'flex')};

  cursor: pointer;
  :hover {
    background: #f2f4f8;
  }
  :disabled {
    background-color: #e0e0e0;
  }
`;

export default XMLElements;

import React from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Subtitle2 } from 'components/Shared';

import {
  faLinkedinIn,
  faTwitter,
  faYoutube,
} from '@fortawesome/free-brands-svg-icons';

const Footer = () => (
  <FooterWrapper>
    <FooterContent>
      <Subtitle2>©2023 Conversionline, Inc.</Subtitle2>
      <Social>
        <a href="https://www.youtube.com/@conversionline" target="_blank" rel="noreferrer">
          <FontAwesomeIcon icon={faYoutube} color="#000000" size="18px" />
        </a>
        <a
          href="https://www.linkedin.com/company/conversionlineio"
          target="_blank"
          rel="noreferrer"
        >
          <FontAwesomeIcon icon={faLinkedinIn} color="#000000" size="18px" />
        </a>
        <a
          href="https://twitter.com/conversionline"
          target="_blank"
          rel="noreferrer"
        >
          <FontAwesomeIcon icon={faTwitter} color="#000000" size="18px" />
        </a>
      </Social>
    </FooterContent>
  </FooterWrapper>
  );

export default Footer;

const FooterWrapper = styled.div`
  width: 100%;
  padding: 62px 130px;
  background: #f2f4f8;
  @media (max-width: 1024px) {
    padding: 28px 20px;
  }
`;
const FooterContent = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;
const Social = styled.div`
  display: flex;
  gap: 20px;
  @media (max-width: 1024px) {
    gap: 10px;
  }
`;

import React from 'react';
import styled, { keyframes } from 'styled-components';
import { useNavigate } from 'react-router';
import { useMutation } from '@tanstack/react-query';
import { DesignServices } from 'services';
import { useAuth } from 'context/AuthContext';

import { Subtitle3 } from 'components/Shared';
import {getRoute} from 'utils';

const ResultItem = ({ item }) => {
  const [token] = useAuth((store) => store.token);
  const navigate = useNavigate();

  const { mutate } = useMutation({
    mutationFn: DesignServices.createDesign,
    mutationKey: ['saveDesign'],
    onSuccess: ({ data }) => {
      navigate(`${getRoute('design')}?id=${data.id}`);
    },
  });

  return (
    <ResultItemContainer
      loading={item.path === 'https://cnvs.s3.eu-central-1.amazonaws.com/null'}
    >
      {item.path === 'https://cnvs.s3.eu-central-1.amazonaws.com/null' ? (
        <ImageLoading />
      ) : (
        <img src={item.path} alt="ads" />
      )}
      <EditButton
        onClick={() => mutate({
            data: {
              template: item.canvasObject,
              type: 0,
            },
            token,
          })}
      >
        <Subtitle3>Edit</Subtitle3>
      </EditButton>
    </ResultItemContainer>
  );
};

export default ResultItem;

const ResultItemContainer = styled.div`
  width: 176px;
  height: 240px;
  border-radius: 8px;
  border: 2px solid #fff;
  padding: 32px 8px 8px;
  transition: all 0.2s ease-in-out;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  img {
    border-radius: 8px;
    width: 100%;
    height: 100%;
    max-height: 180px;
    object-fit: contain;
  }

  :hover {
    transform: scale(1.1);
    box-shadow: 0 4px 4px 0 rgba(91, 204, 124, 0.16);
    button {
      display: ${(props) => (props.loading ? 'none' : 'flex')};
    }
    img {
      border-radius: 8px 8px 0 0 !important;
    }
  }
`;
const skeletonLoading = keyframes`
  100% {
    transform: translateX(100%);
  }
  `;
const ImageLoading = styled.div`
  display: inline-block;
  height: 100%;
  width: 100%;
  position: relative;
  overflow: hidden;
  background-color: #dddbdd;
  border-radius: 8px;

  &::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    transform: translateX(-100%);
    background-image: linear-gradient(
      90deg,
      rgba(255, 255, 255, 0) 0,
      rgba(255, 255, 255, 0.2) 20%,
      rgba(255, 255, 255, 0.5) 60%,
      rgba(255, 255, 255, 0)
    );
    animation: ${skeletonLoading} 5s infinite;
    content: "";
  }
`;
const EditButton = styled.button`
  margin-top: -5px;
  display: none;
  border: none;
  cursor: pointer;
  background-color: white;
  justify-content: center;
  border-radius: 0 0 8px 8px;
  padding: 3px 0;
  width: 100%;
`;

import React from 'react';
import styled from 'styled-components';

const AuthMainWrapper = ({ children }) => (
  <AuthWrapper>
    <VideoWrapper>
      <video autoPlay muted playsInline>
        <source
          src="https://cnvs.s3.eu-central-1.amazonaws.com/cdn/site-assets/Conversionline+-+Outrov2.mp4"
          type="video/mp4"
        />
      </video>
    </VideoWrapper>
    <AuthContainer>{children}</AuthContainer>
  </AuthWrapper>
  );

const AuthWrapper = styled.div`
  height: 100vh;
  display: flex;

  @media (max-width: 1024px) {
    height: 100dvh;
    flex-direction: column;
  }
`;
const AuthContainer = styled.div`
  min-height: 100vh;
  height: 100%;
  width: 100vw;
  gap: 0;
  margin-top: 0;
  @media (max-width: 1024px) {
    display: flex;
    flex-direction: column;
    gap: 0;
    margin-top: 0;
    min-height: unset;
  }
`;
const VideoWrapper = styled.div`
  grid-area: image;
  width: 100%;
  height: 100vh;
  position: relative;
  video {
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute;
    top: 0;
    left: 0;
  }
  @media (max-width: 1024px) {
    width: 100%;
    height: 300px;
    position: relative;
    min-height: 250px;
  }
`;
export default AuthMainWrapper;

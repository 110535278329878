import React from 'react';
import styled from 'styled-components';
import { useQuery } from '@tanstack/react-query';
import { DesignServices } from 'services';
import { useAuth } from 'context/AuthContext';
import { Subtitle3 } from 'components/Shared';

const BrandKit = () => {
  const [token] = useAuth((store) => store.token);
  const { data } = useQuery({
    queryKey: ['getBrandkit'],
    queryFn: () => DesignServices.getBrandKit(token),
    enabled: false,
    initialData: {
      logo: ['https://cf.keikei.co/cdn/banners/LOGO3.png'],
      colors: ['#000', '#e2e2e2', '#fafafa', '#675EE0'],
      fonts: [
        { family: 'Courier New', size: '32px' },
        { family: 'Arial', size: '24px' },
        { family: 'Impact', size: '12px' },
      ],
    },
  });
  return (
    <Container>
      <FeatureWraper>
        <Subtitle3>LOGOS</Subtitle3>
        <Row>
          {data.logo.map((logo, i) => (
            <LogoWrapper key={i}>
              <img src={logo} alt="logo" />
            </LogoWrapper>
            ))}
        </Row>
      </FeatureWraper>
      <FeatureWraper>
        <Subtitle3>COLORS</Subtitle3>
        <Row>
          {data.colors.map((color, i) => <Color background={color} key={i} />)}
        </Row>
      </FeatureWraper>
      <FeatureWraper>
        <Subtitle3>FONTS</Subtitle3>

        {data.fonts.map((font, i) => (
          <TypographyBox size={font.size} fontFamily={font.family}>
            <h4>Hello</h4>
            <span>{`${font.family},${font.size}`}</span>
          </TypographyBox>
          ))}
      </FeatureWraper>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  overflow-y: scroll;
  ::-webkit-scrollbar {
    display: none;
    width: 0 !important;
  }
`;

const FeatureWraper = styled.div``;

const Row = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  margin-top: 20px;
`;

const LogoWrapper = styled.div`
  width: 100px;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  img {
    max-width: 100%;
  }
`;

const Color = styled.div`
  background-color: ${({ background }) => background || '#e2e2e2'};
  width: 45px;
  height: 45px;
`;

const TypographyBox = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  background: #f2f4f8;
  width: 100%;
  padding: 7px 15px;
  margin-top: 10px;
  h4,
  span {
    font-family: ${({ fontFamily }) => fontFamily || 'Arial'};
    font-size: ${({ size }) => size || '16px'};
  }
`;

export default BrandKit;

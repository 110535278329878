import { client } from '../client';

const AuthServices = {
  register: async (data) => client.post('auth/email/register', data),
  login: async (data) => client.post('auth/email/login', data),
  refreshToken: async (data) => client.post('auth/token/refresh', data),
  forgotPassword: async (data) => client.post('auth/email/forgot-password', data),
  resetPassword: async (data) => client.post('auth/email/reset-password', data),
  me: (token) => client
          .get('user/me', {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          })
          .then((res) => res.data),
    editProfile: (token, data) => client
            .put('user', data, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            })
            .then((res) => res.data),
};

export default AuthServices;

import React, { useState } from 'react';
import styled, { keyframes } from 'styled-components';
import { createSearchParams, useNavigate } from 'react-router-dom';
import { useMutation, useQuery } from '@tanstack/react-query';
import { useCookies } from 'react-cookie';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { toast } from 'react-toastify';
import {
  faBriefcase,
  faDroplet,
  faMagnifyingGlassChart,
  faSparkles,
  faText,
  faWreathLaurel,
} from '@fortawesome/pro-light-svg-icons';
import { faSquareB } from '@fortawesome/pro-solid-svg-icons';
import { useAuth } from 'context/AuthContext';
import { OnboardingServices, AuthServices } from 'services';
import { Headline2, Headline3, Button } from 'components/Shared';
import { BrandListItem, OnboardingInput } from 'components/Onboarding';
import { getRoute } from 'utils';

const CreateAssetPage = () => {
  const navigate = useNavigate();

  const [companyUrl, setCompanyUrl] = useState('');
  const [continueButtonDisabled, setContinueButtonDisabled] = useState(true);
  // const [selectedCompetitor, setSelectedCompetitor] = useState(null);
  const [selectedIndustry, setSelectedIndustry] = useState(null);
  const [brandData, setBrandData] = useState({
    name: '',
    logo: '',
    fonts: '',
    industry: '',
    colors: [],
    competitor: [],
  });

  const [token, set] = useAuth((store) => store);
  const [cookies, setCookie] = useCookies(['userToken', 'refreshToken']);

  const changeStep = (stepName) => {
    navigate({
      pathname: getRoute('onboarding'),
      search: createSearchParams({
        step: stepName,
        // competitor: selectedCompetitor,
      }).toString(),
    });
  };

  const { data: companyIndustriesData } = useQuery({
    queryKey: ['getCompanyIndustries'],
    queryFn: () => OnboardingServices.getCompanyIndustries(token.token),
    onError: (error) => {
      toast.error(
        error?.message
          ? error.message
          : 'Something went wrong about company industries',
      );
    },
  });

  const { mutate, isPending } = useMutation({
    mutationFn: (data) => OnboardingServices.getCompanyMetaData(data, token.token),
    onSuccess: (res) => {
      const tmpData = {
        ...brandData,
        name: res.data.brandName,
        logo: res.data.logoImages[0],
        svg: res.data.svgLogos,
        fonts: res.data.fonts
          ? Object.keys(res.data.fonts).map((key) => res.data.fonts[key])
          : '',
        industry:
          Object.keys(companyIndustriesData).map((key) => ({
            label: companyIndustriesData[key],
            value: key,
          })) || '',
        colors:
          res.data.colors.length === 0
            ? ['#cecece', '#dde2', '#faafaa']
            : res.data.colors.length > 4
            ? res.data.colors.slice(0, 4)
            : res.data.colors,
        competitor: res.data.compatitor || [],
      };
      setBrandData(tmpData);
      setContinueButtonDisabled(false);
      // setSelectedCompetitor(tmpData.competitor[1].domain);
      setSelectedIndustry(res.data.industry);
    },
    onError: (error) => {
      if (error?.response?.data?.errors) {
        Object.keys(error.response.data.errors).map((key) => toast.error(error.response.data.errors[key]));
      } else {
        toast.error('Something went wrong, please try again later');
      }
    },
  });

  const { mutate: refreshTokenMutate } = useMutation({
    mutationFn: (data) => AuthServices.refreshToken(data),
    mutationKey: ['refreshToken'],
    onSuccess: ({ data }) => {
      set(data);
      setCookie('userToken', data.token);
      setCookie('refreshToken', data.refreshToken);
      changeStep('ads');
    },
  });

  const {
    mutate: setCompanyMetaDataMutation,
    isPending: isSetCompanyMetaDataLoading,
  } = useMutation({
    mutationFn: (data) => OnboardingServices.setCompanyMetaData(data, token.token),
    onSuccess: (res) => {
      refreshTokenMutate({
        refreshToken: cookies.refreshToken,
        // company: res.id,
      });
    },
    onError: (error) => {
      if (error?.response?.data?.errors) {
        Object.keys(error.response.data.errors).map((key) => toast.error(error.response.data.errors[key]));
      } else {
        toast.error('Something went wrong, please try again later');
      }
    },
  });

  const generate = () => {
    setBrandData({
      name: '',
      logo: '',
      fonts: '',
      industry: '',
      colors: [],
      competitor: [],
    });
    mutate({
      companyUrl,
    });
  };

  const saveMetaData = () => {
    const companyMetaData = {
      name: brandData.name,
      description: '',
      webSite: companyUrl,
    };
    companyMetaData.extra = JSON.parse(JSON.stringify(brandData));

    // if (selectedCompetitor) {
    //   companyMetaData.extra.competitor = selectedCompetitor;
    // }
    if (selectedIndustry) {
      companyMetaData.extra.industry = selectedIndustry;
    }
    if (
      companyMetaData.extra.industry === ''
      || typeof companyMetaData.extra.industry === 'object'
      || typeof companyMetaData.extra.industry === 'Array'
    ) {
      toast.error(
        'Brand industry field can not be empty. Please select an industry.',
      );
      return;
    }
    if (!companyMetaData.extra.logo) {
      toast.error(
        'Brand logo field can not be empty. Please drag and drop a logo or add a new one.',
      );
      return;
    }
    // if (
    //   companyMetaData.extra.competitor === ''
    //   || (typeof companyMetaData.extra.competitor === 'object'
    //     && companyMetaData.extra.competitor?.length >= 0)
    // ) {
    //   toast.error(
    //     'Brand competitor field can not be empty. Please select a competitor.',
    //   );
    //   return;
    // }

    setCompanyMetaDataMutation(companyMetaData);
  };

  return (
    <>
      <Headline2>Create Brand Assets</Headline2>
      <UrlWrapper>
        <Headline3 style={{ position: 'relative', marginLeft: '-29px' }}>
          Paste Your Url Here
          {' '}
          <Hand>👇</Hand>
        </Headline3>
        <InputWrapper>
          <OnboardingInput
            placeholder="https://www.conversionline.ai/"
            value={companyUrl}
            onChange={(e) => setCompanyUrl(e.target.value)}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                e.stopPropagation();
                generate();
              }
            }}
          />
        </InputWrapper>
        <Button
          type="primary"
          content="GENERATE"
          size="xlarge"
          loading={isPending}
          onClick={generate}
          disabled={!companyUrl}
        />
      </UrlWrapper>
      {brandData?.name !== '' && (
        <>
          <BrandListContainer>
            <BrandListHeader>
              <BrandLogoWrapper>
                <FontAwesomeIcon icon={faSquareB} />
              </BrandLogoWrapper>
              <Headline3>Brand List</Headline3>
            </BrandListHeader>
            <BrandListRow>
              <BrandListItem
                title="Brand Name"
                icon={faSparkles}
                inputType="text"
                data={brandData}
                setData={setBrandData}
              />
              <BrandListItem
                title="Brand Logo"
                icon={faWreathLaurel}
                inputType="image"
                data={brandData}
                setData={setBrandData}
              />
            </BrandListRow>
            <BrandListRow>
              <BrandListItem
                title="Brand Font"
                icon={faText}
                inputType="font"
                data={brandData}
                setData={setBrandData}
              />
              <BrandListItem
                title="Brand Industry"
                icon={faBriefcase}
                inputType="select"
                data={brandData}
                setData={setBrandData}
                selectedIndustry={selectedIndustry}
                setSelectedIndustry={setSelectedIndustry}
              />
            </BrandListRow>
            <BrandListRow>
              <BrandListItem
                title="Brand Color"
                icon={faDroplet}
                inputType="color"
                data={brandData}
                setData={setBrandData}
              />
              {/* <BrandListItem
                title="Competitor"
                icon={faMagnifyingGlassChart}
                inputType="select"
                data={brandData}
                setData={setBrandData}
                selectedCompetitor={selectedCompetitor}
                setSelectedCompetitor={setSelectedCompetitor}
              /> */}
            </BrandListRow>
          </BrandListContainer>
          <CreateAssetPageFooter>
            <Button
              type="primary"
              size="medium"
              content="Continue"
              handleClick={saveMetaData}
              disabled={continueButtonDisabled}
              loading={isSetCompanyMetaDataLoading}
            />
          </CreateAssetPageFooter>
        </>
      )}
    </>
  );
};

export default CreateAssetPage;

const moveDown = keyframes`
  0% {
    transform: translateY(0px);
  }
  50% {
    transform: translateY(10px);
  }
  100% {
    transform: translateY(0px);
  }
`;
const Hand = styled.span`
  position: absolute;
  animation-name: ${moveDown};
  animation-duration: 2s;
  transition-timing-function: ease;
  animation-iteration-count: 2;
`;
const UrlWrapper = styled.div`
  width: 345px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 30px;
`;
const InputWrapper = styled.div`
  margin: 30px 0;
`;
const BrandListContainer = styled.div`
  position: relative;
  margin-top: 41px;
  border-radius: 8px;
  border: 3px solid white;
  background: #fafafa;
  padding: 45px 14px;
  margin-bottom: 30px;

  div:last-child {
    margin-bottom: 0;
  }
`;
const CreateAssetPageFooter = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 1054px;
  margin-bottom: 210px;
`;
const BrandListRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 78px;
  margin-bottom: 14px;
`;
const BrandListHeader = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  gap: 13px;
  top: -34px;
`;
const BrandLogoWrapper = styled.div`
  width: 62px;
  height: 62px;
  border-radius: 8px;
  padding: 11px;
  background: white;
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    height: 100%;
    width: auto;
    color: #675ee0;
  }
`;

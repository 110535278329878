import React, { useEffect } from 'react';
import { useQuery } from '@tanstack/react-query';
import styled from 'styled-components';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { DesignServices } from 'services';
import { useApp } from 'context/AppContext';
import { useAuth } from 'context/AuthContext';

import 'react-lazy-load-image-component/src/effects/blur.css';

const Templates = ({ setTemplateCount }) => {
  const [token] = useAuth((store) => store.token);
  const { stageSize, setInitialDesign } = useApp();
  const size = `${stageSize?.width}x${stageSize?.height}`;
  const { data } = useQuery({
    queryKey: ['getTemplates', { size }],
    queryFn: () => DesignServices.getTemplates(token, size),
  });

  const onClick = (data) => {
    setInitialDesign(data);
  };

  useEffect(() => {
    setTemplateCount(data?.data?.length);
  }, [data, stageSize]);

  return (
    <Container>
      {data?.data.map((item) => (
        <button onClick={() => onClick(item)}>
          <LazyLoadImage
            src={item.previewPath}
            alt="template preview"
            effect="blur"
          />
        </button>
        ))}
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  align-items: space-between;
  justify-content: space-between;
  align-content: space-between;
  width: 100%;
  flex-wrap: wrap;
  overflow-y: scroll;
  ::-webkit-scrollbar {
    display: none;
    width: 0 !important;
  }

  button {
    width: 114px;
    height: auto;
    border: none;
    margin-top: 10px;
    align-self: flex-end;
    background-color: none;
    img {
      height: 100%;
      width: 100%;
    }
  }
`;

export default Templates;

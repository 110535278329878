import React from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/pro-light-svg-icons';

import { Caption1 } from 'components/Shared';

const SelectOption = ({ optionList, isSelected, onMultiSelect }) => (
  <OptionsContainer>
    {optionList.map((filter, index) => (
      <CheckBoxOption
        key={index}
        selected={isSelected(filter)}
        onClick={() => {
              onMultiSelect(filter);
              // handleAddFilter(filter);
            }}
      >
        <CheckBox selected={isSelected(filter)}>
          {isSelected(filter) && (
          <FontAwesomeIcon icon={faCheck} color="#675EE0" />
                )}
        </CheckBox>
        <Caption1>
          {filter.length > 16
                ? filter.substring(0, 16).concat('...')
                : filter}
        </Caption1>
      </CheckBoxOption>
        ))}
  </OptionsContainer>
  );

const OptionsContainer = styled.div`
  position: absolute;
  z-index: 4;
  top: 45px;
  width: 100%;
  max-height: 310px;
  overflow-y: auto;
  border-radius: 8px;
  border: 1px solid rgba(103, 94, 224, 0.2);
  background: #fafafa;
  box-shadow: 0 4px 8px 0 rgba(91, 204, 124, 0.16);
  padding: 10px 0;

  ::-webkit-scrollbar {
    width: 4px;
  }
  ::-webkit-scrollbar-track {
    margin: 5px 0;
    background: #fafafa;
    border-radius: 8px;
  }
  ::-webkit-scrollbar-thumb {
    background: #e5e5e5;
    border-radius: 8px;
  }
  ::-webkit-scrollbar-thumb:hover {
    background: #e5e5e5;
  }
`;
const CheckBoxOption = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 5px 10px;
  margin: 0 6px;
  min-height: 32px;
  border-radius: 8px;
  border: 1px solid #fafafa;
  background: #fafafa;
  overflow: hidden;
  text-overflow: ellipsis;
`;
const CheckBox = styled.div`
  width: 16px;
  height: 16px;
  border-radius: 4px;
  border: ${(props) => (props.selected ? '1px solid #675EE0' : '1px solid #7F7F7F')};
  background: #fafafa;
  display: flex;
  justify-content: center;
  align-items: center;
`;
export default SelectOption;

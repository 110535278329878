import React from 'react';
import styled from 'styled-components';
import { faAnglesLeft } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Headline4, Subtitle3 } from 'components/Shared';
import { SearchBar } from 'components/Design';

const SubMenuContainer = ({
  title,
  info,
  searchPlaceholder,
  onClick,
  searchQuery,
  setSearchQuery,
  children,
}) => {
  const onSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };
  return (
    <ContentWrapper>
      <Header>
        <Top>
          <Headline4>{title}</Headline4>
          <button onClick={onClick}>
            <FontAwesomeIcon
              icon={faAnglesLeft}
              size="xl"
              style={{ color: '#7F7F7F' }}
            />
          </button>
        </Top>
        {searchPlaceholder && (
          <SearchBar
            placeholder={searchPlaceholder}
            value={searchQuery}
            setValue={onSearchChange}
          />
        )}
        {info && (
          <Subtitle3
            style={{
              color: '#7F7F7F',
              marginTop: 20,
            }}
          >
            {info}
          </Subtitle3>
        )}
      </Header>
      {children}
    </ContentWrapper>
  );
};

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 11px 21px 11px 20px;
  width: 100%;
  width: 288px;
  background: #fff;
  box-shadow: 0px 8px 8px 0px rgba(0, 0, 0, 0.08);
`;
const Header = styled.div`
  width: 100%;
  margin-bottom: 20px;
  button {
    background: none;
    border: none;
    background-color: "transparent";
  }
`;
const Top = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
`;
export default SubMenuContainer;

export const sizes = {
  xs: '0.625rem', // 10px
  s: '0.75rem', // 12px
  m: '0.875rem', // 14px
  base: '1rem', // 16px
  l: '1.125rem', // 18px
  xl: '1.75rem', // 28px
  xxl: '3rem', // 48px
  largest: '3.75rem', // 60px
};
export const buttonTypes = {
  primary: {
    color: '#FFF',
    background: '#675EE0',
    border: 'none',
    borderRadius: '8px',
    hover: {
      background: '#443FE3',
      border: 'none',
      color: '#FFFEFF',
    },
    disabled: {
      background: '#7F7F7F',
      color: '#BEBEBE',
      border: 'none',
    },
  },
  secondary: {
    color: '#050000',
    background: 'white',
    border: '1px solid #050000',
    borderRadius: '8px',
    hover: {
      border: '1px solid #7F7F7F',
      color: '#050000',
    },
    disabled: {
      border: '1px solid #E4E4E4',
      color: '#E4E4E4',
      background: 'white',
    },
  },
  gradient: {
    color: 'white',
    background: 'linear-gradient(#F3367A, #7474D4)',
    border: 'none',
    borderRadius: '8px',
    hover: {
      color: 'white',
      border: 'none',
      background: 'linear-gradient(#F3367A, #7474D4)',
    },
    disabled: {
      background: '#7F7F7F',
      color: '#BEBEBE',
      border: 'none',
    },
  },
};
export const buttonSizes = {
  xsmall: {
    width: '24px',
    height: '24px',
    fontSize: sizes.xs,
    padding: '0',
    letterSpacing: '1px',
  },
  small: {
    width: 'auto',
    height: 'auto',
    fontSize: sizes.xs,
    padding: '8px 16px;',
    letterSpacing: '1px',
    lineHeight: '16px',
  },
  medium: {
    width: 'auto',
    height: 'auto',
    fontSize: sizes.m,
    padding: '12px 24px',
    letterSpacing: '2px',
  },
  large: {
    width: 'max-content',
    height: 'auto',
    fontSize: sizes.m,
    padding: '12px 24px',
    letterSpacing: '2px',
  },
  xlarge: {
    width: '100%',
    height: '56px',
    fontSize: sizes.m,
    padding: '17px 34px',
    letterSpacing: '2px',
    mobileWidth: '100%',
  },
  icon: {
    width: '24px',
    height: '24px',
    fontSize: sizes.m,
    letterSpacing: '2px',
    padding: '0px',
  },
};
export const fontWeights = {
  light: 300,
  regular: 400,
  medium: 500,
  bold: 700,
};

export const resizeStage = (shapes, stageSize, targetSize) => {
  const scaleX = targetSize.width / stageSize.width;
  const scaleY = targetSize.height / stageSize.height;

  const resized = shapes.map((shape) => {
    if (shape.type === 'TEXT') {
      const {
 fontSize, width, x, y,
} = shape;
      const scaleFactor = Math.min(scaleX, scaleY);

      const newFontSize = fontSize * scaleFactor;
      const newWidth = width * scaleX;

      return {
        ...shape,
        fontSize: newFontSize,
        width: newWidth,
        x: x * scaleX,
        y: y * scaleY,
      };
    }
      const {
 width, height, x, y,
} = shape;
      const targetAspectRatio = width / height;

      let newWidth = Math.min(width * scaleX, targetSize.width);
      let newHeight = newWidth / targetAspectRatio;

      if (newHeight * scaleY > targetSize.height) {
        newHeight = Math.min(height * scaleY, targetSize.height);
        newWidth = newHeight * targetAspectRatio;
      }

      const maxX = targetSize.width - newWidth;
      const maxY = targetSize.height - newHeight;
      const adjustedX = Math.min(maxX, Math.max(0, x * scaleX));
      const adjustedY = Math.min(maxY, Math.max(0, y * scaleY));

      return {
        ...shape,
        width: newWidth,
        height: newHeight,
        x: adjustedX,
        y: adjustedY,
      };
  });

  return resized;
};

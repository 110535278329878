import React from 'react';
import styled from 'styled-components';
import { Headline4, Headline5 } from 'components/Shared';
import { CommentIcon, ShareIcon, StatusbarIcon } from 'icons';
import { faEllipsis } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmarkLarge } from '@fortawesome/pro-light-svg-icons';
import {useAuth} from '../../../../context/AuthContext';

const Index = ({ base64 }) => {
  const [user] = useAuth((store) => store.user);

  return (
    <Container>
      <StatusBar>
        <Headline4 style={{color: 'white'}}>
          9:41
        </Headline4>
        <StatusbarIcon />
      </StatusBar>
      <StoryArea>
        <ProcessBar><Line /></ProcessBar>
        <Top>
          <ProfileContainer>
            <ProfilePicture />
            <BrandText>
              {user?.companyName || 'Brand Name'}
              <span>23h</span>
            </BrandText>
          </ProfileContainer>
          <IconWrapper>
            <FontAwesomeIcon icon={faEllipsis} color="white" />
            <FontAwesomeIcon icon={faXmarkLarge} color="white" />
          </IconWrapper>
        </Top>
        <img src={`data:image/jpeg;base64,${base64}`} alt="instagram-story" />
      </StoryArea>
      <Bottom>
        <SendMessageContainer>
          <SendMessageInput>
            <Headline5 style={{color: 'white', marginLeft: '17px'}}>
              Send message
            </Headline5>
          </SendMessageInput>
          <Icons>
            <CommentIcon />
            <ShareIcon />
          </Icons>
        </SendMessageContainer>
        <HomeIndicatorContainer>
          <HomeIndicator />
        </HomeIndicatorContainer>
      </Bottom>
    </Container>
  );
};

const Container = styled.div`
  width: 375px;
  height: 621px;
  background: #000;
  margin: 80px 0;
  display: flex;
  flex-direction: column;
`;
const StatusBar = styled.div`
  padding: 8px 14px 13px 21px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
const StoryArea = styled.div`
  flex: 1;
  position: relative;
  
  img {
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0.9;
  }
`;
const Bottom = styled.div`
  background-color: black;
  padding: 17px 23px 0;
`;
const SendMessageContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 28px;
`;
const SendMessageInput = styled.div`
  border-radius: 100px;
  border: 1px solid #666;
  width: 259px;
  height: 41px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;
const Icons = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 14px;
  margin-left: 14px;
`;
const HomeIndicatorContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  margin-bottom: 8px;
`;
const HomeIndicator = styled.div`
  border-radius: 100px;
  background: #FFF;
  width: 135px;
  height: 5px;
`;
const ProcessBar = styled.div`
  position: relative;
  z-index: 4;
  border-radius: 13px;
  background: rgba(223, 219, 216, 0.50);
  height: 2px;
  width: calc(100% - 16px);
  margin: 9px 8px;
`;
const Line = styled.div`
  position: absolute;
  border-radius: 13px;
  background: #FFF;
  left: 0;
  top: 0;
  width: 101px;
  height: 2px;
`;
const Top = styled.div`
  position: relative;
  z-index: 4;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-left: 12px;
`;
const ProfileContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;
const ProfilePicture = styled.div`
  width: 32px;
  height: 32px;
  border-radius: 50%;
  background: white;
`;
const BrandText = styled.div`
  color: white;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 11px; /* 91.667% */
  letter-spacing: -0.165px;
  margin-left: 12px;
  
  span {
    opacity: 0.5;
    margin-left: 12px;
  }
`;
const IconWrapper = styled.div`
  margin-right: 8px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 14px;
`;
export default Index;

import * as React from 'react';

const EyeIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={18}
    height={16}
    fill="none"
    {...props}
  >
    <g clipPath="url(#a)">
      <path
        fill={props.fill || '#7F7F7F'}
        d="M9 1C6.475 1 4.453 2.15 2.981 3.519 1.52 4.875.541 6.5.078 7.616a.994.994 0 0 0 0 .768c.463 1.116 1.44 2.741 2.903 4.097C4.453 13.85 6.475 15 9 15s4.547-1.15 6.019-2.519c1.462-1.36 2.44-2.981 2.906-4.097a.994.994 0 0 0 0-.768c-.466-1.116-1.444-2.741-2.906-4.097C13.547 2.15 11.525 1 9 1ZM4.5 8a4.5 4.5 0 1 1 9 0 4.5 4.5 0 0 1-9 0ZM9 6a2.002 2.002 0 0 1-2.634 1.897c-.172-.056-.372.05-.366.231A3.002 3.002 0 1 0 9.128 5c-.181-.006-.287.19-.231.366.066.2.103.412.103.634Z"
      />
    </g>
    <defs>
      <clipPath id="a">
        <path fill="#fff" d="M0 0h18v16H0z" />
      </clipPath>
    </defs>
  </svg>
);
export default EyeIcon;

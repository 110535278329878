import React from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEllipsis } from '@fortawesome/pro-regular-svg-icons';
import { Headline2 } from 'components/Shared';
import {
 BookmarkIcon, CommentIcon, LikeIcon, ShareIcon,
} from 'icons';
import {useAuth} from '../../../../context/AuthContext';

const Index = ({ base64 }) => {
    const [user] = useAuth((store) => store.user);

    return (
      <Container>
        <Top>
          <ProfilePicture />
          <Text>
            {user?.companyName || 'Brand Name'}
          </Text>
          <FontAwesomeIcon icon={faEllipsis} color="white" />
        </Top>
        <Content>
          <img src={`data:image/jpeg;base64,${base64}`} alt="instagram-post" />
        </Content>
        <Bottom>
          <Left>
            <LikeIcon />
            <CommentIcon />
            <ShareIcon />
          </Left>
          <Dots>
            <Dot active />
            <Dot />
            <Dot />
            <Dot />
          </Dots>
          <div>
            <BookmarkIcon />
          </div>
        </Bottom>
      </Container>
    );
};

const Container = styled.div`
  width: 360px;
  height: 492px;
  background: black;
  display: flex;
  flex-direction: column;
`;
const Top = styled.div`
  display: flex;
  align-items: center;
  padding: 6px 8px;
`;
const ProfilePicture = styled.div`
  width: 31.5px;
  height: 31.5px;
  border-radius: 50%;
  background: #fff;
`;
const Text = styled(Headline2)`
  color: #FFF;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 12px; /* 100% */
  letter-spacing: -0.165px;
  flex: 1;
  margin-left: 10px;
`;
const Content = styled.div`
  flex: 1;

  img {
    width: 100%;
    height: 100%;
  }
`;
const Bottom = styled.div`
  position: relative;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 12px 14px 12px 8px;
  background-color: black;
`;
const Left = styled.div`
  flex: 1;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 12px;
`;
const Dots = styled.div`
  position: absolute;
  width: 100%;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 4px;
`;
const Dot = styled.div`
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background: ${(props) => (props.active ? '#0098FD' : '#666')};
`;
export default Index;

function hexToRgb(hex) {
  const bigint = parseInt(hex.slice(1), 16);
  const r = (bigint >> 16) & 255;
  const g = (bigint >> 8) & 255;
  const b = bigint & 255;
  return { r, g, b };
}

function rgbToHex(r, g, b) {
  return `#${((r << 16) | (g << 8) | b).toString(16).padStart(6, '0')}`;
}

function adjustColorIntensity(intensity, color) {
  if (intensity < 1) intensity = 1;
  if (intensity > 100) intensity = 100;

  const startRGB = hexToRgb(color);
  const targetRGB = hexToRgb('#FFFFFF');

  const stepR = (targetRGB.r - startRGB.r) / 100;
  const stepG = (targetRGB.g - startRGB.g) / 100;
  const stepB = (targetRGB.b - startRGB.b) / 100;

  const newR = Math.round(startRGB.r + stepR * intensity);
  const newG = Math.round(startRGB.g + stepG * intensity);
  const newB = Math.round(startRGB.b + stepB * intensity);

  const newColor = rgbToHex(newR, newG, newB);
  return newColor;
}
function getNeonColor(baseColor) {
  const baseRGB = hexToRgb(baseColor);
  const neonR = Math.min(baseRGB.r + 50, 255);
  const neonG = Math.min(baseRGB.g + 50, 255);
  const neonB = Math.min(baseRGB.b + 50, 255);

  const neonColor = rgbToHex(neonR, neonG, neonB);
  return neonColor;
}

function getNeonEffectProperties(intensity, color, effect) {
  const neonColor = getNeonColor(color);

  return {
    shadowColor: neonColor,
    shadowBlur: 30,
    shadowOpacity: 1,
    stroke: adjustColorIntensity(intensity - 20, color),
    strokeWidth: 2,
    fill: adjustColorIntensity(intensity, color),
    baseColor: color,
    appliedEffect: effect,
  };
}

export default getNeonEffectProperties;

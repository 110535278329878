import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { faUpload } from '@fortawesome/pro-solid-svg-icons';
import getImageProps from 'utils/Design/calculateImageProps';
import { useAuth } from 'context/AuthContext';
import { useApp } from 'context/AppContext';
import { DesignServices } from 'services';
import { Button } from 'components/Shared';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

const ImageUpload = () => {
  const queryClient = useQueryClient();
  const [token] = useAuth((store) => store.token);
  const { data } = useQuery({
    queryKey: ['getUploads'],
    queryFn: () => DesignServices.getUploads(token),
  });

  const mutation = useMutation({
    mutationKey: ['uploadImage'],
    mutationFn: (data) => DesignServices.imageUpload(data, token),
    onSuccess: (_data, _error) => {
      queryClient.invalidateQueries(['getUploads']);
    },
  });
  const { addShape, stageSize } = useApp();
  const [images, setImages] = useState([]);
  const [selectedImage, setSelectedImage] = useState(null);
  const selectedImgRef = useRef();

  const handleImageChange = (e, name) => new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = async (e) => {
        resolve(e.target.result);
        setImages([
          {
            url: reader.result,
            name,
            order: 0,
          },
          ...images,
        ]);
        mutation.mutate({
          base64: e.target.result,
          mimeType: 'image/jpeg',
          fileName: name,
          orderColumn: 1,
        });
      };
      reader.readAsDataURL(e.target.files[0]);

      let img;
      img = document.createElement('img');

      img.onload = function () {};
      img.src = URL.createObjectURL(e.target.files[0]);
    });

  useEffect(() => {
    if (data?.data) {
      setImages(data.data);
    }
  }, [data]);
  return (
    <ImageContentWrapper>
      <ImageUploadInput type="primary" size="xlarge">
        <FontAwesomeIcon icon={faUpload} size="xl" />
        <label forHtml="image-upload">Upload</label>
        <input
          type="file"
          id="image-upload"
          name="image-upload"
          onChange={(e) => handleImageChange(e, e.target.files[0].name)}
          multiple
        />
      </ImageUploadInput>
      <Images>
        {images?.map((image, i) => (
          <LazyLoadImage
            key={image.fileName}
            src={image.path}
            alt="template preview"
            effect="blur"
            style={{
                width: '75px',
                height: '110px',
                marginTop: '20px',
                objectFit: 'contain',
              }}
            onClick={() => {
                setSelectedImage(image);
              }}
          />
          ))}
        {/* copy selected image for can get real dimensions */}
        <SecretImg
          ref={selectedImgRef}
          key={`gokce-${selectedImage?.fileName}`}
          src={selectedImage?.path}
          alt={selectedImage?.fileName}
          onLoad={(e) => {
            const { width, height } = e.target;
            const imageProps = getImageProps({
              image: selectedImage.path,
              imageSize: { width, height },
              stageSize,
            });

            addShape(imageProps);
            setSelectedImage(null);
          }}
          onClick={(e) => {
            const { width, height } = e.target;
            const imageProps = getImageProps({
              image: selectedImage.path,
              imageSize: { width, height },
              stageSize,
            });
            addShape(imageProps);
          }}
        />
      </Images>
    </ImageContentWrapper>
  );
};

const ImageContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
  max-width: 100%;
  ::-webkit-scrollbar {
    display: none;
    width: 0 !important;
  }
`;

const ImageUploadInput = styled(Button)`
  cursor: pointer;
  position: relative;
  :hover {
    opacity: 0.8;
  }

  label {
    margin-left: 10px;
  }

  input {
    opacity: 0;
    position: absolute;
    width: 100%;
    height: 100%;
  }
`;

const Images = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top: 20px;
  width: 100%;
`;

const SecretImg = styled.img`
  opacity: 0;
`;

export default ImageUpload;

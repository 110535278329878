import React, { useState } from 'react';
import styled from 'styled-components';
import { useMutation } from '@tanstack/react-query';
import { useForm } from 'react-hook-form';
import {toast} from 'react-toastify';
import { waitlistSchema } from 'lib/validationSchemas';
import { yupResolver } from '@hookform/resolvers/yup';
import { CommonServices } from 'services';
import { Select } from 'components/Landing';
import { Headline3, Subtitle2 } from 'components/Shared';

const Waitlist = () => {
  const [selectedValue, setSelectedValue] = useState('');

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(waitlistSchema),
  });

  const notify = () => toast.success('Success!');

  const handleSelectChange = (selectedOption) => {
    setSelectedValue(selectedOption);
  };

  const { mutate } = useMutation({
    mutationFn: CommonServices.joinWaitlist,
    mutationKey: ['waitlist'],
    onSuccess: ({ data }) => {
      notify();
    },
    onError: (error) => {
      if (error?.response?.data?.errors) {
        Object.keys(error.response.data.errors).map((key) => toast.error(error.response.data.errors[key]));
      } else {
        toast.error('Something went wrong, please try again later');
      }
    },
  });
  const formSubmit = (data) => {
    mutate({ email: data.email, usageReason: selectedValue });
  };

  return (
    <WaitlistWrapper id="waitlist">
      <div style={{ maxWidth: '288px' }}>
        <Headline3 style={{ color: 'white', textAlign: 'center' }}>
          Join the Waitlist
        </Headline3>
        <Subtitle2
          style={{ color: 'white', textAlign: 'center', marginTop: '40px' }}
        >
          Sign up to secure your spot and stay in the loop. We can’t wait to
          have you on board!
        </Subtitle2>
      </div>

      <FormWrapper onSubmit={handleSubmit(formSubmit)}>
        <input
          placeholder="Your Work E-mail"
          name="email"
          error={errors.email?.message}
          {...register('email')}
          style={{
            border: errors.email?.message
              ? '1px solid red'
              : '1px solid dddbf5',
          }}
        />
        {errors.email?.message && (
          <span style={{ color: 'red' }}>{errors.email?.message}</span>
        )}

        <Select onSelectChange={handleSelectChange} />

        <Button type="submit">Request Early Access</Button>
      </FormWrapper>
    </WaitlistWrapper>
  );
};
const Button = styled.button`
  height: 56px;
  border-radius: 8px;
  background: #ffffff;
  outline: none;
  border: none;
  padding: 12px 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  cursor: pointer;
  font-family: "Lato", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: ${({ theme }) => theme.sizes.m};
  line-height: 26px;
  letter-spacing: 1px;
  color: #000000;
  text-transform: uppercase;
  transition: all 0.2s ease-in-out;
  &:hover {
    background: rgba(#f3367a, 0.6);
  }
`;

const WaitlistWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: 867px;
  height: 518px;
  border-radius: 16px;
  background: linear-gradient(90deg, #675ee0 0%, #5acb7a 100%);
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.1);
  margin-top: 30px;
  background-size: 150% 150%;
  animation: gradientBg 10s ease infinite;
  @media (max-width: 1024px) {
    flex-direction: column;
    height: unset;
    padding: 30px 0 60px 0;
    gap: 30px;
    width: 100%;
  }
`;
const FormWrapper = styled.form`
  display: flex;
  flex-direction: column;
  gap: 30px;
  position: relative;
  span {
    color: #ff2100;
    position: absolute;
    top: 61px;
    left: 20px;
    font-family: "Lato", sans-serif;
    font-weight: 400;
    line-height: 18px;
    font-size: 12px;
    text-transform: capitalize;
  }
  @media (max-width: 1024px) {
    width: 100%;
    padding: 0 20px;
    input,
    div {
      width: 100%;
      div {
        width: 100%;
        div {
          width: 8px;
        }
      }
    }
    span {
      left: 40px;
    }
  }
  input {
    font-family: "Lato", sans-serif;
    font-weight: 400;
    line-height: 22px;
    font-size: 14px;
    width: 345px;
    height: 56px;
    background: #fafafa;
    border: ${({ error }) => (error ? '1px solid #FF2100' : '1px solid #dddbf5')};
    padding: 17px 20px;
    font-size: 16px;
    border-radius: 8px;
    outline: none;
    transition: all 0.2s ease-in-out;
    :-webkit-autofill {
      -webkit-box-shadow: 0 0 0 30px #e4e4e4 inset !important;
    }
    ::placeholder {
      font-family: "Lato", sans-serif;
      font-style: normal;
      font-weight: 500;
      font-size: ${({ theme }) => theme.sizes.m};
      line-height: 30px;
      color: #000000;
    }
    @media (max-width: 1024px) {
      width: 100%;
    }
  }
`;
export default Waitlist;

import React, { useState } from 'react';
import styled from 'styled-components';
import Marquee from 'react-fast-marquee';
import { Helmet } from 'react-helmet';
import {
  Header,
  ButtonGradient,
  Footer,
  Headline1,
  Headline2,
  Headline3,
  Headline4,
} from 'components/Shared';
import {
  Masonry,
  AdsJackpot,
  FeatureCard,
  VideoModal,
} from 'components/Landing';
import { Waitlist } from 'components/Auth';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { thin } from '@fortawesome/fontawesome-svg-core/import.macro';

const CUSTORMERS = [
  {
    image:
      'https://cnvs.s3.eu-central-1.amazonaws.com/cdn/banner/slide-logos.png',
  },
  {
    image:
      'https://cnvs.s3.eu-central-1.amazonaws.com/cdn/banner/slide-logos.png',
  },
  {
    image:
      'https://cnvs.s3.eu-central-1.amazonaws.com/cdn/banner/slide-logos.png',
  },
];
const FEATURE_LIST = [
  {
    title: 'Feed Management',
    description:
      'Handpick the product groups you want to target  with Feed Optimization.',
    features: ['Maximize XML Control', 'Optimize your Ad Feed for Higher Conversion', 'Create Templates Effortlessly to Specific Brands or Product Categories'],
    icon: {
      width: '33px',
      height: '18px',
      colored:
        'https://cnvs.s3.eu-central-1.amazonaws.com/cdn/site-assets/gear-purple.png',
      uncolored:
        'https://cnvs.s3.eu-central-1.amazonaws.com/cdn/site-assets/gear-white.png',
    },
  },
  {
    title: 'AI Recommendations',
    description:
      'Get closer to success with data-backed recommendations, personalized for your brand.',
    features: ['Create AI Ad Templates', 'Gain Data - Backed Insights', 'Edit Designs in Real-Time'],
    icon: {
      width: '31px',
      height: '30px',
      colored:
        'https://cnvs.s3.eu-central-1.amazonaws.com/cdn/banner/star-colored.png',
      uncolored:
        'https://cnvs.s3.eu-central-1.amazonaws.com/cdn/icons/on+boarding%3Ahome/star.png',
    },
  },
  {
    title: 'Competitive Analysis',
    description: 'Improve your ads with valuable market knowledge.',
    features: ['See Market Trends', 'Discover Winning Strategies', 'Iterate with AI Insights'],
    icon: {
      width: '26px',
      height: '26px',
      colored:
        'https://cnvs.s3.eu-central-1.amazonaws.com/cdn/banner/graph-colored.png',
      uncolored:
        'https://cnvs.s3.eu-central-1.amazonaws.com/cdn/icons/on+boarding%3Ahome/graph.png',
    },
  },
];
const Landing = () => {
  const [showVideo, setShowVideo] = useState(false);
  const toggleVideo = () => setShowVideo(!showVideo);
  return (
    <>
      <Helmet>
        <title>Conversionline | Homepage</title>
        <meta
          name="description"
          content="Conversionline is a data-driven ad platform using AI to craft personalized ad templates(Image/Video) from your product data and competitor insights. Its key feature: instantly apply templates to thousands of your products, with editable results."
        />
      </Helmet>
      <Header />
      <Page1>
        <Content className="fadeOut">
          <Headline1>
            Effortlessly Create Thousands of
            {' '}
            <strong>Ad Creatives</strong>
            {' '}
            That
            Convert
          </Headline1>
          <Headline4
            style={{
              maxWidth: '529px',
              marginTop: '30px',
            }}
          >
            Create limitless AD Creatives without the hassle of manual design.
            Work Smart. Get AI Insights. Grow revenue.
          </Headline4>
          <ButtonGradient content="Get Started" href="#waitlist" />
          <AdsJackpot className="fadeOut" />
        </Content>
      </Page1>
      <Page2>
        <Headline3>
          Generate Creatives for Multiple Platforms in One Place
        </Headline3>
        <CustomerBar>
          <Marquee speed={100}>
            <MarqueeContent>
              {CUSTORMERS.map((customer) => (
                <img src={customer.image} alt="customer-images" />
              ))}
            </MarqueeContent>
          </Marquee>
        </CustomerBar>
        <FeatureWrapper>
          {FEATURE_LIST.map((feature) => <FeatureCard feature={feature} />)}
        </FeatureWrapper>
      </Page2>
      <Page3>
        <HideDesktop>
          <ButtonGradient content="TRY FOR FREE" href="#waitlist" />
        </HideDesktop>
        <DemoImageWrapper>
          {' '}
          <img src="https://cnvs.s3.eu-central-1.amazonaws.com/cdn/site-assets/demo-image.png" alt="demo" />
        </DemoImageWrapper>
        <VideoSectionContent>
          <Headline2>
            Transform
            {' '}
            <strong>XML Product Data</strong>
            {' '}
            into Stunning Ads. Fast.
          </Headline2>
          <Headline4>
            Choose specific product groups to create personalized ads with Feed Optimization,
            ensuring your marketing is just right for your audience.

          </Headline4>
          <HideMobile>
            <ButtonGradient content="TRY FOR FREE" href="#waitlist" />
          </HideMobile>
        </VideoSectionContent>
      </Page3>
      <Page4>
        <Headline2 style={{ zIndex: 9 }}>
          Design Powerhouse, but for Your Ads.
        </Headline2>
        <FeatureSectionContent>
          <ImageWrapper>
            <img src="https://cnvs.s3.eu-central-1.amazonaws.com/cdn/site-assets/video-thumb.png" alt="video-thumb" />
            <PlayButton onClick={toggleVideo}>
              <FontAwesomeIcon icon={thin('play')} size="xl" color="#fff" />
            </PlayButton>
          </ImageWrapper>
        </FeatureSectionContent>
        <VideoModal isModalOpen={showVideo} onModalClose={toggleVideo} />
      </Page4>
      <Page5>
        <Headline2>Simplify Advertising Workflow</Headline2>
        <Waitlist />
      </Page5>
      <Masonry />
      <Footer />
    </>
  );
};

const Page1 = styled.section`
  background-color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  padding: 150px 0 20px 0;
  @media (max-width: 1024px) {
    padding: 0 20px;
    padding-top: 100px;
  }
`;

const Page2 = styled.section`
  background: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 60px 0;
  text-align: center;
  @media (max-width: 1024px) {
    padding: 0 20px;
    h3 {
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 28px;
    }
  }
`;
const Page3 = styled.section`
  background: #f2f4f8;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  padding: 200px 0;
  height: 100vh;
  text-align: left;
  @media (max-width: 1024px) {
    flex-direction: column-reverse;
    padding: 30px 20px;
    justify-content: unset;
    height: max-content;
  }
`;

const Page4 = styled.section`
  padding-top: 60px;
  padding-bottom: 90px;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  overflow: hidden;
  @media (max-width: 1024px) {
    padding: 20px;
    text-align: center;
    h1 {
      font-size: 22px;
      font-style: normal;
      font-weight: 500;
      line-height: 34px;
    }
    img {
      width: 100% !important;
    }
  }
`;
const Page5 = styled.section`
  background: #f2f4f8;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 60px;
  padding-bottom: 60px;
  height: 100vh;
  @media (max-width: 1024px) {
    height: max-content;
    text-align: center;
    padding: 30px 20px;
    h1 {
      font-size: 22px;
      font-style: normal;
      font-weight: 500;
      line-height: 34px;
      max-width: 220px;
    }
  }
`;
const CustomerBar = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 83%;
  overflow-x: hidden;
  margin: 40px 0;
  position: relative;
  height: 30px;
  img {
    height: 30px;
    object-fit: cover;
    margin-right: 50px;
    display: inline-block;
  }
  @media (max-width: 1024px) {
    margin: 30px;
    img {
      height: 22px;
    }
  }
`;

const MarqueeContent = styled.div`
  display: flex;
  align-items: center;
`;
const FeatureWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-top: 70px;
  margin-bottom: 60px;
  gap: 30px;

  @media (max-width: 1024px) {
    justify-content: center;
    margin-top: 0;
  }
`;
const DemoImageWrapper = styled.div`
  width: 36.11vw;
  height: 36.11vw;
  margin-top: 40px;
  img {
    width: 100%;
    object-fit: cover;
  }
  @media (max-width: 1024px) {
    width: 100%;
    height: 100%;
    margin-top: 0 !important;
  }
`;
const VideoSectionContent = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 720px;
  text-align: left;
  position: relative;
  h1 {
    strong {
      font-weight: 400;
      color: #5bcc7c;
    }
  }
  h4 {
    margin-top: 40px;
  }
  @media (max-width: 1024px) {
    align-items: center;
    text-align: center;
    h1 {
      font-size: 22px;
      font-style: normal;
      font-weight: 500;
      line-height: 34px;
    }
    h4 {
      margin-top: 20px;
      margin-bottom: 20px;
    }
  }
`;
const FeatureSectionContent = styled.div`
  display: flex;
  margin-top: 60px;
  justify-content: center;
  gap: 15px;
  @media (max-width: 1024px) {
    flex-direction: column;
    margin-top: 30px;
    gap: 0;
  }
`;
const ImageWrapper = styled.div`
  width: 840px;
  position: relative;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  &::before {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.3);
  }
  @media (max-width: 1024px) {
    width: 100%;
  }
`;
const PlayButton = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #675ee0;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  :hover {
    transform: scale(1.1) translate(-50%, -50%);
  }
`;
const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 870px;
  text-align: center;
  position: relative;
  h1 {
    strong {
      font-weight: 400;
      color: #5bcc7c;
    }
  }
  @media (max-width: 1024px) {
    h1 {
      font-size: 22px;
      line-height: 34px;
    }
    h4 {
      margin-top: 20px;
    }
  }
`;
const HideDesktop = styled.div`
  @media (min-width: 1024px) {
    display: none;
    width: 0;
    z-index: -1;
  }
`;
const HideMobile = styled.div`
  @media (max-width: 1024px) {
    display: none;
    width: 0;
    z-index: -1;
  }
`;
export default Landing;

import React, { useState, useRef, useEffect } from 'react';
import styled from 'styled-components';
import { useQuery } from '@tanstack/react-query';
import { CommonServices } from 'services';
import { Subtitle2 } from 'components/Shared';

const Select = ({ onSelectChange }) => {
  const [selectedOption, setSelectedOption] = useState('');
  const [isOptionsOpen, setIsOptionsOpen] = useState(false);
  const selectRef = useRef(null);

  const { data, isLoading, error } = useQuery({
    queryKey: ['getUsageReason'],
    queryFn: () => CommonServices.getUsageReason(),
  });
  const handleOptionChange = (event) => {
    setSelectedOption(event.target.value);
  };

  const toggleOptions = () => {
    setIsOptionsOpen(!isOptionsOpen);
  };

  useEffect(() => {
    const handleDocumentClick = (event) => {
      if (selectRef.current && !selectRef.current.contains(event.target)) {
        setIsOptionsOpen(false);
      }
    };

    document.addEventListener('click', handleDocumentClick);

    return () => {
      document.removeEventListener('click', handleDocumentClick);
    };
  }, []);

  useEffect(() => {
    // Notify the parent component when the selected value changes
    onSelectChange(selectedOption);
  }, [selectedOption, onSelectChange]);

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Something went wrong...</div>;
  }
  return (
    <SelectWrapper>
      <HiddenSelect value={selectedOption} onChange={handleOptionChange}>
        <option value="" disabled>
          Select an option
        </option>
        {Object.keys(data.data).map((key) => (
          <option value={data.data[key]}>
            <Subtitle2 key={key}>{data.data[key]}</Subtitle2>
          </option>
        ))}
      </HiddenSelect>
      <StyledSelect
        ref={selectRef}
        onClick={toggleOptions}
        onBlur={() => setIsOptionsOpen(false)}
        showOptions={isOptionsOpen}
      >
        {selectedOption
          ? selectedOption.replace(/([A-Z])/g, ' $1').trim()
          : 'Select an option'}
        <ArrowIcon showOptions={isOptionsOpen} />
      </StyledSelect>
      {isOptionsOpen && (
        <OptionsList>
          {Object.keys(data.data).map((key) => (
            <Option onClick={() => setSelectedOption(data.data[key])}>
              <Subtitle2
                key={key}
                style={{ letterSpacing: '0px', fontSize: '14px' }}
              >
                {data.data[key]}
              </Subtitle2>
            </Option>
          ))}
        </OptionsList>
      )}
    </SelectWrapper>
  );
};

const SelectWrapper = styled.div`
  position: relative;
`;

const HiddenSelect = styled.select`
  display: none;
`;

const StyledSelect = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 345px;
  height: 56px;
  background: #fafafa;
  border: 1px solid #dddbf5;
  padding: 16px;
  font-size: 14px;
  cursor: pointer;
  text-transform: capitalize;
  border-radius: ${({ showOptions }) => (showOptions ? '8px 8px 0 0' : '8px')};
  border-bottom: ${({ showOptions }) => showOptions && 'none'};
`;

const ArrowIcon = styled.div`
  border: solid #000;
  border-width: 0 2px 2px 0;
  display: inline-block;
  padding: 3px;
  transform: ${({ showOptions }) => (showOptions ? 'rotate(-135deg)' : 'rotate(45deg)')};
  transition: transform 0.2s ease-in-out;
`;

const OptionsList = styled.ul`
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  max-height: 200px;
  overflow-y: auto;
  background: #fafafa;
  border: 1px solid #dddbf5;
  padding: 8px 0;
  list-style-type: none;
  z-index: 1;
  border-radius: 0 0 8px 8px;
`;

const Option = styled.li`
  padding: 8px 16px;
  cursor: pointer;

  &:hover {
    background: #f2f2f2;
  }
`;

export default Select;

import React, {useState} from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router';
import { useMutation } from '@tanstack/react-query';
import { useAuth } from 'context/AuthContext';
import { DesignServices } from 'services';
import { Headline4 } from 'components/Shared';
import { CreateDesignMenu } from 'components/Dashboard';
import {getRoute} from 'utils';

const CreateProjectItem = ({
  title,
  img,
  align,
  size = {
    width: 1080,
    height: 1920,
  },
    custom,
}) => {
  const [token] = useAuth((store) => store.token);
  const navigate = useNavigate();

  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const [width, setWidth] = useState(null);
  const [height, setHeight] = useState(null);

  const { mutate } = useMutation({
    mutationFn: DesignServices.createDesign,
    mutationKey: ['designs'],
    onSuccess: ({ data }) => {
      navigate(`${getRoute('design')}?id=${data.id}`);
    },
  });

  const onClick = () => {
    if (custom) {
      setIsMenuOpen(true);
    } else {
      mutate({
        data: {
          type: 0,
          template: {
            stage: {
              width: size.width,
              height: size.height,
              background: '#fff',
            },
            design: [],
          },
        },
        token,
      });
    }
  };

  return (
    <CreateProjectCard onClick={onClick}>
      <CardImage align={align}>{img}</CardImage>
      <Headline4>{title}</Headline4>
      {custom
          && (
          <CreateDesignMenu
            isOpen={isMenuOpen}
            setIsOpen={setIsMenuOpen}
            left="0"
            height={height}
            setHeight={setHeight}
            width={width}
            setWidth={setWidth}
            onClick={() => {
                mutate({
                  data: {
                    type: 0,
                    template: {
                      stage: {
                        width,
                        height,
                        background: '#fff',
                      },
                      design: [],
                    },
                  },
                  token,
                });
              }}
          />
) }
    </CreateProjectCard>
  );
};

const CreateProjectCard = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  max-width: 160px;
  flex-basis: 16.66%;
`;
const CardImage = styled.div`
  border-radius: 8px;
  border: 2px solid #fff;
  background: #fafafa;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: ${(props) => (props.align === 'end' ? 'flex-end' : 'center')};
  padding: ${(props) => (props.align === 'end' ? '10px 0 0' : '10px')};
  width: 100%;
  height: 120px;
  margin-bottom: 10px;
  transition: all 0.2s ease-in-out;

  img {
    height: 100%;
  }

  :hover {
    transform: scale(1.1);
    box-shadow: 0 4px 4px 0 rgba(91, 204, 124, 0.16);
  }
`;
export default CreateProjectItem;

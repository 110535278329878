import React from 'react';

const Index = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill="none"
    {...props}
  >
    <path
      fill="#fff"
      d="M19.423 2.43H4.536c-.585 0-1.064.43-1.064.963v18.664c0 .532.346.668.776.295l7.3-6.305a.505.505 0 0 1 .623-.005l7.534 6.325c.43.363.781.222.781-.31V3.393c0-.532-.473-.963-1.063-.963Zm-.532 17.283-5.641-4.736a2.156 2.156 0 0 0-1.388-.494c-.51 0-1.015.17-1.409.508l-5.386 4.654V3.882h13.824v15.83Z"
    />
    <path
      fill="#fff"
      fillRule="evenodd"
      d="M3.125 3.385c0-.754.665-1.301 1.397-1.301h14.914c.74 0 1.397.548 1.397 1.3v18.684c0 .319-.107.672-.44.804-.314.125-.64-.028-.886-.235l-7.545-6.33c-.05-.039-.153-.038-.2.002l-7.313 6.31c-.242.21-.567.372-.883.251-.337-.13-.441-.488-.441-.802V3.385Zm1.397-.629c-.44 0-.734.316-.734.629v18.683c0 .088.013.14.023.168a.618.618 0 0 0 .208-.127l7.314-6.313.001-.001a.834.834 0 0 1 1.046-.008l.002.001 7.549 6.332a.6.6 0 0 0 .212.123.438.438 0 0 0 .027-.175V3.385c0-.314-.29-.629-.734-.629H4.522Zm15.637 19.488ZM3.79 22.24h.001Zm.933-18.7h14.512v16.897l-6.194-5.196a1.833 1.833 0 0 0-1.179-.418c-.44 0-.869.146-1.197.43l-5.942 5.13V3.537Zm.663.671v14.716l4.85-4.187a2.486 2.486 0 0 1 1.626-.589 2.49 2.49 0 0 1 1.601.571l5.11 4.287V4.21H5.385Z"
      clipRule="evenodd"
    />
  </svg>
    );

export default Index;

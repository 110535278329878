import React from 'react';
import { Helmet } from 'react-helmet';
import { AuthMainWrapper, RegisterForm } from 'components/Auth';

const Signup = () => (
  <>
    <Helmet>
      <title>Conversionline | Sign In</title>
      <meta
        name="description"
        content="Conversionline is a data-driven ad platform using AI to craft personalized ad templates(Image/Video) from your product data and competitor insights. Its key feature: instantly apply templates to thousands of your products, with editable results."
      />
    </Helmet>
    <AuthMainWrapper>
      <RegisterForm />
    </AuthMainWrapper>
  </>
  );

export default Signup;

import React from 'react';

const Logo = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="21"
    height="29"
    fill="none"
    viewBox="0 0 21 29"
    {...props}
  >
    <g
      stroke="#fff"
      strokeLinecap="round"
      strokeWidth="1.2"
      clipPath="url(#clip0_1560_12313)"
    >
      <path
        strokeLinejoin="round"
        d="M14.527 23.976c-1.013.337-2.098.52-3.219.52C5.503 24.503.79 19.698.79 13.772S5.503 3.048 11.308 3.048c3.757 0 7.04 2.014 8.901 5.032V.806"
      />
      <path
        strokeMiterlimit="10"
        d="M11.308 19.553c-3.125 0-5.662-2.586-5.662-5.772 0-3.187 2.537-5.773 5.662-5.773s5.661 2.586 5.661 5.773l-.014 14.233"
      />
      <path
        strokeMiterlimit="10"
        d="M14.527 6.316c2.874 1.268 4.886 4.183 4.886 7.575v10.13M3.219 13.89c0 4.557 3.52 8.249 7.867 8.249 2.349 0 4.447-1.084 5.89-2.799"
      />
    </g>
    <defs>
      <clipPath id="clip0_1560_12313">
        <path
          fill="#fff"
          d="M0 0H28.737V21H0z"
          transform="rotate(-90 14.37 14.37)"
        />
      </clipPath>
    </defs>
  </svg>
    );

export default Logo;

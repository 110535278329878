import React from 'react';
import styled, { keyframes } from 'styled-components';
import { createSearchParams, useNavigate } from 'react-router-dom';
import { Headline2, Headline4, Button } from 'components/Shared';
import KeikeiCutoutVideo from 'assets/video/KeikeiCutout.mov';
import {getRoute} from 'utils';

const WelcomePage = () => {
  const navigate = useNavigate();

  const changeStep = (stepName) => {
    navigate({
      pathname: getRoute('onboarding'),
      search: createSearchParams({
        step: stepName,
      }).toString(),
    });
  };
  return (
    <>
      <Headline2
        style={{
          marginTop: '-25px',
          zIndex: '2',
        }}
      >
        Welcome
        {' '}
        <Wave>👋</Wave>
      </Headline2>
      <Headline4
        style={{
          marginTop: '10px',
          marginBottom: '30px',
        }}
      >
        We’re thrilled to have you here.
      </Headline4>
      <Headline4
        style={{
          textAlign: 'center',
        }}
      >
        Get started with our handy guide to help you navigate through
        <br />
        the ins and outs of our beta.
      </Headline4>
      <VideoContainer>
        <video width="800" height="400" autoPlay muted>
          <source src={KeikeiCutoutVideo} type="video/mp4" />
        </video>
      </VideoContainer>
      <WelcomeFooter>
        <Button
          type="primary"
          content="Access The Beta"
          size="medium"
          handleClick={() => changeStep('create-assets')}
        />
      </WelcomeFooter>
    </>
  );
};

export default WelcomePage;

const VideoContainer = styled.div`
  width: 800px;
  height: 400px;
  border-radius: 16px;
  background: #fafafa;
  margin: 30px 0;

  video {
    border-radius: 16px;
  }
`;

const waveAnimation = keyframes`
  0% { transform: rotate( 0.0deg) }
  10% { transform: rotate(14.0deg) }  /* The following five values can be played with to make the waving more or less extreme */
  20% { transform: rotate(-8.0deg) }
  30% { transform: rotate(14.0deg) }
  40% { transform: rotate(-4.0deg) }
  50% { transform: rotate(10.0deg) }
  60% { transform: rotate( 0.0deg) }  /* Reset for the last half to pause */
  100% { transform: rotate( 0.0deg) }
`;
const Wave = styled.span`
  animation-name: ${waveAnimation};
  animation-duration: 2.5s;
  animation-iteration-count: 1;
  transform-origin: 70% 70%;
  display: inline-block;
`;
const WelcomeFooter = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 120px;
  margin-bottom: 50px;
`;

import * as React from 'react';

const OpacityIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={20}
    height={20}
    fill="none"
    {...props}
  >
    <g fill="#000" fillRule="evenodd" clipPath="url(#a)" clipRule="evenodd">
      <path d="M2.5 1.666h1.667A.833.833 0 0 1 5 2.499v1.667a.833.833 0 0 1-.833.833H2.5a.833.833 0 0 1-.833-.833V2.499a.833.833 0 0 1 .833-.833Zm0 6.667h1.667A.833.833 0 0 1 5 9.166v1.667a.833.833 0 0 1-.833.833H2.5a.833.833 0 0 1-.833-.833V9.166a.833.833 0 0 1 .833-.833Zm0 6.666h1.667a.833.833 0 0 1 .833.834v1.666a.833.833 0 0 1-.833.834H2.5a.833.833 0 0 1-.833-.834v-1.666a.833.833 0 0 1 .833-.834Z" />
      <path
        d="M9.167 1.666h1.666a.833.833 0 0 1 .834.833v1.667a.833.833 0 0 1-.834.833H9.167a.833.833 0 0 1-.834-.833V2.499a.833.833 0 0 1 .834-.833Zm0 6.667h1.666a.833.833 0 0 1 .834.833v1.667a.833.833 0 0 1-.834.833H9.167a.833.833 0 0 1-.834-.833V9.166a.833.833 0 0 1 .834-.833Zm0 6.666h1.666a.833.833 0 0 1 .834.834v1.666a.833.833 0 0 1-.834.834H9.167a.833.833 0 0 1-.834-.834v-1.666a.833.833 0 0 1 .834-.834Z"
        opacity={0.45}
      />
      <path
        d="M15.833 1.666H17.5a.833.833 0 0 1 .833.833v1.667a.834.834 0 0 1-.833.833h-1.667A.833.833 0 0 1 15 4.166V2.499a.833.833 0 0 1 .833-.833Zm0 6.667H17.5a.833.833 0 0 1 .833.833v1.667a.833.833 0 0 1-.833.833h-1.667a.833.833 0 0 1-.833-.833V9.166a.833.833 0 0 1 .833-.833Zm0 6.666H17.5a.833.833 0 0 1 .833.834v1.666a.834.834 0 0 1-.833.834h-1.667a.833.833 0 0 1-.833-.834v-1.666a.833.833 0 0 1 .833-.834Z"
        opacity={0.15}
      />
      <path
        d="M5.833 5H7.5a.833.833 0 0 1 .833.833V7.5a.833.833 0 0 1-.833.833H5.833A.833.833 0 0 1 5 7.5V5.833A.833.833 0 0 1 5.833 5Zm0 6.667H7.5a.834.834 0 0 1 .833.833v1.667A.833.833 0 0 1 7.5 15H5.833A.833.833 0 0 1 5 14.167V12.5a.833.833 0 0 1 .833-.833Z"
        opacity={0.7}
      />
      <path
        d="M12.5 5h1.667a.833.833 0 0 1 .833.833V7.5a.833.833 0 0 1-.833.833H12.5a.833.833 0 0 1-.833-.833V5.833A.834.834 0 0 1 12.5 5Zm0 6.667h1.667A.834.834 0 0 1 15 12.5v1.667a.833.833 0 0 1-.833.833H12.5a.833.833 0 0 1-.833-.833V12.5a.834.834 0 0 1 .833-.833Z"
        opacity={0.3}
      />
    </g>
    <defs>
      <clipPath id="a">
        <path fill="#fff" d="M0 0h20v20H0z" />
      </clipPath>
    </defs>
  </svg>
);
export default OpacityIcon;

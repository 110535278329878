export function calculateZoomLevel(height) {
  const targetHeight = 1000;
  const heightRatio = targetHeight / height;
  let zoomLevel = 40 * heightRatio;
  const minZoomLevel = 10; // Minimum zoom seviyesi
  const maxZoomLevel = 400;

  if (zoomLevel < minZoomLevel) {
    zoomLevel = minZoomLevel;
  } else if (zoomLevel > maxZoomLevel) {
    zoomLevel = maxZoomLevel;
  }

  return Math.floor(zoomLevel);
}

import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { toast } from 'react-toastify';
import { useMutation, useQuery } from '@tanstack/react-query';
import {
  faDroplet,
  faLink,
  faMagnifyingGlassChart,
  faSparkles,
  faText,
  faWreathLaurel,
} from '@fortawesome/pro-light-svg-icons';
import {useCookies} from 'react-cookie';
import { useAuth } from 'context/AuthContext';
import {AuthServices, DesignServices, OnboardingServices} from 'services';
import { Button, Headline3, LoadingModal } from 'components/Shared';
import { Layout } from 'components/Dashboard';
import { BrandListItem } from 'components/Onboarding';

const Brand = () => {
  const [token, set] = useAuth((store) => store.token);
  const [cookies, setCookie] = useCookies(['user']);

  const [companyData, setCompanyData] = useState(null);
  const [companyDataExtra, setCompanyDataExtra] = useState(null);

  const { data, isLoading, isFetched } = useQuery({
    queryKey: ['getBrandkit'],
    queryFn: () => DesignServices.getBrandKit(token),
  });

  useEffect(() => {
    if (companyData === null && companyDataExtra === null && data) {
      const tmpData = data.data.extra;
      if (tmpData.colors.length > 4) {
        tmpData.colors = tmpData.colors.slice(0, 4);
      }
      tmpData.url = data.data.webSite;
      setCompanyDataExtra(tmpData);
      setCompanyData(data.data);
    }
  }, [data]);

  const [selectedCompetitor, setSelectedCompetitor] = useState(null);
  const [isBrandDataSaved, setIsBrandDataSaved] = useState(false);

  const { mutate: setCompanyDataMutation, isPending: isSetCompanyDataLoading } = useMutation({
      mutationFn: (data) => OnboardingServices.editCompanyMetaData(data, token),
      onError: (error) => {
        if (error?.response?.data?.errors) {
          Object.keys(error.response.data.errors).map((key) => toast.error(error.response.data.errors[key]));
        } else {
          toast.error('Something went wrong, please try again later');
        }
      },
      onSuccess: ({ data }) => {
        setIsBrandDataSaved(true);
        toast.success('Brand Updated Successfully');
      },
    });

  const { data: meData } = useQuery({
    queryFn: () => AuthServices.me(token),
    queryKey: ['me'],
    enabled: isBrandDataSaved,
  });

  useEffect(() => {
    const data = Array.isArray(meData) ? meData[0] : meData;
    if (data) {
      if (data.userRoles[0].company.name) {
        const tmpData = {
          id: data.id,
          email: data.email,
          firstName: data.firstName,
          lastName: data.lastName,
          companyName: data.userRoles[0].company.name,
        };
        setCookie('user', JSON.stringify(tmpData));
        set({
          user: tmpData,
        });
      }
    }
  }, [meData]);

  const onSaveButtonClick = () => {
    const tmpData = { ...companyDataExtra };
    let webSite = '';
    if (selectedCompetitor) {
      tmpData.competitor = '';
    }
    webSite = tmpData.url;
    delete tmpData.url;
    const tmpCompanyData = { ...companyData };
    tmpCompanyData.webSite = webSite;
    tmpCompanyData.extra = tmpData;
    tmpCompanyData.name = tmpData.name;
    setCompanyDataMutation(tmpCompanyData);
  };

  if (isLoading || !companyData) return <Layout><LoadingModal /></Layout>;

  return (
    <Layout>
      <Container>
        <Headline3>Branding Setup</Headline3>
        <BrandListWrapper>
          <BrandListItem
            title="Brand Url"
            icon={faLink}
            inputType="text"
            data={companyDataExtra}
            setData={setCompanyDataExtra}
            isHome
          />
          <BrandListItem
            title="Brand Name"
            icon={faSparkles}
            inputType="text"
            data={companyDataExtra}
            setData={setCompanyDataExtra}
            isHome
          />
          <BrandListItem
            title="Brand Logo"
            icon={faWreathLaurel}
            inputType="image"
            data={companyDataExtra}
            setData={setCompanyDataExtra}
            isHome
          />
          <BrandListItem
            title="Brand Font"
            icon={faText}
            inputType="font"
            data={companyDataExtra}
            setData={setCompanyDataExtra}
            isHome
            bottom
          />
          <BrandListItem
            title="Brand Color"
            icon={faDroplet}
            inputType="color"
            data={companyDataExtra}
            setData={setCompanyDataExtra}
            isHome
          />
          {/* <BrandListItem
            title="Competitor"
            icon={faMagnifyingGlassChart}
            inputType="text"
            isHome
            data={companyDataExtra}
            setData={setCompanyDataExtra}
            selectedCompetitor=""
            setSelectedCompetitor={setSelectedCompetitor}
          /> */}
        </BrandListWrapper>
        <ButtonContainer>
          <Button
            type="primary"
            size="small"
            content="Save Changes"
            handleClick={onSaveButtonClick}
            loading={isSetCompanyDataLoading}
          />
        </ButtonContainer>
      </Container>
    </Layout>
  );
};

const Container = styled.div`
  width: 80%;
  margin: 41px auto;
`;
const BrandListWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
  margin-top: 30px;
  column-gap: 78px;

  > div {
    
    margin-bottom: 15px;
  }
`;
const ButtonContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: 15px;
`;
export default Brand;

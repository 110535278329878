import * as React from 'react';

const TextBoxIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={51}
    height={48}
    fill="none"
    {...props}
  >
    <rect width={51} height={48} fill="#E4E4E4" rx={4} />
    <path
      fill="#000"
      d="M27.234 7.664A1.872 1.872 0 0 0 25.5 6.5c-.766 0-1.445.46-1.734 1.164L11.484 37.75H9.875A1.87 1.87 0 0 0 8 39.625 1.87 1.87 0 0 0 9.875 41.5h8.75a1.87 1.87 0 0 0 1.875-1.875 1.87 1.87 0 0 0-1.875-1.875h-3.086l2.555-6.25h14.82l2.555 6.25h-3.094a1.87 1.87 0 0 0-1.875 1.875 1.87 1.87 0 0 0 1.875 1.875h8.75A1.87 1.87 0 0 0 43 39.625a1.87 1.87 0 0 0-1.875-1.875h-1.61L27.236 7.664Zm4.149 20.086H19.617L25.5 13.336l5.883 14.414Z"
    />
  </svg>
);
export default TextBoxIcon;

import React, { useRef, useState } from 'react';
import styled from 'styled-components';
import { useClickAway } from 'react-use';
import { useLocation, useNavigate } from 'react-router-dom';
import { useCookies } from 'react-cookie';
import { useAuth } from 'context/AuthContext';
import { Caption1, Subtitle2, Subtitle3 } from 'components/Shared';
import { getRoute } from 'utils';

const Index = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const [cookies, setCookie, removeCookie] = useCookies([
    'userToken',
    'refreshToken',
    'user',
  ]);
  const [user, set] = useAuth((store) => store.user);

  const profileContainerRef = useRef(null);
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  useClickAway(profileContainerRef, () => {
    setIsMenuOpen(false);
  });

  const onLogout = () => {
    removeCookie('userToken');
    removeCookie('refreshToken');
    removeCookie('user');
    set({
      user: null,
      token: null,
      refreshToken: null,
    });
  };

  if (user && user?.companyName) {
    return (
      <Container ref={profileContainerRef}>
        <Profile onClick={() => setIsMenuOpen(true)}>
          <Subtitle2>{user.companyName}</Subtitle2>
        </Profile>
        {isMenuOpen && (
          <Menu>
            <Top>
              <Subtitle3>{user.companyName}</Subtitle3>
            </Top>
            <Wrapper>
              <Option
                active={location.pathname === getRoute('profile')}
                onClick={() => navigate(getRoute('profile'))}
              >
                <Caption1>Personal Information</Caption1>
              </Option>
              <Option onClick={onLogout}>
                <Caption1>Logout</Caption1>
              </Option>
            </Wrapper>
          </Menu>
        )}
      </Container>
    );
  }
};

const Container = styled.div`
  position: relative;
  z-index: 9;
`;
const Profile = styled.div`
  padding: 7px 12px;
  border-radius: 8px;
  border: 1px solid rgba(103, 94, 224, 0.2);
  background: #fafafa;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;

const Menu = styled.div`
  position: absolute;
  top: 45px;
  right: 0;
  width: 194px;
  border-radius: 8px;
  border: 1px solid rgba(103, 94, 224, 0.2);
  background: #fafafa;
  box-shadow: 0 4px 8px 0 rgba(91, 204, 124, 0.16);
`;
const Top = styled.div`
  padding: 10px;
  border-bottom: 1px solid rgba(103, 94, 224, 0.2);
`;
const Wrapper = styled.div`
  padding: 17px 6px;
`;
const Option = styled.div`
  padding: 5px 7px;
  border-radius: 8px;
  background: ${(props) => (props.active ? '#F0EFFC' : 'transparent')};
  border: ${(props) => (props.active ? '1px solid #675EE0' : '1px solid transparent')};
  cursor: pointer;

  :hover {
    background: #f0effc;
  }
`;
export default Index;

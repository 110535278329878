import React, { useState } from 'react';
import Slider from 'rc-slider';
import styled from 'styled-components';
import { useApp } from 'context/AppContext';
import { ColorPicker } from 'components/Design';

import 'rc-slider/assets/index.css';

const TextShadowOptions = () => {
  const { changeShape } = useApp();
  const [shadowOffset, setShadowOffset] = useState(0);
  const [offsetVertical, setOffsetVertical] = useState(0);
  const [shadowBlur, setShadowBlur] = useState(0);
  const [transparency, setTransparency] = useState(0);
  const [shadowColor, setShadowColor] = useState('#000000');

  const onShadowOffsetChange = (val) => {
    if (val === 0) {
      return;
    }
    changeShape({ shadowOffsetX: val });
    setShadowOffset(val);
  };

  const onOffsetVerticalChange = (val) => {
    if (val === 0) {
      return;
    }
    changeShape({ shadowOffsetY: val });
    setOffsetVertical(val);
  };

  const onBlurChange = (val) => {
    if (val === 0) {
      return;
    }
    changeShape({ shadowBlur: val });
    setShadowBlur(val);
  };

  const onTransparencyChange = (val) => {
    if (val === 100) {
      return;
    }

    changeShape({ shadowOpacity: val / 100 });
    setTransparency(val);
  };

  const onColorChange = (val) => {
    changeShape({ shadowColor: val });
    setShadowColor(val);
  };

  return (
    <>
      <Wrapper>
        <RowView>
          <span>Shadow Horizontal</span>
          <span>{shadowOffset}</span>
        </RowView>
        <Slider
          onChange={onShadowOffsetChange}
          min={-90}
          max={90}
          value={shadowOffset}
          defaultValue={0}
        />
      </Wrapper>
      <Wrapper>
        <RowView>
          <span>Blur</span>
          <span>{shadowBlur}</span>
        </RowView>

        <Slider
          onChange={onBlurChange}
          min={-100}
          max={100}
          value={shadowBlur}
          defaultValue={0}
        />
      </Wrapper>
      <Wrapper>
        <RowView>
          <span>OffSet Vertical</span>
          <span>{offsetVertical}</span>
        </RowView>

        <Slider
          onChange={onOffsetVerticalChange}
          min={-100}
          max={100}
          value={offsetVertical}
          defaultValue={0}
        />
      </Wrapper>
      <Wrapper>
        <RowView>
          <span>transparency</span>
          <span>{transparency}</span>
        </RowView>

        <Slider
          onChange={onTransparencyChange}
          min={0}
          max={100}
          value={transparency}
          defaultValue={100}
        />
      </Wrapper>
      <ColorPicker color={shadowColor} setColor={onColorChange} />
    </>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 20px;
`;

const RowView = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 10px;
`;

export default TextShadowOptions;

import React from 'react';

const Index = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="19"
    height="19"
    fill="none"
    viewBox="0 0 19 19"
    {...props}
  >
    <path
      stroke="#fff"
      strokeLinejoin="round"
      strokeWidth="1.15"
      d="M17.864 7.362L11.68 1.166v3.559C5.944 4.749 1.29 9.403 1.29 15.15c0 .983.15 1.929.398 2.825 1.232-4.38 5.226-7.59 9.992-7.603v3.173l6.184-6.196v.012z"
    />
  </svg>
    );

export default Index;

import React, { useRef } from 'react';
import styled from 'styled-components';
import { useHoverDirty } from 'react-use';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { NextFeature, Subtitle2 } from 'components/Shared';

const ControlButton = ({
  title,
  icon,
  selected,
  onButtonClick,
  nextFeature,
}) => {
  const ref = useRef();
  const isHovering = useHoverDirty(ref);
  return (
    <Button selected={selected} onClick={onButtonClick} ref={ref}>
      {isHovering && nextFeature && (
        <NextFeature
          pos={{
            x: -18,
            y: -34,
          }}
        />
      )}
      <FontAwesomeIcon icon={icon} size="2x" />
      <Subtitle2 selected={selected}>{title}</Subtitle2>
    </Button>
  );
};
const Button = styled.button`
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 8px;
  font-size: 10px;
  font-weight: 600;
  width: 100%;
  justify-content: center;
  line-height: 14px;
  text-align: center;
  padding: 20px 9px 15px;
  border: none;
  background: transparent;
  border-radius: 8px;
  cursor: pointer;
  position: relative;
  outline: none;
  margin-top: 5px;
  ::after {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: calc(100% - 16px);
    height: 100%;
    border-radius: 8px;
    background: ${(props) => (props.selected ? '#F0EFFC' : 'transparent')};
    z-index: 1;
    transition: all 0.1s ease-in-out;
  }
  svg,
  h4 {
    z-index: 2;
  }
  :hover {
    ::after {
      background-color: #f2f4f8;
    }
  }
`;

export default ControlButton;

import React, {useEffect, useState} from 'react';
import styled from 'styled-components';
import { Headline2 } from 'components/Shared';
import {useAuth} from '../../../../context/AuthContext';

const Index = ({ base64, width, height }) => {
    const [isVertical, setIsVertical] = useState(height > width);
    const [user] = useAuth((store) => store.user);

    useEffect(() => {
        setIsVertical(height > width);
    }, [width, height]);

    return (
      <Container>
        <Top>
          <ProfilePicture />
          <Text>
            {user?.companyName || 'Brand Name'}
          </Text>
        </Top>
        <Content isVertical={isVertical}>
          <img
            src={`data:image/jpeg;base64,${base64}`}
            alt="facebook-post"
            style={{
                height: `${isVertical ? '100%' : 'auto'}`,
                width: `${isVertical ? 'auto' : '100%'}`,
                maxHeight: '295px',
                maxWidth: '500px',
          }}
          />
        </Content>
        <Bottom>
          <div>
            <BrandName>
              Brand name
            </BrandName>
            <ShopNow>
              Shop Now
            </ShopNow>
          </div>
          <LearnMoreButton>
            Learn More
          </LearnMoreButton>
        </Bottom>
      </Container>
    );
};

const Container = styled.div`
  width: 500px;
  height: 416px;
  background: black;
  display: flex;
  flex-direction: column;
`;
const Top = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 10px 16px 8px;
`;
const ProfilePicture = styled.div`
  width: 36px;
  height: 36px;
  border-radius: 50%;
  background: #fff;
`;
const Text = styled(Headline2)`
  color: #FFF;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 12px; /* 100% */
  letter-spacing: -0.165px;
  flex: 1;
  margin-left: 10px;
`;
const Content = styled.div`
  flex: 1;
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
`;
const Bottom = styled.div`
  padding: 10px 20px 30px;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
`;
const BrandName = styled(Headline2)`
  color: #FFF;
  font-family: Inter;
  font-size: 8.72px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`;
const ShopNow = styled(Headline2)`
  color: #FFF;
  font-family: Inter;
  font-size: 10.02px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-top: 2px;
`;
const LearnMoreButton = styled.div`
  width: 85px;
  height: 26px;
  border-radius: 6px;
  background: #FFF;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #000;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`;

export default Index;
